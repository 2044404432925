<template lang="pug">
.modal-verification
  .modal-verification__image
    InlineSvg(:src="require('~/assets/img/icons/call.svg')")
  h3 {{ title }}

  .modal-verification__form

    //DatePicker(
    //  v-bind="fields.birthday"
    //  v-model="fields.birthday.value"
    //  @blur="onFieldBlur(fields.birthday)"
    //  @input="onFieldInput(fields.birthday)"
    //)

    ModelSelect(v-model="countryPhoneStart" :options="countryPhoneStartOptions")

    FormInput(
      v-bind="fields.phone"
      v-model="fields.phone.value"
      :startMarker="'+' + countryPhoneStart"
      @blur="onFieldBlur(fields.phone)"
      @input="onFieldInput(fields.phone)"
    )

    CheckboxInput.modal-verification__checkbox(v-if="show18" v-model="check18")
      span(v-t="'check18text'")

  Button(is-primary :is-disabled="isDisabled()" @click="submit") {{ $t('confirmPhoneNumber') }}

  .modal-verification__caption {{ $t('weDontSellData') }}

</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
import { mapActions, mapState } from 'vuex'
import { isEuMember } from 'is-eu-member'
// eslint-disable-next-line import/no-named-as-default
import parsePhoneNumber from 'libphonenumber-js'
import DatePicker from '~/components/DatePicker/DatePicker'
import FormInput from '~/components/FormInput/FormInput'
import CheckboxInput from '~/components/CheckboxInput/CheckboxInput'
import BaseSelect from '~/components/BaseSelect/BaseSelect'

export default {
  name: 'ModalVerification',

  components: {
    DatePicker,
    FormInput,
    BaseSelect,
    CheckboxInput,
    ModelSelect
  },

  props: {
    title: {
      type: String,
      required: true
    },

    show18: {
      type: Boolean,
      default: true
    },

    onSuccess: {
      type: Function,
      default: () => {}
    }
  },

  data: () => {
    return {
      countryPhoneStart: '7',
      check18: false
    }
  },

  computed: {
    ...mapState('user', ['verification']),

    countryPhoneStartOptions () {
      const items = [
        {
          text: this.$t('australia'),
          value: '61'
        },
        {
          text: this.$t('austria'),
          value: '43'
        },
        {
          text: this.$t('azerbaijan'),
          value: '994'
        },
        {
          text: this.$t('albania'),
          value: '355'
        },
        {
          text: this.$t('algeria'),
          value: '213'
        },
        {
          text: this.$t('americanSamoa'),
          value: '685'
        },
        {
          text: this.$t('usa'),
          value: '1'
        },
        {
          text: this.$t('anguilla'),
          value: '1264'
        },
        {
          text: this.$t('angola'),
          value: '244'
        },
        {
          text: this.$t('andorra'),
          value: '376'
        },
        {
          text: this.$t('antiguaAndBarbuda'),
          value: '1268'
        },
        {
          text: this.$t('argentina'),
          value: '54'
        },
        {
          text: this.$t('armenia'),
          value: '374'
        },
        {
          text: this.$t('aruba'),
          value: '297'
        },
        {
          text: this.$t('afghanistan'),
          value: '93'
        },
        {
          text: this.$t('bahamas'),
          value: '1242'
        },
        {
          text: this.$t('bangladesh'),
          value: '880'
        },
        {
          text: this.$t('barbados'),
          value: '1246'
        },
        {
          text: this.$t('bahrain'),
          value: '973'
        },
        {
          text: this.$t('belize'),
          value: '501'
        },
        {
          text: this.$t('belarus'),
          value: '375'
        },
        {
          text: this.$t('belgium'),
          value: '32'
        },
        {
          text: this.$t('benin'),
          value: '229'
        },
        {
          text: this.$t('bermuda'),
          value: '1441'
        },
        {
          text: this.$t('bulgaria'),
          value: '359'
        },
        {
          text: this.$t('bolivia'),
          value: '591'
        },
        {
          text: this.$t('bosniaAndHerzegovina'),
          value: '387'
        },
        {
          text: this.$t('botswana'),
          value: '267'
        },
        {
          text: this.$t('brazil'),
          value: '55'
        },
        {
          text: this.$t('britishVirginIslands'),
          value: '1284'
        },
        {
          text: this.$t('brunei'),
          value: '673'
        },
        {
          text: this.$t('burkinaFaso'),
          value: '226'
        },
        {
          text: this.$t('burundi'),
          value: '257'
        },
        {
          text: this.$t('bhutan'),
          value: '975'
        },
        {
          text: this.$t('vanuatu'),
          value: '678'
        },
        {
          text: this.$t('vatican'),
          value: '39'
        },
        {
          text: this.$t('uk'),
          value: '44'
        },
        {
          text: this.$t('hungary'),
          value: '36'
        },
        {
          text: this.$t('venezuela'),
          value: '58'
        },
        {
          text: this.$t('virginIslands'),
          value: '1340'
        },
        {
          text: this.$t('vietnam'),
          value: '84'
        },
        {
          text: this.$t('gabon'),
          value: '241'
        },
        {
          text: this.$t('haiti'),
          value: '509'
        },
        {
          text: this.$t('guyana'),
          value: '592'
        },
        {
          text: this.$t('gambia'),
          value: '220'
        },
        {
          text: this.$t('ghana'),
          value: '233'
        },
        {
          text: this.$t('guadeloupe'),
          value: '590'
        },
        {
          text: this.$t('guatemala'),
          value: '502'
        },
        {
          text: this.$t('guineaBissau'),
          value: '245'
        },
        {
          text: this.$t('guinea'),
          value: '224'
        },
        {
          text: this.$t('germany'),
          value: '49'
        },
        {
          text: this.$t('gibraltar'),
          value: '350'
        },
        {
          text: this.$t('netherlands'),
          value: '31'
        },
        {
          text: this.$t('honduras'),
          value: '504'
        },
        {
          text: this.$t('hongKong'),
          value: '852'
        },
        {
          text: this.$t('grenada'),
          value: '1473'
        },
        {
          text: this.$t('greenland'),
          value: '299'
        },
        {
          text: this.$t('greece'),
          value: '30'
        },
        {
          text: this.$t('georgia'),
          value: '995'
        },
        {
          text: this.$t('guam'),
          value: '1671'
        },
        {
          text: this.$t('congo'),
          value: '243'
        },
        {
          text: this.$t('djibouti'),
          value: '253'
        },
        {
          text: this.$t('dominicanRepublic'),
          value: '1829'
        },
        {
          text: this.$t('dominica'),
          value: '1767'
        },
        {
          text: this.$t('egypt'),
          value: '20'
        },
        {
          text: this.$t('zambia'),
          value: '260'
        },
        {
          text: this.$t('zimbabwe'),
          value: '263'
        },
        {
          text: this.$t('israel'),
          value: '972'
        },
        {
          text: this.$t('india'),
          value: '91'
        },
        {
          text: this.$t('indonesia'),
          value: '62'
        },
        {
          text: this.$t('jordan'),
          value: '962'
        },
        {
          text: this.$t('iraq'),
          value: '964'
        },
        {
          text: this.$t('iran'),
          value: '98'
        },
        {
          text: this.$t('ireland'),
          value: '353'
        },
        {
          text: this.$t('iceland'),
          value: '354'
        },
        {
          text: this.$t('spain'),
          value: '34'
        },
        {
          text: this.$t('italy'),
          value: '39'
        },
        {
          text: this.$t('yemen'),
          value: '967'
        },
        {
          text: this.$t('kazakhstan'),
          value: '7 '
        },
        {
          text: this.$t('caymanIslands'),
          value: '1345'
        },
        {
          text: this.$t('cambodia'),
          value: '855'
        },
        {
          text: this.$t('cameroon'),
          value: '237'
        },
        {
          text: this.$t('canada'),
          value: '1 '
        },
        {
          text: this.$t('qatar'),
          value: '974'
        },
        {
          text: this.$t('kenya'),
          value: '254'
        },
        {
          text: this.$t('cyprus'),
          value: '357'
        },
        {
          text: this.$t('kiribati'),
          value: '686'
        },
        {
          text: this.$t('china'),
          value: '86'
        },
        {
          text: this.$t('colombia'),
          value: '57'
        },
        {
          text: this.$t('comoros'),
          value: '269'
        },
        {
          text: this.$t('republicOfTheCongo'),
          value: '242'
        },
        {
          text: this.$t('kosovo'),
          value: '381'
        },
        {
          text: this.$t('costaRica'),
          value: '506'
        },
        {
          text: this.$t('coteDIvoire'),
          value: '225'
        },
        {
          text: this.$t('cuba'),
          value: '53'
        },
        {
          text: this.$t('kuwait'),
          value: '965'
        },
        {
          text: this.$t('kyrgyzstan'),
          value: '996'
        },
        {
          text: this.$t('laos'),
          value: '856'
        },
        {
          text: this.$t('latvia'),
          value: '371'
        },
        {
          text: this.$t('lesotho'),
          value: '266'
        },
        {
          text: this.$t('liberia'),
          value: '231'
        },
        {
          text: this.$t('lebanon'),
          value: '961'
        },
        {
          text: this.$t('libya'),
          value: '218'
        },
        {
          text: this.$t('lithuania'),
          value: '370'
        },
        {
          text: this.$t('liechtenstein'),
          value: '423'
        },
        {
          text: this.$t('luxembourg'),
          value: '352'
        },
        {
          text: this.$t('mauritius'),
          value: '230'
        },
        {
          text: this.$t('mauritania'),
          value: '222'
        },
        {
          text: this.$t('madagascar'),
          value: '261'
        },
        {
          text: this.$t('macau'),
          value: '853'
        },
        {
          text: this.$t('malawi'),
          value: '265'
        },
        {
          text: this.$t('malaysia'),
          value: '60'
        },
        {
          text: this.$t('mali'),
          value: '223'
        },
        {
          text: this.$t('maldives'),
          value: '960'
        },
        {
          text: this.$t('malta'),
          value: '356'
        },
        {
          text: this.$t('morocco'),
          value: '212'
        },
        {
          text: this.$t('martinique'),
          value: '596'
        },
        {
          text: this.$t('marshallIslands'),
          value: '692'
        },
        {
          text: this.$t('mexico'),
          value: '52'
        },
        {
          text: this.$t('micronesia'),
          value: '691'
        },
        {
          text: this.$t('mozambique'),
          value: '258'
        },
        {
          text: this.$t('moldova'),
          value: '373'
        },
        {
          text: this.$t('monaco'),
          value: '377'
        },
        {
          text: this.$t('mongolia'),
          value: '976'
        },
        {
          text: this.$t('montserrat'),
          value: '1664'
        },
        {
          text: this.$t('burma'),
          value: '95'
        },
        {
          text: this.$t('namibia'),
          value: '264'
        },
        {
          text: this.$t('nauru'),
          value: '674'
        },
        {
          text: this.$t('nepal'),
          value: '977'
        },
        {
          text: this.$t('nigeria'),
          value: '234'
        },
        {
          text: this.$t('niger'),
          value: '227'
        },
        {
          text: this.$t('nicaragua'),
          value: '505'
        },
        {
          text: this.$t('niue'),
          value: '683'
        },
        {
          text: this.$t('newZealand'),
          value: '64'
        },
        {
          text: this.$t('newCaledonia'),
          value: '687'
        },
        {
          text: this.$t('norway'),
          value: '47'
        },
        {
          text: this.$t('unitedArabEmirates'),
          value: '971'
        },
        {
          text: this.$t('oman'),
          value: '968'
        },
        {
          text: this.$t('cookIslands'),
          value: '682'
        },
        {
          text: this.$t('turksAndCaicosIslands'),
          value: '1649'
        },
        {
          text: this.$t('norfolkIsland'),
          value: '672'
        },
        {
          text: this.$t('pakistan'),
          value: '92'
        },
        {
          text: this.$t('palau'),
          value: '680'
        },
        {
          text: this.$t('panama'),
          value: '507'
        },
        {
          text: this.$t('papuaNewGuinea'),
          value: '675'
        },
        {
          text: this.$t('paraguay'),
          value: '595'
        },
        {
          text: this.$t('peru'),
          value: '51'
        },
        {
          text: this.$t('poland'),
          value: '48'
        },
        {
          text: this.$t('portugal'),
          value: '351'
        },
        {
          text: this.$t('puertoRico'),
          value: '1787'
        },
        {
          text: this.$t('macedonia'),
          value: '389'
        },
        {
          text: this.$t('reunion'),
          value: '262'
        },
        {
          text: this.$t('russia'),
          value: '7'
        },
        {
          text: this.$t('rwanda'),
          value: '250'
        },
        {
          text: this.$t('romania'),
          value: '40'
        },
        {
          text: this.$t('samoa'),
          value: '685'
        },
        {
          text: this.$t('sanMarino'),
          value: '378'
        },
        {
          text: this.$t('saoTomeAndPrincipe'),
          value: '239'
        },
        {
          text: this.$t('saudiArabia'),
          value: '966'
        },
        {
          text: this.$t('swaziland'),
          value: '268'
        },
        {
          text: this.$t('northKorea'),
          value: '850'
        },
        {
          text: this.$t('northernMarianaIslands'),
          value: '1670'
        },
        {
          text: this.$t('seychelles'),
          value: '248'
        },
        {
          text: this.$t('senegal'),
          value: '221'
        },
        {
          text: this.$t('saintVincentAndTheGrenadines'),
          value: '1784'
        },
        {
          text: this.$t('saintKittsAndNevis'),
          value: '1869'
        },
        {
          text: this.$t('saintLucia'),
          value: '1758'
        },
        {
          text: this.$t('serbia'),
          value: '381'
        },
        {
          text: this.$t('singapore'),
          value: '65'
        },
        {
          text: this.$t('syria'),
          value: '963'
        },
        {
          text: this.$t('slovakia'),
          value: '421'
        },
        {
          text: this.$t('slovenia'),
          value: '386'
        },
        {
          text: this.$t('solomonIslands'),
          value: '677'
        },
        {
          text: this.$t('somalia'),
          value: '252'
        },
        {
          text: this.$t('sudan'),
          value: '249'
        },
        {
          text: this.$t('suriname'),
          value: '597'
        },
        {
          text: this.$t('sierraLeone'),
          value: '232'
        },
        {
          text: this.$t('tajikistan'),
          value: '992'
        },
        {
          text: this.$t('thailand'),
          value: '66'
        },
        {
          text: this.$t('taiwan'),
          value: '886'
        },
        {
          text: this.$t('tanzania'),
          value: '255'
        },
        {
          text: this.$t('timorLeste'),
          value: '670'
        },
        {
          text: this.$t('togo'),
          value: '228'
        },
        {
          text: this.$t('tokelau'),
          value: '690'
        },
        {
          text: this.$t('tonga'),
          value: '676'
        },
        {
          text: this.$t('trinidadAndTobago'),
          value: '1868'
        },
        {
          text: this.$t('tuvalu'),
          value: '688'
        },
        {
          text: this.$t('tunisia'),
          value: '216'
        },
        {
          text: this.$t('turkmenistan'),
          value: '993'
        },
        {
          text: this.$t('turkey'),
          value: '90'
        },
        {
          text: this.$t('uganda'),
          value: '256'
        },
        {
          text: this.$t('uzbekistan'),
          value: '998'
        },
        {
          text: this.$t('ukraine'),
          value: '380'
        },
        {
          text: this.$t('uruguay'),
          value: '598'
        },
        {
          text: this.$t('faroeIslands'),
          value: '298'
        },
        {
          text: this.$t('fiji'),
          value: '679'
        },
        {
          text: this.$t('philippines'),
          value: '63'
        },
        {
          text: this.$t('finland'),
          value: '358'
        },
        {
          text: this.$t('falklandIslands'),
          value: '500'
        },
        {
          text: this.$t('france'),
          value: '33'
        },
        {
          text: this.$t('frenchGuiana'),
          value: '594'
        },
        {
          text: this.$t('frenchPolynesia'),
          value: '689'
        },
        {
          text: this.$t('croatia'),
          value: '385'
        },
        {
          text: this.$t('centralAfricanRepublic'),
          value: '236'
        },
        {
          text: this.$t('chad'),
          value: '235'
        },
        {
          text: this.$t('montenegro'),
          value: '382'
        },
        {
          text: this.$t('czechRepublic'),
          value: '420'
        },
        {
          text: this.$t('chile'),
          value: '56'
        },
        {
          text: this.$t('switzerland'),
          value: '41'
        },
        {
          text: this.$t('sweden'),
          value: '46'
        },
        {
          text: this.$t('sriLanka'),
          value: '94'
        },
        {
          text: this.$t('ecuador'),
          value: '593'
        },
        {
          text: this.$t('equatorialGuinea'),
          value: '240'
        },
        {
          text: this.$t('elSalvador'),
          value: '503'
        },
        {
          text: this.$t('eritrea'),
          value: '291'
        },
        {
          text: this.$t('estonia'),
          value: '372'
        },
        {
          text: this.$t('ethiopia'),
          value: '251'
        },
        {
          text: this.$t('southAfrica'),
          value: '27'
        },
        {
          text: this.$t('southKorea'),
          value: '82'
        },
        {
          text: this.$t('jamaica'),
          value: '1876'
        },
        {
          text: this.$t('japan'),
          value: '81'
        }
      ]
      items.sort((a, b) => {
        return a.text.localeCompare(b.text)
      })
      return items
    },

    fields () {
      return {
        // birthday: {
        //   name: 'birthday',
        //   type: 'datepicker',
        //   title: this.$t('birthday'),
        //   placeholder: this.$t('birthdayPlaceholder'),
        //   mask: '##.##.####',
        //   value: '',
        //   error: '',
        //   isRequired: true,
        // },

        phone: {
          name: 'phone',
          type: 'text',
          title: this.$t('phoneNumber'),
          mask: '##############',
          placeholder: '9991112233',
          value: '',
          error: '',
          isRequired: true
        }
      }
    }
  },

  mounted () {
    if (this.$i18n.locale !== 'ru') {
      this.countryPhoneStart = '44'
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    isDisabled () {
      return (
        Object.values(this.fields).some((field) => {
          return !field.value || field?.error
        }) || (this.show18 && !this.check18)
      )
    },

    submit () {
      Object.values(this.fields).forEach(field => this.onFieldBlur(field))

      if (this.isDisabled()) {
        return
      }

      const phone =
        `${this.countryPhoneStart}${this.fields.phone.value}`.replace(
          /[^\d]/g,
          ''
        )

      this.showModal({
        component: 'ModalPhoneConfirmation',
        data: {
          phone,
          onSuccess: async () => {
            try {
              await this.sendVerification()
            } catch (e) {
              this.$errors.handle(e)
            }
          }
        }
        // canClose: false,
      })
    },

    async sendVerification () {
      this.$nuxt.$loading.start()

      try {
        const phone = `+${this.countryPhoneStart}${this.fields.phone.value}`
        const parsedPhone = parsePhoneNumber(phone)
        const data = {
          phone: phone.replace(/[^\d]/g, ''),
          country: parsedPhone?.country,
          birthday: '01.01.2000'
        }

        if (isEuMember(parsedPhone?.country)) {
          this.onSuccess({ ...this.verification, ...data })
          return
        }

        await this.$axios.post('/user/account/account-verification', data)
        await this.$store.dispatch('user/fetchVerification', { force: true })
        this.onSuccess()
      } catch (e) {
        console.error('modal verification save', e)
        this.$errors.handle(e)
        throw e
      }

      this.$nuxt.$loading.finish()
    },

    onFieldBlur (field) {
      if (field.name === 'birthday' && field.value) {
        const date = this.$dateFns.parse(field.value, 'dd.MM.yyyy', new Date())

        if (
          field.value.length < 10 ||
          isNaN(date.getTime()) ||
          date > new Date()
        ) {
          this.fields[field.name].error = this.$t('incorrectDate')
          this.$set(this.fields[field.name], 'error', this.$t('incorrectDate'))
        }
      } else if (field && field.isRequired && !field.value) {
        this.fields[field.name].error = this.$t('fieldRequired')
        this.$set(this.fields[field.name], 'error', this.$t('fieldRequired'))
      }

      this.$forceUpdate()
    },

    onFieldInput ({ name }) {
      this.$set(this.fields[name], 'error', '')
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.ui.selection.dropdown {
  background-color: $blue-grey60;
  color: $white;
}

.search {
  color: $white !important;
}

ui.serach.dropdown.active > input {
  color: $white;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  background-color: $blue-grey60;
  border-color: transparent;

  &:hover {
    border-color: transparent;
  }
}

.ui.selection.active.dropdown:hover .menu {
  border-color: transparent;
}

.ui.selection.dropdown:focus,
.ui.selection.dropdown:focus .menu {
  border-color: transparent;
}

.ui.selection.dropdown .menu>.item {
  border-top: none;
  color: $white;
}

.modal.modal--modalverification {
  padding: 56px 24px 40px;
  max-width: 504px;
}

.modal-verification {
  &__caption {
    font-size: 14px;
    color: $blue-grey10;
  }

  .form-input {
    margin-top: 16px;

    &.--error {
      padding-bottom: 22px;
    }
  }

  .base-select {
    margin-top: 16px;

    &.--error {
      padding-bottom: 22px;
    }
  }

  .button {
    width: 100%;
    margin: 16px 0 40px;
  }

  h3 {
    @include heading3;

    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  }

  &__image {
    display: flex;
    justify-content: center;

    svg {
      margin-bottom: 12px;
      background: $primary60-20;
      border-radius: 50%;
    }
  }

  &__checkbox {
    margin-top: 20px;
    margin-left: 6px;
    margin-bottom: 20px;
  }
}
</style>
