import numeral from 'numeral'
import { mapActions, mapGetters, mapState } from 'vuex'
import { DUEL_STATUSES } from '~/utils/constants'
import {
  getDateText,
  getPrizePool,
  isUserAdult as isUserAdultUtil,
  isPhoneAndBirthdateNeeded,
  isFullContactsDataNeeded,
  notify
} from '~/utils/utils'

export default {
  computed: {
    ...mapState('games', ['gamesById']),
    ...mapState('user', ['user', 'verification']),
    ...mapGetters('user', ['gameAccount']),
    ...mapGetters('duels', ['notifiedDuels']),

    commission () {
      const total = this.entryFee

      return ((total / 100) * 10).toFixed(2)
    },

    entryFeeMinusCommission () {
      const total = this.entryFee

      return (total - this.commission).toFixed(2)
    },

    entryFee () {
      if (this.isPaid) {
        return (this.duel.entry_fee / 100).toFixed(2)
      }

      return this.duel.entry_fee
    },

    prizePool () {
      return getPrizePool(this.duel)
    },

    webcamLink () {
      return this.duel?.extra_data?.webcam?.link
    },

    game () {
      return this.gamesById[this.duel.game_id]
    },

    duelStatusText () {
      return this.$t(DUEL_STATUSES[this.duel.state])
    },

    parsedDate () {
      const date = this.duel.date.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    startsAt () {
      const date = this.duel.created_at.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    hasAccess () {
      return (
        this.user?.id &&
        [this.duel.account_id, this.duel.opponent_id].includes(this.user.id)
      )
    },

    isCounterVisible () {
      if (
        !this.duel.date ||
        ['cancelled', 'completed'].includes(this.duel.state)
      ) {
        return false
      }

      return (
        this.parsedDate > new Date() &&
        this.$dateFns.differenceInDays(this.parsedDate, new Date()) < 1
      )
    },

    timeText () {
      const date = this.parsedDate

      return this.$dateFns.format(date, 'HH:mm', {
        locale: this.$i18n.locale
      })
    },

    dateText () {
      return getDateText.call(this, this.parsedDate)
    },

    isGameButtonVisible () {
      if (this.game.name === 'lichess') {
        return this.duel.extra_data.game?.id
      }

      if (this.game.name === 'clash-royale') {
        return this.duel.extra_data.config?.creator_link && this.hasAccess
      }

      return false
    },

    currentGameAccount () {
      return this.gameAccount(this.game.name)
    },

    isPaid () {
      return this.duel.currency === 'money'
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('duels', ['setNotifiedDuel']),

    getRatingText () {
      const min = this.duel.min_rating
      const max = this.duel.max_rating

      if (!min && !max) {
        return this.$t('ratingAny')
      }

      if (!min) {
        return this.$t('ratingBefore', { rating: this.formatPrice(max) })
      }

      if (!max) {
        return this.$t('ratingAfter', { rating: this.formatPrice(min) })
      }

      return `${this.formatPrice(min)} — ${this.formatPrice(max)}`
    },

    formatPrice (number) {
      return numeral(number).format('0,0').replace(/,/g, ' ')
    },

    isUserAdult () {
      return isUserAdultUtil(this.verification, this.$dateFns)
    },

    isContactsDataNeeded () {
      return (
        isFullContactsDataNeeded(this.verification) ||
        isPhoneAndBirthdateNeeded(this.verification)
      )
    },

    onEnterEventClick () {
      if (this.game.name === 'lichess') {
        window.open('https://lichess.org/' + this.duel.extra_data.game.id)
      } else if (this.game.name === 'clash-royale') {
        window.open(this.duel.extra_data?.config?.creator_link)
      } else {
        window.open(
          'https://link.brawlstars.com/invite/gameroom/ru?tag=' +
            this.duel.extra_data?.config?.lobby_data
        )
      }
    },

    onWebcamClick () {
      window.open(this.webcamLink)
    },

    updateDuel (newDuel) {
      if (newDuel.state === 'cancelled') {
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('duelStateCancelled'),
            type: 'info',
            text: newDuel.cancel_reason || this.$t('errorMessageDefault')
          }
        })
      } else if (newDuel.state === 'ready') {
        this.confirmOpponent(newDuel)
      } else if (
        newDuel.state === 'in_progress' &&
        // this.duel.state !== 'in_progress' &&
        this.hasAccess
      ) {
        this.notifyDuelPlayers(newDuel)

        if (
          this.game.name !== 'pubg-mobile' &&
          this.game.name !== 'free-fire'
        ) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('duelStarted'),
              type: 'info',
              text: this.$t('duelStarted'),
              buttons: [
                this.webcamLink && {
                  component: 'VideoChatButton',
                  props: { isDuel: true },
                  title: this.$t('videoLaunch'),
                  action: this.onWebcamClick
                },
                {
                  props: { isPrimary: true },
                  title: this.$t('goToGame'),
                  action: this.onEnterEventClick
                }
              ]
            }
          })
        }
      } else if (newDuel.state === 'completed' && this.hasAccess) {
        if (!newDuel.winner_id) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('duelStateCompleted'),
              type: 'info',
              text: this.$t('duelDrawText')
            }
          })
        } else if (newDuel.winner_id === this.user.id) {
          const currency = {
            coins: this.$t('fromCoins'),
            money: 'G-Cash'
          }

          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('duelStateCompleted'),
              type: 'info',
              text: this.$t('duelVictoryText', {
                count: getPrizePool(this.duel),
                currency: currency[this.duel.currency]
              })
            }
          })
        } else {
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('duelStateCompleted'),
              type: 'info',
              text: this.$t('duelLoseText')
            }
          })
        }
      }

      this.duel = newDuel
      this.$forceUpdate()
    },

    notifyDuelPlayers (duel) {
      const game = this.gamesById[duel.game_id]

      if (this.notifiedDuels?.[duel.state]?.includes(duel.id)) {
        return
      }

      if (duel.state === 'ready' && duel.account.id === this.user?.id) {
        notify(this.$t('someoneAccepted', { name: duel.opponent.full_name }), {
          onClick: () => {
            this.$router.push(
              this.localeLocation(`/${game.name}/duels/${duel.password}`)
            )
          }
        })

        this.setNotifiedDuel(duel)
      } else if (
        duel.state === 'in_progress' &&
        duel.opponent_id === this.user?.id
      ) {
        notify(
          this.$t('duelWithSomeoneStarted', { name: duel.account.full_name }),
          {
            onClick: () => {
              this.$router.push(
                this.localeLocation(`/${game.name}/duels/${duel.password}`)
              )
            }
          }
        )

        this.setNotifiedDuel(duel)
      }
    },

    showCancelDuelPrompt () {
      this.showModal({
        component: 'ModalMessage',
        data: {
          text: this.$t('areYouSureYouWannaCancelDuel'),
          type: 'warning',
          title: this.$t('stepConfirm'),
          buttons: [
            {
              props: { isSecondary: true },
              title: this.$t('cancelDuel'),
              action: this.cancelDuel
            },
            {
              props: { isPrimary: true },
              title: this.$t('doNotCancel')
            }
          ]
        }
      })
    },

    showLeaveDuelPrompt () {
      this.showModal({
        component: 'ModalMessage',
        data: {
          text: this.$t('areYouSureYouWannaLeaveDuel'),
          type: 'warning',
          title: this.$t('stepConfirm'),
          buttons: [
            {
              props: { isSecondary: true },
              title: this.$t('leaveDuel'),
              action: this.leaveDuel
            },
            {
              props: { isPrimary: true },
              title: this.$t('stay')
            }
          ]
        }
      })
    },

    async validateDuel (data) {
      this.$nuxt.$loading.start()

      try {
        await this.$axios.post(
          `/games/${this.game.name}/duels/${this.duel.password}/validate`,
          data
        )
      } catch (e) {
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    },

    async cancelDuel () {
      this.$nuxt.$loading.start()

      try {
        await this.$axios.post(
          `/games/${this.game.name}/duels/${this.duel.password}/cancel`
        )
      } catch (e) {
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
      this.$router.push(this.localeLocation(`/${this.game.name}/duels`))
    },

    async leaveDuel () {
      this.$nuxt.$loading.start()

      try {
        await this.$axios.post(
          `/games/${this.game.name}/duels/${this.duel.password}/leave`
        )
      } catch (e) {
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    },

    checkActionAvailability (action) {
      if (!this.user) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'login',
            afterLogin: () => {
              action?.()
            }
          }
        })

        return false
      }

      return true
    },

    async joinDuelRequest () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post(
          `/games/${this.game.name}/duels/${this.duel.password}/join`
        )

        this.sendAnalytics(data.data)
      } catch (e) {
        this.$errors.handle(e)

        this.$gtag.event('duel_join_fail', {
          game: this.game.name,
          error: this.$errors.getText(e)
        })
      }

      this.$nuxt.$loading.finish()
    },

    joinDuel () {
      if (!this.checkActionAvailability(this.joinDuel)) {
        return
      }

      this.showModal({
        component: 'ModalCheckRequirements',
        data: {
          duel: this.duel,
          onSuccess: () => {
            if (this.isPaid) {
              this.showModal({
                component: 'ModalPayForParticipation',
                data: {
                  title: this.$t('payForDuelEntry'),
                  text: this.$t('duelParticipation').toLowerCase(),
                  duel: this.duel,
                  afterPay: () => {
                    this.joinDuelRequest()
                    this.$store.commit('modal/toggle', false)
                  }
                }
              })
            } else {
              this.joinDuelRequest()
            }
          }
        },
        shouldShow: false
      })
    },

    async createDuelTemplateRequest () {
      this.$nuxt.$loading.start()

      try {
        let duelData = this.duel
        const config = this.duel.extra_data.config

        if (this.game.name === 'lichess') {
          duelData = {
            title: this.duel.title,
            is_private: this.duel.is_private,
            entry_fee: +this.duel.entry_fee,
            // is_anticheat_required: this.fields.is_anticheat_required.value,
            is_anticheat_required: false,
            extra_data: {
              config: {
                'clock.limit': config['clock.limit'],
                'clock.increment': config['clock.increment'],
                color: config.color,
                variant: 'standard'
              }
            },
            currency: this.duel.currency
          }

          if (this.duel.min_rating) {
            duelData.min_rating = this.duel.min_rating
          }

          if (this.duel.max_rating) {
            duelData.max_rating = this.duel.max_rating
          }
        }

        const { data } = await this.$axios.post(
          `/games/${this.game.name}/duels`,
          duelData
        )

        this.sendCreateAnalytics(data.data)

        this.$router.push(
          this.localeLocation(`/${this.game.name}/duels/${data.data.password}`)
        )
      } catch (e) {
        console.error(
          `${new Date().toUTCString()} :: duel template create error ::`,
          e
        )
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    },

    createDuelTemplate () {
      if (!this.checkActionAvailability(this.createDuelTemplate)) {
        return
      }

      this.showModal({
        component: 'ModalCheckRequirements',
        data: {
          duel: this.duel,
          onSuccess: () => {
            if (this.isPaid) {
              this.showModal({
                component: 'ModalPayForParticipation',
                data: {
                  title: this.$t('payForDuelEntry'),
                  text: this.$t('duelParticipation').toLowerCase(),
                  duel: this.duel,
                  afterPay: () => {
                    this.createDuelTemplateRequest()
                  }
                }
              })
            } else {
              this.createDuelTemplateRequest()
            }
          }
        },
        shouldShow: false
      })
    },

    async sendAnalytics ({ paymentID }) {
      // const isPaidByTickets = false
      // const ticketNominal = isPaidByTickets ? TICKETS_NOMINAL : 0

      // const { id } = await this.$ga.getOperationData('duel_registration')

      // this.$ga.onlineEvent({
      //   event: 'purchase',
      //   cm1: this.isPaid ? 0 : this.entryFee,
      //   cm2: this.isPaid ? this.entryFee : 0,
      //   cm3: this.commission,
      //   cd3: this.game.name,
      //   cd4: this.duel.password,
      //   cd5: this.isPaid ? 'paid' : 'freeroll',
      //   cd6: this.gameAccount(this.game.name)?.ratings?.default,
      //   cd8: 'duel_fee',
      //   cd9: id,
      //   transactionId: paymentID,
      //   transactionTotal: this.isPaid ? this.entryFee : 0,
      //   transactionProducts: [
      //     {
      //       sku: this.duel.password,
      //       name: 'duel',
      //       category: this.game.name,
      //       price: this.isPaid ? this.entryFee : 0,
      //       quantity: 1
      //     }
      //   ]
      // })
    },

    async sendCreateAnalytics ({
      paymentID,
      password,
      currency,
      // eslint-disable-next-line camelcase
      entry_fee,
      rake
    }) {
      // const isPaid = currency === 'money'
      // eslint-disable-next-line camelcase
      // const entryFee = isPaid ? Math.ceil(entry_fee / 100) : entry_fee
      // const commission = Math.ceil((entryFee / 100) * rake)

      // const { id } = await this.$ga.getOperationData('duel_create')

      // this.$ga.onlineEvent({
      //   event: 'purchase',
      //   cm1: isPaid ? 0 : entryFee,
      //   cm2: isPaid ? entryFee : 0,
      //   cm3: commission,
      //   cd3: this.game.name,
      //   cd4: password,
      //   cd5: isPaid ? 'paid' : 'freeroll',
      //   cd6: this.gameAccount(this.game.name)?.ratings?.default,
      //   cd8: 'duel_fee',
      //   cd9: id,
      //   transactionId: paymentID,
      //   transactionTotal: isPaid ? entryFee : 0,
      //   transactionProducts: [
      //     {
      //       sku: password,
      //       name: 'duel',
      //       category: this.game.name,
      //       price: isPaid ? entryFee : 0,
      //       quantity: 1
      //     }
      //   ]
      // })
    }
  }
}
