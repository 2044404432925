<template lang="pug">
.layout-empty
  .layout__live
    LiveWinnersOfferwall

  Header
  .layout__content
    .layout__page
      portal-target(v-if='isHeaderMobile', name='breadcrumbs')
      Nuxt
    Footer
  MobileMenu(v-if='isHeaderMobile')

  client-only
    CookiePolicy
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import Header from '~/components/Header/Header'
import Footer from '~/components/Footer/Footer'
import Sidebar from '~/components/Sidebar/Sidebar'
import CookiePolicy from '~/components/CookiePolicy/CookiePolicy'
import LiveWinnersOfferwall from '~/components/LiveWinners/LiveWinnersOfferwall'
import LiveWinners from '~/components/LiveWinners/LiveWinners'
import MobileMenu from '~/components/MobileMenu/MobileMenu'

export default {
  name: 'LayoutEmpty',

  components: {
    LiveWinners,
    LiveWinnersOfferwall,
    CookiePolicy,
    Header,
    Footer,
    Sidebar,
    MobileMenu
  },

  data () {
    return {
      resizeTimeout: undefined
    }
  },

  computed: {
    ...mapState('viewport', ['breakpoints']),
    ...mapGetters('viewport', ['isHeaderMobile'])
  },

  watch: {
    $route: {
      handler (to, from) {
        const query = { ...this.$route.query }
        query.utm_source = query.utm_source || this.$cookiz.get('utm_source')
        query.utm_medium = query.utm_medium || this.$cookiz.get('utm_medium')
        query.utm_campaign = query.utm_campaign || this.$cookiz.get('utm_campaign')
        query.utm_term = query.utm_term || this.$cookiz.get('utm_term')
        query.utm_content = query.utm_content || this.$cookiz.get('utm_content')
        query.external_id = query.external_id || this.$cookiz.get('keitaroId')
        query.sub1 = query.sub1 || this.$cookiz.get('marketingSub1')
        query.pixel = query.pixel || this.$cookiz.get('pixel')
        query.fbclid = query.fbclid || this.$cookiz.get('fbclid')

        for (const key in query) {
          if (query[key] === undefined || query[key] === null || query[key] === '') {
            delete query[key]
          }
        }

        this.$router.push({ ...to, query: { ...to.query, ...query } })
      },
      immediate: true
    }
  },

  mounted () {
    window.addEventListener('resize', this.onResize)

    this.checkWindowSize()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    ...mapMutations('viewport', ['setWindowSize']),

    onResize () {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout)
      }

      this.resizeTimeout = setTimeout(this.checkWindowSize, 50)
    },

    checkWindowSize () {
      let size = ''
      this.breakpoints
        .slice()
        .sort((a, b) => b.width - a.width)
        .some((item) => {
          if (window.innerWidth <= item.width) {
            size = item
          } else {
            return true
          }
        })

      if (!size) {
        size = this.breakpoints[this.breakpoints.length - 1]
      }

      this.setWindowSize(size)
    }
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.WWW_HOST + this.$route.path
        }
      ],
      link: [
        {
          rel: 'alternate',
          hreflang: 'en',
          href: this.$config.WWW_HOST + this.$route.path
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-empty {
  @include silent-scroll;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;

  @media (min-width: $breakpointTablet) {
    @include scroll;
    overflow-x: hidden;
  }
}
</style>
