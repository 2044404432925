import { render, staticRenderFns } from "./break.vue?vue&type=template&id=682c3e50&lang=pug&"
import script from "./break.vue?vue&type=script&lang=js&"
export * from "./break.vue?vue&type=script&lang=js&"
import style0 from "./break.vue?vue&type=style&index=0&id=682c3e50&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports