<template lang="pug">
  .team-row(:class="{'--small': isSmall}")
    .team-row__cells
      .team-row__cell(v-if="withReward || withPlace" class="--place" :class="{'--order': withPlace}")
        .team-row__place
          InlineSvg(v-if="withPlace" :src="require(`~/assets/img/icons/star.svg`)")
          span {{ team.place }}

      .team-row__avatar(v-if="player.avatar || withPlace" :class="{ '--dashed': !player.avatar }")
        img(v-if="player.avatar" :src="player.avatar", :alt="player.full_name" height="48" width="48" v-lazy-load)
        InlineSvg(v-else :src="require(`~/assets/img/icons/player-empty.svg`)")

      .team-row__cell(class="--wide")
        .team-row__members(v-if="team.name")
          Tooltip(popup-class-name="player-row__controls-popup")
            .team-row__name-team {{ team.name }}

            template(v-slot:tooltip)
              template(v-for="(player, index) in members")
                PlayerGameName(:key="player.id" :account="player.game_account" :game="game" :class="{'--captain': player.is_captain}")
                span(v-if="player.id && player.full_name")
                  | &nbsp;(s
                  a.team-row__members-profile(:href="'/user/id/' + player.id" target="_blank") {{ player.full_name }}
                  | )
                span.team-row__members-comma(v-if="index < team.members.length - 1") ,

        .team-row__members(v-else-if="members")
          template(v-for="(player, index) in members")
            PlayerGameName(:key="player.id" :account="player.game_account" :game="game" :class="{'--captain': player.is_captain}")
            span(v-if="player.id && player.full_name")
              | &nbsp;(
              a.team-row__members-profile(:href="'/user/id/' + player.id" target="_blank") {{ player.full_name }}
              | )
            .team-row__members-comma(v-if="index < team.members.length - 1") ,

        .team-row__name(v-if="team.name")
          //span.team-row__name-team(v-if="team.name" @click="onTeamNameClick(team)")
            InlineSvg(v-if="withPlace" :src="require('~/assets/img/icons/halfstar.svg')")
            | {{ team.name }}
          span(v-if="withReward && !['completed', 'cancelled'].includes(tournament.state)") {{ $t('willBeDecided') }}

          .team-row__status-message(v-if="team.is_on_moderation") {{ $t('stateOnModeration') }}
          .team-row__status-message(v-if="team.ban_reason") {{ $t('sheIsBlocked') }}
          .team-row__status-message(v-if="team.is_prize_removed") {{ $t('appealedReward') }}

        .team-row__right(v-if="withReward")
          .team-row__right-value
            Currency(:type="currency") {{ team.coins }}

      .team-row__cell(v-if="withMembers" class="--members")
        InlineSvg(:src="require(`~/assets/img/icons/user.svg`)")
        span {{ team.members_count }}

    .team-row__bottom(v-if="withReward")
      .team-row__bottom-item(v-if="withReward")
        .team-row__bottom-title {{ $t('reward') }}
        .team-row__bottom-value
          Currency(:type="currency") {{ team.coins }}

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PlayerGameName from '~/components/PlayerGameName/PlayerGameName'
import Tooltip from '~/components/Tooltip/Tooltip'

export default {
  name: 'TeamRow',

  components: { PlayerGameName, Tooltip },

  props: {
    team: {
      type: Object,
      required: true
    },

    tournament: {
      type: Object,
      required: true
    },

    withReward: {
      type: Boolean
    },

    withPlace: {
      type: Boolean
    },

    withMembers: {
      type: Boolean
    },

    currency: {
      type: String,
      default: 'coins'
    },

    isSmall: {
      type: Boolean
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapGetters('viewport', ['isMobile']),
    ...mapState('games', ['gamesByName', 'gamesById']),

    members () {
      return this.team.members
        ?.slice()
        .sort((a, b) => b.is_captain - a.is_captain)
    },

    game () {
      return this.tournament
        ? this.gamesById[this.tournament.game_id]
        : this.gamesByName[this.$route.params.game]
    },

    player () {
      return (
        /* eslint-disable-next-line */
        this.team.members?.find(({ is_captain }) => is_captain) ||
        this.team.members?.[0] ||
        {}
      )
    }
  },

  methods: {
    async onTeamNameClick (team) {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.get(`/teams/${team.id}`)

        this.$store.dispatch('modal/showModal', {
          component: 'PlayersListModal',
          data: {
            title: team.name,
            players: data.data,
            location: 'team'
          }
        })
      } catch (e) {
        this.$errors.handle(e)
        console.error('team memebers error', e)
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<style lang="scss">
.team-row {
  margin-top: 8px;
  border-radius: 8px;
  background: $blue-grey70;
  color: $white;

  &.--small {
    background: $blue-grey60;
  }

  &__members {
    .player-game-name.--captain {
      color: $teal60;
    }

    &-profile {
      text-decoration: underline;
    }

    &-comma {
      display: inline;

      &::after {
        content: " ";
      }
    }
  }

  &__cells {
    display: flex;
    width: 100%;
    min-height: 72px;
    align-items: center;

    .--small & {
      min-height: 48px;
      flex-wrap: nowrap;
    }
  }

  &__avatar {
    border-radius: 12px;
    overflow: hidden;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: none;
    margin-left: 16px;
    min-height: 72px;
    min-width: 48px;

    @media (max-width: $breakpointMobile) {
      &:not(:first-child) {
        margin-left: 0;
      }
    }

    &.--dashed {
      border: 1px dashed $grey20;
      min-height: auto;
      margin-top: 8px;

      svg {
        height: 24px;
        width: 24px;
        object-fit: cover;
      }
    }

    img {
      height: 48px;
      width: 48px;
      min-width: 48px;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  &__cell {
    min-height: 72px;
    padding: 8px 24px;
    display: flex;
    align-items: center;

    .--small & {
      min-height: 24px;
      padding: 0 16px;
      margin: 12px 0;

      &.--wide {
        @include text-overflow;
      }
    }

    &.--wide {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.--members {
      @include heading6;

      border-left: 1px solid $blue-grey40;
      color: $blue-grey05;

      svg {
        margin-right: 4px;

        path {
          fill: $blue-grey05;
        }
      }
    }

    &.--place {
      border-right: 1px solid $blue-grey40;

      @media (max-width: $breakpointMobile) {
        border: none;
        padding-right: 16px;
        padding-left: 16px;
      }
    }
  }

  &__place {
    display: flex;
    align-items: center;
    min-width: 30px;

    svg {
      margin-right: 4px;
    }

    span {
      @include heading6;

      color: $white;

      .--order & {
        @include text-small;

        color: $blue-grey10;
        padding-left: 4px;
      }
    }
  }

  &:first-child &__place svg path {
    fill: $amber50;
  }

  &:nth-child(2) &__place svg path {
    fill: $grey05;
  }

  &:nth-child(3) &__place svg path {
    fill: $orange90;
  }

  &__name {
    @include heading6;

    color: $white;
    margin-left: 12px;

    .--small & {
      font-size: 16px;
    }
  }

  &__name-team {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  &__right {
    &-value {
      display: flex;
      align-items: center;
    }

    @media (max-width: $breakpointMobile) {
      display: none;
    }
  }

  &__bottom {
    margin-top: 8px;
    padding: 0 16px;
    display: none;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 16px;
    }

    &-title {
      @include caption-small;

      color: $blue-grey10;
    }

    @media (max-width: $breakpointMobile) {
      display: block;
    }
  }

  &__status-message {
    @include caption-small;

    color: $amber50;
  }
}
</style>
