<template lang="pug">
.modal-mission
  img.modal-mission__img(:src="image" alt="Mission")
  .modal-mission__container
    h1.modal-mission__title {{ title }}
    p.modal-mission__text(v-html="body")
    Button.modal-mission__button#modal-mission__button--modal(is-primary @click="startMission") {{ $t('startMission') }}
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    buttonLink: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },

  methods: {
    startMission () {
      this.$gtag.event('mission_start', { mission_id: this.id })
      window.open(this.buttonLink)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalmission {
  min-width: 360px;
  max-width: 508px !important;
  padding: 0 !important;
}

.modal-mission {
  &__img {
    width: 100%;
    max-height: 292px;
    border-radius: 16px;
  }

  &__container {
    background-color: $blue-grey70;
    margin-top: 10px;
    padding: 0 16px;
    color: $white;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 32px;

    * {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__link {
    cursor: pointer;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 32px;
  }
}
</style>
