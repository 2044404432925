<template lang="pug">
  .form-input(
    :class="{'--password': type === 'password', '--error': error, '--view-only': isViewMode, '--is-white': isWhite }"
  )
    .form-input__title(v-if="title")
      | {{ title }}
    label.form-input__label
      template(v-if="isViewMode")
        | {{ value || $t('inputDefault') }}
      template(v-else)
        .form-input__textarea(v-if="type === 'textarea'")
          textarea(
            :placeholder="placeholder"
            :disabled="localDisabled"
            :class="{'form-input__no-icon--textarea': !(type === 'password' || icon)}"
            @input="onInput"
            @blur="onBlur"
          )
            | {{ value }}
          .form-input__placeholder(v-if="multilinePlaceholderIsActive")
            div(v-for="(s, index) in multilinePlaceholderArray" :key="`placeholder_${index}`") {{ s }}

        input(
          v-else-if="!startMarker"
          v-bind="$attrs"
          v-mask="mask"
          :type="currentType"
          :value="value"
          :placeholder="placeholder"
          :disabled="localDisabled"
          :class="{'form-input__no-icon': !(type === 'password' || icon)}"
          @input="onInput"
          @blur="onBlur"
          @keypress="onKeypress"
          @keydown="onKeydown"
          @focus="onFocus"
          @paste="onPaste"
        )
        .form-input__with-start(v-else)
          .form-input__start {{ startMarker }}
          input(
            v-bind="$attrs"
            v-mask="mask"
            :type="currentType"
            :value="value"
            :placeholder="placeholder"
            :disabled="localDisabled"
            :class="{'form-input__no-icon': !(type === 'password' || icon)}"
            @input="onInput"
            @blur="onBlur"
            @keypress="onKeypress"
            @keydown="onKeydown"
            @focus="onFocus"
            @paste="onPaste"
          )

      .form-input__icon.--password(v-if="type === 'password'" @click="togglePasswordVisibility")
        InlineSvg(:src="require(`~/assets/img/icons/${passwordIcon}.svg`)")
      .form-input__icon(v-if="icon" :class="{'form-input__icon--right': iconRight}" @click="iconClick")
        InlineSvg(:src="require(`~/assets/img/icons/${icon}.svg`)")
      .form-input__error(v-if="error")
        | {{ error }}
    .form-input__warning(v-if="warning && !error")
      | {{ warning }}
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'FormInput',

  directives: {
    mask: (el, binding) => {
      if (binding.value) {
        mask(el, binding)
      }
    }
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    multilinePlaceholder: {
      type: String,
      default: undefined
    },

    startMarker: {
      type: String,
      default: undefined
    },

    icon: {
      type: String,
      default: ''
    },

    iconRight: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    },

    error: {
      type: String,
      default: ''
    },

    warning: {
      type: String,
      default: ''
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    isViewMode: {
      type: Boolean,
      default: false
    },

    mask: {
      type: [String, Array],
      default: null
    },

    isWhite: {
      type: Boolean,
      default: false
    },

    max: {
      type: Number,
      default: 10000
    }
  },

  data ({ isDisabled }) {
    return {
      isPasswordVisible: false,
      localDisabled: isDisabled
    }
  },

  computed: {
    currentType () {
      if (this.type === 'password' && this.isPasswordVisible) {
        return 'text'
      }

      return this.type
    },

    passwordIcon () {
      return this.isPasswordVisible ? 'eye' : 'eye-closed'
    },

    multilinePlaceholderArray () {
      return this.multilinePlaceholder.split('/n')
    },

    multilinePlaceholderIsActive () {
      return this.multilinePlaceholder && (this.value?.length || 0) < 1
    }
  },

  created () {
    this.localDisabled = this.isEditable || this.isDisabled
  },

  methods: {
    togglePasswordVisibility () {
      this.isPasswordVisible = !this.isPasswordVisible
    },

    iconClick () {
      this.$emit('iconClick')
    },

    onInput ({ target: { value } }) {
      if (value.length > this.max) {
        return this.$emit('input', value.slice(0, -1))
      }

      this.$emit('input', value)
    },

    onBlur () {
      this.$emit('blur')
    },

    onKeypress (event) {
      this.$emit('keypress', event)
    },

    onKeydown (event) {
      this.$emit('keydown', event)
    },

    onFocus (event) {
      this.$emit('focus', event)
    },

    onPaste (event) {
      this.$emit('paste', event)
    },

    edit () {
      this.localDisabled = !this.localDisabled
    }
  }
}
</script>

<style lang="scss">
.form-input {
  &__title {
    @include caption-small;

    color: $grey20;
    margin-bottom: 12px;
  }

  &__with-start {
    margin-top: 16px;
    background: $blue-grey60;
    color: $white;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 10px;
    align-items: center;

    &.--error {
      padding-bottom: 22px;
    }
  }

  &__start {
    border-right: 1px solid $blue-grey10;
    margin: 10px 0;
    padding-right: 20px;
    padding-left: 20px;
  }

  &__textarea {
    position: relative;
  }

  &__placeholder {
    position: absolute;
    top: 16px;
    left: 16px;
    color: $blue-grey10;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &__label {
    position: relative;
    // background: $blue-grey70;
    background: $blue-grey60;
    width: 100%;
    display: block;
    border-radius: 8px;
    transition: all 0.2s $easing;

    .--error > & {
      background: $red60-20;
    }

    .--view-only & {
      @include text-display100;

      background: transparent;
    }

    input,
    textarea {
      border-radius: 8px;
      padding: 0 36px 0 46px;
      height: 48px;
      width: 100%;
      border: 0;
      outline: none;
      background: transparent;
      color: $white;
      @include text-display100;

      &::placeholder {
        color: $white20;
        @include text-display100;
      }

      &:-webkit-autofill {
        background: transparent !important;
      }

      &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    /* stylelint-disable-next-line */
    textarea {
      height: auto;
      min-height: 116px;
      padding-top: 16px;
      padding-bottom: 16px;
      line-height: 100%;
    }
  }
  &__icon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;

    .--password & {
      left: auto;
      right: 12px;

      svg path {
        fill: $amber60;
      }
    }

    &--right {
      left: auto;
      right: 12px;
    }
  }

  &__no-icon {
    padding: 0 16px 0 16px !important;

    &--textarea {
      padding: 10px 16px !important;
    }
  }

  &__pass-icon {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
  }

  &__error {
    @include text-display100;

    position: absolute;
    bottom: -22px;
    right: 0;
    font-size: 18px;
    color: $red40;
  }

  &__warning {
    @include text-display100;

    text-transform: uppercase;
    text-align: right;
    font-size: 12px;
    color: $orange60;
    margin-top: 8px;
  }

  &__edit {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;
    border: 0;
    background: 0;
    cursor: pointer;
    transition: opacity 0.3s $easing;

    &:hover {
      opacity: 0.7;
    }

    .--password & {
      right: 36px;
    }
  }

  &.--is-white {
    .form-input__label {
      background: $blue-grey05;
    }

    input,
    textarea {
      color: $blue-grey70;

      &::placeholder {
        @include text-display100;

        color: $blue-grey70;
        opacity: 0.5;
      }
    }
  }
}
</style>
