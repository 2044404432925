<template lang="pug">
  .live-stats.live-winners(@mouseover="pauseUpdating", @mouseout="resumeUpdating")
    transition(name="fade" duration="1000")
      transition-group.combined__items(v-if="isLoaded" name="slide-right" duration="100" tag="div")
        .combined__item(v-for="(item, i) in items" class="--green" :key="`${item.id}-${i}`")
          img.combined__item-cover(:src="getItemCover(item)" @click="onIconClick(item)" alt="cover")
          .combined__item-body
            .combined__item-type {{ $t(getItemType(item.type)) }}
            .combined__item-name(@click="onPlayerClick(item)") {{ item.full_name }}
          .combined__item-cost
            span.combined__item-cost-value(v-html="(item.money / 100).toFixed(2).replace('.00', '') + 'G'")

</template>

<script>
import numeral from 'numeral'
import LiveStats from '~/components/LiveStats/LiveStats'

export default {
  name: 'LiveWinnersOfferwall',

  extends: LiveStats,

  data () {
    return {
      winners: [],
      winnersList: [],
      currentIndex: -1,
      interval: undefined
    }
  },

  computed: {
    items () {
      return this.winners
    }

    // socketEventName () {
    //   return `platform:statistics:update:${this.game?.name}`
    // }
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    pauseUpdating () {
      clearInterval(this.interval)
    },

    resumeUpdating () {
      this.interval = setInterval(this.addToWinners, 2000)
    },

    formatNumber (val) {
      const number = (val / 100).toFixed(2)

      return numeral(number)
        .format('0,0.00')
        .replace(/,/g, '&nbsp;')
        .replace('.00', '')
    },

    onPlayerClick (user) {
      window.open(`${this.$config.WWW_HOST}/user/${user.alias}`, '_blank')
    },

    onIconClick (item) {
      if (item.type === 'offerwall_payment') {
        window.open(`${this.$config.WWW_HOST}/missions`, '_blank')
      }
      window.open(`${this.$config.WWW_HOST}/user/${item.alias}`, '_blank')
    },

    getItemCover (item) {
      if (item.type === 'offerwall_payment' && ['AYET', 'BITLABS', 'CPX', 'INBRAIN', 'LOOTABLY', 'OFFERTORO', 'POLLFISH', 'NOTIK', 'MONLIX', 'ADGATE', 'MOBSUCCESS', 'OFFERDADDY', 'LUCKYWALL', 'MAKEMONEY', 'MYLEAD', 'REVLUM', 'OVALIO'].includes(item.offerwall_name)) {
        return `https://cdn.gaming-goods.com/images/stats/offerwalls/${item.offerwall_name}.webp`
      } else if (item.type === 'offerwall_payment' && ['MYCHIPS'].includes(item.offerwall_name)) {
        return `https://cdn.gaming-goods.com/images/stats/offerwalls/${item.offerwall_name}.png`
      } else if (item.type === 'tournament_prize' || item.type === 'duel_prize') {
        return `https://cdn.gaming-goods.com/images/stats/games/${item.game_id}.webp`
      }
      return 'https://cdn.gaming-goods.com/images/stats/offerwalls/OFFERWALL.webp'
    },

    getItemType (itemType) {
      const types = {
        offerwall_payment: 'offerwall',
        tournament_prize: 'tournament',
        duel_prize: 'duel'
      }

      return types[itemType]
    },

    addToWinners () {
      this.winners.unshift(this.winnersList[this.index])
      if (this.index === 0) {
        this.index = this.winnersList.length - 1
      } else {
        this.index--
      }
      if (this.winners.length > 20) {
        this.winners.pop()
      }
    },

    async getData () {
      try {
        const { data } = await this.$axios.get(
          'https://api.gaming-goods.com/platform/combined-statistics'
        )

        this.winnersList = data.data
        this.index = this.winnersList.length - 1
        this.interval = setInterval(this.addToWinners, 2000)

        this.isLoaded = true
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: offerwall live stats error ::`, e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.combined {
  &__item {
    display: grid;
    grid-template-columns: 26px 1fr max-content;
    grid-gap: 4px;
    align-items: center;
    background: $blue-grey60;
    border-radius: 8px;
    margin-right: 4px;
    overflow: hidden;

    &-cover {
      width: 26px;
      margin: 4px 0 4px 4px;
    }

    &-body {
      padding: 4px;
    }

    &-type {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $white;
    }

    &-name {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $blue-grey10;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-cost {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      background: linear-gradient(179.69deg, rgba(76, 175, 80, 0) 0.29%, rgba(76, 175, 80, 0.125) 99.75%);
      padding: 10px 6px;

      &-value {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $green50;
      }
    }
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>

<style lang="scss">
.live-winners {
  display: flex;

  .live-stats {
    &__items {
      margin: 0;
      padding: 0;

      @media (max-width: $breakpointDesktop) {
        margin: 0;
      }
    }

    &__item {
      margin: 0;
      margin-left: 24px;
      padding: 0 0 0 30px;
      overflow: hidden;

      @media (max-width: $breakpointHeaderMobile) {
        padding: 0 0 0 20px;
        margin-left: 16px;

        &:first-child {
          margin-left: 8px;
        }
      }

      &:last-child {
        padding-right: 56px;
      }

      &-value {
        margin-bottom: 1px;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90px;
        font-size: 14px;
        line-height: 17px;
        text-transform: none;
        cursor: pointer;
        text-decoration: none;
        font-family: Roboto, sans-serif;
        font-weight: 500;

        @media (max-width: $breakpointHeaderMobile) {
          max-width: 64px;
          font-size: 9px;
          line-height: 9px;
        }
      }

      svg {
        left: 0;
      }
    }
  }

  &__game {
    @include text-small;

    border-right: 1px solid $blue-grey50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 178px;
    max-width: 178px;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background: $blue-grey80;
    height: inherit;
    z-index: 3;

    &-title {
      font-style: normal;
      color: white;
      margin-bottom: 2px;
      font-weight: 500;
      font-size: 13px;
      position: relative;
      top: -2px;
    }

    &-block {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-name {
      font-style: normal;
      font-weight: $font-medium;
      width: 100%;
      color: $amber60;
      font-size: 15px;
      padding-left: 4px;

      .--lichess & {
        color: #fff;
      }
    }

    img {
      max-height: 16px;
      max-width: 16px;
      margin-right: 4px;
    }

    @media (max-width: $breakpointDesktop) {
      max-width: 118px;
      min-width: 118px;
      padding-left: 16px;

      &-title {
        font-size: 8px;
        line-height: 8px;
        min-width: auto;
      }

      img {
        width: 16px !important;
      }

      &-name {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }
}
</style>
