<template lang="pug">
  .currency-select(v-if="currencies.length")
    .currency-select__currency(
      v-for="curr in currencies"
      :class="[`--${curr}`, { '--active': value === curr }]"
      @click="setCurrency(curr)"
    )
      InlineSvg(v-if="curr === 'coins'" :src="require('~/assets/img/icons/coins.svg')")
      InlineSvg(v-if="curr === 'money'" :src="require('~/assets/img/icons/v.svg')")
</template>

<script>
export default {
  name: 'CurrencySelect',

  props: {
    currencies: {
      type: Array,
      default: () => ['money']
    },

    value: {
      type: String,
      required: true
    }
  },

  data: ({ value }) => {
    return {
      activeCurrencies: value
    }
  },

  methods: {
    setCurrency (curr) {
      this.$emit('input', curr)
    }
  }
}
</script>

<style lang="scss">
.currency-select {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 8px;
  background: $blue-grey60;
  color: $white;
  border-radius: 8px;
  height: 48px;
  line-height: 48px;

  &__currency {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s $easing;
    border-radius: 8px;
    cursor: pointer;

    svg path {
      fill: $blue-grey10;
      transition: fill 0.2s $easing;
    }

    &.--active.--coins {
      background: $amber60-20;
    }

    &.--active.--money {
      background: $green60-20;
    }

    /* stylelint-disable-next-line */
    &.--active.--coins svg,
    &.--coins svg:hover {
      /* stylelint-disable-next-line */
      path {
        fill: $amber60;
      }
    }

    /* stylelint-disable-next-line */
    &.--active.--money svg,
    &.--money svg:hover {
      /* stylelint-disable-next-line */
      path {
        fill: $green60;
      }
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}
</style>
