<template lang="pug">
  .modal-after-freeroll(:class="{ '--sign-try': isSignTry }")
    template(v-if="!isSignTry")
      InlineSvg(:src="require('~/assets/img/vvv-tickets.svg')").modal-after-freeroll__tickets
      .modal-after-freeroll__title {{ $t('congrats') }}

      .modal-after-freeroll__title {{ $t('youWonNumber', { prize: prizeValue }) }}
        InlineSvg(:src="require('~/assets/img/icons/euro.svg')")

    // .modal__header(v-else) {{ $t('noMoreTriesShort') }}

    // .modal-after-freeroll__subtitle {{ $t('noMoreTries') }}
    // .modal-after-freeroll__description {{ $t('removeLimit') }}

    .modal-after-freeroll__offers
      .modal-after-freeroll__offer
        .modal-after-freeroll__offer-line
        img(:src='require(`~/assets/img/other/phone-subscription.png`)').modal-after-freeroll__offer-background

        .modal-after-freeroll__offer-info
          .modal-after-freeroll__offer-title {{ $t('buySubscription') }}
          .modal-after-freeroll__offer-description {{ $t('monthlyOrWeekly') }}

        Currency(size="24" type="money" is-reversed).modal-after-freeroll__offer-price {{ subscriptionPrice / 100 }}
        Button(is-primary class="modal-after-freeroll__offer-button" @click="onBuyClick") {{ $t('buy') }}

      .modal-after-freeroll__offer(class="--blue")
        .modal-after-freeroll__offer-line
        img(:src='require(`~/assets/img/other/phone-offerwall.png`)').modal-after-freeroll__offer-background

        .modal-after-freeroll__offer-info
          .modal-after-freeroll__offer-title {{ $t('earnWithOfferwall') }}

        Button(is-primary class="modal-after-freeroll__offer-button" @click="onOfferwallClick") {{ $t('earn') }}

    //.modal-after-freeroll__description {{ isMoney ? $t('youCanSpendEuro') : $t('youCanSpend') }}ы
    // TournamentSlider(:tournaments="tournaments").modal-after-freeroll__slider

</template>

<script>
import { mapState } from 'vuex'
import TournamentSlider from '~/components/TournamentSlider/TournamentSlider'

export default {
  name: 'ModalAfterFreeroll',

  components: { TournamentSlider },

  props: {
    tournaments: {
      type: Array,
      required: false,
      default: () => []
    },

    isSignTry: {
      type: Boolean,
      default: false
    },

    prize: {
      type: Number,
      required: true
    },

    currency: {
      type: String,
      default: 'money'
    }
  },

  data () {
    return {
      subscriptionPrice: 349
    }
  },

  computed: {
    ...mapState('user', ['subscriptions']),

    isMoney () {
      return this.currency === 'money'
    },

    prizeValue () {
      return this.isMoney ? this.prize / 100 : this.prize
    }
  },

  mounted () {
    this.getSubscriptionPrice()
  },

  methods: {
    async getSubscriptionPrice () {
      try {
        const { data } = await this.$axios.get('/products')

        const subscription = data.data
          .find(({ type }) => {
            return type.startsWith('subscription_freeroll')
          })

        this.subscriptionPrice = subscription.price
      } catch (e) {
        console.error('subscription fetch error', e)
      }
    },

    onBuyClick () {
      this.$router.push(this.localeLocation('/store/subscriptions'))
    },

    onOfferwallClick () {
      this.$router.push(this.localeLocation('/missions'))
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalafterfreeroll {
  max-width: 528px;
  background: $blue-grey70;
}

.modal-after-freeroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .modal__header {
    padding-right: 40px;
  }

  &.--sign-try {
    text-align: left;
  }

  &__tickets {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: $white;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    svg {
      width: 32px;
      height: 32px;
      margin-left: 8px;
      position: relative;
      bottom: 4px;
    }
  }

  &__subtitle {
    @include heading3;

    color: white;
    margin-top: 16px;
  }

  &__description {
    @include body;

    color: $white;
    margin-top: 16px;
  }

  &__slider {
    margin-top: 32px;
  }

  &__offers {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
    margin-top: 24px;
    width: 100%;

    @media (min-width: $breakpointTablet) {
      display: flex;
      width: auto;
    }
  }

  &__offer {
    position: relative;
    text-align: left;
    background-color: $blue-grey60;
    border: 1px solid $amber50;
    border-radius: 16px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 164px;
    width: 100%;
    overflow: hidden;

    @media (min-width: $breakpointTablet) {
      min-height: 214px;
    }

    &.--blue {
      border-color: $light-blue60;

      .modal-after-freeroll__offer-line {
        background-color: $light-blue60;
      }
    }

    &-info {
      margin-bottom: 8px;
      height: 100%;
      z-index: 1;
    }

    &-title {
      @include heading4;

      color: white;
      margin-bottom: 4px;
      max-width: 70%;
    }

    &-description {
      color: white;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 22px;
    }

    &-price {
      @include heading4;

      color: $white;
      margin-bottom: 4px;
      z-index: 1;
    }

    &-button {
      padding: 14px 24px !important;
      z-index: 1;
    }

    &-line {
      position: absolute;
      width: 88px;
      height: 180px;
      right: -62px;
      top: -90px;
      background: $amber50;
      transform: rotate(-45deg);
      z-index: 1;
    }

    &-background {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 90%;
      z-index: 0;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
</style>
