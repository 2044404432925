<script>
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import { mapActions } from 'vuex'
import StoreService from '../../../services/StoreService'
import FormInput from '~/components/FormInput/FormInput.vue'

export default {
  name: 'AddNewAppModal',

  components: {
    BaseSelect,
    FormInput
  },

  props: {
    appType: {
      type: String,
      default: 'game'
    }
  },

  data () {
    return {
      applicationType: this.appType,
      applicationTitle: '',
      coverUrl: ''
    }
  },

  computed: {
    appTypes () {
      return [
        {
          value: 'game',
          title: this.$t('servicesInGame')
        },
        {
          value: 'app',
          title: this.$t('applications')
        },
        {
          value: 'social',
          title: this.$t('socialNetworks')
        },
        {
          value: 'offerwall',
          title: this.$t('offerwalls')
        }
      ]
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('marketplace', ['fetchApps']),

    async createApp () {
      console.log('create app with type', this.applicationType, ', name', this.applicationTitle, ', img', this.coverUrl)
      const { data } = await StoreService.addMarketplaceApp(this.$axios, {
        app_type: this.applicationType,
        title: this.applicationTitle,
        cover: this.coverUrl
      })

      const id = data?.data?.result

      if (id) {
        this.fetchApps()

        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('success'),
            type: 'info',
            text: this.$t('applicationAdded'),
            buttons: [
              {
                props: { isPrimary: true },
                title: this.$t('addCategory'),
                action: () => {
                  this.showModal({
                    component: 'AddNewCategoryModal',
                    data: {
                      appId: id
                    }
                  })
                }
              }
            ]
          }
        })
      }
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('addApplication') }}
    </p>

    <p :class="$style.label">
      {{ $t('applicationType') }}
    </p>

    <BaseSelect
      v-model="applicationType"
      :options="appTypes"
      :with-empty-value="false"
      :placeholder="$t('choose')"
    />

    <p :class="$style.label">
      {{ $t('title') }}
    </p>

    <FormInput v-model="applicationTitle" :placeholder="$t('title')" />

    <p :class="$style.label">
      {{ $t('cover') }}
    </p>

    <div :class="$style.withImg">
      <FormInput v-model="coverUrl" placeholder="URL" />
      <img v-if="coverUrl.startsWith('http')" :class="$style.img" :src="coverUrl" alt="URL" />
    </div>

    <Button is-primary :class="$style.button" @click="createApp">
      {{ $t('create') }}
    </Button>
  </div>
</template>

<style>
.modal--addnewcategorymodal {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 32px;
}

.label {
  margin: 24px 0 12px;
  padding-left: 8px;
  border-left: 8px solid $blue-grey10;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.withImg {
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-gap: 8px;
  align-items: center;
}

.img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
}

.button {
  width: 280px;
  margin: 24px 0 0;
}
</style>
