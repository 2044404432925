// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_yABrg{width:100%;max-width:600px;padding-top:10px}.title_kihR4{color:#fff;font-size:32px;line-height:120%;font-weight:700;margin-bottom:32px}.label_WrTZd{margin:24px 0 12px;padding-left:8px;border-left:8px solid #90a4ae;color:#90a4ae;font-size:12px;font-weight:500;line-height:12px;text-transform:uppercase}.withImg_T4Fca{display:grid;grid-template-columns:1fr 48px;grid-gap:8px;align-items:center}.img_QsXy8{width:48px;height:48px;object-fit:cover;border-radius:8px}.button_262vN{width:280px;margin:24px 0 0}.fields_T6V9\\+{display:grid;grid-template-columns:1fr;grid-gap:16px 8px;align-items:center}.field_rbVHD{display:grid;grid-template-columns:1fr 1fr max-content;grid-gap:8px}@media(min-width: 768px){.field_rbVHD{grid-template-columns:2fr 1fr max-content}}.delete_ZrxFO{display:flex;align-items:center;justify-content:center;width:48px;height:48px;border-radius:8px;background:#232f3f;color:#cfd8dc;font-size:24px;line-height:100%;font-weight:500}.deleteIcon_aLwJT{width:24px;height:24px}.fullWidth_RufUB{grid-column:1/-1;width:100%}.enum_jZMmO{display:grid;grid-column:1/-1;grid-template-columns:1fr 1fr;grid-gap:8px;align-items:center;margin:12px 0}.enum__title_9ErCp{color:#90a4ae;text-transform:uppercase;font-size:12px;line-height:14px;font-weight:500;margin:0 0 4px 16px;grid-column:1/-1}.add_7F6Is{display:flex;align-items:center;justify-content:center;width:100%;height:48px;background:#2b3848;border-radius:8px;cursor:pointer}.add__icon_UHpdK{width:32px;height:32px}.fieldAdd_d8j56{grid-column:1/-1;display:flex;align-items:center;justify-content:center;width:100%;height:48px;background:#2b3848;color:#90a4ae;border-radius:8px;margin-top:12px;cursor:pointer}.fieldAdd__icon_u8\\+1T{width:32px;height:32px;margin-right:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_yABrg",
	"title": "title_kihR4",
	"label": "label_WrTZd",
	"withImg": "withImg_T4Fca",
	"img": "img_QsXy8",
	"button": "button_262vN",
	"fields": "fields_T6V9+",
	"field": "field_rbVHD",
	"delete": "delete_ZrxFO",
	"deleteIcon": "deleteIcon_aLwJT",
	"fullWidth": "fullWidth_RufUB",
	"enum": "enum_jZMmO",
	"enum__title": "enum__title_9ErCp",
	"add": "add_7F6Is",
	"add__icon": "add__icon_UHpdK",
	"fieldAdd": "fieldAdd_d8j56",
	"fieldAdd__icon": "fieldAdd__icon_u8+1T"
};
module.exports = ___CSS_LOADER_EXPORT___;
