<template lang="pug">
.tab-mega-grid
  .tab-mega-grid__container
    .tab-mega-grid__stage(v-for="(stage, stageIndex) in stages" :key="stage.id")
      .tab-mega-grid__stage-header
        .tab-mega-grid__stage-name {{ getRoundTitleByType(stage.round_type) }}
        .tab-mega-grid__stage-status
          InlineSvg(
            :class='statusIconClass(stage)'
            :src='require("~/assets/img/icons/status.svg")'
          )
          span.tab-mega-grid__stage-status-text {{ stageStatusText(stage) }}
      .tab-mega-grid__stage-body(v-if="rooms(stage)")
        .tab-mega-grid__stage-card(v-for="(room, i) in rooms(stage)" :key="room.id" :class="{'tab-mega-grid__stage-card--my': room.is_my_stage, 'tab-mega-grid__stage-card--completed': room.state === 'completed', 'tab-mega-grid__stage-card--last': stageIndex === stages.length - 1}")
          .tab-mega-grid__stage-card-top
            .tab-mega-grid__stage-card-top-left
              .tab-mega-grid__stage-status.tab-mega-grid__stage-status--bordered
                InlineSvg(
                  :class='statusIconClass(room)'
                  :src='require("~/assets/img/icons/status.svg")'
                )
                span.tab-mega-grid__stage-status-text {{ stageStatusText(room) }}
              .tab-mega-grid__stage-card-room-name {{ getRoundTitleByType(stage.round_type) }} {{ letterByIndex(i) }}
            InlineSvg.tab-mega-grid__stage-card-top-right(v-if="roomIsReal(room)" :src="require('~/assets/img/icons/arrow-right.svg')" @click="onRoomClick(room, stage)")
          .tab-mega-grid__stage-card-bottom(:class="{'tab-mega-grid__stage-card-bottom--first': stageIndex === 0}")
            .tab-mega-grid__stage-card-bottom-pair(v-if="room && room.id")
              .tab-mega-grid__stage-card-bottom-pair-key {{ $t('playersAmount') }}:
              .tab-mega-grid__stage-card-bottom-pair-value {{ room.players_count }}
            .tab-mega-grid__stage-card-bottom-pair(v-if="room && stage.state === 'open'")
              .tab-mega-grid__stage-card-bottom-pair-key {{ $t('timerStartsIn') }}:
              .tab-mega-grid__stage-card-bottom-pair-value
                Countdown(:end-time="roomDate(room)")
                  span.next-tournament__time-value(slot="process" slot-scope="{ timeObj }")
                    | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}

      .tab-mega-grid__stage-body(v-else)
        .tab-mega-grid__stage-card(v-for="i in roomsCount(stage)" :key="`room_${stage.id}_${i}`" :class="{'tab-mega-grid__stage-card--last': stageIndex === stages.length - 1}")
          .tab-mega-grid__stage-card-top
            .tab-mega-grid__stage-card-top-left
              .tab-mega-grid__stage-status.tab-mega-grid__stage-status--bordered
                InlineSvg(
                  :class='statusIconClass(stage)'
                  :src='require("~/assets/img/icons/status.svg")'
                )
                span.tab-mega-grid__stage-status-text {{ stageStatusText(stage) }}
              .tab-mega-grid__stage-card-room-name {{ getRoundTitleByType(stage.round_type) }} {{ letterByIndex(i) }}
          .tab-mega-grid__stage-card-bottom(:class="{'tab-mega-grid__stage-card-bottom--first': stageIndex === 0}")
            .tab-mega-grid__stage-card-bottom-pair(v-if="stage.state === 'open'")
              .tab-mega-grid__stage-card-bottom-pair-key {{ $t('timerStartsIn') }}:
              .tab-mega-grid__stage-card-bottom-pair-value
                Countdown(:end-time="roomDate(stage)")
                  span.next-tournament__time-value(slot="process" slot-scope="{ timeObj }")
                    | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
</template>

<script>
import TournamentsService from '@/services/TournamentsService'
import { EVENT_STATUSES } from '@/utils/constants'
import { mapActions } from 'vuex'

export default {
  name: 'TabMegaGrid',

  props: {
    stages: {
      type: Array,
      default: undefined
    },

    tournament: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    rooms (stage) {
      if (stage?.rooms?.[0]?.id) {
        return stage.rooms
      }

      return undefined
    },

    roomsCount (stage) {
      return stage?.room_count
    },

    roomDate (room) {
      return room?.date?.replace(' ', 'T') + 'Z'
    },

    roomIsReal (room) {
      return !!room?.id
    },

    stageStatusText (stage) {
      if (stage.state === 'open') {
        return this.$t('stateExpectation')
      }
      return this.$t(EVENT_STATUSES[stage.state])?.toLowerCase()
    },

    letterByIndex (i) {
      return String.fromCharCode(97 + i).toUpperCase()
    },

    statusIconClass (stage) {
      return {
        'tab-mega-grid__stage-status-icon': true,
        'tab-mega-grid__stage-status-icon--closed': ['completed', 'cancelled'].includes(stage.state),
        'tab-mega-grid__stage-status-icon--started': stage.state === 'in_progress'
      }
    },

    getRoundTitleByType (type) {
      return (
        {
          group: this.$t('qualifyingGroup'),
          quarter: this.$t('stageQuarter'),
          'semi-final': this.$t('stageSemifinal'),
          final: this.$t('stageFinal')
        }[type] || type
      )
    },

    async onRoomClick (room, stage) {
      if (!room?.id) {
        return
      }

      this.$nuxt.$loading.start()

      try {
        const { data } = await TournamentsService.loadTournamentById(this.$axios, this.$route.params.game, room.id)

        this.showModal({
          component: 'PhaseModal',
          data: {
            tournament: data.data,
            parentTournament: this.tournament,
            stage,
            my: room.is_my_stage
          }
        })
      } catch (e) {
        console.error('phase erorr', e)
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-mega-grid {
  width: 100%;
  overflow-x: auto;
  @include scroll;

  &__container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    overflow-x: hidden;
    padding-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 80px;
      right: 80px;
      top: 43px;
      height: 1px;
      width: calc(100% - 160px);
      background: $blue-grey30;
      z-index: -1;
    }
  }

  &__stage {
    margin: 0 28px;
    min-width: 278px;

    @media (min-width: $breakpointTablet) {
      min-width: 25vw;
    }

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: max-content;
      padding: 24px 32px;
      background: rgba(21, 31, 45);
      margin: auto;
    }

    &-name {
      margin-bottom: 8px;
      color: $blue-grey05;
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      text-transform: uppercase;
    }

    &-status {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: $blue-grey05;

      &--bordered {
        padding: 2px 4px;
        background: $black-transparent;
        border-radius: 4px;
        color: $white;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        width: max-content;
      }

      &-icon {
        width: 16px;
        height: 16px;

        ::v-deep path {
          fill: $blue-grey30;
        }

        &--open {
          ::v-deep path {
            fill: $green50;
          }
        }

        &--closed {
          ::v-deep path {
            fill: $red50;
          }
        }

        &--started {
          ::v-deep path {
            fill: $amber60;
          }
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &-card {
      position: relative;
      min-width: 278px;
      background: $blue-grey60;
      color: $white;
      border-radius: 8px;
      min-height: 120px;
      margin: 16px 0;

      &::before {
        position: absolute;
        top: -16px;
        right: -28px;
        background: $blue-grey30;
        content: '';
        width: 1px;
        height: calc(100% + 32px);
      }

      &::after {
        position: absolute;
        top: 50%;
        right: -28px;
        background: $blue-grey30;
        content: '';
        width: 20px;
        height: 1px;
      }

      &:first-child {
        &::before {
          position: absolute;
          top: 50%;
          right: -28px;
          background: $blue-grey30;
          content: '';
          width: 1px;
          height: calc(100% + 32px);
        }
      }

      &:last-child {
        &::before {
          position: absolute;
          bottom: 50%;
          right: -28px;
          background: $blue-grey30;
          content: '';
          width: 1px;
          height: calc(50% + 16px);
        }
      }

      @media (min-width: $breakpointTablet) {
        min-width: 30vw;
      }

      &--last {
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }

        &:last-child {
          &::before {
            content: none;
          }
          &::after {
            content: none;
          }
        }
      }

      &--my {
        border-left: 6px solid $cyan60;
      }

      &--completed {
        opacity: 0.5;
      }

      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid $blue-grey40;

        &-right {
          width: 24px;
          height: 24px;
          cursor: pointer;

          ::v-deep g, path {
            fill: $primary60;
          }
        }
      }

      &-bottom {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px 16px;

        &::before {
          position: absolute;
          top: 0;
          left: -28px;
          background: $blue-grey30;
          content: '';
          width: 20px;
          height: 1px;
        }

        &--first {
          &::before {
            content: none;
          }
        }

        &-pair {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 8px;

          &-key {
            color: $blue-grey10;
            font-size: 11px;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
          }

          &-value {
            color: $white;
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
          }
        }
      }

      &-room-name {
        margin-top: 4px;
        color: $white;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
}
</style>
