<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PaymentService from '@/services/PaymentService'
import NumberInput from '@/components/NumberInput/NumberInput'
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput.vue'
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import FormInput from '@/components/FormInput/FormInput'
import CardForm from '@/components/CardForm'
import InfoMessage from '@/components/InfoMessage/InfoMessage'

export default {
  name: 'NewPayOutModal',

  components: {
    NumberInput,
    CheckboxInput,
    BaseSelect,
    FormInput,
    CardForm,
    InfoMessage
  },

  data () {
    return {
      methods: [],
      methodId: undefined,
      checkbox: false,
      amount: '0',
      cardNumber: '',
      cardRecipient: '',
      cardExpiration: '',
      walletNumber: '',
      sbpInfo: '',
      sbpBank: '',
      sbpFullName: '',
      coin: '',
      banks: []
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['canBeWithdrawnAmount', 'minWithdrawalLimit']),

    selectedMethod () {
      return this.methods?.find(m => m.id === this.methodId)
    },

    minWithdrawalAmount () {
      if (this.selectedMethod?.coins && this.coin) {
        return this.selectedMethod.coins.find(c => c.methodName === this.coin)?.minPayOut || 2
      }

      return this.selectedMethod?.minPayOut || 2
    },

    commissionPercent () {
      if (this.selectedMethod?.coins && this.coin) {
        return this.selectedMethod.coins.find(c => c.methodName === this.coin)?.commissionPayOut || 0
      }

      return this.selectedMethod?.commissionPayOut || 0
    },

    commissionFix () {
      console.log('this.coin', this.coin)
      if (this.selectedMethod?.coins && this.coin) {
        return this.selectedMethod.coins.find(c => c.methodName === this.coin)?.fixCommissionPayOut || 0
      }

      return this.selectedMethod?.fixCommissionPayOut || 0
    },

    commissionAmount () {
      return Math.ceil((+this.amount * (this.commissionPercent / 100) + this.commissionFix) * 100) / 100
    },

    totalWithoutCommission () {
      return (+this.amount - this.commissionAmount).toFixed(2).replace('.00', '')
    },

    canBeWithdrawnAmountShort () {
      return +this.canBeWithdrawnAmount < 1000
        ? this.canBeWithdrawnAmount
        : (+this.canBeWithdrawnAmount / 1000).toFixed(1) + 'K'
    },

    coins () {
      return this.selectedMethod?.coins?.map(c => ({
        value: c.methodName,
        title: c.title
      }))
    },

    expirationFormatMMYYYY () {
      const splitted = this.cardExpiration?.split('/')

      if (splitted.length > 1) {
        splitted[1] = `20${splitted[1]}`
      }

      return splitted.join('/')
    },

    details () {
      switch (this.methodId) {
        case 'ru-card':
        case 'credit-card':
          return [
            {
              key: this.$t('withdrawSum'),
              value: this.amount,
              isMoney: true
            },
            {
              key: this.$t('payCommission', { commission: this.commissionPercent, additional: this.commissionFix > 0 ? `+${this.commissionFix} EUR` : '' }),
              value: this.commissionAmount,
              isMoney: true
            },
            {
              key: this.$t('withdrawalOption'),
              value: this.$t(this.selectedMethod.title)
            },
            {
              key: this.$t('cardNumber'),
              value: this.cardNumber === '' ? '-' : this.cardNumber
            }
          ]
        case 'ru-sbp':
          return [
            {
              key: this.$t('withdrawSum'),
              value: this.amount,
              isMoney: true
            },
            {
              key: this.$t('payCommission', { commission: this.commissionPercent, additional: this.commissionFix > 0 ? `+${this.commissionFix} EUR` : '' }),
              value: this.commissionAmount,
              isMoney: true
            },
            {
              key: this.$t('withdrawalOption'),
              value: this.$t(this.selectedMethod.title)
            },
            {
              key: this.$t('mobilePhone'),
              value: this.sbpInfo === '' ? '-' : this.sbpInfo
            }
          ]
        case 'crypto':
          return [
            {
              key: this.$t('withdrawSum'),
              value: this.amount,
              isMoney: true
            },
            {
              key: this.$t('payCommission', { commission: this.commissionPercent, additional: this.commissionFix > 0 ? `+${this.commissionFix} EUR` : '' }),
              value: this.commissionAmount,
              isMoney: true
            },
            {
              key: this.$t('withdrawalOption'),
              value: this.$t(this.selectedMethod.title)
            },
            {
              key: this.$t('coin'),
              value: this.coin === '' ? '-' : this.coin || '-'
            },
            {
              key: this.$t('yourCryptocurrensyWallet', { currency: '' }),
              value: this.walletNumber || '-'
            }
          ]
        default:
          return [
            {
              key: this.$t('withdrawSum'),
              value: this.amount,
              isMoney: true
            }
          ]
      }
    },

    isVerificationNeeded () {
      const MAX_UNVERIFIED_PAYOUT = 1000

      return (
        Math.ceil(Math.abs(this.user.this_year_payouts || 0) / 100) +
        (Number(this.amount) || 0) >
        MAX_UNVERIFIED_PAYOUT
      )
    },

    buttonDisabled () {
      return (
        (this.user.verification_status !== 'verified' && this.isVerificationNeeded) ||
        !this.user.email ||
        !this.user.is_email_verified ||
        !this.methodId ||
        !this.checkbox ||
        +this.amount < this.minWithdrawalAmount ||
        (
          this.methodId === 'crypto' &&
          (!this.coin || this.coin === '' || !this.walletNumber || this.walletNumber.trim() === '')
        ) ||
        (
          this.methodId === 'ru-card' &&
          (!this.cardNumber || this.cardNumber.trim() === '')
        ) ||
        (
          this.methodId === 'ru-sbp' &&
          (!this.sbpInfo || this.sbpInfo.trim() === '' || !this.sbpBank || this.sbpBank.trim() === '' || !this.sbpFullName || this.sbpFullName.trim() === '')
        ) ||
        (
          this.methodId === 'credit-card' &&
          (!this.cardNumber || this.cardNumber.trim() === '' || !this.cardExpiration || this.cardExpiration.trim() === '' || !this.cardRecipient || this.cardRecipient.trim() === '')
        )
      )
    }
  },

  watch: {
    methodId () {
      if (this.minWithdrawalAmount > +this.amount) {
        this.amount = this.minWithdrawalAmount.toFixed(2).replace('.00', '')
      }
    },

    coin () {
      if (this.minWithdrawalAmount > +this.amount) {
        this.amount = this.minWithdrawalAmount.toFixed(2).replace('.00', '')
      }
    }
  },

  mounted () {
    this.loadBanks()
    this.fetchVerification()
    this.fetchCanBeWithdrawn()
    this.methods = PaymentService.getPaymentMethods('payOut')
      .filter(method => !method.ruOnly || this.$i18n.locale === 'ru')
      .filter(method => !method.euOnly || this.$i18n.locale !== 'ru')
    // ?.filter(m => m.id !== 'ru-card' || this.user?.id === 3088 || this.user?.id === 2291)
    // ?.filter(m => m.id !== 'ru-sbp' || this.user?.id === 3088 || this.user?.id === 2291)
  },

  methods: {
    ...mapActions('user', ['fetchVerification', 'fetchCanBeWithdrawn']),
    ...mapActions('modal', ['showModal']),

    isActive (method) {
      return method.id === this.methodId
    },

    setActive (method) {
      this.methodId = method?.id
    },

    methodStyles (method) {
      return {
        [this.$style.method]: true,
        [this.$style.methodActive]: this.isActive(method)
      }
    },

    roundStyles (method) {
      return {
        [this.$style.method__round]: true,
        [this.$style.method__roundActive]: this.isActive(method)
      }
    },

    async loadBanks () {
      this.banks = ((await this.$axios.get('https://yourpayment.pro/api/merchant-public/banks'))?.data || [])
        .filter(b => b.method_type === 'phone_number')
        .map(b => ({
          title: b.name,
          value: b.type_name
        }))
    },

    setCardPan (value) {
      this.cardNumber = value
    },

    setCardExpiration (value) {
      this.cardExpiration = value
    },

    setCardRecipient (value) {
      this.cardRecipient = value
    },

    async payOutCreditCard () {
      const paymentBody = {
        amount: this.amount,
        currency: 'EUR',
        method: this.selectedMethod?.methodName,
        card_account: {
          card: {
            pan: this.cardNumber,
            expiration: this.expirationFormatMMYYYY
          },
          recipient_info: this.cardRecipient
        },
        return_url: `${location.protocol}//${location.host}${location.pathname}`
      }

      const { data } = await PaymentService.getPayOutLink(
        this.$axios,
        paymentBody
      )

      const paymentId = data?.data

      if (!paymentId) {
        return data.error || this.$t('payOutError')
      }
    },

    async payOutRuCard () {
      if (this.selectedMethod?.methodName === 'PAY2PLAY') {
        const response = await PaymentService.payOutWithPay2Play(this.$axios, this.amount, this.cardNumber)

        const paymentId = response?.data?.data

        if (!paymentId) {
          return response?.data.error_data?.error || response?.data.error || this.$t('payOutError')
        }
      } else if (this.selectedMethod?.methodName === 'YOURPAYMENT') {
        const response = await PaymentService.payOutWithYourPayment(this.$axios, this.amount, this.cardNumber)

        const paymentId = response?.data?.data

        if (!paymentId) {
          return response?.data.error_data?.error || response?.data.error || this.$t('payOutError')
        }
      }
    },

    async payOutRuSbp () {
      if (this.selectedMethod?.methodName === 'PAY2PLAY_SBP') {
        const response = await PaymentService.payOutWithPay2PlaySBP(this.$axios, this.amount, [this.sbpInfo, this.sbpBank, this.sbpFullName].join(', '))

        const paymentId = response?.data?.data

        if (!paymentId) {
          return response?.data.error_data?.error || response?.data.error || this.$t('payOutError')
        }
      } else if (this.selectedMethod?.methodName === 'YOURPAYMENT_SBP') {
        const response = await PaymentService.payOutWithYourPaymentSBP(this.$axios, this.amount, [this.sbpInfo, this.sbpFullName].join(', '), this.sbpBank)

        const paymentId = response?.data?.data

        if (!paymentId) {
          return response?.data.error_data?.error || response?.data.error || this.$t('payOutError')
        }
      }
    },

    async payOutCrypto () {
      const paymentBody = {
        amount: this.amount,
        currency: this.coin,
        address: this.walletNumber
      }

      const { data } = await PaymentService.coinpaymentsPayOut(this.$axios, paymentBody)
      const paymentId = data?.data

      if (!paymentId) {
        return data.error_data?.error || data.error || this.$t('payOutError')
      }
    },

    async payOut () {
      this.$nuxt.$loading.start()
      try {
        let error = null
        switch (this.methodId) {
          case 'credit-card':
            error = await this.payOutCreditCard()
            break
          case 'ru-card':
            error = await this.payOutRuCard()
            break
          case 'ru-sbp':
            error = await this.payOutRuSbp()
            break
          case 'crypto':
            error = await this.payOutCrypto()
            break
        }
        this.fetchCanBeWithdrawn()
        this.$nuxt.$loading.finish()

        if (!error) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('success'),
              type: 'success',
              text: this.$t('success')
            }
          })
        } else {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'error',
              text: error
            }
          })
        }
      } catch (e) {
        console.log(e)
        this.$nuxt.$loading.finish()
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            text: this.$t('payOutError')
          }
        })
      }
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('fundsWithdraw') }}
    </p>

    <div :class="$style.body">
      <InfoMessage v-if="user.verification_status === 'not_verified'" :class="$style.notification" type="warning">
        <div>
          <span v-html="$t('forWithdrawNeedContactInfo')" />
        </div>
      </InfoMessage>
      <InfoMessage v-else-if="user.verification_status !== 'verified' && isVerificationNeeded" :class="$style.notification" type="warning">
        <div>
          <span v-html="$t('forWithdrawNeedVerification')" />
        </div>
      </InfoMessage>
      <InfoMessage v-if="!user.email || !user.is_email_verified" :class="$style.notification" type="warning">
        <div>
          <span v-html="$t('forWithdrawNeedConfirmEmail')" />
        </div>
      </InfoMessage>

      <p :class="$style.titleLevel2">
        {{ $t('withdrawalOptions') }}
      </p>

      <div :class="$style.methods">
        <div v-for="method in methods" :key="method.id" :class="methodStyles(method)" @click="setActive(method)">
          <img v-if="method.mainIcon" :class="$style.method__icon" :src="method.mainIcon.src" :alt="method.mainIcon.alt" />
          <span :class="$style.method__title">{{ $t(method.title) }}</span>
          <div :class="roundStyles(method)">
            <div v-if="isActive(method)" :class="$style.method__roundInside">
            </div>
          </div>
        </div>
      </div>

      <div :class="$style.details">
        <p :class="$style.titleLevel2">
          {{ $t('requisites') }}
        </p>

        <p v-if="!methodId" :class="$style.text">
          {{ $t('whereToWithdraw') }}
        </p>

        <div v-else-if="methodId === 'crypto'">
          <p :class="$style.amountInput__label">
            {{ $t('coin') }}
          </p>

          <BaseSelect
            v-model="coin"
            :options="coins"
            :placeholder="$t('choose')"
          />

          <p :class="$style.amountInput__label">
            {{ $t('yourCryptocurrensyWallet', { currency: coin }) }}
          </p>

          <FormInput v-model="walletNumber" placeholder="№" />
        </div>

        <div v-else-if="methodId === 'credit-card'">
          <CardForm @setCardPan="setCardPan" @setCardExpiration="setCardExpiration" @setCardRecipient="setCardRecipient" />

          <div :class="$style.noRuCards">
            <p :class="$style.noRuCards__text">
              <InlineSvg :class="$style.noRuCards__icon" :src="require('~/assets/img/icons/info.svg')" width="16" height="16" />
              {{ $t('russianCardWarningWithdrawal2') }}
            </p>
          </div>
        </div>

        <div v-else-if="methodId === 'ru-card'">
          <p :class="$style.amountInput__label">
            {{ $t('cardNumber') }}
          </p>

          <FormInput v-model="cardNumber" placeholder="XXXX XXXX XXXX XXXX" />
        </div>

        <div v-else-if="methodId === 'ru-sbp'">
          <p :class="$style.amountInput__label">
            {{ $t('mobilePhone') }}
          </p>

          <FormInput v-model="sbpInfo" placeholder="+7XXXXXXXXXX" />

          <p :class="$style.amountInput__label">
            {{ $t('yourBankName') }}
          </p>

          <!--<FormInput v-model="sbpBank" :placeholder="$t('yourBankName')" />-->
          <BaseSelect
            v-model="sbpBank"
            :options="banks"
            :with-empty-value="false"
            :placeholder="$t('yourBankName')"
          />

          <p :class="$style.amountInput__label">
            {{ $t('recipientName') }}
          </p>

          <FormInput v-model="sbpFullName" :placeholder="$t('recipientName')" />
        </div>
      </div>

      <p :class="$style.titleLevel2">
        {{ $t('withdrawSum') }}
      </p>

      <div :class="$style.amounts">
        <div :class="$style.amounts__pair">
          <span :class="$style.amounts__pairKey">{{ $t('checkoutBalance') }}</span>
          <Currency type="money" real>
            <span :class="$style.amounts__pairValue">
              {{ canBeWithdrawnAmountShort }}
            </span>
          </Currency>
        </div>
        <div :class="$style.amounts__border"></div>
        <div :class="$style.amounts__pair">
          <span :class="$style.amounts__pairKey">{{ $t('available') }}</span>
          <Currency type="money" real>
            <span :class="$style.amounts__pairValue">
              {{ minWithdrawalLimit }}
            </span>
          </Currency>
        </div>
        <div :class="$style.amounts__border"></div>
        <div :class="$style.amounts__pair">
          <span :class="$style.amounts__pairKey">{{ $t('minAmountToWithdraw') }}</span>
          <Currency type="money" real>
            <span :class="$style.amounts__pairValue">
              {{ minWithdrawalAmount }}
            </span>
          </Currency>
        </div>
      </div>

      <p :class="$style.amountInput__label">
        {{ $t('writeSumForWithdraw') }}
      </p>

      <NumberInput
        v-model="amount"
        :class="$style.amountInput"
        :increment="0.01"
        :min="minWithdrawalAmount"
        :max="+minWithdrawalLimit || 100000"
        :fixed="2"
        currency="money"
        real
        full-width
      />

      <div :class="$style.specs">
        <div v-for="spec in details" :key="spec.key" :class="$style.spec">
          <span :class="$style.spec__key">{{ spec.key }}</span>
          <Currency v-if="spec.isMoney" type="money" size="16" real>
            <span :class="$style.spec__value">{{ spec.value }}</span>
          </Currency>
          <span v-else :class="$style.spec__value">{{ spec.value }}</span>
        </div>

        <div :class="[$style.spec, $style.specFinal]">
          <span :class="[$style.spec__key, $style.spec__keyBig]">{{ $t('totalWithdrawal') }}</span>
          <Currency type="money" size="24" real>
            <span :class="[$style.spec__value, $style.spec__valueBig]">{{ totalWithoutCommission }}</span>
          </Currency>
        </div>
      </div>

      <p :class="$style.address">
        {{ $t('ourCompanyAddress') }}: Solian Enterprises Limited. Registered number: HE 412444. Emmanouil road 44, KIRZIS CENTER, 3031 Limassol, Cyprus
      </p>

      <div :class="$style.checkbox">
        <CheckboxInput v-model="checkbox" />
        <span :class="$style.checkbox__label" v-html="$t('agreements')"></span>
      </div>

      <div :class="$style.footer">
        <Button :class="$style.button" is-primary :disabled="buttonDisabled" @click="payOut">
          {{ $t('withdraw') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style>
.modal--newpayoutmodal {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  max-height: 95vh;
}

.title {
  color: $white;
  font-size: 24px;
  line-height: 100%;
  font-weight: 700;
  margin-bottom: 32px;
  padding: 0 16px 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid $blue-grey30;
}

.body {
  @include scroll;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    width: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $blue-grey40;
    border-radius: 2px;
    border-left: 8px solid $blue-grey70;
    border-right: 4px solid $blue-grey70;
  }
}

.notification {
  margin: 12px 0;
}

.titleLevel2 {
  color: $white;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}

.methods {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}

.method {
  display: grid;
  grid-template-columns: 24px 1fr 20px;
  grid-gap: 12px;
  align-items: center;
  padding: 12px;
  background: $blue-grey60;
  border: 1px solid $blue-grey60;
  border-radius: 8px;
  cursor: pointer;

  &Active {
    border: 1px solid $primary60;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__title {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
  }

  &__round {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $blue-grey20;

    &Active {
      border: 2px solid $primary60;
    }

    &Inside {
      width: 100%;
      height: 100%;
      background: $primary60;
      border: 2px solid $blue-grey60;
      border-radius: 50%;
    }
  }
}

.details {
  margin: 40px 0;
}

.text {
  color: $blue-grey10;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.amounts {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-gap: 4px;
  align-items: center;
  background: $blue-grey80;
  padding: 12px;
  border-radius: 8px;

  &__border {
    height: 100%;
    width: 1px;
    background: $blue-grey30;
  }

  &__pair {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &Key {
      display: block;
      color: $blue-grey10;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-bottom: 4px;
      text-align: center;
    }

    &Value {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.amountInput {
  margin: 8px 0 32px;

  &__label {
    margin: 16px 0;
    color: $blue-grey10;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
  }
}

.specs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  align-items: center;
  margin: 32px 0;
}

.spec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &Final {
    border-top: 1px solid $blue-grey30;
    padding-top: 16px;
  }

  &__key {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $blue-grey10;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    &Big {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  &__value {
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    max-width: 50vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &Big {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
  }
}

.address {
  margin: 16px 0;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 16px 0 32px;

  &__label {
    color: rgba($white, .8);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0;
  border-top: 1px solid $blue-grey30;
}

.button {
  &:disabled {
    opacity: 0.4;
  }
}

.noRuCards {
  margin: 12px 0;
  margin-right: 4px;
  padding: 12px 16px;
  background: $red60-20;
  border-radius: 8px;

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $red40;
  }

  &__icon {
    g {
      fill: $red40;
    }
  }
}
</style>
