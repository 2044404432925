// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_IXksC{width:100%;padding-top:10px}.title_gHV\\+j{color:#fff;font-size:32px;line-height:120%;font-weight:700;margin-bottom:16px;padding-bottom:22px;border-bottom:1px solid #334153}.footer_ARxL4{display:flex;align-items:center;justify-content:flex-end;padding:24px 0 0;border-top:1px solid #334153}.skip_Uv4SU{min-width:100% !important}@media(min-width: 768px){.skip_Uv4SU{min-width:328px !important}}.tg_Zx3Mu{display:grid;grid-template-columns:1fr max-content;grid-gap:12px;align-items:start;margin-bottom:16px}.main_oSUaR{margin-top:24px;color:#fff;font-size:18px;font-weight:400;line-height:24px}.main_oSUaR a{color:#42a5f5}.copy_h1v6y{margin-top:12px;max-width:200px}.left_sygmt{display:flex;align-items:flex-start;justify-content:flex-start;width:max-content;color:#fff;font-size:20px;font-weight:700;line-height:28px}.iconContainer_t6jnS{padding-right:24px;margin-right:24px;border-right:1px solid #334153}.icon_HNXrb path{fill:#1e88e5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_IXksC",
	"title": "title_gHV+j",
	"footer": "footer_ARxL4",
	"skip": "skip_Uv4SU",
	"tg": "tg_Zx3Mu",
	"main": "main_oSUaR",
	"copy": "copy_h1v6y",
	"left": "left_sygmt",
	"iconContainer": "iconContainer_t6jnS",
	"icon": "icon_HNXrb"
};
module.exports = ___CSS_LOADER_EXPORT___;
