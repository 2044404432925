export const state = () => ({
  breakpoints: [
    {
      type: 'mobile',
      width: 550
    },
    {
      type: 'headerMobile',
      width: 700
    },
    {
      type: 'tablet',
      width: 768
    },
    {
      type: 'desktop',
      width: 1024
    },
    {
      type: 'desktopMedium',
      width: 1200
    },
    {
      type: 'desktopWide',
      width: 1300
    },
    {
      type: 'desktopExtraWide',
      width: 1600
    }
  ]
})

export const mutations = {
  setWindowSize (state, size) {
    this.$cookiz.set('vvv-viewport', size)

    state.size = size

    // console.log('breakpoint', size.type)
  }
}

export const getters = {
  isMobile: state => state?.size?.type === 'mobile',

  isHeaderMobile: state =>
    state?.size?.type === 'headerMobile' || state?.size?.type === 'mobile',

  isTablet: state =>
    state?.size?.type === 'mobile' ||
    state?.size?.type === 'headerMobile' ||
    state?.size?.type === 'tablet',

  isDesktop: (state) => {
    return (
      state?.size?.type === 'mobile' ||
      state?.size?.type === 'headerMobile' ||
      state?.size?.type === 'tablet' ||
      state?.size?.type === 'desktop'
    )
  },

  isDesktopMedium: (state) => {
    return [
      'mobile',
      'headerMobile',
      'tablet',
      'desktop',
      'desktopMedium'
    ].includes(state?.size?.type)
  },

  isDesktopWide: (state) => {
    return [
      'mobile',
      'headerMobile',
      'tablet',
      'desktop',
      'desktopMedium',
      'desktopWide'
    ].includes(state?.size?.type)
  },

  isExtraWideDesktop: state => state?.size?.type === 'desktopExtraWide'
}
