<template lang="pug">
  .couch-modal
    h1 {{ $t('chessCoach') }}
    .couch-modal__wrapper
      picture
        source.couch-modal__img(:srcset='couch.imageWebp' type="image/webp")
        source.couch-modal__img(:srcset='couch.image' type="image/jpeg")
        img.couch-modal__img(
          v-if='couch.image',
          :src='couch.image',
          :alt='$t(couch.name)'
        )
      .couch-modal__table
        span.couch-modal__title {{ $t(couch.title) }}
        .couch-modal__name {{ $t(couch.name) }}
        .couch-modal__buttons.couch-modal__btn-small
          Button(is-white is-bordered icon="arrow-up-right" @click="goToLink(couch.linkFide)") {{ $t('linkFide') }}
          Button(is-white is-bordered icon="arrow-up-right" @click="goToLink(couch.linkLichess)") {{ $t('linkLichess') }}
        .couch-modal__key-value
          .couch-modal__key {{ $t('rank') }}
          .couch-modal__value {{ $t(couch.rank) }}
        .couch-modal__key-value
          .couch-modal__key {{ $t('language') }}
          .couch-modal__value {{ $t(couch.lang) }}
        .couch-modal__buttons.couch-modal__btn-big
          Button(is-white is-bordered icon="arrow-up-right" @click="goToLink(couch.linkFide)") {{ $t('linkFide') }}
          Button(is-white is-bordered icon="arrow-up-right" @click="goToLink(couch.linkLichess)") {{ $t('linkLichess') }}
        Button(is-primary @click="goToLink(couch.linkCalendly)").couch-modal__btn-big {{ $t('signUpForALesson') }}

    .coucn-modal__description
      .couch-modal__description-title {{ $t('aboutMyself') }}
      .couch-modal__div {{ $t(couch.description) }}
      Button(is-primary @click="goToLink(couch.linkCalendly)").couch-modal__btn-small {{ $t('signUpForALesson') }}

</template>

<script>
export default {
  name: 'CouchModal',
  props: {
    couch: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--couchmodal {
  max-width: 954px;

  @media (min-width: 900px) {
    padding: 48px 40px;
  }
}

.button__body {
  padding: 0;
  justify-content: center;
}
</style>

<style lang="scss" scoped>
.couch-modal {
  color: $white;

  h1 {
    position: absolute;
    top: 40px;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  &__wrapper {
    position: relative;
    margin-top: 64px;
    margin-bottom: 24px;

    @media (min-width: 900px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
      margin-top: 48px;
      margin-bottom: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border: 40px solid transparent;
      border-top: 40px solid $primary60;
      border-left: 40px solid $primary60;
      border-top-left-radius: 7px;
    }
  }

  &__img {
    width: 100%;
    height: 253px;
    margin-bottom: 8px;
    border-radius: 8px;
    object-fit: cover;
    background-color: $white;

    @media (min-width: 900px) {
      height: 360px;
      margin-bottom: 0;
    }
  }

  &__table {
    @media (min-width: 900px) {
      padding: 32px 24px 16px;
      background-color: $blue-grey60;
      border-radius: 8px;
    }
  }

  &__title {
    color: #9bb0cb;
    font-weight: 500;
    font-size: 15px;
  }

  &__name {
    @include heading2;

    @media (min-width: 900px) {
      margin-bottom: 14px;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
    margin: 20px 0;

    @media (min-width: 900px) {
      margin: 40px 0;
    }
  }

  &__key-value {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    border-radius: 6px;
    background: rgb(43 56 72 / 34.1%);
    padding: 4px 10px 5px;
  }

  &__key {
    font-size: 14px;
    line-height: 18px;
    color: #9bb0cb;
    font-weight: 500;
  }

  &__value {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__description {
    &-title {
      @include heading3;

      margin-bottom: 16px;
    }
  }

  &__div {
    margin-bottom: 24px;
    font-weight: 500;
    line-height: 30px;
    color: #9bafca;
  }

  &__btn-small {
    display: grid;

    @media (min-width: 900px) {
      display: none;
    }
  }

  &__btn-big {
    display: none;

    @media (min-width: 900px) {
      display: grid;
      width: 100%;
    }
  }
}
</style>
