<template lang="pug">
  form.auth.auth__body(:class="{'--error': errorMessage}" @submit.prevent="onSubmit")
    .auth__btn-wraper
      a.auth__btn(
        :class="{ '--active': !isRegistration }"
        @click.prevent="setRegistrationState(false)"
      ) {{ $t('signIn') }}
      a.auth__btn(
        :class="{ '--active': isRegistration }"
        @click.prevent="setRegistrationState(true)"
      ) {{ $t('signUp') }}

    //.auth__input
    //  FormInput.auth__promocode(
    //    v-model="promocode"
    //    :title="$t('activatePromocode')"
    //  )

    template(v-if="notTikTok")
      .auth__social
        a(
          v-for="social in filteredSocials"
          :key="social.id"
          :href="socialUrls[social.id]"
          target="_blank"
          :id="'social_btn_' + social.id"
          @click="trackRegistration"
        )
          Button(
            reverse
            is-secondary
            :icon="{ name: 'auth_'+social.icon, type: 'svg' }"
            :is-disabled="isSocialButtonDisabled"
            @click="setVisabilityChangeAction"
          )
            .auth__text {{ enterText }}

      .auth__title(v-if="filteredSocials.length")
        span {{ $t('or') }}
        .auth__line

    .auth__input(v-for="(input, index) in fields" :key="index")
      template(v-if="!input.hidden")
        CheckboxInput(
          v-if="input.type === 'checkbox'"
          :value="input.value"
          @input="onAgreementChange"
        )
          span(v-html="$t('agreements')")

        FormInput(
          v-else
          v-bind="input"
          v-model="input.value"
          @input="onFieldInput(index, input)"
          @blur="onFieldBlur(index, input)"
          @keypress="onFieldKeypress($event, input, index)"
        )

    .auth__input.auth__input-centered(v-if="!isRegistration")
      span.auth__input-button(@click="onForgotPassword") {{ $t('forgetPassword') }}

    transition(name="fade" duration="200")
      InfoMessage(v-if="warningMessage" type="info")
        | {{ warningMessage }}

    transition(name="fade" duration="200")
      InfoMessage(v-if="errorMessage" type="error")
        | {{ errorMessage }}

    .auth__send
      Button(is-primary type="submit" :is-disabled="isButtonDisabled")
        | {{ buttonText }}

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormInput from '~/components/FormInput/FormInput'
import InfoMessage from '~/components/InfoMessage/InfoMessage'
import CheckboxInput from '~/components/CheckboxInput/CheckboxInput'
import { EMAIL_REGEX } from '~/utils/constants'
import { sendPostback, randomNumber, postUrl } from '~/utils/utils'
import UtmService from '~/services/UtmService'

export default {
  name: 'Auth',

  components: {
    CheckboxInput,
    InfoMessage,
    FormInput
  },

  props: {
    mode: {
      type: String,
      default: 'login'
    },

    afterLogin: {
      type: Function,
      default: () => {}
    }
  },

  data (props) {
    return {
      isRegistration: props.mode === 'register',

      sessionId: null,

      notTikTok: true,

      promocode: '',

      socialUrls: {
        vk: '',
        fb: '',
        discord: '',
        google: ''
      },

      socialToken: `${new Date().getTime()}_${randomNumber(1000000, 9999999)}`,

      loginFields: {
        login: {
          type: 'text',
          value: '',
          title: `${this.$t('login')} / ${this.$t('email')}`,
          // placeholder: this.$t('authLoginPlaceholder'),
          required: true
        },

        password: {
          type: 'password',
          value: '',
          title: this.$t('password')
          // placeholder: this.$t('passwordPlaceholder')
        }
      },

      regFields: {
        email: {
          type: 'text',
          value: '',
          title: this.$t('email')
          // placeholder: this.$t('emailPlaceholder')
        },

        // username: {
        //   type: 'text',
        //   value: '',
        //   title: 'Username'
        //   // placeholder: this.$t('emailPlaceholder')
        // },

        login: {
          type: 'text',
          value: '-',
          hidden: true,
          title: this.$t('login')
          // placeholder: this.$t('loginPlaceholder')
        },

        password: {
          type: 'password',
          value: '',
          title: this.$t('password')
          // placeholder: this.$t('signUpPasswordPlaceholder')
        },

        // confirmPassword: {
        //   type: 'password',
        //   value: '',
        //   title: this.$t('passwordConfirm')
        //   // placeholder: this.$t('passwordConfirmPlaceholder')
        // },

        agreement: {
          type: 'checkbox',
          value: true // this.$i18n.locale !== 'ru'
        }
      },

      socials: [
        // { icon: 'facebook', id: 'fb' },
        // { icon: 'steam', id: 'steam' },
        { icon: 'google', id: 'google' }
        // {
        //   icon: 'vk',
        //   id: 'vk',
        //   condition: () => {
        //     return this.$i18n.locale === 'ru'
        //   }
        // },
        // { icon: 'discord', id: 'discord' }
      ],

      errorMessage: '',

      warningMessage: ''
    }
  },

  computed: {
    ...mapState('user', ['user']),

    fields () {
      return this.isRegistration ? this.regFields : this.loginFields
    },

    buttonText () {
      return !this.isRegistration
        ? this.$t('signInAction')
        : this.$t('signUpAction')
    },

    enterText () {
      return !this.isRegistration ? this.$t('signInVia') : this.$t('signUpVia')
    },

    isButtonDisabled () {
      if (
        Object.values(this.fields).some((field) => {
          return field.error
        })
      ) {
        return true
      }

      return Object.values(this.fields).some(field => !field.value)
    },
    isSocialButtonDisabled () {
      return this.isRegistration && !this.regFields.agreement.value
    },
    isWebview () {
      return true // window?.document?.location?.host === 'vvvgamez.com' && window?.navigator?.userAgent?.toLowerCase().includes('bytedancewebview')
    },

    filteredSocials () {
      return this.socials.filter(item =>
        item.condition ? item.condition() : true
      )
    }
  },

  watch: {
    promocode () {
      this.generateSocialAuthUrls()
    }
  },

  mounted () {
    this.safeRoutePath()
    this.checkNotTikTok()
    try {
      this.generateSocialAuthUrls()
    } catch (e) {
      console.log(e)
    }

    const measurementId = process.env.NODE_ENV === 'development' ? 'G-PGC12ZDCEN' : 'G-CN927GR3HN'
    window?.gtag('get', measurementId, 'session_id', (id) => {
      this.sessionId = id
    })

    this.$gtag.event('show_modal_event', { element: 'auth_and_registration', mode: this.mode })
  },

  methods: {
    ...mapActions('user', ['login', 'fetchUser', 'fetchProducts']),
    ...mapMutations('user', ['setToken']),
    ...mapActions('modal', ['showModal']),
    ...mapMutations('modal', ['toggle']),

    onSubmit () {
      this.authorise()
    },

    setVisabilityChangeAction () {
      this.setToken(this.socialToken)
      window.document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'visible') {
          location.reload()
        }
      })
    },

    safeRoutePath () {
      window.localStorage?.setItem(
        'path-before-auth',
        this.$route.fullPath
      )
    },

    checkNotTikTok () {
      if (!(window.document.location.host === 'vvvgamez.com' || !navigator.userAgent.toLowerCase().includes('bytedancewebview'))) {
        this.notTikTok = false
      }
    },

    onForgotPassword () {
      this.$store.dispatch('modal/showModal', {
        component: 'ResetPassword',
        data: {
          email: this.loginFields.login.value
        }
      })
    },

    getLink (link, ext = 'pdf') {
      ext = '.' + ext

      return link.replace(
        ext,
        this.$i18n.locale === 'ru' ? `_ru${ext}` : `_en${ext}`
      )
    },

    trackRegistration () {
      const action = this.isRegistration ? 'register' : 'login'

      if (action === 'register') {
        try {
          window?.ym?.(96913300, 'reachGoal', 'registration')
        } catch (e) {
          console.log(e)
        }
      }
    },

    async authorise (hardAuth = false) {
      this.$nuxt.$loading.start()
      const action = hardAuth ? 'login' : this.isRegistration ? 'register' : 'login'
      this.errorMessage = ''

      if (action === 'register') {
        try {
          window?.ym?.(96913300, 'reachGoal', 'registration')
        } catch (e) {
          console.log(e)
        }
        this.$gtag.event('click_registration-button', {})

        this.fields.login.value = `${new Date().getTime()}${randomNumber(100000, 999999)}`
      }

      Object.keys(this.fields).forEach(key => this.onFieldBlur(key))

      if (this.isButtonDisabled) {
        return
      }

      try {
        const params = Object.keys(this.fields).reduce((result, key) => {
          result[key] = this.fields[key].value
          return result
        }, {
          cid: this.$cookiz.get('_ga')?.replace(/GA.+?\..+?\./, ''),
          session_id: this.sessionId
        })

        if (this.isRegistration && this.promocode && this.promocode !== '') {
          params.promocode = this.promocode
        }

        const { token, id } = await this.$store.dispatch(`user/${action}`, params)

        if (token) {
          await this.fetchUser()
          this.toggle(false)
          this.afterLogin()

          this.$gtag.event('login', { account_id: id, user_id: id, method: 'native', is_it_a_seller: !!this.user?.is_seller, is_it_a_buyer: this.user?.is_buyer })

          await UtmService.sendUtm(this.$axios, {
            utm_source: this.$cookiz.get('utm_source'),
            utm_medium: this.$cookiz.get('utm_medium'),
            utm_campaign: this.$cookiz.get('utm_campaign'),
            utm_term: this.$cookiz.get('utm_term'),
            utm_content: this.$cookiz.get('utm_content'),
            web_id: JSON.stringify(this.$cookiz.get('gret')),
            ga: JSON.stringify(this.$cookiz.get('_ga'))
          })

          if (this.$route.path.includes('profile')) {
            window.location.reload(true)
          } else {
            this.fetchProducts()
          }

          // setTimeout(
          //   () =>
          //     this.showModal({
          //       component: 'ModalSubscriptionAdvert',
          //     }),
          //   7000
          // )

          // setTimeout(
          //   () => {
          //     if (this.user && !this.user?.game_accounts?.length) {
          //       this.showModal({
          //         component: 'ModalChooseGameToLinkAccount'
          //       })
          //     }
          //   },
          //   7000
          // )
        } else if (this.isRegistration && id) {
          this.$gtag.event('show_modal_event', { element: 'confirm-email' })

          try {
            window.fbq('track', 'CompleteRegistration')
          } catch (e) {
            console.log(e)
          }

          try {
            postUrl('KochavaTracker://sendEventString?nameString=registration_complete')
          } catch (e) {
            console.log(e)
          }

          // this.showModal({
          //   component: 'SuccessModal',
          //   data: {
          //     icon: 'email',
          //     title: this.$t('confirmEmail'),
          //     text: this.$t('confirmEmailText', {
          //       email: this.regFields.email.value
          //     })
          //   }
          // })
          try {
            sendPostback(this.$axios, this.$config)
          } catch (e) {
            console.log(e)
          }

          this.loginFields.login.value = this.regFields.email.value
          this.loginFields.password.value = this.regFields.password.value

          this.authorise(true)
        }
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: auth error ::`, e)

        const code = e.response?.data?.code
        const message = this.$errors.getText(e)
        const responseMessage = e.response?.data?.message

        if (e.response?.data?.code === 13) {
          this.showModal({
            component: 'BanModal',
            data: {
              reason: message
            }
          })
          this.$gtag.event('show_modal_event', { element: 'ban' })
        } else if (code) {
          this.errorMessage = message
        } else if (responseMessage) {
          this.errorMessage = responseMessage
        } else {
          // this.errorMessage = this.$t('errorMessageDefault')
          this.errorMessage = e
          // this.showModal({
          //   component: 'ModalRefreshPassword'
          // })
        }
      }

      this.$nuxt.$loading.finish()
    },

    onFieldInput (name, field) {
      this.errorMessage = ''
      this.$set(this.fields[name], 'error', '')

      if (name === 'confirmPassword' || name === 'password') {
        this.$set(this.fields.password, 'error', '')

        if (this.fields.confirmPassword) {
          this.$set(this.fields.confirmPassword, 'error', '')
        }
      }
    },

    onFieldBlur (name, field) {
      if (this.isRegistration && name === 'email') {
        if (this.fields[name].value) {
          if (
            !EMAIL_REGEX.test(String(this.fields[name].value).toLowerCase())
          ) {
            this.fields[name].error = this.$t('errorEmail')
          }
        }
      }

      if (this.isRegistration && name === 'login') {
        if (this.fields[name].value?.length < 5) {
          this.fields[name].error = this.$t('errorLogin')
        }
      }

      if (this.isRegistration && name === 'username') {
        if (this.fields[name].value?.length < 2) {
          this.fields[name].error = this.$t('errorLogin')
        }
      }

      if (name === 'confirmPassword' || name === 'password') {
        // if (
        //   this.fields.password.value &&
        //   this.fields.confirmPassword?.value &&
        //   this.fields.password.value !== this.fields.confirmPassword?.value
        // ) {
        //   this.fields.password.error = this.$t('errorPasswords')
        //   if (this.fields.confirmPassword) {
        //     this.fields.confirmPassword.error = this.$t('errorPasswords')
        //   }
        // } else {
        this.fields.password.error = ''
        if (this.fields.confirmPassword) {
          this.fields.confirmPassword.error = ''
        }

        if (
          this.fields.password.value &&
          this.fields.confirmPassword?.value &&
          this.fields.password.value.length < 5
        ) {
          this.fields.password.error = this.$t('errorLogin')
        }
      }
      // }

      if (field && field.type === 'password') {
        this.warningMessage = ''
      }

      this.$forceUpdate()
    },

    onFieldKeypress (event, field, index) {
      this.$gtag.event(`click_registration_modal_change_field_${index}`, {})

      this.warningMessage = ''
      if (field.type === 'password' && event.getModifierState('CapsLock')) {
        this.warningMessage = this.$t('errorCaps')
      }
    },

    onAgreementChange (value) {
      this.fields.agreement.value = value
      this.$gtag.event(`click_registration_modal_change_agreement_${value}`, {})
    },

    generateSocialAuthUrls () {
      const socialLinks = {
        vk: this.$config.VUE_APP_VK_AUTH_LINK,
        fb: this.$config.VUE_APP_FB_AUTH_LINK,
        discord: this.$config.VUE_APP_DC_AUTH_LINK,
        google: this.$config.VUE_APP_GO_AUTH_LINK
      }

      Object.keys(socialLinks).forEach((id) => {
        let oauthUrl = socialLinks[id]
        const state = {}

        const ref = this.$cookiz.get('ref') || window.localStorage?.getItem('ref')

        if (ref) {
          state.ref = ref
        }

        state.host = window.location.host

        if (this.isWebview) {
          state.auth_code = this.socialToken
        }

        const externalId = window.localStorage?.getItem('keitaroId')
        if (externalId) {
          state.external_id = externalId
        }

        const marketingSub1 = window.localStorage?.getItem('marketingSub1')
        if (marketingSub1) {
          state.sub1 = marketingSub1
        }

        const kochavaDeviceId = this.$cookiz.get('kochava_device_id', { parseJSON: false })
        if (kochavaDeviceId) {
          state.kochava_device_id = kochavaDeviceId
        }

        const utmSource = this.$cookiz.get('utm_source')
        if (utmSource) {
          state.utm_source = utmSource
        }

        const utmMedium = this.$cookiz.get('utm_medium')
        if (utmMedium) {
          state.utm_medium = utmMedium
        }

        const fbPixel = window.localStorage?.getItem('pixel')
        const fbClid = window.localStorage?.getItem('fbclid')
        if (fbPixel && fbClid) {
          state.pixel = fbPixel
          state.fb_click_id = fbClid
        }

        if (this.promocode && this.promocode !== '') {
          state.promocode = this.promocode
        }

        state.external_host = location.hostname

        state.cid = this.$cookiz.get('_ga')?.replace(/GA.+?\..+?\./, '')
        state.session_id = this.sessionId

        oauthUrl += `&state=${JSON.stringify(state)}`
        this.socialUrls[id] = oauthUrl.replace('accounts.google.com/o/oauth2/v2/auth', 'vvvapps.com/googleredirect')
      })
    },

    socialAuth ({ id }) {
      const socialLinks = {
        vk: this.$config.VUE_APP_VK_AUTH_LINK,
        fb: this.$config.VUE_APP_FB_AUTH_LINK,
        discord: this.$config.VUE_APP_DC_AUTH_LINK,
        google: this.$config.VUE_APP_GO_AUTH_LINK
      }

      let oauthUrl = socialLinks[id]
      const state = {}

      try {
        window.fbq('track', 'CompleteRegistration')
      } catch (e) {
        console.log(e)
      }

      if (this.isRegistration) {
        this.$gtag.event(`click_registration_modal_social_button_${id}`, {})

        const ref = this.$cookiz.get('ref') || window.localStorage?.getItem('ref')

        if (ref) {
          state.ref = ref
        }
      }

      state.host = window.location.host

      if (this.isWebview) {
        state.auth_code = `${new Date().getTime()}_${randomNumber(1000000, 9999999)}`
        this.setToken(state.auth_code)
      }

      const externalId = window.localStorage?.getItem('keitaroId')
      if (externalId) {
        state.external_id = externalId
      }

      const marketingSub1 = window.localStorage?.getItem('marketingSub1')
      if (marketingSub1) {
        state.sub1 = marketingSub1
      }

      const fbPixel = window.localStorage?.getItem('pixel')
      const fbClid = window.localStorage?.getItem('fbclid')
      if (fbPixel && fbClid) {
        state.pixel = fbPixel
        state.fb_click_id = fbClid
      }

      if (this.promocode && this.promocode !== '') {
        state.promocode = this.promocode
      }

      state.external_host = location.hostname

      state.cid = this.$cookiz.get('_ga')?.replace(/GA.+?\..+?\./, '')
      state.session_id = this.sessionId

      oauthUrl += `&state=${JSON.stringify(state)}`
      window.open(oauthUrl.replace('accounts.google.com/o/oauth2/v2/auth', 'vvvapps.com/googleredirect'))
    },

    setRegistrationState (state) {
      this.errorMessage = ''
      this.isRegistration = state
      this.$emit('recalculatePosition')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--auth {
  max-width: 490px;
}

.auth {
  &.--error {
    /* animation: shake 0.3s; */
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__btn-wraper {
    display: flex;
    align-items: center;
  }

  &__btn {
    @include heading4;

    padding: 0 16px 26px;
    position: relative;
    cursor: pointer;
    color: $blue-grey10;
    transition: 0.2s $easing;

    &::before {
      content: "";
      width: 100%;
      position: absolute;
      display: block;
      height: 4px;
      left: 0;
      bottom: 0;
      background: $primary60;
      transition: 0.2s $easing;
      transform: scale(0);
    }

    &.--active {
      color: $white;

      &::before {
        transform: scale(1);
      }
    }
  }

  &__text {
    @include caption-small;

    // margin-top: 24px;
    padding-right: 10px;
  }

  &__social {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 248px;
      height: 48px;
      min-width: 48px;
      margin: 0 8px;
    }
  }

  &__title {
    margin: 24px 0 0;
    position: relative;
    width: 60%;
    display: flex;
    justify-content: center;

    span {
      @include caption-small;

      color: $blue-grey10;
      width: fit-content;
      background: $blue-grey70;
      position: relative;
      z-index: 2;
      padding: 0 16px;
    }
  }

  &__line {
    position: absolute;
    width: 100%;
    top: 50%;
    height: 1px;
    background: $blue-grey30;
    transform: translateY(-50%);
  }

  &__input {
    margin-top: 24px;
    width: 100%;

    .checkbox-input__label {
      align-items: flex-start;
    }

    .checkbox-input__text {
      min-width: 0;
    }

    &-button {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 100%;
      font-weight: $font-medium;
      color: $grey20;
      margin-bottom: 16px;
      cursor: pointer;
    }

    &-centered {
      text-align: center;
    }

    &-link {
      color: $primary60;
      text-decoration: underline;
    }
  }

  &__send {
    width: 100%;
    margin-top: 32px;

    .button {
      width: 100%;
    }
  }

  &__promocode {
    margin-top: 24px;

    .form-input__label {
      border: 1px solid #D4AA13;
    }
  }

  .info-message {
    margin-top: 32px;
  }

  svg {
    width: 30px;
    height: 30px;
  }
}
</style>
