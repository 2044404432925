<template lang="pug">
.modal-mission-betfury
  img.modal-mission-betfury__img(:src="image" alt="Mission")
  .modal-mission-betfury__container
    h1.modal-mission-betfury__title(v-t="'betfuryTitle'")
    p.modal-mission-betfury__text(v-html="$t('betfuryBody')")
    Button.modal-mission-betfury__button#modal-mission-betfury-proceed(is-primary @click="startMission") {{ $t('proceed') }}
    Button.modal-mission-betfury__button#modal-mission-betfury-twitter(is-secondary @click="goToTwitter") {{ $t('twitter') }}
    Button.modal-mission-betfury__button#modal-mission-betfury-completed(is-secondary @click="completeMission") {{ $t('iCompleted') }}
</template>

<script>
export default {
  data () {
    return {
      image: 'https://cdn.gaming-goods.com/images/modals/betfury.png',
      buttonLink: 'https://betfury.gg/?r=63077217fcaa0f1102f3a81e',
      twitterLink: 'https://twitter.com/betfury_gaming'
    }
  },

  methods: {
    startMission () {
      this.$gtag.event('mission_start', { mission_id: 'betfury' })
      window.open(this.buttonLink)
    },

    goToTwitter () {
      window.open(this.twitterLink, '_blank')
    },

    completeMission () {
      this.$gtag.event('mission_complete_request', { mission_id: 'betfury' })

      this.$store.commit('modal/toggle')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalmissionbetfury {
  width: 100%;
  max-width: 100%;
  padding: 0 !important;
  height: 100vh;

  @media (min-width: $breakpointTablet) {
    height: auto;
    min-width: 360px;
    max-width: 508px !important;
  }
}

.modal-mission-betfury {
  &__img {
    background: #000;
    width: 100%;
    max-height: 210px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__container {
    background-color: $blue-grey70;
    margin-top: 16px;
    padding: 0 16px;
    color: $white;

    @media (min-width: $breakpointTablet) {
      padding: 0 32px;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    color: $white;

    * {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    li {
      list-style-type: decimal;
      margin-left: 20px;
      padding-left: 6px;
    }
  }

  &__link {
    cursor: pointer;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 8px;
    padding: 24px !important;
    max-height: 48px;

    @media (min-width: $breakpointTablet) {
      margin-bottom: 13px;
    }
  }
}
</style>
