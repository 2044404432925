<template lang="pug">
  .clash-royale-account
    .modal__header {{ $t('account') }} Clash Royale

    .clash-royale-account__body
      Steps(:steps="stepsTitles" :current-step-index="stepIndex")

      .clash-royale-account__step(v-if="stepIndex === 0")
        .clash-royale-account__item
          FormInput(:title="$t('gameAccountTagTitle')" placeholder="#XXXXXX" v-model="playerTag")
        .clash-royale-account__item
          ImageSlider(:slides="tagSlides")

      .clash-royale-account__step(v-if="stepIndex === 1")
        .clash-royale-account__confirm-title {{ $t('clashAccountIsYours') }}
        ClashRoyaleGameAccount(:player="playerData")

      .clash-royale-account__step(v-if="stepIndex === 2")
        .fourth-step__first
          InfoMessage(type="allow" :title="$t('clashAccountSuccess')")

      .clash-royale-account__item(v-if="errorMessage")
        InfoMessage(type="error") {{ errorMessage }}

    .modal__footer
      Button(v-if="stepIndex === 1" is-secondary @click="onPreviousStepClick") {{ $t('clashAccountNotMy') }}
      Button(is-primary :is-disabled="isButtonDisabled" @click="onNextStepClick") {{ primaryButtonText }}

</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ImageSlider from '~/components/ImageSlider/ImageSlider'
import FormInput from '~/components/FormInput/FormInput'
import Steps from '~/components/Steps/Steps'
import InfoMessage from '~/components/InfoMessage/InfoMessage'
import ClashRoyaleGameAccount from '~/components/ClashRoyaleAccount/ClashRoyaleGameAccount'

const CDN_URL = 'https://cdn.gaming-goods.com/games/clash-royale/verification'

export default {
  name: 'ClashRoyaleAccount',

  components: {
    FormInput,
    ImageSlider,
    Steps,
    InfoMessage,
    ClashRoyaleGameAccount
  },

  data () {
    return {
      steps: [
        {
          title: this.$t('clashAccountSearch'),
          action: () => this.searchGameAccount()
        },
        {
          title: this.$t('stepConfirm'),
          action: () => this.linkGameAccount()
        },
        {
          title: this.$t('stepSuccess'),
          // action: () => this.toggle()
          action: () => {
            this.showModal({
              component: 'ModalTournamentsAfterAccountLink',
              canClose: false,
              data: {
                gameName: 'Clash Royale',
                gameId: 2
              }
            })
          }
        }
      ],
      errorMessage: '',
      stepIndex: 0,

      playerTag: '',
      playerData: null
    }
  },

  computed: {
    tagSlides () {
      return [
        {
          text: this.$t('clashLinkSlide1'),
          image: CDN_URL + '/slider_tag/1.gif'
        },
        {
          text: this.$t('clashLinkSlide2'),
          image: CDN_URL + '/slider_tag/2.gif'
        },
        {
          text: this.$t('clashLinkSlide3'),
          image: CDN_URL + '/slider_tag/3.gif'
        }
      ]
    },

    stepsTitles () {
      return this.steps.map(step => step.title)
    },

    isButtonDisabled () {
      return this.stepIndex === 0 && !this.playerTag
    },

    primaryButtonText () {
      return [
        this.$t('buttonContinue'),
        this.$t('clashAccountIsMy'),
        this.$t('buttonFinish')
      ][this.stepIndex]
    },

    refactoredTag () {
      let tag = this.playerTag

      if (!tag?.startsWith('#')) {
        tag = '#' + this.playerTag
      }

      return tag?.toUpperCase()
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('modal', ['toggle']),

    onNextStepClick () {
      this.errorMessage = ''

      this.steps[this.stepIndex].action()
    },

    async searchGameAccount () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.get(
          '/games/clash-royale/account/info',
          {
            params: { tag: this.refactoredTag }
          }
        )

        if (!data.error) {
          this.playerData = data.data
          this.stepIndex += 1
        } else {
          this.errorMessage = data.message || this.$t('errorMessageDefault')
        }
      } catch (e) {
        this.errorMessage = this.$t('brawlLinkTagError')
      }

      this.$nuxt.$loading.finish()
    },

    async linkGameAccount () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post('/games/clash-royale/account', {
          tag: this.refactoredTag
        })

        if (data.data) {
          this.stepIndex += 1

          this.$gtag.event('game_account_link', { step: 3, game: 'clash-royale' })
        } else {
          this.errorMessage = this.$t('clashLinkError')
        }
      } catch (e) {
        this.errorMessage = this.$errors.getText(e)
      }

      this.$nuxt.$loading.finish()
    },

    async onPreviousStepClick () {
      this.errorMessage = ''

      if (this.stepIndex === 1) {
        this.playerTag = ''
        this.stepIndex -= 1
      }

      if (this.stepIndex === 2) {
        this.$nuxt.$loading.start()

        try {
          const { data } = await this.$axios.delete(
            '/games/clash-royale/account'
          )

          if (data.data) {
            this.stepIndex -= 1
          } else {
            this.errorMessage = this.$t('errorMessageDefault')
          }
        } catch (e) {
          this.errorMessage = this.$errors.getText(e)
        }

        this.$nuxt.$loading.finish()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--clashroyaleaccount {
  padding-bottom: 0;
  max-width: 736px;
}

.clash-royale-account {
  &__body {
    margin: 40px 0;
  }

  &__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $blue-grey30;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &.--no-padding {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__confirm-title {
    @include heading5;

    font-size: 20px;
    color: $white;
    margin-bottom: 16px;
  }

  .steps {
    margin-bottom: 40px;
  }

  .modal__footer {
    .button:last-child {
      margin-left: 16px;
    }
  }

  .info-message.--error {
    margin-top: 16px;
  }
}
</style>
