<script>
import FormInput from '@/components/FormInput/FormInput.vue'
import StoreService from '@/services/StoreService'
import { mapMutations } from 'vuex'

export default {
  name: 'ModalLeaveFeedback',

  components: {
    FormInput
  },

  props: {
    orderId: {
      type: Number,
      required: true
    },

    sellerId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      stars: undefined,
      comment: ''
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    getStarClass (star) {
      return {
        [this.$style.star__img]: true,
        [this.$style.star__imgActive]: star <= this.stars
      }
    },

    setStars (star) {
      this.stars = star
    },

    close () {
      this.toggle()
    },

    async sendReview () {
      const result = await StoreService.sendReview(this.$axios, this.orderId, this.stars, this.comment)

      console.log(result)

      this.close()
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('reviewOrder') }}
    </p>

    <div :class="$style.stars">
      <div v-for="i in 5" :key="`star_1_${i}`" :class="$style.star">
        <InlineSvg :src="require('~/assets/img/star.svg')" :class="getStarClass(i)" @click="setStars(i)" />
      </div>
    </div>

    <FormInput v-model="comment" :placeholder="$t('becomeAdvertiserComment')" type="textarea" />

    <Button :class="$style.button" is-primary @click="sendReview">
      {{ $t('buttonSend') }}
    </Button>
  </div>
</template>

<style>
.modal--modalleavefeedback {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 32px;
}

.stars {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-gap: 9px;
  margin: 24px auto;
  width: max-content;

  &:hover {
    .modal-questionnaire__star-img {
      path {
        fill: $amber40;
      }
    }
  }
}

.star {
  width: 100%;

  &:hover {
    ~ .modal-questionnaire__star {
      .modal-questionnaire__star-img {
        path {
          fill: $blue-grey10;
        }
      }
    }
  }

  &__img {
    cursor: pointer;

    &Active {
      path {
        fill: $amber90 !important;
      }
    }

    path {
      fill: $blue-grey10;
    }

    &:hover {
      path {
        fill: $amber40;
      }
    }
  }
}

.button {
  min-width: 100%;
  margin-top: 60px;
}
</style>
