<template lang="pug">
  .email-modal
    .email-modal__body
      .email-modal__icon
        InlineSvg(:src="require(`~/assets/img/icons/readed-mail.svg`)")

      .email-modal__title {{ $t('emailModalTitle') }}

      .email-modal__input
        FormInput(:placeholder="$t('emailModalPlaceholder')" v-model="email" @blur="onFieldBlur" @input="onFieldInput")

      InfoMessage(v-if="error.length" type="error").email-modal__error
        | {{ error }}

      .email-modal__btn
        Button(is-primary @click="onSendEmailClick" :is-disabled="error.length || !email.length")
          | {{ $t('buttonSend') }}

</template>

<script>
import FormInput from '~/components/FormInput/FormInput'
import { EMAIL_REGEX } from '~/utils/constants'

export default {
  name: 'EmailModal',

  components: {
    FormInput
  },

  data () {
    return {
      email: '',
      error: ''
    }
  },

  methods: {
    async onSendEmailClick () {
      this.$nuxt.$loading.start()

      const putData = {
        email: this.email.trim()
      }

      try {
        await this.$axios.put('/user', putData)

        if (putData.email) {
          this.$store.dispatch('modal/showModal', {
            component: 'SuccessModal',
            data: {
              icon: 'email',
              title: this.$t('confirmEmail'),
              text: this.$t('confirmEmailText', { email: putData.email })
            }
          })
        }
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: email send ::`, e)

        this.$store.dispatch('modal/showModal', {
          component: 'ModalMessage',
          data: {
            text: this.$errors.getText(e),
            buttons: [
              {
                props: { isPrimary: true },
                title: this.$t('tryAgain'),
                shouldClose: false,
                action: () => {
                  this.$store.dispatch('modal/showModal', {
                    component: 'EmailModal'
                  })
                }
              }
            ]
          }
        })
      }

      this.$nuxt.$loading.finish()
    },

    onFieldInput () {
      this.error = ''
    },

    onFieldBlur () {
      if (!EMAIL_REGEX.test(String(this.email.trim()).toLowerCase())) {
        this.error = this.$t('errorEmail')
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--emailmodal {
  max-width: 505px;
  padding-bottom: 0;
}

.email-modal {
  &__body {
    padding: 24px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include heading2;

    color: $white;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  &__input {
    margin-bottom: 16px;
    width: 100%;
  }

  &__btn,
  .button {
    width: 100%;
  }

  &__error {
    margin-bottom: 16px;
  }
}
</style>
