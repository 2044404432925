<template lang="pug">
  .tournament-slider
    .tournament-slider__body
      TournamentCard(:tournament="tournaments[currentTournamentIndex]")

      .tournament-slider__button(v-if="showPrevButton" class="--left" @click="onButtonClick(false)")
        InlineSvg(:src="require('~/assets/img/icons/arrow-left.svg')")

      .tournament-slider__button(v-if="showNextButton" class="--right" @click="onButtonClick(true)")
        InlineSvg(:src="require('~/assets/img/icons/arrow-right.svg')")

    .tournament-slider__indexes
      .tournament-slider__indexes-item(v-for="index in tournaments.length" :class="{ '--active': index - 1 === currentTournamentIndex }")

</template>

<script>
import TournamentCard from '~/components/TournamentCard/TournamentCard'

export default {
  name: 'TournamentSlider',

  components: { TournamentCard },

  props: {
    tournaments: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      currentTournamentIndex: 0
    }
  },

  computed: {
    showPrevButton () {
      return this.currentTournamentIndex !== 0
    },

    showNextButton () {
      const lastIndex = this.tournaments.length - 1
      return this.currentTournamentIndex !== lastIndex
    }
  },

  methods: {
    onButtonClick (isNext) {
      if (isNext) {
        this.currentTournamentIndex += 1
      } else {
        this.currentTournamentIndex -= 1
      }
    }
  }
}
</script>

<style lang="scss">
.tournament-slider {
  &__body {
    position: relative;
  }

  &__button {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue-grey60;
    border: 1px solid $blue-grey40;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 1;

    &.--left {
      left: -20px;
    }

    &.--right {
      right: -20px;
    }

    &:hover {
      background: $blue-grey50;
    }
  }

  &__indexes {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    &-item {
      height: 4px;
      width: 32px;
      background: $blue-grey20;
      border-radius: 19px;

      &.--active {
        background: $amber60;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .tournament-card {
    background: $blue-grey60;
  }
}
</style>
