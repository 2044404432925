<template lang="pug">
  NuxtLink.tournament-card(
    v-if="tournament"
    :class="{ '--list-item': view === 'list', '--big': view === 'big', '--cell': view === 'cells', '--small': view === 'list' && isSmall, '--white': isWhite }"
    :to="localeLocation(`/${game.name}/${tournament.is_mega_tournament ? 'phased-tournaments' : 'tournaments'}/${tournament.id}`)"
    v-on="$listeners"
    v-bind="$attrs"
  )
    .tournament-card__body
      .tournament-card__image
        img(v-if="tournamentImage" :src="tournamentImage", :alt="tournament.title" v-lazy-load)

        .tournament-card__status(:class="statusClass")
          InlineSvg(:src="require('~/assets/img/icons/status.svg')")
          span {{ statusText }}

        .tournament-card__private(v-if="tournament.is_private" :title="$t('privateTournamentSubtitle')")
          InlineSvg(:src="require(`~/assets/img/icons/lock.svg`)")

        .tournament-card__freeroll(v-else-if="!isTournamentSponsored && isFreeroll") Freeroll
        TournamentCardLabels(v-if="view === 'cells'" :labels="labels" :is-white="isWhite")

      .tournament-card__content
        .tournament-card__content-top
          .tournament-card__info
            .tournament-card__date
              span {{ date }} {{ $t('timeAt') }} {{ time }}
              span ·
              span {{ gameExtraDataTitle }}
            .tournament-card__title
              | {{ tournament.title }}
              .tournament-card__youtube(v-if="youtubeLink")
                InlineSvg(:src="require('~/assets/img/icons/youtube.svg')")
                | {{ $t('streamButton') }}
              .tournament-card__twitch(v-if="twitchLink")
                InlineSvg(:src="require('~/assets/img/icons/twitch.svg')")
                | {{ $t('streamButton2') }}

          .tournament-card__players(v-if="view === 'list' && players.length")
            .caption
              | {{ $t('membersCount') }}&nbsp;
              strong {{ tournament.players_count }}

        TournamentCardLabels(v-if="view !== 'cells' && !isSmall" :labels="labels")

        .tournament-card__players(v-if="view !== 'list'")
          .caption
            InlineSvg.tournament-card__participant-icon(:src="require('~/assets/img/icons/players.svg')")
            strong {{ membersText }}

          Button(is-primary).tournament-card__participant-link
            | {{ $t('participate') }}

    TournamentCardLabels(v-if="view === 'list' && isSmall" :labels="labels")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { EVENT_STATUSES } from '~/utils/constants'
import { getPrizePool } from '~/utils/utils'
import TournamentMixin from '~/mixins/Tournament'
import Players from '~/components/Players/Players'
import TournamentCardLabels from '~/components/TournamentCard/TournamentCardLabels'

export default {
  name: 'TournamentCard',

  components: { TournamentCardLabels, Players },

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      required: true
    },

    view: {
      type: String,
      default: 'cells'
    },

    isSmall: {
      type: Boolean,
      default: false
    },

    isWhite: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapState('games', ['gamesById']),
    ...mapGetters('viewport', ['isMobile', 'isTablet']),

    gameExtraDataTitle () {
      return this.game?.extra_data?.title
    },

    generalLabels () {
      let prizeIcon = 'trophy'
      let prizeTitle = this.$t('prizePool')
      let prizeValue = this.formatNumber(this.prizePool)

      const isNotFixedPrizePool = [
        'not_fixed_with_guarantee',
        'not_fixed'
      ].includes(this.tournament.prize_settings?.prize_pool_type)

      if (this.isDoublePrize) {
        prizeIcon = 'x2'
        prizeTitle = this.$t('prizeWinnerReward')
        prizeValue = this.formatNumber(this.doublePrize)
      } else if (this.tournament.prize_settings?.prize_pool_type === 'fixed') {
        prizeIcon = 'fixed'
        prizeTitle = this.$t('constantPrizePool')
      } else if (isNotFixedPrizePool) {
        prizeIcon = 'not-fixed'
        prizeTitle = this.$t('risingPrizePool')
      }

      const items = [
        {
          id: 'cost',
          title: this.$t('entryFee'),
          icon: 'cost',
          value: this.formatNumber(this.bonus?.value || this.entryFee),
          currency: {
            type: this.tournament.prize_settings?.entry_fee_currency
          },
          tickets: this.isPaid ? this.ticketsPrice : null,
          bonus: this.bonus?.percents,
          crossed: this.bonus ? this.formatNumber(this.entryFee) : null
        },
        {
          id: 'prize',
          title: prizeTitle,
          icon: prizeIcon,
          value: prizeValue,
          currency: {
            type: this.tournament.prize_settings?.prize_currency
          }
        }
      ]

      const ratingValue = this.getRatingText()
      if (ratingValue) {
        items.unshift({
          id: 'rating',
          title: this.$t('rating'),
          icon: 'new-rating',
          value: this.getRatingText()
        })
      }

      if (this.withRewardsPoints) {
        let value = ''

        if (this.tournament.state !== 'completed') {
          value = ''
        } else if (this.tournament.prizes?.point) {
          value =
            this.tournament.prize_settings?.prize_currency === 'money'
              ? this.tournament.prizes?.point / 100
              : this.tournament.prizes?.point
          value = value.toFixed(2).replace('.00', '')
        }

        items.push({
          icon: 'target',
          title: this.$t('rewardForKill'),
          value,
          currency:
            this.tournament.prizes?.point &&
            this.tournament.state === 'completed'
              ? {
                type: this.tournament.prize_settings?.prize_currency
              }
              : null
        })
      }

      if (this.tournament.prize_settings?.nominations?.length) {
        items.push({
          icon: 'nominations',
          title: this.$t('nominations'),
          value: this.tournament.prize_settings?.nominations?.length
        })
      }

      if (
        this.tournament.prize_settings?.place_distribution_type !== 'point' &&
        ['top', 'percentage'].includes(
          this.tournament.prize_settings?.prize_winner_type
        )
      ) {
        const prizes = {
          id: 'places',
          icon: 'star-with-paddings'
        }
        const placesCount = this.tournament.prize_settings.win_place_count

        if (this.tournament.prize_settings.prize_winner_type === 'top') {
          prizes.title = this.$t('prizePlacesCount')
          prizes.value = placesCount
        } else if (
          this.tournament.prize_settings.prize_winner_type === 'percentage'
        ) {
          prizes.title = this.$t('prizePlacesPercentage')
          prizes.value = `${placesCount}%`
        }

        items.push(prizes)
      }

      return items
    },

    lichessLabels () {
      let clock

      if (this.tournament.extra_data?.state?.clock?.limit) {
        clock = {
          limit: this.tournament.extra_data.state.clock.limit / 60,
          increment: this.tournament.extra_data.state.clock.increment
        }
      } else if (this.tournament.extra_data?.config?.clockTime) {
        clock = {
          limit: this.tournament.extra_data.config.clockTime,
          increment: this.tournament.extra_data.config.clockIncrement
        }
      }

      const labels = [...this.generalLabels]

      if (clock) {
        labels.push({
          title: this.$t('timeControl'),
          icon: 'time',
          value: `${clock.limit}+${clock.increment}`
        })
      }

      if (this.tournament.extra_data.state?.minutes) {
        labels.push({
          icon: 'duration',
          title: this.$t('durationInMinutes'),
          value: this.tournament.extra_data.state?.minutes
        })
      }

      // if (this.tournament.extra_data.config?.berserkable) {
      //   labels.push({
      //     icon: 'berserk',
      //     title: 'Берсерк разрешён',
      //     value: 'Берсерк',
      //   })
      // }

      // if (this.tournament.is_anticheat_required) {
      //   labels.push({
      //     title: this.$t('anticheatIsRequired'),
      //     icon: 'antichit-warning',
      //     value: this.$t('anticheat'),
      //   })
      // }

      // if (this.webcamLink) {
      //   labels.push({
      //     title: this.$t('videoIsRequired'),
      //     icon: 'cam',
      //     value: this.$t('videoChat')
      //   })
      // }

      return labels
    },

    pubgLabels () {
      return [
        ...this.generalLabels,
        {
          title: this.$t('gameMode'),
          icon: 'gamepad',
          value: this.tournament.extra_data.config.mode
        },
        {
          title: this.$t('map'),
          icon: 'world',
          value: this.$t(this.tournament.extra_data.config.map)
        }
      ]
    },

    brawlStarsLabels () {
      return [
        ...this.generalLabels,
        {
          title: this.$t('gameMode'),
          icon: 'gamepad',
          value: this.$t(this.tournament.extra_data.config?.mode)
        },
        {
          title: this.$t('map'),
          icon: 'world',
          value: this.$t(this.tournament.extra_data.config?.map)
        }
      ]
    },

    clashRoyaleLabels () {
      return [
        ...this.generalLabels,
        {
          title: this.$t('gameMode'),
          icon: 'gamepad',
          value: this.$t(this.tournament.extra_data.config.game_mode)
        },
        {
          title: this.$t('maxLosesCount'),
          icon: 'max-loses',
          value:
            this.tournament.extra_data?.config?.max_loses_count !== 0
              ? this.tournament.extra_data?.config?.max_loses_count
              : '∞'
        },
        {
          icon: 'duration',
          title: this.$t('durationInMinutes'),
          value: this.tournament.extra_data.config.duration
        }
      ]
    },

    labels () {
      if (this.game.name === 'lichess') {
        return this.lichessLabels
      } else if (this.game.name === 'clash-royale') {
        return this.clashRoyaleLabels
      } else if (this.game.name === 'brawl-stars') {
        return this.brawlStarsLabels
      }

      return this.pubgLabels
    },

    statusText () {
      return this.$t(EVENT_STATUSES[this.tournament.state])
    },

    players () {
      if (this.tournament.players_count) {
        return Array(this.tournament.players_count).fill({})
      } else if (this.tournament.players?.length) {
        return this.tournament.players
      } else if (this.tournament.teams?.length) {
        return this.tournament.teams
      }

      return []
    },

    date () {
      return this.$dateFns.format(this.startsAt, 'd MMMM', {
        locale: this.$i18n.locale
      })
    },

    time () {
      return this.$dateFns.format(this.startsAt, 'HH:mm', {
        locale: this.$i18n.locale
      })
    },

    prizePool () {
      return getPrizePool(this.tournament)
    },

    statusClass () {
      const state = this.tournament.state

      return {
        'tournament-card__status--opened': state === 'open',
        'tournament-card__status--closed': ['completed', 'cancelled'].includes(
          state
        ),
        'tournament-card__status--started': state === 'in_progress'
      }
    },

    game () {
      return this.gamesById[this.tournament.game_id] || {}
    },

    membersText () {
      const max = this.tournament?.tournament_player_settings?.max_players_count
      const text = max && !this.isSmall ? ` / ${max}` : ''

      return this.tournament?.players_count + text
    },

    tournamentImage () {
      return this.tournament?.image_url
    }
  },

  methods: {
    plural: require('plural-ru')
  }
}
</script>

<style lang="scss">
.tournament-card {
  width: 100%;
  border-radius: 8px;
  background: $blue-grey70;
  display: block;
  transition: box-shadow 0.2s $easing;

  &.--white {
    background: #f0f4f5;
    color: $blue-grey60;

    .tournament-card__content {
      * {
        color: $blue-grey80;
      }
    }

    .tournament-card__labels {
      background-color: rgb(240 245 247 / 75%);
      color: $blue-grey80;
    }

    .button--primary {
      background: #fec70a !important;

      &:hover {
        background: #fec70a !important;

        &::before {
          background: #fec70a !important;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
  }

  &__youtube {
    @include hint-small;

    margin-top: 8px;
    color: $red60;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }

  &__twitch {
    @include hint-small;

    margin-top: 8px;
    color: #9147ff;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;

      path {
        fill: #9147ff;
      }
    }
  }

  &__image {
    padding: 8px 8px 0;
    position: relative;
    width: 100%;
    height: 268px;
    min-height: 268px;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      height: 100%;
      border-radius: 8px;
    }
  }

  &__freeroll {
    height: 26px;
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 0 10px;
    border-radius: 0 8px;
    background: #4caf50;
    color: #fff;
    line-height: 26px;
    font-size: 17px;
    font-weight: 500;
  }

  &__private {
    height: 32px;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 40px;
    border-radius: 0 8px;
    background: $orange60;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__labels {
    position: absolute;
    left: 7px;
    right: 7px;
    bottom: -1px;
    display: flex;
    flex-wrap: wrap;
    background: $black80;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    z-index: 1;
    padding: 4px 0 0;
  }

  &__status {
    height: 32px;
    position: absolute;
    left: 8px;
    top: 8px;
    background: $black-transparent;
    backdrop-filter: blur(8px);
    border-radius: 8px 0;
    padding: 0 10px 0 8px;
    display: flex;
    align-items: center;

    &--opened {
      svg,
      path {
        fill: $green50;
      }
    }

    &--closed {
      svg,
      path {
        fill: $red50;
      }
    }

    &--started {
      svg,
      path {
        fill: $amber60;
      }
    }

    svg {
      margin-right: 6px;
      position: relative;
      top: -1px;
    }

    > span {
      @include heading7;

      color: $white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;

    &-top {
      flex-grow: 1;
    }
  }

  &__info {
    padding: 24px 20px;
    height: 100%;
  }

  &__date {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #9bb0cb;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 600;

    span {
      margin-right: 8px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #fff;
    margin-top: 8px;
  }

  &__players {
    justify-content: space-between;
    padding: 16px 20px;
    min-height: 72px;
    max-height: 72px;
    display: flex;
    align-items: center;
  }

  &:hover {
    box-shadow:
      0 5px 5px -3px rgb(0 0 0 / 20%),
      0 3px 10px 1px rgb(0 0 0 / 14%),
      0 1px 14px 2px rgb(0 0 0 / 12%);
  }

  &.--list-item {
    margin-bottom: 24px;
    width: 100%;
    position: relative;

    .tournament-card {
      &__body {
        flex-direction: row;
      }

      &__image {
        width: 232px;
        min-width: 232px;
        min-height: 130px;
        height: 130px;
        padding: 8px;
        box-sizing: content-box;
      }

      &__content {
        margin-left: 24px;
        justify-content: initial;

        &-top {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
        }
      }

      &__info {
        margin: 0 24px 0 0;
        padding: 18px 0;
        border: none;
        flex-grow: 1;
        height: auto;
      }

      &__labels {
        position: static;
        margin: 16px 24px 12px 0;
        background: $blue-grey60;
        width: fit-content;
      }

      &__players {
        padding: 0;
        margin: 18px 24px 18px 0;
        height: auto;
        min-height: auto;
        border-top: none;
      }
    }

    &.--small {
      margin-bottom: 16px;
      padding-bottom: 12px;

      .tournament-card {
        &__body {
          border-bottom: 1px solid $blue-grey40;
        }

        &__content {
          margin-left: 16px;

          &-top {
            flex-direction: column;
            flex-wrap: nowrap;
            border: none;
          }
        }

        &__players {
          margin-top: 12px;
          min-height: 44px;
        }

        &__labels {
          margin: 12px 8px 0;
        }
      }
    }
  }

  &.--big {
    .tournament-card {
      &__body {
        flex-direction: row;
        border-bottom: none;
      }

      &__content {
        margin-left: 16px;

        &-top {
          flex-direction: column;
          flex-wrap: nowrap;
          border: none;
        }
      }

      &__status {
        left: 16px;
        top: 16px;
      }

      &__freeroll {
        right: 16px;
        top: 16px;
      }

      &__info {
        padding-left: 0;
        border: none;
      }

      &__title {
        font-size: 32px;
        line-height: 32px;
      }

      &__date {
        font-weight: $font-medium;
        font-size: 16px;
      }

      &__players {
        margin: 0;
        min-height: 44px;
        padding: 16px 16px 16px 0;
        display: flex;
        justify-content: space-between;
      }

      &__labels {
        position: static;
        margin: 16px 16px 12px 0;
        background: $blue-grey60;
        width: fit-content;
      }

      &__image {
        width: 48%;
        min-width: 48%;
        height: auto;
        min-height: 270px;
        max-height: 405px;
        margin: 0;
        padding: 16px;

        img {
          height: 100%;
        }
      }
    }
  }
}

.tournament-card__participant-icon {
  padding-right: 7px;
  width: 23px;
  height: 23px;
  position: relative;
  top: -2px;
}

.tournament-card__participant-link {
  @media (max-width: $breakpointMobile) {
    padding: 7px 14px !important;
  }
}
</style>
