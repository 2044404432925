<template lang="pug">
.modalvvvbonusreceived
  InlineSvg.modalvvvbonusreceived__image(:src="require('~/assets/img/backgrounds/euros.svg')")

  p.modalvvvbonusreceived__title(v-t="'rewardReceived'")

  p.modalvvvbonusreceived__subtitle(v-t="'reward'")

  .modalvvvbonusreceived__reward
    Currency(type="coins" is-reversed) {{ coins }}
    template(v-if="money > 0")
      | +
      Currency(type="money" is-reversed real) {{ formattedMoney }}

  Button.modalvvvbonusreceived__close(is-primary @click="toggle") {{ $t('buttonClose') }}
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'ModalVVVBonusReceived',

  props: {
    coins: {
      type: Number,
      required: true
    },
    money: {
      type: Number,
      required: true
    }
  },

  computed: {
    formattedMoney () {
      return (this.money / 100).toFixed(2).replace('.00', '')
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle'])
  }
}
</script>

<style lang="scss">
.modal.modal--modalvvvbonusreceived {
  max-width: 528px;
}
</style>

<style lang="scss" scoped>
.modalvvvbonusreceived {
  color: $white;
  text-align: center;

  &__image {
    margin-bottom: 29px;
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  &__subtitle {
    color: $blue-grey05;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 0;
  }

  &__reward {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue-grey80;
    border-radius: 8px;
    padding: 16px;
  }

  &__close {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
