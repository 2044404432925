<script>
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import NumberInput from '@/components/NumberInput/NumberInput.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import StoreService from '@/services/StoreService'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EditProductModal',

  components: {
    BaseSelect,
    NumberInput,
    FormInput
  },

  props: {
    product: {
      type: Object,
      required: true
    },

    updated: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      activeServiceType: 'game',
      price: (this.product.min_price / 100).toFixed(2),
      amount: this.product.quantity.toString(),
      description: this.product.description,
      shortDescription: this.product.short_description,
      additionalData: this.product.specifications?.reduce((a, b) => ({ ...a, [b.key]: b.value }), {}),
      updateInProgress: false,
      autoData: this.product.auto_messages
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('marketplace', ['apps']),

    currentApp () {
      if (!this.apps) {
        return undefined
      }

      console.log(this.product)

      return this.apps.filter(app => app.categories.map(c => c.id).includes(this.product.application_category_id))
        ?.map(app => ({ ...app, value: app.id.toString() }))
        ?.[0]
    },

    currentAppName () {
      return this.currentApp?.title || '-'
    },

    appCategories () {
      return this.currentApp?.categories?.map(category => ({
        title: category?.category_name,
        value: category?.id?.toFixed(0)
      }))
    },

    category () {
      return this.product?.application_category_id
    },

    categoryName () {
      return this.appCategories?.find(c => +c.value === this.category)?.title || '-'
    },

    categoryId () {
      return +(this.appCategories?.find(c => c.value === this.category)?.value || 0)
    },

    additionalFields () {
      return this.currentApp?.categories?.find(c => c.id === this.category)?.fields || []
    },

    customerPrice () {
      if (this.price === 0) {
        return '-'
      }

      return (Math.ceil((this.price * 1.05) * 100) / 100).toFixed(2)
    },

    canUpdate () {
      return this.price > 0 && this.description && this.description.trim() !== '' && this.shortDescription && this.shortDescription.trim() !== ''
    }
  },

  mounted () {
    if (!this.apps) {
      this.fetchApps()
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('marketplace', ['fetchApps']),

    selectorVariantStyles (type) {
      return {
        [this.$style.selector__variant]: true,
        [this.$style.selector__variantActive]: type === this.activeServiceType
      }
    },

    roundStyles (type) {
      return {
        [this.$style.selector__iconRound]: true,
        [this.$style.selector__iconRoundActive]: type === this.activeServiceType
      }
    },

    enumToOptions (enumData) {
      return enumData.map(value => ({
        title: value,
        value
      }))
    },

    async deleteProduct () {
      if (this.updateInProgress) {
        return
      }

      this.updateInProgress = true
      this.$nuxt.$loading.start()

      const product = {
        id: this.product.id,
        quantity: 0
      }

      try {
        const { data } = await StoreService.updateMarketplaceProduct(this.$axios, product)
        this.$nuxt.$loading.finish()

        if (data?.data) {
          this.updated()
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('success'),
              type: 'info',
              text: this.$t('success')
            }
          })
        }
      } catch (e) {
        this.$nuxt.$loading.finish()
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('error'),
            type: 'error',
            text: this.$t('error')
          }
        })
      }
    },

    async updateProduct () {
      if (!this.canUpdate || this.updateInProgress) {
        return
      }

      this.updateInProgress = true
      this.$nuxt.$loading.start()

      const product = {
        id: this.product.id,
        description: this.description,
        short_description: this.shortDescription,
        specifications: this.additionalData ? Object.keys(this.additionalData).map(key => ({
          key,
          value: this.additionalData[key]
        })) : [],
        min_price: +(this.price * 100).toFixed(0),
        quantity: this.amount
      }

      if (this.product.auto_messages) {
        product.auto_messages = this.autoData
      }

      try {
        const { data } = await StoreService.updateMarketplaceProduct(this.$axios, product)
        this.$nuxt.$loading.finish()

        if (data?.data) {
          this.updated()
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('success'),
              type: 'info',
              text: this.$t('success')
            }
          })
        }
      } catch (e) {
        this.$nuxt.$loading.finish()
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('error'),
            type: 'error',
            text: this.$t('error')
          }
        })
      }
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('buttonEdit') }}
    </p>

    <p :class="$style.subtitle">
      {{ $t("generalInfoAboutService") }}
    </p>

    <template v-if="apps">
      <p :class="$style.label">
        {{ currentAppName }}
      </p>
    </template>

    <template>
      <p :class="$style.label">
        {{ $t('category') }} {{ categoryName }}
      </p>
    </template>

    <div :class="$style.twoColumn">
      <div>
        <p :class="$style.label">
          {{ $t('price') }}
        </p>

        <NumberInput
          v-model="price"
          :class="$style.fullWidth"
          :increment="0.01"
          :fixed="2"
          real
          full-width
          currency="money"
        />
      </div>
      <div>
        <p :class="$style.label">
          {{ $t('amount') }}
        </p>

        <NumberInput
          v-if="amount !== '1111111'"
          v-model="amount"
          :class="$style.fullWidth"
          :increment="1"
          :max="9000000"
          :min="1"
          :fixed="0"
          full-width
        />
        <div v-else :class="$style.fieldBlocked">
          {{ $t('notLimited') }}
        </div>
      </div>
    </div>

    <template>
      <div v-for="additionalField in additionalFields" :key="additionalField.title || additionalField.name">
        <p :class="$style.label">
          {{ additionalField.title || additionalField.name }}
        </p>

        <NumberInput
          v-if="additionalField.type === 'number'"
          v-model="additionalData[`${additionalField.title || additionalField.name}`]"
          :class="$style.fullWidth"
          :increment="1"
          :min="0"
          :fixed="0"
          full-width
        />
        <BaseSelect
          v-else-if="additionalField.enum"
          v-model="additionalData[`${additionalField.title || additionalField.name}`]"
          :options="enumToOptions(additionalField.enum)"
          :placeholder="$t('choose')"
        />
        <FormInput v-else v-model="additionalData[`${additionalField.title || additionalField.name}`]" />
      </div>
    </template>

    <template v-if="apps">
      <template v-if="product.auto_messages">
        <p :class="$style.subtitle">
          {{ $t("goods") }}
        </p>

        <FormInput v-model="autoData" type="textarea" />
      </template>

      <p :class="$style.subtitle">
        {{ $t("shortDescription") }}
      </p>

      <FormInput v-model="shortDescription" type="textarea" />

      <p :class="$style.subtitle">
        {{ $t("description") }}
      </p>

      <FormInput v-model="description" type="textarea" />

      <p :class="$style.subtitle">
        {{ $t("details") }}
      </p>

      <div :class="$style.details">
        <div :class="$style.detail">
          <span :class="$style.detail__key">
            {{ $t(activeServiceType) }}
          </span>
          <span :class="$style.detail__value">
            {{ currentAppName }}
          </span>
        </div>
        <div :class="$style.detail">
          <span :class="$style.detail__key">
            {{ $t('category') }}
          </span>
          <span :class="$style.detail__value">
            {{ categoryName }}
          </span>
        </div>
        <div :class="$style.detail">
          <span :class="$style.detail__key">
            {{ $t('amount') }}
          </span>
          <span :class="$style.detail__value">
            {{ amount }}
          </span>
        </div>
        <div :class="$style.detail">
          <span :class="$style.detail__key">
            {{ $t('price') }}
          </span>

          <Currency v-if="price > 0" type="money" real>
            <span :class="$style.detail__value">{{ price }}</span>
          </Currency>
          <span v-else :class="$style.detail__value">-</span>
        </div>
        <div :class="$style.detail">
          <span :class="$style.detail__key">
            {{ $t('priceForCustomer') }}
          </span>
          <span :class="$style.detail__value">
            {{ customerPrice }}
          </span>
        </div>
      </div>

      <Button is-primary :class="$style.save" :disabled="!canUpdate" @click="updateProduct">
        {{ $t('buttonSave') }}
      </Button>

      <Button is-secondary :class="$style.save" @click="deleteProduct">
        {{ $t('delete') }}
      </Button>
    </template>
  </div>
</template>

<style>
.modal--editproductmodal {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 32px;
}

.subtitle {
  margin: 40px 0 24px;
  color: $white;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.label {
  margin: 24px 0 8px;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.selector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  &__variant {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $blue-grey50;
    border: 2px solid $blue-grey50;
    border-radius: 12px;
    padding: 16px;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &Active {
      border: 2px solid $primary60;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-right: 8px;

    &Round {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid $blue-grey20;

      &Active {
        border: 2px solid $primary60;
      }
    }

    &Dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $primary60;
    }
  }
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
}

.fullWidth {
  width: 100%;
}

.details {
  display: grid;
  padding: 24px 0;
  grid-gap: 12px;
  grid-template-columns: 1fr;
  border-top: 1px solid $blue-grey40;
  border-bottom: 1px solid $blue-grey40;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__key {
    color: $blue-grey10;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__value {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.save {
  width: 100% !important;
  margin-top: 40px;

  &:disabled {
    opacity: 0.3;
  }
}

.fieldBlocked {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  width: 100%;
  padding: 0 16px;
  border-radius: 8px;
  background: $blue-grey70;
  color: $blue-grey10;
}
</style>
