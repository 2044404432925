<template lang="pug">
.nominations-list
  div(v-for="(nomination, index) in nominations" :key="")
    .nominations-list__nomination
      .nominations-list__nomination-header
        InlineSvg.nominations-list__nomination-header-icon(
          v-if="nominationHasIcon(nomination)"
          :class="getNominationStyle(nomination)"
          :src="nominationIcon(nomination)"
        )
        span.nominations-list__nomination-header-text {{ nomination.title }}
      .nominations-list__nomination-body
        AvatarWithStars(:src="nominationAvatar(nomination)" :userId="nominationUserId(nomination)")
        span.nominations-list__nomination-body-name {{ nominationWinnerName(nomination) }}
        Currency(:type="nominationCurrency(nomination)" isReversed)
          span.nominations-list__nomination-body-prize {{ nominationPrize(nomination) }}
</template>

<script>
export default {
  name: 'TournamentNominations',

  props: {
    tournament: {
      type: Object,
      required: true
    }
  },

  computed: {
    nominations () {
      return this.tournament?.prize_settings?.nominations?.map((nomination) => {
        const amount = nomination.currency === 'money' ? nomination.amount / 100 : nomination.amount

        return { ...nomination, amount }
      })
    }
  },

  methods: {
    getNominationColor (nomination) {
      return nomination?.icon_color?.replace('#', '') || ''
    },

    getNominationStyle (nomination) {
      return [
        'nominations-list__nomination-header-icon',
        `nominations-list__nomination-header-icon--${this.getNominationColor(nomination)}`
      ]
    },

    nominationHasIcon (nomination) {
      return nomination?.icon_name
    },

    nominationIcon (nomination) {
      return require(`~/components/TournamentRewards/icons/${nomination?.icon_name}.svg`)
    },

    nominationAvatar (nomination) {
      return nomination?.winner?.avatar
    },

    nominationUserId (nomination) {
      return nomination?.winner?.id
    },

    nominationWinnerName (nomination) {
      return nomination?.winner?.full_name
    },

    nominationCurrency (nomination) {
      return nomination?.currency
    },

    nominationPrize (nomination) {
      return nomination?.amount
    }
  }
}
</script>

<style lang="scss" scoped>
.nominations-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  @media (min-width: $breakpointDesktopWide) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__nomination {
    padding: 20px 16px;
    background: $blue-grey70;
    border-radius: 8px;

    &-header {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 12px;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid $blue-grey50;

      &-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $white;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-icon {
        &--FFFFFF, &--white {
          ::v-deep path, rect {
            fill: #fff;
          }
        }

        &--334153 {
          ::v-deep path, rect {
            fill: #334153;
          }
        }

        &--1E88E5 {
          ::v-deep path, rect {
            fill: #1e88e5;
          }
        }

        &--FFB300 {
          ::v-deep path, rect {
            fill: #ffb300;
          }
        }

        &--FB8C00 {
          ::v-deep path, rect {
            fill: #fb8c00;
          }
        }

        &--00ACC1 {
          ::v-deep path, rect {
            fill: #00acc1;
          }
        }

        &--673AB7 {
          ::v-deep path, rect {
            fill: #673ab7;
          }
        }

        &--43A047 {
          ::v-deep path, rect {
            fill: #43a047;
          }
        }

        &--3F51B5 {
          ::v-deep path, rect {
            fill: #3f51b5;
          }
        }

        &--9C27B0 {
          ::v-deep path, rect {
            fill: #9c27b0;
          }
        }

        &--D81B60 {
          ::v-deep path, rect {
            fill: #d81b60;
          }
        }

        &--009688 {
          ::v-deep path, rect {
            fill: #009688;
          }
        }

        &--FDD835 {
          ::v-deep path, rect {
            fill: #fdd835;
          }
        }
      }
    }

    &-body {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      grid-gap: 12px;
      align-items: center;
      padding-top: 24px;

      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $white;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-prize {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $white;
      }
    }
  }
}
</style>
