<template lang="pug">
  span.tooltip(
    :class="[`--${position}`]"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  )
    slot
    portal(to="tooltip" v-if="isTooltipVisible")
      .tooltip__popup(ref="popup" :class="[`--${popupClassName}`, `--${position}`, {'--mounted': isMounted }]" :style="{ top, left }" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave")
          slot(name="tooltip")
</template>

<script>
import { mapGetters } from 'vuex'

let hideTimeout = null

export default {
  name: 'Tooltip',

  props: {
    forceVerticalPosition: {
      type: String,
      default: null
    },

    isVertical: {
      type: Boolean,
      default: false
    },

    popupClassName: {
      type: String,
      default: '--popup'
    }
  },

  data () {
    return {
      isTooltipVisible: false,
      position: 'top-right',
      top: 'auto',
      left: 'auto',
      isMounted: false
    }
  },

  computed: {
    ...mapGetters('viewport', ['isMobile', 'isTablet'])
  },

  mounted () {
    // window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    this.onResize()
  },

  beforeDestroy () {
    // window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onClick () {
      if (this.isTablet && !this.isTooltipVisible) {
        this.onMouseEnter()
      }
    },

    onScroll () {
      this.isTooltipVisible = false
    },

    onResize () {
      let position = ''

      const height = window.innerHeight
      const width = window.innerWidth

      const rect = this.$el.getBoundingClientRect()
      const popupRect = this.$refs.popup?.getBoundingClientRect()

      const body = document.body
      const docEl = document.documentElement

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
      const scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft

      const clientTop = docEl.clientTop || body.clientTop || 0
      const clientLeft = docEl.clientLeft || body.clientLeft || 0

      const top = rect.top + scrollTop - clientTop
      const left = rect.left + scrollLeft - clientLeft
      const bottom = top + rect.height
      const right = left + rect.width

      this.top = 'auto'
      this.left = 'auto'

      if (this.forceVerticalPosition) {
        position = this.forceVerticalPosition
      } else if (height - rect.top < height / 2) {
        position = 'top'
      } else {
        position = 'bottom'
      }

      if (popupRect) {
        if (position === 'top') {
          if (this.isMobile || this.isVertical) {
            this.top = top - popupRect.height + 'px'
          } else {
            this.top = bottom - popupRect.height + 'px'
          }
        } else if (position === 'bottom') {
          if (this.isMobile || this.isVertical) {
            this.top = bottom + 'px'
          } else {
            this.top = top + 'px'
          }
        }
      }

      if (width - rect.left < width / 2) {
        position += '-left'
      } else {
        position += '-right'
      }

      if (popupRect) {
        if (this.isMobile) {
          this.left = '50%'
        } else if (this.isVertical && position.includes('right')) {
          this.left = left + 'px'
        } else if (this.isVertical) {
          this.left = right - popupRect.width + 'px'
        } else if (position.includes('left')) {
          this.left = left - popupRect.width + 'px'
        } else {
          this.left = right + 'px'
        }
      }

      this.position = position
    },

    onMouseEnter () {
      clearTimeout(hideTimeout)
      this.isTooltipVisible = true

      this.$nextTick().then(
        this.$nextTick(() => {
          this.onResize()
          this.isMounted = true
        })
      )
    },

    onMouseLeave () {
      this.isMounted = false

      hideTimeout = setTimeout(() => {
        this.isTooltipVisible = false
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  position: relative;
  cursor: help;

  &.--pointer {
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    z-index: 102;
    width: fit-content;
    height: fit-content;
    border: 1px solid $grey50;
    border-radius: 8px;
    background: $blue-grey70;
    transition: opacity 0.2s $easing;
    opacity: 0;

    &.--mounted {
      opacity: 1;
    }

    > div {
      box-sizing: border-box;
      padding: 16px;
    }

    @media (max-width: $breakpointMobile) {
      margin: 0 !important;
      max-width: calc(100vw - 16px);
      transform: translate(-50%);
    }
  }
}
</style>
