class StoreService {
  constructor () {
    if (StoreService.instance) {
      return StoreService.instance
    }
  }

  getProducts (engine) {
    return engine.get('/products')
  }

  getGameCurrenciesBrands (engine) {
    return engine.get('/marketplace/v-brands')
  }

  getGameCurrencies (engine, brand) {
    const params = {
      brand
    }

    return engine.get('/marketplace/virtual', { params })
  }

  getMarketplaceProducts (engine, page, size, category, brand) {
    const params = {
      page,
      size
    }

    if (brand !== '') {
      params.brand = brand
    }

    if (category !== '') {
      params.category = category
    }

    return engine.get('/marketplace', { params })
  }

  getMarketplaceProductsWithUserID (engine, page, size, categoryId, bestsellers = false) {
    const params = {
      page,
      size
    }

    if (categoryId !== '') {
      params.category_id = categoryId
    }

    if (bestsellers) {
      params.bestseller = bestsellers
    }

    return engine.get('/marketplace/with-uid', { params })
  }

  getMarketplaceProductsToModerate (engine) {
    return engine.get('/marketplace/not-moderated')
  }

  moderateProduct (engine, id) {
    return engine.post(`/marketplace/${id}/moderate`)
  }

  deleteProduct (engine, id) {
    return engine.post(`/marketplace/${id}/delete`)
  }

  getMarketplaceProductsBySellerID (engine, page, size, sellerId, onSale) {
    const params = {
      page,
      size,
      on_sale: onSale
    }

    return engine.get(`/marketplace/by-user/${sellerId}`, { params })
  }

  getMarketplaceFavouriteProducts (engine, page, size) {
    const params = {
      page,
      size
    }

    return engine.get('/marketplace/favourites', { params })
  }

  getMarketplaceApps (engine) {
    return engine.get('/marketplace/apps', {
      headers: {
        Origin: process.env.WWW_HOST,
        Referer: process.env.WWW_HOST
      }
    })
  }

  getMarketplaceCounters (engine) {
    return engine.get('/marketplace/apps/categories-counters')
  }

  getMarketplaceApp (engine, appId) {
    return engine.get(`/marketplace/apps/${appId}`)
  }

  getMarketplaceAppByPath (engine, path) {
    return engine.get(`/marketplace/apps/by-path/${path}`)
  }

  addMarketplaceApp (engine, app) {
    return engine.post('/marketplace/app/add', { item: app })
  }

  addMarketplaceAppCategory (engine, appId, category) {
    return engine.post(`/marketplace/app/${appId}/category/add`, { item: category })
  }

  addMarketplaceProduct (engine, item) {
    return engine.post('/marketplace/add', { item })
  }

  updateMarketplaceProduct (engine, item) {
    return engine.post('/marketplace/update', { item })
  }

  getCart (engine) {
    return engine.get('/marketplace/cart')
  }

  addToCart (engine, itemId, coinsToUse) {
    return engine.post('/marketplace/cart/add', {
      item: {
        item_id: itemId,
        coins: coinsToUse
      }
    })
  }

  addToFavourites (engine, itemId) {
    return engine.post('/marketplace/favourites/add', {
      item: {
        product_id: itemId
      }
    })
  }

  dropFavourite (engine, itemId) {
    return engine.post('/marketplace/favourites/drop', {
      item: {
        product_id: itemId
      }
    })
  }

  clearFavourites (engine, itemId) {
    return engine.post('/marketplace/favourites/clear')
  }

  dropFromCart (engine, itemId) {
    return engine.post('/marketplace/cart/drop', {
      item: {
        item_id: itemId
      }
    })
  }

  purchase (engine, productId, data) {
    return engine.post(`/products/${productId}/purchase`, data)
  }

  purchaseWithCard (engine, productId, isProlong, isRu = false, gameId = 0) {
    let url = '/payments/providers/unlimint/recurring'
    let body = {
      productID: productId,
      gameId,
      return_url: `${location.protocol}//${location.host}/profile/subscription`,
      currency: 'EUR',
      autoProlong: isProlong,
      autoProlongBalance: false
    }

    if (isRu) {
      url = '/payments/providers/contactpay/recurring'
      body = {
        productID: productId,
        gameId,
        return_url: `${location.protocol}//${location.host}/profile/subscription`,
        currency: 'EUR',
        payway: 'card_acquiring_rub'
      }
    }

    return engine.post(url, body)
  }

  getMarketplace (engine, productId) {
    return engine.get(`/marketplace/${productId}`)
  }

  getMarketplaceOrders (engine) {
    return engine.get('/marketplace/orders')
  }

  getMarketplaceOrdersAsSeller (engine) {
    return engine.get('/marketplace/seller/orders')
  }

  createOrder (engine, customerData, link) {
    return engine.post('/marketplace/order', { order_data: customerData, returning_url: link })
  }

  createVirtualOrder (engine, customerData, link, phone) {
    return engine.post('/marketplace/v-order', { order_data: customerData, returning_url: link, phone })
  }

  payForOrder (engine, orderId, method, url, phone) {
    return engine.post(`/marketplace/v-order/${orderId}/pay`, {
      method,
      url,
      phone
    })
  }

  completeVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/complete`)
  }

  fulfillVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/prepare`)
  }

  cancelVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/cancel`)
  }

  getCanRefund (engine, productId) {
    return engine.get(`/user/products/${productId}/refund`)
  }

  refund (engine, productId) {
    return engine.post(`/user/products/${productId}/refund`)
  }

  cancel (engine, productId) {
    return engine.post(`/user/products/${productId}/cancellation`)
  }

  sendReview (engine, orderId, stars, comment) {
    return engine.post(`/marketplace/v-order/${orderId}/review`, {
      stars,
      comment
    })
  }

  getReviewStatsBySellerId (engine, sellerId) {
    return engine.get(`/marketplace/reviews-stats/${sellerId}`)
  }

  getReviewsBySellerId (engine, sellerId, page, size) {
    const params = {
      page,
      size
    }
    return engine.get(`/marketplace/reviews/${sellerId}`, { params })
  }
}

export default new StoreService()
