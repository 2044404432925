<template lang="pug">
  .live-stats
    transition(name="fade" duration="200")
      .live-stats__items(v-if="isLoaded")
        .live-stats__item(v-for="(item, index) in items" :key="index" :class="`--${item.color}`")
          InlineSvg(:src="require(`./icons/${item.icon}.svg`)")

          .live-stats__item-inner
            strong
              number(:from="0" :to="item.value" :format="n => num(n)" :duration="2" easing="Power1.easeOut")
            span {{ item.title }}

    SocialLinks

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import numeral from 'numeral'
import { debounce } from 'throttle-debounce'
import SocialLinks from '~/components/SocialLinks/SocialLinks'

export default {
  name: 'LiveStats',

  components: { SocialLinks },

  data: () => {
    return {
      money: 0,
      coins: 0,
      accounts: 0,
      banned: 0,
      duels: 0,
      tournaments: 0,
      tournamentPlayers: 0,
      isLoaded: false
    }
  },

  computed: {
    ...mapState('user', ['onlineCount']),
    ...mapGetters('user', ['isAdmin']),

    items () {
      return [
        {
          icon: 'v',
          color: 'green',
          title: this.$t('statsPayed'),
          value: this.money
        },
        {
          icon: 'flatten',
          color: 'yellow',
          title: this.$t('statsCoins'),
          value: this.coins
        },
        {
          icon: 'globe',
          color: 'purple',
          title: this.$t('statsOnline'),
          value: this.onlineCount + this.tournamentPlayers // :D
        },
        {
          icon: 'group',
          color: 'purple',
          title: this.$t('statsAccounts'),
          value: this.accounts
        },
        {
          icon: 'user-ban',
          color: 'purple',
          title: this.$t('statsBanned'),
          value: this.banned
        },
        // {
        //   icon: 'pvp',
        //   color: 'blue',
        //   title: this.$t('statsDuels'),
        //   value: this.duels,
        // },
        {
          icon: 'thunder',
          color: 'blue',
          title: this.$t('statsTournaments'),
          value: this.tournaments
        }
      ]
    }
  },

  mounted () {
    this.getData()
    this.debouncedUpdate = debounce(500, this.getData)
    const eventName = this.socketEventName || 'platform:statistics:update'

    this.$socket.on(eventName, () => {
      this.debouncedUpdate()
    })
  },

  beforeDestroy () {
    const eventName = this.socketEventName || 'platform:statistics:update'

    this.$socket.off(eventName)
  },

  methods: {
    num (val) {
      return numeral(val).format('0,0').replace(/,/g, ' ')
    },

    async getData () {
      try {
        // const { data } = await this.$axios.get('/platform/statistics')
        const { data } = await this.$axios.get('/platform/statistics')

        Object.keys(data.data).forEach((key) => {
          if (key === 'money') {
            this[key] = data.data[key] / 100
          } else {
            this[key] = data.data[key]
          }
        })
        this.isLoaded = true
      } catch (e) {
        console.error('live stats error')
      }
    }
  }
}
</script>

<style lang="scss">
.live-stats {
  @include scroll-none;

  height: 50px;
  width: 100%;
  background: $blue-grey80;
  z-index: 5;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__close {
    svg rect {
      transition: 0.2s $easing;
    }

    &:hover svg rect {
      fill: #fff;
    }

    position: fixed;
    right: 0;
    top: 0;
    height: 48px;
    padding-right: 20px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: $blue-grey80;
    z-index: 2;

    @media (max-width: $breakpointHeaderMobile) {
      height: 40px;
    }

    @media (max-width: $breakpointHeaderMobileSmall) {
      padding-right: 8px;
    }
  }

  &__items {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
  }

  &__item {
    position: relative;
    padding: 0 14px 0 38px;
    width: auto;

    &:last-of-type {
      padding-right: 49px;

      @media (max-width: $breakpointHeaderMobileSmall) {
        padding-right: 40px;
      }
    }

    @media (max-width: $breakpointHeaderMobile) {
      padding-left: 28px;
    }

    &-inner {
      height: 34px;
      display: flex;
      flex-direction: column;
      position: relative;
      top: 2px;
    }

    svg {
      position: absolute;
      left: 8px;
      top: 50%;
      margin-top: -12px;
      height: 24px;
      width: 24px;

      @media (max-width: $breakpointHeaderMobile) {
        &:first-of-type {
          margin-top: -8px;
          height: 16px;
          width: 16px;
        }
      }
    }

    strong {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $green60;
      margin-bottom: 2px;
      position: relative;
      width: fit-content;
      white-space: nowrap;

      @media (max-width: $breakpointHeaderMobile) {
        margin-bottom: 1px;
      }

      &::after {
        content: "";
        width: 100%;
        height: 16px;
        position: absolute;
        top: 6px;
        left: 0;
        opacity: 0.2;
        z-index: 0;

        @media (max-width: $breakpointHeaderMobile) {
          top: 4px;
        }
      }
    }

    &.--green {
      strong {
        color: $green60;
      }

      svg path {
        fill: $green60;
      }
    }

    &.--yellow {
      strong {
        color: $amber60;
      }

      svg path {
        fill: $amber60;
      }
    }

    &.--purple {
      strong {
        color: #e91e63;
      }

      svg path {
        fill: #e91e63;
      }
    }

    &.--blue {
      strong {
        color: $primary60;
      }

      svg path {
        fill: $primary60;
      }
    }
  }

  .live-stats__item-inner > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: white;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
    font-family: Roboto, sans-serif;
  }

  @media (max-width: $breakpointHeaderMobile) {
    height: 40px;
  }
}
</style>
