<template lang="pug">
  .live-stats.live-winners
    .live-winners__game(:class="`--${game.name}`")
      .live-winners__game-inner
        .live-winners__game-title {{ $t('lastWinners') }}
        .live-winners__game-block
          img(v-if="game.extra_data && game.extra_data.icon" :src="game.extra_data.icon" width="20" :alt="game.name")
          .live-winners__game-name(v-if="game.extra_data && game.extra_data.title" :class="`--game-${game.name}`") {{ game.extra_data.title }}

    transition(name="fade" duration="200")
      transition-group.live-stats__items(v-if="isLoaded" name="slide-right" duration="200" tag="div")
        .live-stats__item(v-for="item in items" class="--green" :key="`${item.id}-${item.place || item.point_coins}-${item.coins || item.points}`")
          InlineSvg(:src="require(`../LiveStats/icons/euro.svg`)")
          .live-stats__item-inner
            span.live-stats__item-value(v-html="getCoins(item)")
            span.live-stats__item-title(@click="onPlayerClick(item)") {{ item.full_name }}

</template>

<script>
import numeral from 'numeral'
import { mapState } from 'vuex'
import LiveStats from '~/components/LiveStats/LiveStats'

export default {
  name: 'LiveWinners',

  extends: LiveStats,

  data () {
    return {
      winners: []
    }
  },

  computed: {
    ...mapState('games', ['gamesByName']),

    items () {
      return this.winners
    },

    game () {
      return this.gamesByName[this.$route.params.game] || {}
    },

    socketEventName () {
      return `platform:statistics:update:${this.game?.name}`
    }
  },

  methods: {
    formatNumber (val) {
      const number = (val / 100).toFixed(2)

      return numeral(number)
        .format('0,0.00')
        .replace(/,/g, '&nbsp;')
        .replace('.00', '')
    },

    getCoins (item) {
      let coins = item.coins

      if (!coins) {
        coins = item.point_coins
      }

      return this.formatNumber(coins)
    },

    onPlayerClick (user) {
      window.open(`${this.$config.WWW_HOST}/user/${user.alias}`, '_blank')
    },

    async getData () {
      try {
        const { data } = await this.$axios.get(
          `/platform/${this.game.name}/statistics`
        )

        this.winners = data.data

        this.isLoaded = true
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: live stats error ::`, e)
      }
    }
  }
}
</script>

<style lang="scss">
.live-winners {
  display: flex;

  .live-stats {
    &__items {
      margin: 0;
      padding: 0;

      @media (max-width: $breakpointDesktop) {
        margin: 0;
      }
    }

    &__item {
      margin: 0;
      margin-left: 24px;
      padding: 0 0 0 30px;
      overflow: hidden;

      @media (max-width: $breakpointHeaderMobile) {
        padding: 0 0 0 20px;
        margin-left: 16px;

        &:first-child {
          margin-left: 8px;
        }
      }

      &:last-child {
        padding-right: 56px;
      }

      &-value {
        margin-bottom: 1px;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90px;
        font-size: 14px;
        line-height: 17px;
        text-transform: none;
        cursor: pointer;
        text-decoration: none;
        font-family: Roboto, sans-serif;
        font-weight: 500;

        @media (max-width: $breakpointHeaderMobile) {
          max-width: 64px;
          font-size: 9px;
          line-height: 9px;
        }
      }

      svg {
        left: 0;
      }
    }
  }

  &__game {
    @include text-small;

    border-right: 1px solid $blue-grey50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 178px;
    max-width: 178px;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background: $blue-grey80;
    height: inherit;
    z-index: 3;

    &-title {
      font-style: normal;
      color: white;
      margin-bottom: 2px;
      font-weight: 500;
      font-size: 13px;
      position: relative;
      top: -2px;
    }

    &-block {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-name {
      font-style: normal;
      font-weight: $font-medium;
      width: 100%;
      color: $amber60;
      font-size: 15px;
      padding-left: 4px;

      .--lichess & {
        color: #fff;
      }
    }

    img {
      max-height: 16px;
      max-width: 16px;
      margin-right: 4px;
    }

    @media (max-width: $breakpointDesktop) {
      max-width: 118px;
      min-width: 118px;
      padding-left: 16px;

      &-title {
        font-size: 8px;
        line-height: 8px;
        min-width: auto;
      }

      img {
        width: 16px !important;
      }

      &-name {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }
}
</style>
