<template lang="pug">
.winners
  .winners-table

    .winners-header
      .winners-header-title(v-t="'tournamentPageTabOverviewWinnersTableHeaderPlace'")
      .winners-header-title(v-t="'tournamentPageTabOverviewWinnersTableHeaderWinner'")
      .winners-header-title(v-t="'tournamentPageTabOverviewWinnersTableHeaderForCoinPrize'")

    .winners-line(v-for="(prize, i) in tournamentPrizes" :key="i")
      .winners-line-place
        InlineSvg.winners-line-place-icon(:src="require('~/assets/img/icons/leaderboard.svg')")
        span.winners-line-place-number {{ i + 1 }}
      .winners-line-player
        AvatarWithStars(:src="placeAvatar(i + 1)" :userId="placePlayerId(i + 1)")
        span.winners-line-player-name {{ placePlayerName(i + 1) }}
      .winners-line-prize(v-if="!isCoinTournament")
        Currency(:type="prizeCurrency" isReversed)
          span.winners-line-prize-sum {{ placePrizeWithSubscriptionText(i) }}
      .winners-line-prize(v-else)
        Currency(type="coins" isReversed)
          span.winners-line-prize-sum {{ placePrizeWithoutSubscription(i) }}
        span.winners-line-prize-sum +&nbsp;
        Currency(type="tickets" is-reversed)
          span.winners-line-prize-sum {{ tournamentTrophyPrize(i) }}

</template>

<script>
import AvatarWithStars from '@/components/AvatarWithStars'

export default {
  name: 'TournamentWinners',

  components: { AvatarWithStars },

  props: {
    tournament: {
      type: Object,
      required: true
    }
  },

  computed: {
    prizeCurrency () {
      return this.tournament?.prize_settings?.prize_currency
    },

    isCoinTournament () {
      return this.prizeCurrency === 'coins'
    },

    tournamentPrizes () {
      return this.tournament?.prizes?.places
    }
  },

  methods: {
    placeAvatar (place) {
      const player = this.tournament?.players?.find(player => player.place === place)
      if (player) {
        return player.avatar
      }
    },

    placePlayerId (place) {
      const player = this.tournament?.players?.find(player => player.place === place)
      if (player) {
        return player.id
      }
    },

    placePlayerName (place) {
      const player = this.tournament?.players?.find(player => player.place === place)
      if (player) {
        return player.full_name
      }
    },

    placePrizeWithSubscription (place) {
      return this.tournament?.prizes?.places?.[place]
    },

    placePrizeWithSubscriptionText (place) {
      if (this.isCoinTournament) {
        return this.placePrizeWithSubscription(place)
      }
      return (this.placePrizeWithSubscription(place) / 100).toFixed(2)
    },

    placePrizeWithoutSubscription (place) {
      if (this.isCoinTournament) {
        return this.placePrizeWithSubscription(place)
      }

      return (this.placePrizeWithSubscription(place) * 12).toFixed(0)
    },

    tournamentTrophyPrize (place) {
      return Math.ceil(this.placePrizeWithoutSubscription(place) / 1000).toFixed(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.winners {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 15px;
  margin-bottom: 10px;

  @media (min-width: $breakpointDesktopWide) {
    margin-bottom: 0;
  }

  &-header {
    display: grid;
    grid-template-columns: 40px 1fr 94px;
    grid-gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 8px 4px;
    margin-bottom: 5px;
    background: $blue-grey70;
    border-radius: 8px;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 40px 1fr 130px;
    }

    &-title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $blue-grey05;
      text-align: center;
    }
  }

  &-table {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }

  &-line {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 40px 1fr 94px;
    align-items: center;
    padding: 12px 4px;
    background: $blue-grey60;
    border-radius: 8px;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 40px 1fr 130px;
    }

    &-place {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: rgba($white, 0.9);
      border-right: 1px solid $blue-grey30;

      &-number {
        margin-top: 4px;
      }

      &-icon {
        width: 16px;
      }
    }

    &-player {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $white;
        margin-left: 9px;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-prize {
      text-align: center;

      &-sum {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $white;
      }
    }
  }
}
</style>
