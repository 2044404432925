<template lang="pug">
  .auth-anticheat-wrapper
    .auth-anticheat
      .auth-anticheat__header
        .auth-anticheat__img-section
          .auth-anticheat__img-avatar
            img(:src="userAvatar" :alt="user && user.full_name")
          .auth-anticheat__connect-ico-wrap
            InlineSvg(:src="require('~/assets/img/icons/link.svg')")
          .auth-anticheat__img-app
            InlineSvg(:src="require('~/assets/img/VVV-logo.svg')")
        .auth-anticheat__title {{ $t('anticheatAuth') }}
        a.auth-anticheat__link(@click="onLink") {{ $t('addedInfo') }}
      .auth-anticheat__body
        .auth-anticheat__info-tab
          .auth-anticheat__ico-box
            .auth-anticheat__tab-ico-wrap.auth-anticheat__tab-ico-wrap--top-s
              InlineSvg(:src="require('~/assets/img/icons/shield.svg')")
          .auth-anticheat__text-wrap
            .auth-anticheat__tab-title {{ $t('anticheatAuthTitle') }}
            .auth-anticheat__tab-subtitle {{ $t('anticheatAuthSubtitle') }}
    Button.auth-anticheat__btn-auth(is-primary @click="onAuth") {{ $t('anticheatActivate') }}

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthAnticheat',

  computed: {
    ...mapState('user', ['user', 'token']),

    userAvatar () {
      return this.user?.avatar
    }
  },

  methods: {
    onAuth () {
      window.location = `vvv:${this.token}`
    },

    onLink () {
      window.open('/info/anticheat', '_blank')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--authanticheat {
  max-width: 490px;
}

.auth-anticheat {
  @include scroll;

  overflow-y: auto;
  position: relative;

  &__link {
    @include caption-small;

    cursor: pointer;
    display: block;
    color: $blue60;
    width: 100%;
    text-align: center;
    margin: 0 auto 32px;
  }

  &__header {
    margin-top: 40px;
  }

  &__img-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  &__img-avatar {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
  }

  &__img-app {
    & svg {
      height: 84px;
      width: 84px;
      object-fit: cover;
      object-position: center;
      margin: auto;
    }
  }

  &__btn-auth {
    width: 100% !important;
  }

  &__connect-ico-wrap {
    margin: 0 16px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    @include heading3;

    color: $white;
    text-align: center;
    margin-bottom: 18px;
  }

  &__info-tab {
    border-radius: 8px;
    background: $blue-grey60;
    display: flex;
    margin-bottom: 16px;
    padding-bottom: 24px;
    padding-right: 24px;
  }

  &__ico-box {
    display: flex;
    width: 80px;
    height: 100% !important;
    justify-content: center;
    min-width: 80px !important;
  }

  &__tab-ico-wrap {
    display: flex;
    justify-content: center;

    & img {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
    }
  }

  &__text-wrap {
    margin-top: 24px;
  }

  &__tab-title {
    @include heading4;

    color: $white;
    margin-bottom: 16px;
  }

  &__tab-subtitle {
    @include text-display100;

    color: $blue-grey10;
    max-width: 575px;
  }
}

.auth-anticheat__tab-ico-wrap--top-s {
  padding-top: 40px;
}

.auth-anticheat__info-tab:nth-child(2) {
  .auth-anticheat__tab-subtitle:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>
