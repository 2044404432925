<script>
// import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import NumberInput from '~/components/NumberInput/NumberInput'
import FormInput from '~/components/FormInput/FormInput'

export default {
  name: 'CheckoutShort',

  components: {
    FormInput,
    // BaseSelect,
    CheckboxInput,
    NumberInput
  },

  props: {
    realPaymentMode: {
      type: Boolean,
      default: false
    },

    product: {
      type: Object,
      required: true
    },

    content: {
      type: [Array, undefined],
      default: undefined
    },

    value: {
      type: [String, Number],
      required: true
    },

    payMethods: {
      type: Array,
      required: true
    },

    finalPriceWithCommission: {
      type: [String, Number],
      required: true
    },

    totalCommission: {
      type: [String, Number],
      required: true
    },

    commissionText: {
      type: String,
      required: true
    },

    showButton: {
      type: Boolean,
      required: true
    },

    withOptions: {
      type: Boolean,
      default: false
    },

    isSeller: {
      type: Boolean,
      required: true
    },

    orderAmount: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      checkbox: true,
      amount: '1',
      optionValues: {}
    }
  },

  computed: {
    ...mapState('marketplace', ['currencies']),
    ...mapGetters('user', ['isAuthorized']),

    payMethod: {
      get () {
        return this.value
      },

      set (v) {
        this.setValue(v)
      }
    },

    checkoutButtonDisabled () {
      return this.isAuthorized && (!this.checkbox || this.isSeller || this.payMethod === '')
    },

    price1product () {
      return (this.product.max_price / 100).toFixed(2).replace('.00', '')
    },

    orderContent () {
      if (this.withOptions) {
        const optionTypeVariants = this.productOptions?.find(po => po.type === 'variants')

        return (this.options).map((o) => {
          const key = optionTypeVariants?.title + '__' + o.title.replace(/^\[([^\]]*)\].*$/, '$1')
          return {
            ...o,
            quantity: +(this.optionValues[key] || 0)
          }
        }).filter(o => o.quantity > 0)
      }

      return undefined
    },

    total () {
      if (this.content) {
        return this.finalPriceWithCommission
      }

      if (this.withOptions) {
        return (this.orderContent?.map(item => item.quantity * item.max_price).reduce((a, b) => +a + +b, 0) / 100).toFixed(2).replace('.00', '')
      }

      return (+this.finalPriceWithCommission * (this.orderAmount || +this.amount)).toFixed(2).replace('.00', '')
    },

    minAmount () {
      return 1
    },

    maxAmount () {
      return this.product.quantity
    },

    unlimited () {
      return +this.maxAmount === 1111111
    },

    showRub () {
      return this.$i18n.locale === 'ru'
    },

    rubCurrencyRate () {
      return this.currencies?.find(c => c.code === 'RUB')?.exchange_rate || 0
    },

    totalInRub () {
      return Math.ceil(+this.total / (this.rubCurrencyRate * 0.98))
    },

    productOptions () {
      if (this.content) {
        return [
          {
            title: this.$t(this.product?.category?.category_name),
            type: 'variants',
            options: this.content
          }
        ]
      }
      if (this.withOptions && !this.product?.fields?.find(f => f.type === 'variants')) {
        return [
          ...(this.product.fields || []),
          {
            title: this.$t(this.product?.category?.category_name),
            type: 'variants',
            options: [{
              title: this.product?.title,
              id: this.product?.id,
              max_price: this.product?.max_price,
              min_price: this.product?.min_price,
              r_price: this.product?.r_price,
              quantity: this.product?.quantity
            }]
          }
        ]
      }
      return this.product?.fields
    },

    productVariants () {
      return this.productOptions?.find(o => o.type === 'variants')?.options
    },

    options () {
      return this.product?.options
    }
  },

  mounted () {
    this.addToOrder(this.productOptions?.find(o => o.type === 'variants'), this.productVariants?.[0])
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('marketplace', ['setOrderContent', 'setOrderFields']),

    setValue (v) {
      this.$emit('input', v)
    },

    optionByVariant (variant) {
      if (this.content || !this.product?.fields?.length) {
        return variant
      }
      const value = `[${variant?.title}]`
      return this.options?.find(o => o?.title.startsWith(value))
    },

    inRub (price) {
      if (price === '') {
        return '...'
      }

      return Math.ceil(+price / (this.rubCurrencyRate * 0.98))
    },

    variantPrice (variant) {
      return ((this.optionByVariant(variant)?.max_price) / 100).toFixed(2)
    },

    variantPriceInRub (variant) {
      return Math.ceil(this.optionByVariant(variant)?.max_price / (this.rubCurrencyRate * 0.98) / 100)
    },

    variantQuantity (variant) {
      if (this.content) {
        if (+variant.quantity === 1111111) {
          return '∞'
        }
        return variant.quantity
      }

      const quantity = this.optionByVariant(variant)?.quantity
      if (+quantity === 1111111) {
        return '∞'
      }

      return +quantity
    },

    buy () {
      if (!this.isAuthorized) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'register'
          }
        })
      } else {
        this.$emit('buy', +this.amount)
      }
    },

    openGetForFreeModal () {
      this.showModal({
        component: 'GetForFreeModal'
      })
    },

    addToOrder (option, variant) {
      const key = option.title + '__' + variant.title
      const amount = this.optionValues[key]
      if (this.variantQuantity(variant) === '∞' || +(amount || 0) < +this.variantQuantity(variant)) {
        if (!this.optionValues[key]) {
          this.$set(this.optionValues, key, true)
        } else {
          this.$set(this.optionValues, key, +this.optionValues[key] + 1)
        }
      }
    },

    dropFromOrder (option, variant) {
      const key = option.title + '__' + variant.title
      const amount = this.optionValues[key]
      if (+(amount || 0) > 0) {
        this.optionValues[key]--
      }
    },

    openMissionsPage () {
      this.$gtag.event('click_product_page_earn_btn', {})
      this.$router.push(this.localeLocation('/missions'))
    },

    openGamesPage () {
      this.$gtag.event('click_product_page_play_btn', {})
      this.$router.push(this.localeLocation('/missions'))
    },

    goToPayment () {
      if (!this.isAuthorized) {
        this.$gtag.event('click_product_page_pay_btn_not_auth', {})

        this.showModal({
          component: 'Auth',
          data: {
            mode: 'register'
          }
        })
        return
      }

      if (!this.total || +this.total <= 0) {
        return
      }

      if (this.withOptions) {
        window.localStorage.setItem(
          'buy_product_' + this.product?.id,
          JSON.stringify(this.orderContent)
        )
        this.setOrderContent(this.orderContent)
        window.localStorage.setItem(
          'fields_product_' + this.product?.id,
          JSON.stringify(this.optionValues)
        )
        this.setOrderFields(this.optionValues)
        this.$router.push(this.localeLocation(`/marketplace/product/${this.product?.id}/payment`))
      } else {
        this.$router.push(this.localeLocation(`/marketplace/product/${this.product?.id}/payment`))
      }
    }
  }
}
</script>

<template>
  <div :class="$style.checkout">
    <div v-if="realPaymentMode && !content" :class="$style.description__header">
      {{ $t('payMethod') }}
    </div>
    <div v-else-if="withOptions || content" :class="$style.description__header">
      {{ $t('purchaseOfGoods') }}
    </div>

    <div v-if="(withOptions) || content" :class="$style.description__top">
      <div v-if="withOptions" :class="[$style.pair__key, $style['pair__key--thin']]">
        <p>{{ $t("options") }}</p>
      </div>

      <div :class="$style.optionsList">
        <div v-for="option in productOptions" :key="option.id" :class="$style.option">
          <div :class="$style.option__title">
            <span>{{ option.title }}</span>
          </div>

          <p v-if="option.comment && option.comment !== ''" :class="$style.option__comment">
            {{ option.comment }}
          </p>

          <template v-if="option.type === 'text'">
            <FormInput v-model="optionValues[option.title]" />
          </template>

          <template v-else-if="option.type === 'variants'">
            <div v-for="variant in option.options" :key="variant.id" :class="$style.option__variantExampleContainer">
              <div :class="{[$style.option__variantExample]: true, [$style.option__variantExampleNotAvailable]: !variantQuantity(variant) }">
                <div :class="$style.option__variantExampleLeft">
                  <CheckboxInput v-if="withOptions && variantQuantity(variant)" v-model="optionValues[option.title + '__' + variant.title]" />
                  <CheckboxInput v-else />
                  <span :class="$style.option__variantExampleName">{{ variant.title }}</span>
                  <span :class="$style.option__variantExampleAmount">({{ variantQuantity(variant) }})</span>
                </div>

                <div :class="$style.option__variantExampleRight">
                  <Currency type="money" real size="16" is-reversed>
                    <span :class="$style.option__variantExamplePrice">{{ variantPrice(variant) }}</span>
                  </Currency>
                  <template v-if="showRub">
                    (<Currency type="money" size="16" is-reversed rub>
                      <span :class="$style.option__variantExamplePrice">{{ variantPriceInRub(variant) }}</span>
                    </Currency>)
                  </template>
                </div>
              </div>
              <div v-if="withOptions && optionValues[option.title + '__' + variant.title]" :class="$style.option__variantExampleAmountRow">
                <span :class="$style.option__variantExampleAmountRowAction" @click="dropFromOrder(option, variant)">-</span>
                <span :class="$style.option__variantExampleAmountRowAmount">{{ +optionValues[option.title + '__' + variant.title] }}</span>
                <span :class="$style.option__variantExampleAmountRowAction" @click="addToOrder(option, variant)">+</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-else-if="!content" :class="$style.description__top">
      <div :class="$style.pair">
        <div :class="[$style.pair__key, $style['pair__key--thin']]">
          <p>{{ $t("price1pcs") }}</p>
        </div>
        <Currency type="money" size="24" is-reversed real>
          <span :class="[$style.pair__value, $style['pair__value--thin']]">{{ price1product }}</span>
        </Currency>
      </div>

      <div v-if="!realPaymentMode && showRub" :class="$style.pair">
        <span :class="$style.pair__key"></span>
        <span>
          (<Currency type="money" size="24" is-reversed rub>
            <span :class="$style.pair__value">~ {{ totalInRub }}</span>
          </Currency>)
        </span>
      </div>
    </div>

    <template v-if="realPaymentMode">
      <!--      <div :class="$style.paymentMethod">-->
      <!--        <BaseSelect-->
      <!--          v-model="payMethod"-->
      <!--          :options="payMethods"-->
      <!--          :placeholder="$t('choosePayMethod')"-->
      <!--          :with-empty-value="false"-->
      <!--        />-->
      <!--      </div>-->

      <div v-if="payMethod === 'BANKCARD'" :class="$style.noRuCards">
        <p :class="$style.noRuCards__text">
          <InlineSvg :class="$style.noRuCards__icon" :src="require('~/assets/img/icons/info.svg')" width="16" height="16" />
          {{ $t('russianCardWarning2') }}
        </p>
      </div>

      <div v-else-if="payMethod === 'YOURPAYMENT'" :class="$style.ruCards">
        <p :class="$style.ruCards__text">
          <InlineSvg :class="$style.ruCards__icon" :src="require('~/assets/img/icons/info.svg')" width="16" height="16" />
          {{ $t('russianCardWarning3') }}
        </p>
      </div>
    </template>

    <div v-if="realPaymentMode && !orderAmount && !content" :class="$style.paymentMethod">
      <p :class="[$style.pair, $style.label]">
        <span>{{ $t('quantityOfGoods') }}</span>
        <span v-if="!unlimited">{{ $t('maxAmountOfGoods') }} {{ maxAmount }}</span>
      </p>
      <NumberInput
        v-model="amount"
        :increment="1"
        :max="maxAmount"
        :min="minAmount"
        :fixed="0"
        full-width
      />
    </div>

    <div :class="$style.checkout__bottom">
      <div v-if="realPaymentMode && !content" :class="$style.pair">
        <div :class="[$style.pair__key, $style['pair__key--thin']]">
          <p>{{ $t("quantityOfGoods") }}</p>
        </div>
        <span :class="[$style.pair__value, $style['pair__value--thin']]">{{ orderAmount || amount }}</span>
      </div>
      <div v-if="realPaymentMode && !content" :class="$style.pair">
        <div :class="[$style.pair__key, $style['pair__key--thin']]">
          <p>{{ $t("price1pcs") }}</p>
        </div>
        <Currency type="money" size="24" is-reversed real>
          <span :class="[$style.pair__value, $style['pair__value--thin']]">{{ price1product }}</span>
        </Currency>
      </div>
      <div v-if="realPaymentMode && !content" :class="$style.pair">
        <div :class="[$style.pair__key, $style['pair__key--thin']]">
          <p>{{ $t("commission") }}</p>
          <p>{{ commissionText }}</p>
        </div>
        <Currency type="money" size="24" is-reversed real>
          <span :class="[$style.pair__value, $style['pair__value--thin']]">{{ totalCommission }}</span>
        </Currency>
      </div>

      <div v-if="realPaymentMode && !content" :class="$style.description__separator"></div>

      <div v-if="realPaymentMode || withOptions" :class="$style.description__bottom">
        <div :class="$style.pair">
          <span :class="$style.pair__key">{{ $t("totalPay") }}</span>
          <Currency type="money" size="24" is-reversed real>
            <span :class="$style.pair__value">{{ total }}</span>
          </Currency>
        </div>
        <div :class="$style.pair">
          <span :class="$style.pair__key"></span>
          <span>
            <span :class="$style.pair__valueLabel">{{ $t("or") }}</span>
            <Currency type="money" size="24" is-reversed>
              <span :class="$style.pair__value">{{ total }}</span>
            </Currency>
          </span>
        </div>
        <div v-if="showRub" :class="$style.pair">
          <span :class="$style.pair__key"></span>
          <span>
            (<Currency type="money" size="24" is-reversed rub>
              <span :class="$style.pair__value">~ {{ totalInRub }}</span>
            </Currency>)
          </span>
        </div>
      </div>

      <div v-if="realPaymentMode && !isSeller" :class="$style.checkbox">
        <CheckboxInput v-model="checkbox" />
        <span :class="$style.checkbox__label" v-html="$t('marketplaceCheckoutCheckboxText')"></span>
      </div>

      <div v-if="realPaymentMode && payMethod === ''" :class="$style.warning">
        <p :class="$style.warning__text">
          <InlineSvg :class="$style.warning__icon" :src="require('~/assets/img/icons/info.svg')" width="16" height="16" />
          {{ $t('choosePayMethod') }}
        </p>
      </div>

      <div v-if="realPaymentMode">
        <Button v-if="showButton && !isSeller" :class="$style.buy" is-primary :disabled="checkoutButtonDisabled" @click="buy">
          {{ $t("buy") }}
        </Button>
      </div>
      <div v-else :class="$style.hideMobile">
        <!--        <div :class="$style.buttons">-->
        <!--          <Button v-if="!isSeller" :class="$style.teal" is-primary @click="openGetForFreeModal">-->
        <!--            {{ $t("getItForFree") }}-->
        <!--          </Button>-->
        <div :class="$style.buttonsPair">
          <Button v-if="!showRub" is-secondary :class="$style.buttonsPair__btn" @click="openMissionsPage">
            {{ $t("earn") }}
          </Button>
          <Button v-if="!showRub" is-secondary :class="$style.buttonsPair__btn" @click="openGamesPage">
            {{ $t("win") }}
          </Button>
        </div>
        <Button v-if="!isSeller" :class="$style.buy" is-primary :disabled="+total <= 0" @click="goToPayment">
          {{ $t("buy") }}
        </Button>
        <!--        </div>-->
      </div>

      <p :class="$style.address">
        {{ $t("ourCompanyAddress") }}: Solian Enterprises Limited. Registered number: HE 412444. Emmanouil road 44, KIRZIS CENTER, 3031 Limassol, Cyprus
      </p>
    </div>

    <div v-if="!realPaymentMode" :class="$style.lift">
      <!--      <div :class="$style.buttons">-->
      <!--        <Button v-if="!isSeller" :class="$style.teal" is-primary @click="openGetForFreeModal">-->
      <!--          {{ $t("getItForFree") }}-->
      <!--        </Button>-->
      <Button v-if="!showRub" is-secondary :class="$style.lift__btn" @click="openMissionsPage">
        {{ $t("earn") }}
      </Button>
      <Button v-if="!showRub" is-secondary :class="$style.lift__btn" @click="openGamesPage">
        {{ $t("win") }}
      </Button>
      <Button v-if="!isSeller" :class="!showRub ? $style.lift__btn : $style.buy" is-primary :disabled="+total <= 0" @click="goToPayment">
        {{ $t("buy") }}
      </Button>
      <!--      </div>-->
    </div>
  </div>
</template>

<style lang="scss" module>
.checkout {
  width: 100%;
  color: $white;
  background: $blue-grey70;
  border-radius: 8px;

  @media (min-width: $breakpointDesktopWide) {
    margin: 0;
  }

  &__bottom {
    padding: 20px 16px 16px;
  }
}

.noRuCards {
  margin: 12px 16px;
  padding: 12px 16px;
  background: $amber60-20;
  border-radius: 8px;

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $amber40;
  }

  &__icon {
    g {
      fill: $amber40;
    }
  }
}

.warning {
  margin: 12px 0;
  padding: 12px 16px;
  background: $amber60-20;
  border-radius: 8px;

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $amber40;
  }

  &__icon {
    g {
      fill: $amber60;
    }
  }
}

.ruCards {
  margin: 12px 16px;
  padding: 12px 16px;
  background: $amber60-20;
  border-radius: 8px;

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $amber60;
  }

  &__icon {
    g {
      fill: $amber60;
    }
  }
}

.pair {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  &__key {
    color: $white;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    &--thin {
      font-weight: 400;
    }

    p {
      margin: 0 0 4px;
    }
  }

  &__value {
    color: $white;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    &--thin {
      font-weight: 400;
    }

    &Label {
      color: $blue-grey05;
      font-weight: 400;
    }
  }
}

.buy {
  min-width: 100%;

  &:disabled {
    opacity: 0.4;
  }
}

.address {
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 16px 0;
}

.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 32px 0 20px;

  &__label {
    color: rgba($white, .8);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.description {
  &__header {
    padding: 16px;
    border-bottom: 1px solid $blue-grey40;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    @media (min-width: $breakpointDesktopWide) {
      padding: 32px;
    }
  }

  &__top {
    padding: 16px;
    border-bottom: 1px solid $blue-grey40;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    @media (min-width: $breakpointDesktopWide) {
      padding: 20px 32px;
    }
  }

  &__bottom {
    padding: 16px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    @media (min-width: $breakpointDesktopWide) {
      padding: 20px 0;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid $blue-grey40;
  }
}

.paymentMethod {
  padding: 20px 16px;
}

.label {
  margin: 0 0 8px;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.lift {
  display: flex;
  align-items: center;
  gap: 4px;
  position: fixed;
  bottom: 46px;
  left: 0;
  width: 100vw;
  padding: 8px 16px;
  background: $blue-grey80;
  border-top: 1px solid $blue-grey50;

  @media (min-width: $breakpointTablet) {
    bottom: 0;
  }

  @media (min-width: $breakpointDesktopWide) {
    display: none;
  }

  &__btn {
    min-width: 33% !important;
  }
}

.hideMobile {
  display: none;

  @media (min-width: $breakpointDesktopWide) {
    display: block;
  }
}

.buttonsPair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 12px;
  margin-bottom: 12px;

  &__btn {
    min-width: 100% !important;
  }
}

.buttons {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 12px;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 1fr 2fr;
  }

  @media (min-width: $breakpointDesktopWide) {
    grid-template-columns: max-content 1fr;
  }
}

.teal {
  min-width: 100%;
  background: $teal60 !important;
}

.optionsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin: 16px 0 24px;
}

.optionOption {
  padding: 16px;
  background: rgba($blue-grey30, 0.1);
  border-radius: 8px;

  &__title {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content;
    color: $blue-grey10;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
  }
}

.option {
  width: 100%;

  &__title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: $blue-grey10;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
  }

  &__comment {
    color: $white;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 8px 0;
  }

  &__textExample {
    margin: 8px 0;
    width: 100%;
    height: 44px;
    background: $blue-grey60;
    border-radius: 8px;
  }

  &__variantExample {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    height: 40px;
    padding: 0 8px;

    &NotAvailable {
      opacity: 0.2;
    }

    &Container {
      background: $blue-grey60;
      border-radius: 8px;
      margin: 10px 0;
    }

    &Left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &Right {
      min-width: max-content;
      margin-left: 12px;
    }

    &Name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-overflow: ellipsis;
      margin: 0 8px;
    }

    &Amount {
      color: $blue-grey10;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-overflow: ellipsis;
    }

    &Price {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &AmountRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      grid-gap: 8px;
      background: $blue-grey80;
      border: 1px solid $blue-grey60;
      border-radius: 0 0 8px 8px;

      &Action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      &Amount {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px 0;
        background: $blue-grey60;
        border-radius: 8px;
        color: $white;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
</style>
