import { render, staticRenderFns } from "./ModalStatusInfo.vue?vue&type=template&id=21dc7fb8&lang=pug&"
import script from "./ModalStatusInfo.vue?vue&type=script&lang=js&"
export * from "./ModalStatusInfo.vue?vue&type=script&lang=js&"
import style0 from "./ModalStatusInfo.vue?vue&type=style&index=0&id=21dc7fb8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports