export const CROWDIN_FILE_NAME_BY_LOCALE = {
  'en-ES': 'en',
  'en-US': 'en',
  ru: 'ru',
  de: 'de',
  es: 'es-ES',
  it: 'it',
  pt: 'pt-PT'
}

export const NATIVE_LANG_BY_LOCALE = {
  ru: 'Русский',
  'en-US': 'English',
  es: 'Español',
  de: 'Deutsch',
  it: 'Italiano',
  pt: 'Português',
  kk: 'Қазақша'
}
