<template lang="pug">
  .wallet
    .wallet__inner
      .wallet__header {{ $t('menuBalance') }}

      InfoMessage(v-if="!isPayoutEnabled" type="warning" is-small)
        | {{ $t('payoutDisabled') }}

      .wallet__buttons
        Button(is-secondary :is-disabled="!isPayoutEnabled" @click="onPayOutClick") {{ $t('buttonPayOut') }}
        //Button(is-primary @click="onRefillClick") {{ $t('buttonPayIn') }}

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { pay } from '~/utils/utils'
import InfoMessage from '~/components/InfoMessage/InfoMessage'

export default {
  name: 'Wallet',

  components: { InfoMessage },

  computed: {
    ...mapGetters('viewport', ['isMobile', 'isHeaderMobile']),
    ...mapState('user', ['user']),

    isPayoutEnabled () {
      return (
        ['ceo', 'admin'].includes(this.user.role) || this.user.is_payout_enabled
      )
    }
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    onClickOutside ({ target }) {
      if (!this.$el.contains(target)) {
        this.$emit('close')
      }
    },

    onRefillClick () {
      this.$emit('close')

      pay('in', this.$store)
    },

    onPayOutClick () {
      if (!this.isPayoutEnabled) {
        return
      }

      this.$emit('close')

      pay('out', this.$store)
    }
  }
}
</script>

<style lang="scss">
.wallet {
  position: absolute;
  left: 100%;
  top: 64px;
  width: 0;
  transition: top 0.2s $easing;

  .--mini & {
    top: 48px;
  }

  @media (max-width: $breakpointHeaderMobile) {
    left: 4px;
    right: 4px;
    top: 44px;
    width: auto;
    max-width: 100%;
  }

  &__inner {
    min-width: 316px;
    border: 1px solid $blue-grey40;
    border-radius: 8px;
    background: $blue-grey70;
    padding: 20px 16px 24px;
    margin-top: 10px;
    margin-left: -234px;

    @media (max-width: $breakpointHeaderMobile) {
      margin-left: 0;
      min-width: 0;
    }
  }

  &__header {
    @include heading5;

    color: #fff;
    margin-bottom: 12px;
  }

  &__balance {
    margin-top: 16px;
    display: flex;

    .currency {
      @include heading5;

      margin-right: 16px;
      flex-grow: 1;

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  &__buttons {
    display: flex;
    margin-top: 12px;

    .button {
      width: calc(50% - 8px);

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
</style>
