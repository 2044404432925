import { initializeApp, getApps } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

let messagingConst

if (process.client) {
  const firebaseConfig = {
    apiKey: 'AIzaSyB6Rm8gfKidO2UH2akexJMTiAH-RRX0fbw',
    authDomain: 'vvvgamers-12a99.firebaseapp.com',
    projectId: 'vvvgamers-12a99',
    storageBucket: 'vvvgamers-12a99.appspot.com',
    messagingSenderId: '1074568119092',
    appId: '1:1074568119092:web:b514f6b2b67402f4f15b61',
    measurementId: 'G-2X5W6NTZCN'
  }

  const apps = getApps()

  const app = !apps.length ? initializeApp(firebaseConfig) : apps[0]

  messagingConst = getMessaging(app)
}

export const messaging = messagingConst
