<template lang="pug">
.modal-sub-info
  h2.modal-sub-info__title {{ $t('playNoLimits') }}
  p.modal-sub-info__text {{ $t('usersWithSubs') }}

  p.modal-sub-info__text {{ $t('subsGive') }}

  .modal-sub-info__container
    .modal-sub-info__star(v-for="i in 5" :key="i")
      InlineSvg(:src="require('~/assets/img/star.svg')" :class="getStarClass(i)")

  h3.modal-sub-info__subtitle {{ $t('willHavePriority') }}

  picture
    source.modal-sub-info__imageSmall(:srcset="require('~/assets/img/backgrounds/subInfoBackgroundSmall.webp')" type="image/webp")
    source.modal-sub-info__imageSmall(:srcset="require('~/assets/img/backgrounds/subInfoBackgroundSmall.jpeg')" type="image/jpeg")
    img.modal-sub-info__imageSmall(
      :src="require('~/assets/img/backgrounds/subInfoBackgroundSmall.jpeg')",
      alt="Subscrition image"
    )

  picture
    source.modal-sub-info__imageBig(:srcset="require('~/assets/img/backgrounds/subInfoBackground.webp')" type="image/webp")
    source.modal-sub-info__imageBig(:srcset="require('~/assets/img/backgrounds/subInfoBackground.jpeg')" type="image/jpeg")
    img.modal-sub-info__imageBig(
      :src="require('~/assets/img/backgrounds/subInfoBackground.jpeg')",
      alt="Subscrition image"
    )

  Button.modal-sub-info__button(is-primary @click="closeModal") {{ $t('buySubscription') }}
</template>

<script>
import { mapMutations } from 'vuex'

export default {

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle(false)
      this.$router.push(this.localeLocation('/store/subscriptions'))
    },

    getStarClass (i) {
      if (i > 3) {
        return 'modal-sub-info__star-img modal-sub-info__star-img--grey'
      }
      return 'modal-sub-info__star-img'
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalsubinfo {
  max-width: 420px;

  @media (min-width: $breakpointTablet) {
    padding: 40px 51px !important;
    max-width: 520px;
  }
}
</style>

<style lang="scss" scoped>
.modal-sub-info {
  color: $white;
  text-align: center;

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__text {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__subtitle {
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 32px;
    margin: 16px auto 24px;
    border: 1px solid #0076FE;
    border-radius: 16px;

    @media (min-width: $breakpointTablet) {
      padding: 20px 40px;
    }
  }

  &__example {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__button {
    margin-top: 32px;
    width: 100% !important;
  }

  &__imageSmall {
    @media (min-width: $breakpointTablet) {
      display: none;
    }
  }

  &__imageBig {
    display: none;

    @media (min-width: $breakpointTablet) {
      display: block;
    }
  }

  &__star {
    margin-right: 9px;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      width: 22px;
      height: 22px;

      &--grey {
        ::v-deep path {
          fill: $blue-grey50;
        }
      }
    }
  }
}
</style>
