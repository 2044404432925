/* eslint-disable import/no-named-as-default-member */
import qs from 'qs'
import Vue from 'vue'

export const state = () => ({
  recommendedTournaments: [],
  recommendedDuels: [],
  tournamentOfWeek: null,

  gameRecommendedTournaments: {},
  gameRecommendedDuels: {},
  gameTournamentOfWeek: {},

  gameBonusTournaments: {},
  bonusTournaments: [],

  games: [],
  gamesByName: {},
  gamesById: {}
})

export const getters = {
  gamesById (state) {
    return state.gamesById
  }
}

export const actions = {
  async fetchPhasedTournaments ({ commit }, query = {}) {
    const { game = 'lichess' } = query
    const url = query.url || `/games/${game}/mega-tournaments`

    delete query.game

    try {
      const { data } = await this.$axios.get(url, {
        params: query,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      })

      if (data?.data) {
        data.data = data.data.map(tournament => ({
          ...tournament,
          is_anticheat_required: game === 'lichess'
        }))
      }

      return data
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: tournaments fetch error ::`,
        e
      )
    }
  },

  async fetchTournaments ({ commit }, query = {}) {
    const { game = 'lichess' } = query
    const url = query.url || `/games/${game}/tournaments`

    delete query.game
    delete query.url

    try {
      const { data } = await this.$axios.get(url, {
        params: query,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      })

      return data
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: tournaments fetch error ::`,
        e
      )
    }
  },

  async fetchDuels ({ commit }, query = {}) {
    const { game = 'lichess' } = query
    const url = query.url || `/games/${game}/duels`

    delete query.game
    delete query.url

    try {
      const { data } = await this.$axios.get(url, {
        params: query,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      })

      return data
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: duels fetch error (api url: ${url}, query: ${JSON.stringify(
          query
        )}) ::`,
        e
      )
    }
  },

  async fetchGames ({ commit }) {
    try {
      const { data } = await this.$axios.get('/games')

      commit('setGames', data.data)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: games fetch error ::`, e)
    }
  },

  async fetchBonusTournaments ({ commit }, gameName = '') {
    try {
      const { data } = await this.$axios.get(
        `/games/${gameName ? gameName + '/' : ''}tournaments/with-bonus`
      )
      commit('setBonusTournaments', { gameName, data: data.data })
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: tournaments bonus fetch error ::`,
        e
      )
    }

    return true
  },

  fetchRecommendedTournaments ({ commit }, gameName = '') {
    // try {
    //   const { data } = await this.$axios.get(
    //     `/games/${gameName ? gameName + '/' : ''}tournaments/recommended`
    //   )
    //   commit('setRecommendedTournaments', { gameName, data: data.data })
    // } catch (e) {
    //   console.error(
    //     `${new Date().toUTCString()} :: tournaments recommended fetch error ::`,
    //     e
    //   )
    // }

    return true
  },

  fetchRecommendedDuels ({ commit }, gameName = '') {
    // try {
    //   const { data } = await this.$axios.get(
    //     `/games/${gameName ? gameName + '/' : ''}duels/recommended`
    //   )
    //   commit('setRecommendedDuels', { gameName, data: data.data })
    // } catch (e) {
    //   console.error(
    //     `${new Date().toUTCString()} :: duels recommended fetch error ::`,
    //     e
    //   )
    // }

    return true
  }

  // async fetchTournamentOfWeek({ commit }, gameName) {
  //   try {
  //     const { data } = await this.$axios.get(
  //       `/games/${gameName ? gameName + '/' : ''}tournaments/top-of-week`
  //     )
  //     commit('setTournamentOfWeek', { gameName, data: data.data })
  //   } catch (e) {
  //     console.error('duels recommended fetch error', e)
  //   }
  // },
}

export const mutations = {
  setRecommendedTournaments (state, { data, gameName }) {
    if (gameName) {
      state.gameRecommendedTournaments[gameName] = data
    } else {
      state.recommendedTournaments = data
    }
  },

  setRecommendedDuels (state, { data, gameName }) {
    if (gameName) {
      state.gameRecommendedDuels[gameName] = data
    } else {
      state.recommendedDuels = data
    }
  },

  setTournamentOfWeek (state, { data, gameName }) {
    if (Array.isArray(data)) {
      data = data[0]
    }

    if (gameName) {
      state.gameTournamentOfWeek[gameName] = data
    } else {
      state.tournamentOfWeek = data
    }
  },

  setBonusTournaments (state, { data, gameName }) {
    if (gameName) {
      Vue.set(state.gameBonusTournaments, gameName, data)
    } else {
      state.bonusTournaments = data
    }
  },

  setGames (state, payload) {
    const clickableAccountGames = ['lichess', 'clash-royale', 'brawl-stars']
    const unlinkDisabledGames = [
      'lichess',
      'pubg-desktop'
    ]
    const linkDisabledGames = [
      'apex-legends',
      'pubg-desktop',
      'mobile-legends',
      'dota2',
      'pubg-new-state'
    ]
    const anticheatSupportedGames = ['lichess']
    const duelsDateGames = ['pubg-mobile', 'free-fire']
    const overwolfGames = ['pubg-desktop', 'dota2']
    const tournamentAsDuelsGames = [
      'pubg-mobile',
      'pubg-new-state',
      'pubg-desktop'
    ]

    const colors = {
      lichess: '#fff',
      'clash-royale': '#1e88e5',
      'pubg-mobile': '#FF8F00',
      'free-fire': '#FF8F00',
      'pubg-desktop': '#fdd835',
      'brawl-stars': '#ffa726',
      dota2: '#dc3636'
    }

    state.games = payload
      .map(game => ({
        ...game,
        canClickNickname: clickableAccountGames.includes(game.name),
        isAnticheatSupported: anticheatSupportedGames.includes(game.name),
        mainPageLink: game.name === 'game-pix' ? '/games/gamepix' : `/${game.name}/tournaments`,
        canUnlink: !unlinkDisabledGames.includes(game.name),
        canLink: !linkDisabledGames.includes(game.name),
        color: colors[game.name],
        hasDuelsDate: duelsDateGames.includes(game.name),
        isOverwolf: overwolfGames.includes(game.name),
        hasTournamentsAsDuels: tournamentAsDuelsGames.includes(game.name)
      }))
      .sort((a, b) => b.is_enabled - a.is_enabled)

    state.gamesById = state.games.reduce((result, game) => {
      result[game.id] = game

      return result
    }, {})
    state.gamesByName = state.games.reduce((result, game) => {
      result[game.name] = game

      return result
    }, {})
  }
}
