<template lang="pug">
  span.player-game-name(
    v-if="account || player"
  )
    span.player-game-name__name(
      :class="{'--pointer': gameData && gameData.canClickNickname, '--me': withHighlighting && isMe}"
      @click.stop="onGameAccountClick"
    )
      | {{ username }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PlayerGameName',

  props: {
    withTooltip: {
      type: Boolean,
      default: true
    },

    withHighlighting: {
      type: Boolean,
      default: false
    },

    account: {
      type: Object,
      default: null
    },

    player: {
      type: Object,
      default: null
    },

    game: {
      type: Object,
      default: null
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapState('games', ['gamesByName']),
    ...mapGetters('user', ['gameAccount']),

    isVerificationSupported () {
      return ['lichess', 'pubg-mobile', 'free-fire'].includes(
        this.gameData.name
      )
    },

    isVerified () {
      const verificationData = this.account?.extra_data?.verification_data

      if (verificationData) {
        return verificationData.status === 'verified'
      }

      return (
        this.account?.extra_data?.is_verified || this.player?.is_email_verified
      )
    },

    username () {
      return this.account?.username || this.player?.game_username || ''
    },

    id () {
      return this.account?.id || this.player?.game_user_id
    },

    gameData () {
      return (
        this.game ||
        this.gamesByName[this.$route.params.game || this.account.game]
      )
    },

    isMe () {
      const gameAccount = this.gameAccount(this.gameData.name)

      return (
        this.id === gameAccount?.id ||
        this.username === gameAccount?.username ||
        false
      )
    }
  },

  methods: {
    onGameAccountClick () {
      if (this.gameData?.canClickNickname) {
        if (this.gameData.name === 'lichess') {
          window.open('https://lichess.org/@/' + this.username)
        } else if (this.gameData.name === 'clash-royale') {
          window.open(
            'https://royaleapi.com/player/' + this.id.replace('#', '')
          )
        } else if (this.gameData.name === 'brawl-stars') {
          window.open(
            'https://brawlify.com/stats/profile/' + this.id.replace('#', '')
          )
        }
      }
    },

    onVerifyClick () {
      const fideUrl = (this.account?.extra_data || this.player)
        .verification_data?.fideUrl

      if (fideUrl?.startsWith('http')) {
        window.open(fideUrl)
      }
    }
  }
}
</script>

<style lang="scss">
.player-game-name {
  display: inline-flex;
  align-items: center;

  &__verify {
    margin-right: 6px;
  }

  &__name {
    &.--pointer {
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0.2px;
      font-size: 17px;

      &:hover {
        text-decoration: underline;
      }
    }

    &.--me {
      color: $cyan50;
    }

    line-height: 20px;
  }

  > svg {
    cursor: pointer;
  }
}
</style>
