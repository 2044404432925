export const actions = {
  nuxtServerInit ({ commit, dispatch, state }) {
    commit('user/setToken', this.$cookiz.get('auth_token'))

    const requests = []

    if (state.user.token) {
      requests.push(dispatch('user/fetchUser'))
    }

    requests.push(dispatch('games/fetchGames'))

    const savedSize = this.$cookiz.get('vvv-viewport')
    let size

    if (savedSize) {
      size = savedSize
    } else {
      const { isMobile, isTablet } = this.$device
      const type = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'

      size = { type }
    }

    commit('viewport/setWindowSize', size)

    dispatch('marketplace/fetchCurrencies')

    return Promise.all(requests)
  }
}
