<template lang="pug">
.modal-check-requirements
  template(v-if="isTooYoung")
    .modal-check-requirements__image(class="--age")
      //img(:src="require(`./16plus.svg`)" alt="age image")
      h2 18

    h2 {{ $t('tooYoung') }}

    .modal-check-requirements__text(v-if="duel") {{ $t('joinConditionsDuel') }}
    .modal-check-requirements__text(v-else-if="tournament") {{ $t('joinConditionsTournament') }}
    .modal-check-requirements__text(v-else-if="isPayment") {{ $t('joinConditionsPayment') }}

  template(v-else-if="conditions")
    .modal-check-requirements__image
      img(:src="require('~/assets/img/icons/thunder.svg')" alt="conditions")

    template(v-if="duel || tournament")
      h2(v-if="duel") {{ $t('joinDuelText') }}
      h2(v-else) {{ $t('joinTournamentText') }}
      .modal-check-requirements__text(v-if="duel") {{ $t('joinConditionsDuel2') }}
      .modal-check-requirements__text(v-else)  {{ $t('joinConditionsTournament2') }}

    template(v-if="isPayment")
      h2(v-if="isPayOut") {{ $t('joinPayOutText') }}
      h2(v-else) {{ $t('joinPayInText') }}
      .modal-check-requirements__text {{ $t('joinConditionsPayment2') }}

    InfoMessage(v-if="!conditions.length" type="allow") {{ $t('joinConditionsDone') }}

    .modal-check-requirements__item(
      v-for="(item, index) in conditions"
      :key="index"
    )
      .modal-check-requirements__item-title
        img(src="~/assets/img/icons/denied.svg" alt="denied" width="24" height="24")
        | {{ item.title }}
      a.modal-check-requirements__item-value(v-if="item.value" @click.prevent="item.value.action")
        | {{ item.value.text }}

  .modal__footer
    template(v-if="isTooYoung")
      Button(is-secondary @click="onEditDetailsClick") {{ $t('ageQuestion') }}
      Button(v-if="tournament" is-primary @click="onFindAnotherClick") {{ $t('anotherTournamentButton') }}
      Button(v-else-if="duel" is-primary @click="onFindAnotherClick") {{ $t('findAnotherDuel') }}
      Button(v-else is-primary @click="close") {{ $t('buttonClose') }}

    template(v-else)
      Button(v-if="tournament" is-primary :is-disabled="!!conditions.length" @click="process")
        | {{ $t('enterTournament') }}
      Button(v-else-if="duel" is-primary :is-disabled="!!conditions.length" @click="process")
        | {{ duel.state === 'create' ? $t('buttonCreateDuel') :  $t('enterDuel')  }}
      Button(v-else-if="isPayIn" is-primary :is-disabled="!!conditions.length" @click="process")
        | {{ $t('goPayIn') }}
      Button(v-else-if="isPayOut" is-primary :is-disabled="!!conditions.length" @click="process")
        |  {{ $t('goPayOut') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import { create24MetricsScript } from '~/utils/24metrics'
import {
  isFullContactsDataNeeded,
  isPhoneAndBirthdateNeeded,
  isUserAdult
} from '~/utils/utils'
import TournamentMixin from '~/mixins/Tournament'
import DuelMixin from '~/mixins/Duel'

export default {
  name: 'ModalCheckRequirements',

  props: {
    tournament: {
      type: Object,
      default: null
    },

    duel: {
      type: Object,
      default: null
    },

    onSuccess: {
      type: Function,
      required: true
    },

    isPayIn: {
      type: Boolean,
      default: false
    },

    isPayOut: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('games', ['gamesByName', 'gamesById']),
    ...mapState('user', ['user', 'verification']),
    ...mapGetters('user', ['gameAccount']),

    conditions () {
      const items = []

      if (this.tournament || this.duel) {
        const gameAccount = this.gameAccount(this.game.name)

        if (!gameAccount && this.game.name !== 'game-pix') {
          items.push({
            title: this.$t('linkAcc', { game: this.game.extra_data.title }),
            value: {
              text: this.$t('buttonLink'),
              action: () => {
                this.close()
                this.$router.push(this.localeLocation('/profile/game-accounts'))

                this.$gtag.event('game_account_link', { step: 1, game: this.game.name })
              }
            }
          })
        } else if (
          this.game.name !== 'lichess' &&
          gameAccount?.extra_data?.verification_data?.status &&
          gameAccount?.extra_data?.verification_data.status !== 'verified'
        ) {
          items.push({
            title: this.$t('verifyAcc', { game: this.game.extra_data.title }),
            value: {
              text: this.$t('checkStatus'),
              action: () => {
                this.close()
                this.$router.push(this.localeLocation('/profile/game-accounts'))
              }
            }
          })
        }

        // евровый баланс отправляем пополнять только если данные заполнены
        if (
          this.isPaid &&
          // !isPhoneAndBirthdateNeeded(this.verification) &&
          // !isFullContactsDataNeeded(this.verification) &&
          this.user.balance < this.cost
        ) {
          items.push({
            title: this.$t('notEnoughMoney'),
            value: {
              text: '', // this.$t('buttonPayIn'),
              action: () => {
                this.close()
                // this.showModal({ component: 'PayInModal' })
              }
            }
          })
        }

        // if (!this.isPaid && this.user.coins < this.cost) {
        //   items.push({
        //     title: this.$t('notEnoughCoins'),
        //     value: {
        //       text: this.$t('buttonPayIn'),
        //       action: () => {
        //         this.close()
        //         this.$router.push(this.localeLocation('/store'))
        //       }
        //     }
        //   })
        // }
      }

      if (
        (this.isPaid) && // || this.isPayIn) &&
        isPhoneAndBirthdateNeeded(this.verification)
      ) {
        let title = this.$t('joinConditionsPayment3')

        if (this.duel) {
          title = this.$t('joinConditionsDuel3')
        } else if (this.tournament) {
          title = this.$t('joinConditionsTournament3')
        }

        items.push({
          id: 'contact_info',
          title: this.$t('fillInfo'),
          value: {
            text: this.$t('fillButton'),
            action: () => {
              this.showModal({
                component: 'ModalVerification',
                data: {
                  title,
                  onSuccess: (verification) => {
                    if (verification) {
                      window.localStorage.setItem(
                        'saved-verification',
                        JSON.stringify(verification)
                      )
                    }
                    verification =
                      verification || this.$store.state.user.verification

                    if (
                      isFullContactsDataNeeded(verification) &&
                      isUserAdult(verification, this.$dateFns)
                    ) {
                      this.$router.push(
                        this.localeLocation('/profile/personal-data')
                      )
                    } else {
                      this.showModal({
                        component: 'ModalCheckRequirements',
                        data: this.$props
                      })
                    }
                  }
                }
              })
            }
          }
        })
      } else if (
        (!isPhoneAndBirthdateNeeded(this.verification) || this.isPayOut) &&
        isFullContactsDataNeeded(this.verification)
      ) {
        items.push({
          title: this.$t('fillInfo'),
          value: {
            text: this.$t('fillButton'),
            action: () => {
              this.close()
              this.$router.push(
                this.localeLocation(
                  '/profile/personal-data' + (this.isPayOut ? '?payout' : '')
                )
              )
            }
          }
        })
      }

      const isEmailVerified = this.user?.email && this.user?.is_email_verified

      if (
        (this.isPayOut /* || this.isPayIn */ /* || this.tournament */) &&
        !isEmailVerified
      ) {
        items.push({
          title: this.$t('joinConditionEmail'),
          value: {
            text: this.$t('fillButton'),
            action: () => {
              this.close()
              this.showModal({ component: 'EmailModal', data: {} })
            }
          }
        })
      }

      return items
    },

    cost () {
      if (this.tournament) {
        return TournamentMixin.computed.entryFee.call(this)
      }

      if (this.duel && !this.duel.entry_fee) {
        return 0
      }

      return DuelMixin.computed.entryFee.call(this)
    },

    isPaid () {
      return this.duel
        ? this.duel.currency === 'money'
        : this.tournament?.prize_settings?.entry_fee_currency === 'money'
    },

    game () {
      const event = this.tournament || this.duel
      return (
        this.gamesById[event.game_id] ||
        this.gamesByName[this.$route.params.game]
      )
    },

    isPayment () {
      return this.isPayIn || this.isPayOut
    },

    isTooYoung () {
      return (
        (this.isPaid || this.isPayment) &&
        !isPhoneAndBirthdateNeeded(this.verification) &&
        !isUserAdult(this.verification, this.$dateFns)
      )
    }
  },

  async beforeMount () {
    await this.$store.dispatch('user/fetchVerification')
    this.checkPermissions()

    if (!this.isTooYoung && !this.conditions.length) {
      this.close()
      this.process()
    } else if (
      // сразу показываем модалку с подтверждением телефона если только этого не хватает
      this.conditions.length === 1 &&
      this.conditions[0].id === 'contact_info'
    ) {
      this.close()
      this.conditions[0].value.action()
    } else {
      this.$emit('visibility', true)
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    close () {
      this.$store.commit('modal/toggle', false)
    },

    checkPermissions () {
      // if (this.tournament) {
      //   const tournamentData = {
      //     id: this.tournament.id,
      //     source: 'Tournaments'
      //   }
      //
      //   create24MetricsScript(this.$el, this.$cookiz, this.$axios, tournamentData, this.user?.id, 'join-tournament', () => {})
      // }
    },

    onEditDetailsClick () {
      this.close()
      this.$router.push(this.localeLocation('/profile/account#edit'))
    },

    onFindAnotherClick () {
      this.close()
      this.$router.push(
        this.localeLocation(
          this.$route.path.split('/').slice(0, -1).join('/') +
            '??entry_fee=::coins'
        )
      )
    },

    process () {
      // this.close()
      this.onSuccess()
    }
  }
}
</script>

<style lang="scss">
.modal-check-requirements {
  &__item {
    @include hint;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    width: 100%;

    @media (max-width: $breakpointMobile) {
      margin-top: 16px;
    }

    &-title {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        @media (max-width: $breakpointMobile) {
          margin-right: 4px;
        }
      }
    }

    &-value {
      @include button;

      text-align: right;
      color: $primary60;
      cursor: pointer;
      flex: 1;
    }
  }

  &__text {
    @include body;

    margin-bottom: 24px;
    text-align: center;
    color: $blue-grey05;
  }

  &__image {
    margin: 36px auto 16px;
    width: 96px;
    height: 96px;
    overflow: hidden;

    @media (max-width: $breakpointMobile) {
      margin-top: 4px;
    }

    &.--age {
      background: $red05-20;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.modal.modal--modalcheckrequirements {
  max-width: 506px;
  color: #fff;

  h2 {
    @include heading2;

    text-align: center;
    margin-bottom: 12px;
  }

  .modal__footer {
    display: flex;
    border: none;
    padding: 0;
    margin: 40px 0 0;

    .button {
      width: 100%;

      &:only-child {
        width: auto;
      }
    }
  }

  .button + .button {
    margin-left: 16px;
  }

  @media (max-width: $breakpointTablet) {
    .modal__footer {
      flex-direction: column-reverse;

      .button + .button {
        margin: 0 0 8px;
      }
    }
  }
}
</style>
