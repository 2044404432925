<script>
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import { mapActions } from 'vuex'
import StoreService from '@/services/StoreService'
import FormInput from '@/components/FormInput/FormInput.vue'
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput'

export default {
  name: 'AddNewCategoryModal',

  components: {
    CheckboxInput,
    BaseSelect,
    FormInput
  },

  props: {
    appId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      categoryTitle: '',
      fieldTypes: [
        {
          value: 'string',
          title: this.$t('text')
        },
        {
          value: 'number',
          title: this.$t('number')
        },
        {
          value: 'enum',
          title: this.$t('variantsList')
        }
      ],
      fields: []
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('marketplace', ['fetchApps']),

    async createCategory () {
      const fields = this.fields.filter(field => field.title.trim() !== '').map(field => ({
        name: field.title,
        type: field.type === 'enum' ? 'string' : field.type,
        enum: field.type === 'enum' ? field.enum.map(variant => variant.value) : undefined,
        multi: field.type === 'enum' ? field.multi : undefined
      }))

      const { data } = await StoreService.addMarketplaceAppCategory(this.$axios, this.appId, {
        application_id: this.appId,
        category_name: this.categoryTitle,
        fields
      })

      const id = data?.data?.result

      if (id) {
        this.fetchApps()

        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('success'),
            type: 'info',
            text: this.$t('categoryAdded')
          }
        })
      }
    },

    addVariant (field) {
      field.enum.push({
        value: ''
      })
    },

    addField () {
      let id
      if (!this.fields || this.fields.length === 0) {
        id = 1
      } else {
        id = (this.fields[this.fields?.length - 1].id || 0) + 1
      }

      this.fields.push({
        id,
        title: '',
        type: 'string',
        enum: [],
        multi: false
      })
    },

    removeField (fieldId) {
      this.fields = this.fields.filter(f => f.id !== fieldId)
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('addCategory') }}
    </p>

    <p :class="$style.label">
      {{ $t('categoryTitle') }}
    </p>

    <FormInput v-model="categoryTitle" :placeholder="$t('categoryTitle')" />

    <p :class="$style.label">
      {{ $t('fields') }}
    </p>

    <div :class="$style.fields">
      <div v-for="field in fields" :key="field.id" :class="$style.field">
        <FormInput v-model="field.title" :placeholder="$t('title')" />

        <BaseSelect
          v-model="field.type"
          :options="fieldTypes"
          :with-empty-value="false"
          :placeholder="$t('choose')"
        />

        <div :class="$style.delete" @click="removeField(field.id)">
          <InlineSvg :class="$style.deleteIcon" :src="require('~/assets/img/icons/marketplace-delete.svg')" />
        </div>

        <div v-if="field.type === 'enum'" :class="$style.fullWidth">
          <CheckboxInput v-model="field.multi">
            <span>{{ $t("multiChoice") }}</span>
          </CheckboxInput>
        </div>

        <div v-if="field.type === 'enum'" :class="$style.enum">
          <p :class="$style.enum__title">
            {{ $t('variants') }}
          </p>

          <div v-for="(variant, index) in field.enum" :key="`variant_${field.id}_${index}`">
            <FormInput v-model="variant.value" :placeholder="$t('variant')" />
          </div>
          <div :class="$style.add" @click="addVariant(field)">
            <InlineSvg :class="$style.add__icon" :src="require('~/assets/img/icons/plus.svg')" />
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.fieldAdd" @click="addField">
      <InlineSvg :class="$style.add__icon" :src="require('~/assets/img/icons/plus.svg')" />
      {{ $t('addField') }}
    </div>

    <Button is-primary :class="$style.button" @click="createCategory">
      {{ $t('create') }}
    </Button>
  </div>
</template>

<style>
.modal--addnewappmodal {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 32px;
}

.label {
  margin: 24px 0 12px;
  padding-left: 8px;
  border-left: 8px solid $blue-grey10;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.withImg {
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-gap: 8px;
  align-items: center;
}

.img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
}

.button {
  width: 280px;
  margin: 24px 0 0;
}

.fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px 8px;
  align-items: center;
}

.field {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-gap: 8px;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 2fr 1fr max-content;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: $blue-grey60;
  color: $blue-grey05;
  font-size: 24px;
  line-height: 100%;
  font-weight: 500;

  &Icon {
    width: 24px;
    height: 24px;
  }
}

.fullWidth {
  grid-column: 1 / -1;
  width: 100%;
}

.enum {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  align-items: center;
  margin: 12px 0;

  &__title {
    color: $blue-grey10;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin: 0 0 4px 16px;
    grid-column: 1 / -1;
  }
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: $blue-grey50;
  border-radius: 8px;
  cursor: pointer;

  &__icon {
    width: 32px;
    height: 32px;
  }
}

.fieldAdd {
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: $blue-grey50;
  color: $blue-grey10;
  border-radius: 8px;
  margin-top: 12px;
  cursor: pointer;

  &__icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
}
</style>
