<template lang="pug">
  .base-select(:class="{ '--opened': isOpened, '--title-visible': withTitle, '--has-value': currentTitle !== placeholder }")
    .base-select__title(v-if="title") {{ title }}

    .base-select__label(v-if="label") {{ label }}

    select(v-model="currentValue" class="base-select__select" @input="onSelectOption")
      option(
        v-if="withEmptyValue"
        value=""
        :selected="!value"
      ) {{ $t('selectDefault') }}

      option(
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === currentValue"
      )
        | {{ option.icon === 'arrow-down' ? '↓ ' : '' }}
        | {{ option.icon === 'arrow-up' ? '↑ ' : '' }}
        | {{ option.isLocalised ? option.title : $t(option.title) }}

    .base-select__body(@click="onClickInput" :class="{ '--error': error, 'base-select__body--light': light }")
      .base-select__name
        .base-select__name-title(v-if="withTitle && currentTitle !== placeholder") {{ placeholder }}

        InlineSvg.base-select__sort-arrow(v-if="icon" :src="require(`~/assets/img/icons/${icon}.svg`)")
        | {{ isTitleLocalised || !currentValue ? currentTitle : $t(currentTitle)}}

      .base-select__arrow
        InlineSvg(:src="require('~/assets/img/icons/filter-arrow.svg')")
    .base-select__error(v-if="error")
      | {{ error }}

    transition(name="slide-up" duration="200")
      .base-select__drop(v-if="isOpened")
        .base-select__drop-body
          .base-select__drop-body-inner
            .base-select__drop-item(
              v-if="withEmptyValue"
              :class="{'--active': !value }"
              @click="onClickOption({ value: '' })"
            ) {{ $t('selectDefault') }}

            .base-select__drop-item(
              v-for="option in options"
              :class="{'--active': option.value === currentValue }"
              @click="onClickOption(option)"
            )
              img.base-select__option-icon(v-if="option.icon && option.icon.startsWith('http')" :src="option.icon" alt="icon")
              InlineSvg(v-else-if="option.icon && option.icon.startsWith('/currencies')" :src="option.icon")
              InlineSvg(v-else-if="option.icon" :src="require(`~/assets/img/icons/${option.icon}.svg`)")
              span.base-select__drop-item-title {{ option.isLocalised ? option.title : $t(option.title)  }}
              Currency(v-if="option.money" type="money" real size="16" is-reversed)
                | {{ option.money }}

</template>

<script>
export default {
  name: 'BaseSelect',

  props: {
    placeholder: {
      type: String,
      default: ''
    },

    options: {
      type: Array,
      required: true
    },

    value: {
      type: [String, Number],
      required: true
    },

    icon: {
      type: String,
      default: ''
    },

    withEmptyValue: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: ''
    },

    withTitle: {
      type: Boolean,
      default: false
    },

    light: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    error: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      currentValue: null,
      currentTitle: null,
      isOpened: false,
      isTitleLocalised: false
    }
  },

  watch: {
    value: {
      handler (value) {
        const option = this.options.find(item => item.value === value)
        this.currentValue = option ? value : null
        this.currentTitle = option ? option.title : this.placeholder
        this.isTitleLocalised = option?.isLocalised
      },
      immediate: true
    }
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },

  methods: {
    onClickInput () {
      this.isOpened = !this.isOpened

      if (!this.isOpened) {
        this.$emit('blur')
      }
    },

    onClickOption (option) {
      this.isOpened = false

      const optionValue = option?.value || ''

      if (this.value === optionValue) {
        return
      }

      this.$emit('input', optionValue, option)
      this.$emit('blur')
    },

    onSelectOption ({ target }) {
      this.onClickOption(this.options.find(opt => opt?.value === target?.value))
    },

    onClickOutside ({ target }) {
      if (!this.$el.contains(target)) {
        if (this.isOpened) {
          this.$emit('blur')
        }

        this.isOpened = false
      }
    }
  }
}
</script>

<style lang="scss">
.base-select {
  position: relative;

  &__option-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 8px;
  }

  &__title {
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: white;
    margin-bottom: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 600;
  }

  &__label {
    @include caption-small;

    color: $grey20;
    margin-bottom: 16px;
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 16px;
    border-radius: 8px;
    background: $blue-grey60;
    cursor: pointer;
    transition: background 0.2s $easing;
    height: 48px;
    min-width: 165px;

    &:hover {
      background: $blue-grey40;
    }

    &.--error {
      background: $red60-20;
    }

    .--opened & {
      background: $blue-grey40;
    }

    .--title-visible & {
      height: 56px;
    }

    &--light {
      background: $blue-grey40;
    }
  }

  &__name {
    @include text-display100;

    font-weight: 500;
    display: flex;
    align-items: center;
    color: $white;

    &-title {
      font-size: 14px;
      color: $primary05;
      position: absolute;
      left: 0;
      top: -8px;
      white-space: nowrap;
      height: 14px;
      line-height: 14px;
    }

    svg.base-select__sort-arrow {
      margin-right: 8px;
    }

    .--has-value.--title-visible & {
      padding-top: 12px;
      position: relative;
    }
  }

  &__error {
    @include text-display100;

    position: absolute;
    bottom: -22px;
    right: 0;
    font-size: 18px;
    color: $red40;
  }

  &__drop {
    position: absolute;
    z-index: 4;
    max-width: 400px;
    min-width: 100%;
    width: auto;
    border-radius: 8px;
    top: 100%;
    overflow: hidden;
  }

  &__drop-body {
    border: 1px solid $blue-grey30;
    border-radius: 8px;
    margin-top: 8px;
    background: $blue-grey70;
    padding: 8px 0;
  }

  &__drop-body-inner {
    @include scroll;

    padding: 8px 16px;
    max-height: 265px;
  }

  &__drop-item {
    background: $blue-grey60;
    cursor: pointer;
    transition: background 0.2s $easing;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 8px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 100%;
    font-weight: 500;

    @media (min-width: $breakpointTablet) {
      @include heading6;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: $blue-grey40;
    }

    &.--active {
      background: $gradient-primary;
    }

    svg:first-child {
      margin-right: 8px;
    }

    &-title {
      display: block;
      width: 100%;
    }
  }

  &__select {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
  }

  &__arrow {
    transition: 0.2s $easing;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -10px;
    height: 20px;
    width: 20px;
    display: flex;

    .--opened & {
      transform: rotate(-180deg);

      svg path {
        fill: white;
      }
    }
  }

  /*
  &__search {
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 1px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: -1px;
      width: calc(100%+2px);
      height: 1px;
      background: $blue-grey30;
      bottom: 0;
    }
    input {
      @include text-display100;
      color: $blue-grey05;
      background: transparent;
      width: 100%;
      padding: 19px 80px 19px 56px;
      &::placeholder {
        @include text-display100;
        color: $blue-grey05;
      }
    }
  }
  */

  /*
  &__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  &__search-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }

  &__search-number {
    @include heading6;

    color: $white;
  }

  &__search-remove {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: $blue-grey60;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  &__search-clear {
    @include heading6;

    background-color: $gradient-primary;
    color: $amber60;
  }

   */
}
</style>
