<template lang="pug">
  .proxy
    .proxy__image(:style="{'--color': productColor}")
      img(:src="product.image_url" alt="img")

    .proxy__wrapper
      .proxy__type Proxies
      h3.proxy__title {{ product.title }}

      .proxy__holder
        span.proxy__old {{ oldPrice }}
        span.proxy__discount Discount {{ product.extra_data.discount.amount }} %
      p.proxy__price {{ newPrice }} proxy /day
      p {{ getLocalizedParam(product, 'price_info') }}

      Button.proxy__button(is-primary, is-small, @click='buy') {{ $t("buy") }}

      p.proxy__subtitle {{ $t('description')}}
      p.proxy__text {{ getLocalizedParam(product, 'subtitle') }}

      p.proxy__subtitle Plan details
      p.proxy__subtext {{ getLocalizedParam(product, 'description') }}

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ModalCheckoutProxy',

  props: {
    product: {
      type: Object,
      default: null
    },

    afterPay: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    ...mapMutations('modal', ['toggle']),
    ...mapState('user', ['user']),

    oldPrice () {
      return `$${(this.product.extra_data.min_price / 100).toFixed(2)} - $${(this.product.extra_data.max_price / 100).toFixed(2)}`
    },

    newPrice () {
      return `$${(this.product.extra_data.min_price_with_discount /
       100).toFixed(2)} - $${(this.product.extra_data.max_price_with_discount / 100).toFixed(2)}`
    },

    productColor () {
      return this.product.extra_data.color
    }
  },

  methods: {
    ...mapActions('user', ['fetchProducts']),
    ...mapActions('modal', ['showModal']),

    getLocalizedParam (product, param) {
      let locale = this.$i18n.locale

      if (!product.extra_data?.text?.[locale]) {
        locale = 'en'
      }

      return product.extra_data?.text?.[locale]?.[param]
    },

    buy () {
      this.$gtag.event('buy_proxy_click', { product_id: this.product?.id })

      window.open(this.product.extra_data.purchase_link, '_blank')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalcheckoutproxy {
  padding: 20px;
  max-width: 90vw;
  max-height: 95%;
  overflow-y: auto;

  @media (min-width: $breakpointTablet) {
    max-width: 440px;
    padding: 24px;
  }

  @media (min-width: $breakpointDesktop) {
    max-width: 850px;
    padding: 24px 24px 40px;

    .modal__close {
      top: 24px;
    }
  }
}
</style>

<style lang="scss" scoped>
.proxy {
  font-size: 16px;
  color: $white;
  background-color: $blue-grey70;
  border-radius: 8px;
  overflow: hidden;

  @media (min-width: $breakpointDesktop) {
    display: flex;
  }

  &__wrapper {
    position: relative;
  }

  &__image {
    position: relative;
    margin-bottom: 24px;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #232F3F 0%, #232F3F 100%);
    border-radius: 8px;

    @media (min-width: $breakpointDesktop) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 279px;
      height: 342px;
      flex-shrink: 0;
      margin-right: 40px;
      overflow: hidden;
    }

    img {
      position: relative;
      z-index: 10;
    }

    &::before {
      position: absolute;
      right: 0;
      content: "";
      height: 352px;
      width: 80px;
      background: var(--color);
      rotate: -40deg;
      top: -89px;
      z-index: 10;

      @media (min-width: $breakpointDesktop) {
        width: 55px;
      }
    }
  }

  &__type {
    margin-bottom: 8px;
    text-transform: uppercase;
    color: #90A4AE;
  }

  &__title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
  }

  &__holder {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 12px;
  }

  &__old {
    font-size: 14px;
    color: $blue-grey05;
    text-decoration: line-through;
  }

  &__discount {
    margin-left: 5px;
    padding: 2px 6px;
    color: $white;
    background: linear-gradient(261.55deg, #3949AB 5.62%, #5C6BC0 95.25%);
    border-radius: 100px;
  }

  &__price {
    margin-bottom: 4px;
    color: $white;
    font-size: 16px;
    font-weight: 700;
  }

  &__subtitle {
    margin: 32px 0 12px;
    color: $white;
    font-size: 20px;
    font-weight: 700;
  }

  &__button {
    width: 100%;
    margin: 24px 0 32px;

    @media (min-width: $breakpointDesktop) {
      max-width: 245px;
    }
  }
}
</style>
