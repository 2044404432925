<template lang="pug">
.tab-participants
  .tab-participants__general-info
    template(v-if="tournamentParticipantsCount")
      .tab-participants__general-info-block
        .tab-participants__general-info-block-label(v-t="'members'")
        .tab-participants__general-info-block-value {{ tournamentParticipantsText }}
      .tab-participants__general-info-block
        .tab-participants__general-info-block-label(v-t="'prizePool'")
        .tab-participants__general-info-block-value
          template(v-if="isMoneyPrize")
            Currency(type="money" is-reversed)
              span.tab-participants__general-info-block-value {{ tournamentMoneyPrize }}
          Currency(v-else type="coins" is-reversed)
            span.tab-participants__general-info-block-value {{ tournamentCoinPrize }}
    template(v-else)
      .tab-participants__general-info-block.tab-participants__general-info-block--full
        .tab-participants__general-info-block-label(v-t="'prizePool'")
        .tab-participants__general-info-block-value
          template(v-if="isMoneyPrize")
            Currency(type="money" is-reversed)
              span.tab-participants__general-info-block-value {{ tournamentMoneyPrize }}
          Currency(v-else type="coins" is-reversed)
            span.tab-participants__general-info-block-value {{ tournamentCoinPrize }}
    .tab-participants__general-info-block.tab-participants__general-info-block--full
      .tab-participants__general-info-block-label(v-t="'prizePlacesCount'")
      .tab-participants__general-info-block-value {{ winnersCount }}
  template(v-if="arePlayersInTournament")
    .tab-participants__title(v-t="'players'")
    .tab-participants__players
      .tab-participants__player(v-for="player in tournamentPlayers" :key="player.id")
        AvatarWithStars(:src="player.avatar" :userId="player.id")
        span.tab-participants__player-name {{ playerName(player) }}
        .tab-participants__player-place
          InlineSvg.tab-participants__player-place-star(:src="require(`~/assets/img/icons/star.svg`)" :class="placeStarClass(player)")
          p.tab-participants__player-place-number {{ player.place || '?' }}
</template>

<script>
import AvatarWithStars from '@/components/AvatarWithStars'

export default {
  name: 'TabParticipants',

  components: {
    AvatarWithStars
  },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      required: true
    }
  },

  computed: {
    tournamentParticipantsCount () {
      return this.tournament?.players_count
    },

    tournamentParticipantsMaxCount () {
      return this.tournament?.tournament_player_settings?.max_players_count
    },

    tournamentParticipantsText () {
      return `${this.tournamentParticipantsCount} / ${this.tournamentParticipantsMaxCount}`
    },

    prizeCurrency () {
      return this.tournament?.prize_settings?.prize_currency
    },

    isMoneyPrize () {
      return this.prizeCurrency === 'money'
    },

    tournamentCoinPrize () {
      return this.isMoneyPrize ? this.tournament?.prize_pool * 12 : this.tournament?.prize_pool
    },

    tournamentMoneyPrize () {
      return this.isMoneyPrize ? (this.tournament?.prize_pool / 100).toFixed(2) : '0'
    },

    winnersCount () {
      return this.tournament?.prize_settings?.win_place_count
    },

    tournamentPlayers () {
      return this.tournament?.players
    },

    arePlayersInTournament () {
      return this.tournamentPlayers?.length
    }
  },

  methods: {
    playerName (player) {
      return player?.full_name
    },

    placeStarClass (player) {
      return {
        'tab-participants__player-place-star--gold': player.place === 1,
        'tab-participants__player-place-star--silver': player.place === 2,
        'tab-participants__player-place-star--bronze': player.place === 3
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-participants {
  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $white;
  }

  &__general-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-bottom: 24px;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: repeat(4, 1fr);
    }

    &-block {
      padding: 16px 24px;
      background: linear-gradient(179.69deg, rgba(20, 30, 43, 0) 0.29%, rgba(0, 143, 109, 0.16) 99.75%);
      border-radius: 8px;
      border-bottom: 8px solid $cyan60;

      &--full {
        grid-column: 1 / 3;
        background: linear-gradient(179.69deg, rgba(20, 30, 43, 0) 0.29%, rgba(233, 183, 49, 0.18) 99.75%);
        border-bottom: 8px solid $orange60;
        text-align: start;

        @media (min-width: $breakpointTablet) {
          grid-column: auto / span 2;
        }

        * {
          text-align: start !important;
          justify-content: flex-start !important;
        }
      }

      &-value {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: $white;
      }

      &-separator {
        margin: 0 4px;
      }

      &-label {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: $blue-grey05;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  &__players {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }

  &__player {
    display: grid;
    grid-template-columns: max-content 1fr 55px;
    grid-gap: 10px;
    align-items: center;
    padding: 12px 0 12px 12px;
    background: $blue-grey60;
    border-radius: 8px;

    &-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $white;
    }

    &-place {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-left: 1px solid $blue-grey30;

      &-number {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba($white, 0.9);
        margin-top: 2px;
      }

      &-star {
        &--gold {
          ::v-deep path {
            fill: $amber50;
          }
        }

        &--silver {
          ::v-deep path {
            fill: $white;
          }
        }

        &--bronze {
          ::v-deep path {
            fill: $orange90;
          }
        }
      }
    }
  }
}
</style>
