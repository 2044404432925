<template lang="pug">
  .number-input(:class="{'--error': error }")
    .number-input__title(v-if="title")
        | {{ title }}
    label.number-input__label(:class="{'--full': fullWidth}")
      Currency(v-if="currency" :type="currency" onlyRub :real="real")
        input.number-input__input(
          ref="input"
          type="number"
          :value="value"
          :step="increment"
          :placeholder="placeholder"
          @input="onNativeInput"
          @blur="$emit('blur', $event)"
        )
      input.number-input__input(
        v-else
        ref="input"
        type="number"
        :value="value"
        :placeholder="placeholder"
        @input="onNativeInput"
        @blur="$emit('blur', $event)"
      )
      .number-input__error(v-if="error") {{ error }}

      a.number-input__arrow-up(@click.prevent.stop="onIncrement")
        InlineSvg(:src="require('~/assets/img/icons/filter-arrow.svg')")
      a.number-input__arrow-down(@click.prevent.stop="onDecrement")
        InlineSvg(:src="require('~/assets/img/icons/filter-arrow.svg')")

</template>

<script>
export default {
  name: 'NumberInput',

  props: {
    title: {
      type: String,
      default: ''
    },

    currency: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: ''
    },

    increment: {
      type: Number,
      default: 1
    },

    fixed: {
      type: Number,
      default: 0
    },

    max: {
      type: Number,
      default: null
    },

    min: {
      type: Number,
      default: 0
    },

    error: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    onlyRub: {
      type: Boolean,
      default: false
    },

    real: {
      type: Boolean,
      default: false
    },

    fullWidth: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      timer: null
    }
  },

  methods: {
    onNativeInput ({ target }) {
      this.onInput(target.value)
    },
    onInput (value) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if (this.max && value > this.max) {
          this.$emit('input', this.max.toString())
          this.$refs.input.value = this.max
        }

        if (value && value < this.min) {
          this.$emit('input', this.min.toString())
          this.$refs.input.value = this.min
        }
      }, 2000)

      if (value.includes('.')) {
        const splitted = value.split('.')
        value = `${splitted[0]}.${splitted[1].slice(0, 2)}`
      }

      this.$emit('input', value)
    },

    onIncrement () {
      this.onInput((Number(this.value) + this.increment).toFixed(this.fixed))
    },

    onDecrement () {
      let newValue = Number(this.value) - this.increment

      if (newValue < 0) {
        newValue = 0
      }

      this.onInput(newValue.toFixed(this.fixed))
    }
  }
}
</script>

<style lang="scss">
.--full {
  width: 100% !important;
}

.number-input {
  display: flex;
  align-items: flex-start;

  &__error {
    @include text-display100;

    color: $red40;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 4px;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 130px;
    width: 130px;
    padding: 0 30px 0 16px;
    background: $blue-grey60;
    color: $white;
    border-radius: 8px;
    height: 48px;
    line-height: 48px;

    .--error > & {
      background: $red60-20;
    }
  }

  .currency {
    margin-left: -5px;
    width: calc(100% + 5px);
    max-width: calc(100% + 5px);
  }

  &__text {
    @include text-info100;

    color: $white;
  }

  input {
    @include text-info100;

    width: 100%;
    background: transparent;
    color: $white;
    -moz-appearance: textfield;
    min-width: 0;
    padding: 0;
    border: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::placeholder {
      @include text-display100;

      color: $white20;
    }
  }

  &__arrow-up,
  &__arrow-down {
    position: absolute;
    right: 4px;
    top: 8px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s $easing;

    svg {
      transform: rotate(180deg);
    }

    &:active {
      transform: translateY(-2px);
    }
  }

  /* stylelint-disable-next-line */
  &__arrow-down {
    top: auto;
    bottom: 8px;

    svg {
      transform: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__title {
    @include caption-small;

    color: $blue-grey10;
    margin-right: 12px;
  }
}
</style>
