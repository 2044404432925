<template lang="pug">
  .tournament-card__labels(:class="{ '--white': isWhite }")
    .tournament-card__label(v-for="label in labels" v-tippy :content="label.title")
      InlineSvg(v-if="label.icon" :src="require(`~/assets/img/icons/${label.icon}.svg`)" :class="`--${label.icon}`" height="20" width="20")
      template(v-if="label.currency")
        Currency.tournament-card__label-value(
          v-if="label.currency"
          v-bind="label.currency"
          is-reversed
          :class="{'--discount': label.bonus }"
          size="16"
        ) {{ label.value }}
        Currency.tournament-card__label-value.tournament-card__label-crossed(
          v-if="label.crossed"
          v-bind="label.currency"
          is-reversed
          size="16"
        ) {{ label.crossed }}
      .tournament-card__label-value(v-else-if="label.value") {{ label.value }}
      .tournament-card__label-tickets(v-if="label.tickets") {{ $t('or') }}
        Currency(type="tickets" size="16" is-reversed) {{ label.tickets }}
      .tournament-card__label-bonus(v-if="label.bonus") -{{ label.bonus }}%

</template>

<script>
export default {
  name: 'TournamentCardLabels',

  props: {
    labels: {
      type: Array,
      required: true
    },

    isWhite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.tournament-card__label {
  display: flex;
  align-items: center;
  font-weight: $font-medium;
  font-size: 14px;
  color: #fff;
  padding: 4px 12px 8px;
  height: 20px;
  box-sizing: content-box;
  position: relative;

  &.--white {
    background-color: rgb(240 245 247 / 75%);
    color: $blue-grey80;

    &-bonus {
      background-color: rgb(240 245 247 / 75%);
      color: $blue-grey80;
    }
  }

  &-bonus {
    @include text;

    position: absolute;
    left: 2px;
    top: -14px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    background: $amber60;
    border-radius: 4px;
    transform: rotate(-4deg);
    padding: 0 3px;
  }

  &-tickets {
    color: $blue-grey10;

    .currency {
      margin-left: 2px;
      color: #fff;

      svg {
        margin-left: 1px !important;
      }
    }
  }

  &-value {
    margin-left: 4px;

    &.--discount {
      color: $amber60;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &-crossed {
    color: $blue-grey10;
    position: relative;
    margin-right: 4px;

    svg path {
      fill: $blue-grey10;
    }

    &::after {
      position: absolute;
      content: "";
      height: 1px;
      left: -2px;
      right: 0;
      top: 50%;
      background: $blue-grey10;
      transform: rotate(-4deg);
    }
  }

  .currency.--reversed svg {
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    border-right: 1px solid $blue-grey30;
    height: 12px;
    top: 8px;
    right: 0;
    width: 0;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}
</style>
