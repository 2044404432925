<template lang="pug">
  form.pay-in-modal.pay-out-modal(@submit.prevent="onSubmit")
    .modal__header {{ $t('fundsWithdraw') }}

    .pay-in-modal__body(ref="body")

      .pay-in-modal__warnings
        InfoMessage(v-if="user.verification_status === 'not_verified' || (user.verification_status !== 'verified' && isVerificationNeeded) || !user.email || !user.is_email_verified" type="warning")
          div(v-if="user.verification_status === 'not_verified'")
            span(v-html="$t('forWithdrawNeedContactInfo')")

          div(v-else-if="user.verification_status !== 'verified' && isVerificationNeeded")
            span(v-html="$t('forWithdrawNeedVerification')")

          div(v-if="!user.email || !user.is_email_verified")
            span(v-html="$t('forWithdrawNeedConfirmEmail')")

      .pay-in-modal__steps(v-if="currentStepIndex !== 10")
        Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

      .pay-in-modal__section(v-if="currentStepIndex < 2")
        .pay-in-modal__values
          .pay-in-modal__value
            .pay-in-modal__value-inner
              .pay-in-modal__value-title {{ $t('available') }}
              .pay-in-modal__value-money
                Currency(type="money" onlyRub) {{ minWithdrawalLimit }}
              //.pay-in-modal__value-money.pay-in-modal__value-money--verification
              //  | +
              //  Currency(type="money" onlyRub) {{ notVerifiedBalance }} {{ $t('onVerification') }}

          .pay-in-modal__value
            .pay-in-modal__value-inner
              .pay-in-modal__value-title {{ $t('writingOff') }}
              .pay-in-modal__value-money
                Currency(type="money" onlyRub) {{ manualPrice || 0 }}

          .pay-in-modal__value(v-if="commission !== null && currentStepIndex > 0")
            .pay-in-modal__value-inner
              .pay-in-modal__value-title
                | {{ currentMethodId === 'credit-card' ? $t('willBeSentToCard') : $t('willBeSentToWallet') }}
                br
                |({{ $t('payCommission', { commission: commission, additional: '' }) }}
                template(v-if="fixCommission || commissionFix") {{ $t('fixCommission', { commission: `${commissionFix}` }) }}
                | )
              .pay-in-modal__value-money
                Currency(type="money" onlyRub real) {{ willBeCredited }}

      p.pay-in-modal__about-limit(v-if="currentStepIndex < 2")
        span(v-html="$t('payInModalAboutLimit', {balance: canBeWithdrawnAmount})")
        NuxtLink(:to="localeLocation('/myvvv')" @click="closeModal") {{ $t("addedInfo") }}

      template(v-if="currentStepIndex === 0")
        .pay-in-modal__manual(class="--active")
          .pay-in-modal__manual-title
            strong {{ $t('writeSumForWithdraw') }}
            span {{ $t('withdrawMinimalSum') }}

          NumberInput(
            v-model="manualPrice"
            :error="manualError"
            :increment="0.01"
            :min="0.5"
            :max="+minWithdrawalLimit || 100000"
            :fixed="2"
            currency="money"
            @input="onManualInput"
            @blur="onManualBlur"
          )

      template(v-if="currentStepIndex === 1")
        .pay-in-modal__title {{ $t('choosePayMethod') }}
        .pay-in-modal__methods
          .pay-in-modal__out-method(
            v-for="(method, index) in filteredLocalMethods"
            :key="method.id"
            :class="[`--${method.id}`, {'--active': method.id === currentMethodId, '--disabled': method.isDisabled}]"
            @click="onPayMethodClick(index)"
          )
            .pay-in-modal__out-method-top
              .pay-in-modal__out-method-checkbox(:class="{'--active': method.id === currentMethodId }")
              .pay-in-modal__out-method-title {{ method.title }}
              .pay-in-modal__out-method-icons(v-if="method.id === 'credit-card'")
                //- img(src='~/assets/img/payment-systems/mir.svg' alt="mir")
                img(src='~/assets/img/payment-systems/visa.svg' alt="visa")
                //- img(src='~/assets/img/payment-systems/mastercard.svg' alt="mastercard")
                img(src='~/assets/img/payment-systems/visa-secure.svg' alt="visa secure")
                //- img(src='~/assets/img/payment-systems/mc-secure.svg' alt="mastercard id check")
              .pay-in-modal__out-method-icons(v-else)
                InlineSvg(:src="method.icon" :alt="method.id")
            .pay-in-modal__out-method-bottom(v-if="currentMethod.provider === 'solar-staff'")
              .pay-in-modal__out-method-bottom-caption(:class="{ '--error': method.error }")
                template(v-if="method.error") {{ method.error }}

                template(v-else-if="currentMethod.provider === 'solar-staff'")
                  template(v-if="method.id === 'credit-card'") {{ method.error }}
                    template(v-if="user.solar_staff_card_id")
                      | {{ $t('youHaveLinkedCard') }}
                    template(v-else)
                      | {{ $t('youNeedToLinkCard') }}
                  template(v-else-if="method.id === 'qiwi'")
                    | {{ $t('yourQiwiWallet') }}
                  template(v-else-if="method.id === 'webmoney'")
                    | {{ $t('yourWebmoneyWallet') }}
                  template(v-else-if="method.id === 'paypal'")
                    | {{ $t('yourPaypalWallet') }}

              Button(v-if="currentMethodId === 'credit-card' && currentMethod.provider === 'solar-staff' && !method.error" :is-primary="!user.solar_staff_card_id" :is-secondary="!!user.solar_staff_card_id" @click="cardVerify()")
                template(v-if="!user.solar_staff_card_id") {{ $t('linkCard') }}
                template(v-else) {{ $t('linkNewCard') }}
              FormInput(v-if="method.input && !method.error" v-bind="method.input" v-model="method.input.value")

            CardForm(v-if="method.id === 'credit-card' && currentMethod.provider === 'unlimint'" @setCardPan="setCardPan" @setCardExpiration="setCardExpiration" @setCardRecipient="setCardRecipient")

            .pay-in-modal__out-method-bottom(v-if="manualPrice < cardManualMin && currentMethodId === 'credit-card'")
              InfoMessage(type="error") {{ $t('minCardSum', { amount: cardManualMin }) }}
            .pay-in-modal__out-method-bottom(v-else-if="manualPrice < ruCardManualMin && currentMethodId === 'ru-card'")
              InfoMessage(type="error") {{ $t('minCardSum', { amount: ruCardManualMin }) }}

            .pay-in-modal__out-method-bottom(v-if="method.id === 'credit-card' && currentMethod.provider === 'unlimint' && isRussianBrowser")
                .pay-in-modal__out-method-bottom-warning
                  InlineSvg(:src="require('~/assets/img/icons/warning.svg')")
                  | {{ $t('russianCardWarningWithdrawal') }}

            template(v-if="method.id === 'bitcoin'")
              .pay-in-modal__out-method-bottom
                FormInput.pay-in-modal__form-input(
                  v-model="btcWallet"
                  :placeholder="$t('yourBitcoinWallet')"
                )
              .pay-in-modal__out-method-bottom
                .pay-in-modal__out-method-bottom-warning
                  InlineSvg(:src="require('~/assets/img/icons/warning.svg')")
                  | {{ $t('btcTransactionsConfirmationWarning') }}
              .pay-in-modal__out-method-bottom(v-if="manualPrice < minBtc")
                InfoMessage(type="error") {{ $t('minBitcoinSum', { amount: minBtc }) }}

            template(v-if="method.id === 'ru-card'")
              .pay-in-modal__out-method-bottom
                span.card-form__field-label {{ $t('cardNumber') }}
                FormInput.pay-in-modal__form-input(
                  v-model="ruCardNumber"
                  placeholder="1234 1234 1234 1234"
                  maxlength="19"
                )

            template(v-if="method.id === 'qiwi'")
              .pay-in-modal__out-method-bottom
                span.card-form__field-label {{ $t('qiwiPhoneNumber') }}
                FormInput.pay-in-modal__form-input(
                  v-model="qiwiPhoneNumber"
                  placeholder="79876543210"
                )
              .pay-in-modal__out-method-bottom(v-if="manualPrice < cardManualMin")
                InfoMessage(type="error") {{ $t('minCardSum', { amount: cardManualMin }) }}
            template(v-if="method.provider === 'coinpayments'")
              .pay-in-modal__out-method-bottom
                FormInput.pay-in-modal__form-input(
                  v-model="cryptoWallet"
                  :placeholder="$t('yourCryptocurrensyWallet', { currency: method.methodName })"
                )
              .pay-in-modal__out-method-bottom
                .pay-in-modal__out-method-bottom-warning
                  InlineSvg(:src="require('~/assets/img/icons/warning.svg')")
                  | {{ $t('cryptoTransactionsConfirmationWarning') }}
              .pay-in-modal__out-method-bottom(v-if="manualPrice < manualMin")
                InfoMessage(type="error") {{ $t('minCardSum', { amount: manualMin }) }}
        CheckboxInput(v-model="agreement").pay-in-modal__agreement
          span(v-html="$t('agreements')")

      template(v-if="currentStepIndex === 2")
        InfoMessage.pay-out-modal__final(v-if="error" type="deny" :title="$t('errorHasOccurred')") {{ error }}
        InfoMessage.pay-out-modal__final(v-if="success" type="allow" :title="$t('successfulWithdraw')")
          .pay-out-modal__success-balance
            span {{ $t('youWithdraw') }} &nbsp;
            Currency(type="money" onlyRub) {{ manualPrice }}
          .pay-out-modal__success-balance
            span {{ $t('onYourBalance') }} &nbsp;
            Currency(type="money" onlyRub) {{ user.balance }}

      .pay-in-modal__address(v-if="currentStepIndex !== 10")
        |{{$t('ourCompanyAddress')}}: Solian Enterprises Limited. Registered number: HE 412444. Emmanouil road 44, KIRZIS CENTER, 3031 Limassol, Cyprus

    .modal__footer
      HelpButton.pay-out-modal__help-button(v-if="error")
      Button.pay-in-modal__button(v-if="currentStepIndex > 0" is-secondary @click="onBackClick") {{ $t('buttonBack') }}
      Button.pay-in-modal__button(v-if="isFinishStep && currentStepIndex !== 10" is-primary :is-disabled="isDisabled" @click="closeModal") {{ buttonText }}
      Button.pay-in-modal__button(v-if="currentStepIndex !== 10 && !isFinishStep" is-primary :is-disabled="isDisabled" type="submit") {{ buttonText }}

</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FormInput from '~/components/FormInput'
import NumberInput from '~/components/NumberInput/NumberInput'
import PayInModal from '~/components/PayInModal/PayInModal'
import Steps from '~/components/Steps/Steps'
import BaseSelect from '~/components/BaseSelect/BaseSelect'
import CardForm from '~/components/CardForm'
import HelpButton from '~/components/HelpButton'
import PaymentService from '~/services/PaymentService'

export default {
  name: 'PayOutModal',

  components: {
    BaseSelect,
    Steps,
    NumberInput,
    FormInput,
    CardForm,
    HelpButton
  },

  extends: PayInModal,

  props: {
    isComeBack: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardPan: '',
      cardExpiration: '',
      cardRecipient: '',

      ruCardNumber: '',
      qiwiPhoneNumber: '',

      btcWallet: '',
      cryptoWallet: '',

      manualPrice: '0',
      currentMethodIndex: 0,
      currentMethodId: 'credit-card',

      popularItems: [
        { price: 5 },
        { price: 20, isPopular: true },
        { price: 50 },
        { price: 100 }
      ],

      error: '',
      success: false,

      manualMin: 2,
      cardManualMin: 5,
      ruCardManualMin: 13,
      manualError: '',

      btcRate: 100000000,
      minBtc: 100,

      paymentId: null,
      gettingPaymentInterval: undefined
    }
  },

  computed: {
    ...mapState('user', ['user', 'canBeWithdrawn']),
    ...mapGetters('user', ['isAdmin', 'minWithdrawalLimit', 'canBeWithdrawnAmount']),

    localMethods () {
      const methods = [
        {
          id: 'ru-card',
          title: 'Карта банка РФ',
          provider: 'pay2play',
          adminOnly: true,
          min: 13,
          error: '',
          commissionPayOut: 2,
          commissionPayOutSBP: 3,
          fixCommissionPayOut: 0,
          minPayIn: 1.3,
          minPayOut: 13,
          icon: require('~/assets/img/icons/credit-card.svg')
        },
        {
          id: 'credit-card',
          title: this.$t('creditCard'),
          min: this.$config.PROJECT_ENV === 'production' ? 2 : 2,
          error: '',
          provider: 'unlimint',
          commission: 3.3,
          fixCommission: 0.25,
          commissionPayOut: 1.5,
          fixCommissionPayOut: 1,
          minPayIn: 1,
          minPayOut: 4.65,
          icon: require('~/assets/img/icons/credit-card.svg')
        },
        {
          id: 'ltc',
          methodName: 'LTC',
          title: 'Litecoin - LTC (Litecoin)',
          provider: 'coinpayments',
          icon: 'https://cdn.gaming-goods.com/images/Payment%20methods/LTC.svg',
          admin: true,
          commission: 0.5,
          fixCommission: 0,
          commissionPayOut: 0,
          fixCommissionPayOut: 0,
          minPayIn: 2,
          minPayOut: 2
        }
      ]

      return methods.filter(method => !method.adminOnly || this.isAdmin || (this.user.id > 0 && this.$i18n.locale === 'ru')).filter(method => !method.admin || this.isAdmin || this.user.id > 0)
    },

    commission () {
      return this.currentMethod?.commissionPayOut
    },

    commissionFix () {
      return this.currentMethod?.fixCommissionPayOut
    },

    isRussianBrowser () {
      const browserLang = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage

      return browserLang === 'ru'
    },

    willBeCredited () {
      if (!this.manualPrice) {
        return 0
      }
      return this.calcCommission(this.manualPrice).toFixed(2)
    },

    stepTitles () {
      return [
        this.$t('chooseSum'),
        this.$t('payMethod'),
        this.$t('stepConfirm')
      ]
    },

    currentMethod () {
      return this.filteredLocalMethods[this.currentMethodIndex]
    },

    filteredLocalMethods () {
      return this.localMethods.filter(m => m.id !== 'ru-card' || this.user?.id === 3088)
    },

    expirationFormatMMYYYY () {
      const splitted = this.cardExpiration.split('/')
      if (splitted.length > 1) {
        splitted[1] = `20${splitted[1]}`
      }
      return splitted.join('/')
    },

    isVerificationNeeded () {
      const MAX_UNVERIFIED_PAYOUT = 1000 // если нужно будет вернуть как раньше, то поставить 15000

      return (
        Math.ceil(Math.abs(this.user.this_year_payouts || 0) / 100) +
          (Number(this.manualPrice) || 0) >
        MAX_UNVERIFIED_PAYOUT
      )
    },

    isFinishStep () {
      return this.currentStepIndex >= this.steps.length - 1
    },

    buttonText () {
      if (this.currentStepIndex === 1) {
        return this.$t('withdraw')
      } else if (this.currentStepIndex === this.steps.length - 1) {
        return this.$t('buttonFinish')
      } else {
        return this.$t('continue')
      }
    },

    isDisabled () {
      if (!this.picked) {
        return true
      }

      if (this.currentStepIndex === 2) {
        return false
      }

      if (this.currentStepIndex === 1) {
        if (
          (this.manualPrice < this.cardManualMin && this.currentMethodId === 'credit-card') ||
          (this.manualPrice < this.ruCardManualMin && this.currentMethodId === 'ru-card') ||
          (this.manualPrice < this.manualMin && this.currentMethodId === 'coinpayments') ||
          this.manualPrice < 2 ||
          !this.agreement ||
          (this.manualPrice < 5 && this.currentMethodId === 'qiwi') ||
          (this.manualPrice < 5 && this.currentMethodId === 'ru-card') ||
          (this.currentMethodId === 'credit-card' &&
            (this.filteredLocalMethods[0].error ||
              (this.currentMethod.provider === 'solar-staff'
                ? !this.user.solar_staff_card_id
                : false))) ||
          (this.currentMethodId === 'credit-card' &&
            (this.cardPan === '' || this.cardExpiration === '')) ||
          (this.currentMethodId === 'bitcoin' &&
            (this.btcWallet === '' || this.manualPrice < this.minBtc)) ||
          (this.currentMethod.input && !this.currentMethod.input.value)
        ) {
          return true
        }
      }

      return (
        this.manualPrice <= 0 ||
        this.manualPrice > +this.canBeWithdrawn ||
        !this.user.email ||
        !this.user.is_email_verified ||
        this.user.verification_status === 'not_verified' ||
        (this.user.verification_status !== 'verified' &&
          this.isVerificationNeeded)
      )
    },

    notVerifiedBalance () {
      return (this.user.balance - (this.canBeWithdrawn / 100)).toFixed(2).replace('.00', '')
    }
  },

  mounted () {
    this.$emit('updateScrollHeight', { ref: 'body', paddings: 178 })

    this.fetchCanBeWithdrawn()

    this.manualPrice = this.minWithdrawalLimit
    this.onManualBlur()

    if (this.isComeBack) {
      let savedData = window.sessionStorage.getItem('payout-data')

      try {
        savedData = JSON.parse(savedData)

        Object.keys(savedData).forEach((key) => {
          this.$set(this, key, savedData[key])
        })
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: saved data error ::`, e)
      }
    }

    this.getMinBtc()
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('user', ['fetchUser', 'fetchCanBeWithdrawn']),
    ...mapMutations('modal', ['toggle']),

    setCardPan (value) {
      this.cardPan = value
    },

    setCardExpiration (value) {
      this.cardExpiration = value
    },

    setCardRecipient (value) {
      this.cardRecipient = value
    },

    onManualInput () {
      this.manualError = ''
    },

    onManualBlur () {
      this.manualError = ''

      if (this.manualPrice < this.manualMin) {
        this.manualError = this.$t('minError', { min: this.manualMin })
      }
    },

    getMinBtc () {
      PaymentService.getBitcoinRate(this.$axios).then((btcRate) => {
        if (btcRate) {
          this.btcRate = btcRate
          this.minBtc = Math.ceil(btcRate * 0.001)
        }
      })
    },

    async cardVerify () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post(
          '/payments/providers/solar-staff/verify-card'
        )

        window.sessionStorage.setItem('payout-data', JSON.stringify(this.$data))
        window.sessionStorage.setItem('payout-route', this.$route.path)

        if (data.data.terminal_url) {
          window.location.href = data.data.terminal_url
        } else {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'error',
              text: this.$t('terminalError')
            }
          })
        }
      } catch (e) {
        this.$errors.handle(e)
        console.error(`${new Date().toUTCString()} :: verify card error ::`, e)
      }

      this.$nuxt.$loading.finish()
    },

    onPayMethodClick (index) {
      const method = this.filteredLocalMethods[index]

      if (method.isDisabled) {
        return
      }

      method.error = ''

      this.currentMethodId = method.id
      this.currentMethodIndex = index

      this.validateMethod(index)
    },

    closeModal () {
      this.toggle(false)
    },

    calcCommission (amount) {
      const result =
        parseFloat(amount) -
        Math.ceil(100 * ((amount / 100) * this.commission + this.commissionFix)) / 100

      if (this.fixCommission) {
        return result - this.fixCommission
      }

      return result
    },

    validateMethod (index) {
      const method = this.filteredLocalMethods[index]

      if (this.manualPrice < method.min) {
        const target =
          method.id === 'credit-card' ? this.$t('toCard') : this.$t('toWallet')

        method.error = this.$t('minWithdrawSum', { target, sum: method.min })
      }
    },

    async createSolarStaffWorker () {
      try {
        await this.$axios.post('/payments/providers/solar-staff/create-worker')
      } catch (e) {
        this.$errors.handle(e)
        console.error(
          `${new Date().toUTCString()} :: create worker error ::`,
          e
        )
      }
    },

    async payOutWithUnlimint (paymentBody) {
      this.paymentId = null

      const { data } = await PaymentService.getPayOutLink(
        this.$axios,
        paymentBody
      )

      this.paymentId = data?.data

      if (!this.paymentId) {
        this.error = data.error || this.$t('payOutError')
      } else {
        this.error = ''

        this.gettingPaymentInterval = setInterval(
          () => this.startGettingPaymentStatusInLoop(this.paymentId),
          1000
        )
      }
    },

    startGettingPaymentStatusInLoop (paymentId) {
      return PaymentService.getTransactionInfo(this.$axios, paymentId)
        .then((res) => {
          if (res.data.data.is_done) {
            if (res.data.data.is_error) {
              this.error =
                res.data.data.error_data.decline_reason ||
                res.data.data.error_data.decline_code ||
                this.$t('payOutError')
            } else {
              this.success = true
            }
            this.stopGettingPayment()
          }
        })
        .catch((err) => {
          this.error = err
          this.stopGettingPayment()
        })
    },

    stopGettingPayment () {
      clearInterval(this.gettingPaymentInterval)
      this.$nuxt.$loading.finish()
    },

    async onSubmit () {
      this.onManualBlur()

      if (this.isDisabled) {
        return
      }

      if (this.currentStepIndex < 1) {
        this.currentStepIndex++

        if (this.currentStepIndex === 1) {
          this.$nuxt.$loading.start()
          this.onPayMethodClick(0)

          if (this.currentMethod.provider === 'solar-staff') {
            await this.createSolarStaffWorker()
          }

          this.$nuxt.$loading.finish()
        }

        return
      }

      if (this.currentStepIndex >= this.steps.length - 1) {
        this.closeModal()
      } else {
        this.$nuxt.$loading.start()

        const payData = {
          // amount: process.env.NODE_ENV === 'production' ? this.manualPrice : 5,
          amount: this.manualPrice
        }
        let payApiRoute

        if (this.currentMethod.provider === 'solar-staff') {
          if (this.currentMethodId === 'credit-card') {
            payApiRoute = '/payments/providers/solar-staff/payout/cards'
          } else {
            payApiRoute = '/payments/providers/solar-staff/payout/wallets'

            payData.wallet_number = this.currentMethod.input.value.replace(
              '+',
              ''
            )
            payData.action = `payout_${this.currentMethod.id}`
          }
        }

        try {
          if (
            this.currentMethodId === 'credit-card' &&
            this.currentMethod.provider === 'solar-staff'
          ) {
            const { data } = await this.$axios.post(payApiRoute, payData)

            if (!data.error) {
              this.success = true
              this.error = ''
            } else {
              this.error = data.error || this.$t('errorMessageDefault')
            }
            this.currentStepIndex++
            this.$nuxt.$loading.finish()
          } else if (this.currentMethod.provider === 'unlimint') {
            this.$nuxt.$loading.start()

            let paymentBody

            if (this.currentMethodId === 'credit-card') {
              paymentBody = {
                amount: this.manualPrice,
                currency: this.picked,
                method: PaymentService.getMethod(this.currentMethodId)
                  .methodName,
                card_account: {
                  card: {
                    pan: this.cardPan,
                    expiration: this.expirationFormatMMYYYY
                  },
                  recipient_info: this.cardRecipient
                },
                return_url: `${location.protocol}//${location.host}${location.pathname}`
              }
            } else if (this.currentMethodId === 'bitcoin') {
              paymentBody = {
                amount: this.manualPrice,
                currency: this.picked,
                method: PaymentService.getMethod(this.currentMethodId)
                  .methodName,
                cryptocurrency_account: {
                  crypto_address: this.btcWallet
                },
                return_url: `${location.protocol}//${location.host}${location.pathname}`
              }
            }

            await this.payOutWithUnlimint(paymentBody)

            await new Promise(resolve =>
              setTimeout(
                () =>
                  resolve(this.startGettingPaymentStatusInLoop(this.paymentId)),
                1000
              )
            )

            this.currentStepIndex++
          } else if (this.currentMethod.provider === 'coinpayments') {
            const paymentBody = {
              amount: this.manualPrice,
              currency: this.currentMethod.methodName,
              address: this.cryptoWallet
            }

            this.paymentId = null

            const { data } = await PaymentService.coinpaymentsPayOut(this.$axios, paymentBody)
            this.paymentId = data?.data

            if (!this.paymentId) {
              this.error = data.error_data?.error || data.error || this.$t('payOutError')
            } else {
              this.error = ''
              this.currentStepIndex++

              this.gettingPaymentInterval = setInterval(
                () => this.startGettingPaymentStatusInLoop(this.paymentId),
                1000
              )
            }
          } else if (this.currentMethod.provider === 'contactpay') {
            const response = await (this.currentMethod.id === 'ru-card'
              ? PaymentService.payOutWithContactpay(this.$axios, this.manualPrice, this.verification?.phone, this.ruCardNumber)
              : PaymentService.payOutWithContactpay(this.$axios, this.manualPrice, this.qiwiPhoneNumber))

            this.paymentId = response?.data?.data

            if (!this.paymentId) {
              this.error = response?.data.error_data?.error || response?.data.error || this.$t('payOutError')
            } else {
              this.error = ''
              this.currentStepIndex++

              this.gettingPaymentInterval = setInterval(
                () => this.startGettingPaymentStatusInLoop(this.paymentId),
                1000
              )
            }
          } else if (this.currentMethod.provider === 'pay2play') {
            const response = await PaymentService.payOutWithPay2Play(this.$axios, this.manualPrice, this.ruCardNumber)

            this.paymentId = response?.data?.data

            if (!this.paymentId) {
              this.error = response?.data.error_data?.error || response?.data.error || this.$t('payOutError')
            } else {
              this.error = ''
              this.currentStepIndex++
            }
          } else {
            const paymentBody = {
              amount: this.manualPrice,
              currency: this.picked,
              method: PaymentService.getMethod(this.currentMethodId).methodName,
              return_url: `${location.protocol}//${location.host}${location.pathname}`
            }

            if (['PAYPAL', 'QIWI', 'WEBMONEY'].includes(paymentBody.method)) {
              paymentBody.ewallet_account = {
                id: payData.wallet_number
              }
            }

            this.$nuxt.$loading.start()

            await this.payOutWithUnlimint(paymentBody)

            await new Promise(resolve =>
              setTimeout(
                () =>
                  resolve(this.startGettingPaymentStatusInLoop(this.paymentId)),
                1000
              )
            )

            this.currentStepIndex++
          }
        } catch (e) {
          this.currentStepIndex++

          let error = this.$errors.getText(e)

          if (error === this.$t('wrongRequestParams')) {
            error = this.$t('errorCheckYourWalletNumber')
          }

          this.error = error
          console.error(`${new Date().toUTCString()} :: pay out error ::`, e)
          this.$nuxt.$loading.finish()
        }
        this.$nuxt.$loading.finish()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--payoutmodal {
  max-width: 736px;
  max-height: calc(100% - 32px);
  padding-bottom: 0;

  .modal__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-shrink: 0;

    button {
      margin-top: 4px;
    }
  }

  @media (max-width: $breakpointMobile) {
    height: calc(100% - 32px);

    .modal__footer {
      min-height: 71px;
    }

    .modal__header {
      min-height: 52px;
    }
  }
}

.pay-out-modal {
  &.pay-in-modal {
    @media (max-width: $breakpointMobile) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__help-button {
    margin-right: 8px;
  }

  .pay-in-modal {
    .pay-in-modal {
      &__warning {
        &-title {
          color: $white;
          margin-bottom: 8px;
          font-weight: bold;
        }

        &-text {
          color: #cfd8dc;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 24px;
        }

        &-radio {
          display: flex;
        }

        &-container {
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 12px;
          margin-right: 12px;
          width: 100%;
          cursor: pointer;
          color: $white;
          background: #232f3f;
          border-radius: 8px;
          padding: 20px 16px 20px 54px;
          font-size: 16px;
          user-select: none;

          .pay-in-modal__warning-checkmark {
            position: absolute;
            top: 21px;
            left: 16px;
            height: 20px;
            width: 20px;
            border: 2px solid #556877;
            border-radius: 50%;

            &::after {
              content: "";
              position: absolute;
              display: none;
              top: 5px;
              left: 4px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: white;
            }
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .pay-in-modal__warning-checkmark {
              border-color: transparent;
              background-color: #2196f3;

              &::after {
                display: block;
              }
            }
          }
        }

        &-currency {
          margin-left: auto;

          path {
            fill: $green50;
          }
        }

        &-banner {
          display: flex;
          align-items: center;
          color: #ffb300;
          background-color: rgb(255 179 0 / 20%);
          border-radius: 8px;
          padding: 20px;
          margin-top: 24px;

          &-icon {
            margin-right: 16px;
          }
        }
      }
    }

    &__values {
      margin-bottom: 32px;
    }

    &__body {
      @include scroll;

      max-height: calc(100vh - 178px);
      margin: 0 -24px;
      padding-left: 24px;
      padding-right: 24px;

      @media (max-width: $breakpointMobile) {
        margin: 0 -16px;
        padding-left: 16px;
        padding-right: 16px;
        flex: 1 1 auto;
        overflow-y: auto;
        min-height: 0;
        max-height: unset;
      }

      .checkbox-input__text {
        min-width: 0;
      }
    }

    &__manual {
      margin-top: 40px;
      align-items: flex-start;

      &-title {
        @include heading7;

        color: $blue-grey10;
        display: flex;
        flex-direction: column;
        max-width: 260px;
        margin-right: 32px;

        strong {
          @include heading4;

          color: #fff;

          //margin-right: 16px;
          margin-bottom: 8px;
        }
      }

      @media (max-width: $breakpointMobile) {
        flex-direction: column;

        &-title {
          max-width: 100%;
          margin-right: 0;

          span {
            margin-bottom: 16px;
          }
        }
      }
    }

    &__methods {
      display: block;
    }

    &__out-method {
      background: $blue-grey60;
      border-radius: 8px;
      width: 100%;
      margin: 16px 0 24px;
      cursor: pointer;

      &.--disabled {
        cursor: not-allowed;
        opacity: 0.3;

        & .pay-in-modal__out-method-bottom-caption.--error {
          color: #fff;
        }
      }

      &-top {
        display: flex;
        align-items: center;
        padding: 24px 20px;
      }

      &-bottom {
        max-height: 0;
        margin: 0 24px;
        border-top: 0 solid $blue-grey40;
        overflow: hidden;
        transition: all 0.1s $easing;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $breakpointMobile) {
          flex-wrap: wrap;

          &-caption {
            margin-bottom: 8px;
          }
        }

        &-warning {
          display: grid;
          grid-template-columns: 21px 1fr;
          grid-gap: 9px;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $primary05;

          & svg {
            width: 21px;

            path {
              fill: $white;
            }

            g {
              fill: $white;
            }
          }
        }

        .form-input {
          min-width: 65%;

          &__label {
            background-color: $blue-grey70;
          }

          @media (max-width: $breakpointMobile) {
            width: 100%;
          }
        }

        &-caption {
          @include heading7;

          color: $blue-grey10;
          margin-right: 16px;
          white-space: normal;

          &.--error {
            color: $red60;
          }
        }

        .button {
          min-height: 48px;

          &.button--secondary {
            background-color: $blue-grey50;
          }
        }
      }

      &-title {
        @include heading6;

        width: calc(100% - 32px);
        padding: 0 12px;
        color: #fff;
        transition: color 0.2s $easing;
      }

      &-checkbox {
        border: 2px solid #fff;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.2s $easing;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 3px;
          right: 0;
          top: 3px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: #fff;
          transition: transform 0.2s $easing;
          transform: scale(0, 0);
        }
      }

      &-icons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 20px;

        svg {
          height: 20px;
          width: auto;
        }

        img {
          height: 18px;
          margin: 0 7px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.--active .pay-in-modal__out-method-title {
        color: $amber60;
      }

      &.--active .pay-in-modal__out-method-checkbox {
        &::before {
          transform: scale(1, 1);
        }
      }

      &.--active .pay-in-modal__out-method-bottom,
      &.--disabled .pay-in-modal__out-method-bottom {
        max-height: 125px;
        padding: 16px 0 20px;
        border-top: 1px solid $blue-grey40;
      }

      @media (max-width: $breakpointMobile) {
        &.--credit-card {
          .pay-in-modal__out-method {
            &-top {
              flex-wrap: wrap;
            }

            &-icons {
              margin-top: 16px;
            }
          }
        }
      }
    }

    &__address {
      color: #90a4ae;
      font-size: 14px;

      p {
        @include heading7;

        margin-top: 16px;
        line-height: 120%;
        color: $blue-grey10;

        a {
          color: $primary60;
          text-decoration: underline;
        }
      }
    }
  }

  &__final {
    &.info-message {
      margin-top: 40px;
    }
  }

  .base-select {
    margin-bottom: 16px;
  }

  &__success-warning {
    @include text-display120;

    margin-top: 16px;

    a {
      text-decoration: underline;
    }
  }

  &__success-balance {
    @include text-info100;

    min-height: 20px;
    color: $blue-grey05;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .currency {
      @include heading4;

      color: #fff;
    }
  }
}
</style>
