<template lang="pug">
  .modal-team
    .modal__header {{ isEdit ? $t('editTeam') : $t('buttonInviteMember') }}
    .modal-team__body(v-if="!isEdit")
      CopyInput(v-if="team" :title="$t('linkInvite')" :value="`${$config.WWW_HOST}/${game.name}/${type}/${tournament.id}?team_invite=${team.id}`")
      .modal-team__search
        FormInput(:placeholder="$t('idOrNickname')" :value="searchValue" @input="onInput")
        .modal-team__players(v-if="foundPlayers")
          template(v-if="foundPlayers && foundPlayers.length")
            PlayerRow(
              v-for="(player, index) in foundPlayers"
              :key="player && player.id ? player.id : index"
              :player="player"
              with-game-account
              :tournament="tournament"
              location="tournament"
            )
              template(v-slot:controls)
                teamplate(v-if="!isMobile")
                  .modal-team__player-status(v-if="isPlayerInvited(player)")
                    InlineSvg(:src="require(`~/assets/img/icons/allow.svg`)" width="24" height="24")
                    | {{ $t('sent') }}
                  .modal-team__player-status(v-else-if="isPlayerInTeam(player)" class="--team")
                    InlineSvg(:src="require(`~/assets/img/icons/referral.svg`)" width="24" height="24")
                    | {{ $t('inTeam') }}
                Button(
                  v-if="!isPlayerInvited(player) && !isPlayerInTeam(player)"
                  is-primary
                  :is-square="isMobile"
                  :icon="isMobile ? 'add': null" @click="invite(player)"
                ) {{ isMobile ? '' : $t('buttonInvite') }}

              template(v-slot:controlsBottom)
                .modal-team__player-status(v-if="isPlayerInvited(player)")
                  InlineSvg(:src="require(`~/assets/img/icons/allow.svg`)" width="24" height="24")
                  | {{ $t('sent') }}
                .modal-team__player-status(v-else-if="isPlayerInTeam(player)" class="--team")
                  InlineSvg(:src="require(`~/assets/img/icons/referral.svg`)" width="24" height="24")
                  | {{ $t('inTeam') }}

          .modal-team__not-found(v-else)
            InlineSvg(:src="require(`~/assets/img/icons/sad.svg`)")
            | {{ $t('userWithThisIdNotFound') }}
      InfoMessage(v-if="error" type="error") {{ error }}

    .modal-team__body(v-if="isEdit && team" class="--edit")
      .modal-team__players
        PlayerRow(
          v-for="(player, index) in team.members"
          :key="player && player.id ? player.id : index"
          :player="player"
          with-game-account
          :tournament="tournament"
          location="tournament"
        )
          template(v-slot:controls)
            .modal-team__edit
              Button(v-if="player.id !== user.id" :is-square="isMobile" is-secondary icon="close" @click="onRemoveClick(player)") {{ isMobile ? '' : $t('delete') }}
              Button(v-else-if="team.members.length > 1"  :is-square="isMobile" is-secondary icon="close" @click="onLeaveClick(player)") {{ isMobile ? '' : $t('quit') }}
              //transition(name="slide-up" duretion="20")
              //  .tournament-team__menu(v-if="isMenuVisible[player.id]")
              //    .tournament-team__menu-item(@click="onRemoveClick(player)")
              //      InlineSvg(width="20" height="20" :src="require(`~/assets/img/icons/close.svg`)")
              //      | $t('delete')

        InfoMessage(v-if="error" type="error") {{ error }}

</template>

<script>
import { debounce } from 'throttle-debounce'
import { mapGetters, mapState } from 'vuex'
import CopyInput from '~/components/CopyInput/CopyInput'
import FormInput from '~/components/FormInput/FormInput'
import PlayerRow from '~/components/PlayerRow/PlayerRow'

export default {
  name: 'ModalTeam',

  components: { PlayerRow, FormInput, CopyInput },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    team: {
      type: Object,
      default: null
    },

    isEdit: {
      type: Boolean,
      default: false
    },

    onTeamUpdate: {
      type: Function,
      default: () => {}
    }
  },

  data: () => {
    return {
      searchValue: '',
      foundPlayers: null,
      error: '',
      isMenuVisible: {}
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('games', ['gamesById']),
    ...mapGetters('viewport', ['isMobile']),

    type () {
      return this.team.mega_tournament_id ? 'phased-tournaments' : 'tournaments'
    },

    game () {
      return this.gamesById[this.tournament.game_id]
    },

    teamSize () {
      return this.tournament.tournament_player_settings?.team_size
    }
  },

  watch: {
    team (value) {
      if (!value || (!this.isEdit && value.members.length === this.teamSize)) {
        // this.$store.commit('modal/toggle', false)
      }
    }
  },

  mounted () {
    this.debouncedSearch = debounce(1500, this.search)
  },

  methods: {
    isPlayerInvited (player) {
      const inTeam = this.team?.members?.find(
        member => player.id === member.id
      )
      return inTeam?.team_member_status === 'invited'
    },

    isPlayerInTeam (player) {
      const inTeam = this.team?.members?.find(
        member => player.id === member.id
      )
      return inTeam?.team_member_status === 'active'
    },

    async onRemoveClick (player) {
      this.error = ''

      try {
        await this.$axios.post(
          `/teams/${this.game.name}/${this.team.id}/remove/${player.id}`
        )
      } catch (e) {
        this.error = this.$errors.getText(e)

        console.error('team remove err', e)
      }

      this.onTeamUpdate()
    },

    async onLeaveClick () {
      this.error = ''

      try {
        await this.$axios.post(`/teams/${this.game.name}/${this.team.id}/leave`)
      } catch (e) {
        this.error = this.$errors.getText(e)
        console.error('team leave err', e)
      }

      this.onTeamUpdate()
    },

    onMenuClick (player) {
      this.$set(this.isMenuVisible, player.id, true)
      this.isMenuVisible[player.id] = true
    },

    onClickOutside ({ target }) {
      if (Object.keys(this.isMenuVisible) && !this.$el.contains(target)) {
        this.isMenuVisible = {}
      }
    },

    async invite (player) {
      this.error = ''

      try {
        await this.$axios.post(
          `/teams/${this.game.name}/${this.team.id}/invite`,
          {
            invitee_id: player.id
          }
        )
      } catch (e) {
        this.error = this.$errors.getText(e)
        console.error('team  invite err', e)
      }
    },

    async search () {
      this.error = ''

      try {
        const { data } = await this.$axios.post('/user/search', {
          query: parseInt(this.searchValue)
            ? parseInt(this.searchValue)
            : this.searchValue.trim(),
          game_id: this.game.id
        })

        this.foundPlayers = data.data
      } catch (e) {
        this.foundPlayers = []
        console.error('team search err', e)
      }
    },

    onInput (value) {
      this.searchValue = value

      if (this.searchValue) {
        this.debouncedSearch()
      } else {
        this.foundPlayers = null
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalteam {
  max-width: 621px;
  padding-bottom: 0;
  overflow: hidden;

  @media (max-width: $breakpointMobile) {
    max-height: unset;
  }
}

.modal-team {
  &__not-found {
    @include hint;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-top: 24px;
    text-align: center;

    @media (max-width: $breakpointMobile) {
      margin-top: 12px;
    }

    svg {
      width: 40px;
      height: 40px;
      margin-bottom: 16px;

      @media (max-width: $breakpointMobile) {
        margin-bottom: 8px;
      }
    }
  }

  &__player {
    &-status {
      @include hint;

      display: flex;
      align-items: center;
      white-space: nowrap;

      @media (max-width: $breakpointMobile) {
        justify-content: center;
        width: 100%;
      }

      svg {
        margin-right: 8px;
      }

      &.--team {
        svg path {
          fill: $primary60;
        }
      }
    }
  }

  &__players {
    margin-top: 16px;
    margin-bottom: 16px;

    button.button.button--primary.button--square,
    button.button.button--secondary.button--square {
      width: 40px;
      height: 40px;
      padding: 0;

      .button__body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: $breakpointMobile) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .player-row {
      padding: 8px 0;
      border-bottom: 1px solid $blue-grey40;
      border-radius: 0;
      margin: 0;

      &:last-child {
        border-bottom: 0;
      }

      &__avatar {
        margin-left: 0;
      }

      &__cell.--wide {
        padding-right: 0;
      }

      &__bottom-item {
        @media (max-width: $breakpointMobile) {
          padding-bottom: 8px;
          justify-content: flex-end;
        }
      }
    }
  }

  &__search {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $blue-grey40;
    padding-bottom: 24px;

    @media (max-width: $breakpointMobile) {
      padding-bottom: 0;
    }
  }

  &__edit {
    position: relative;
  }

  &__body {
    @include scroll;

    padding: 24px 24px 0;
    margin: 0 -24px;
    overflow-y: auto;
    max-height: calc(100vh - 120px);

    .info-message.--error {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    &.--edit {
      padding-top: 0;
    }

    @media (max-width: $breakpointMobile) {
      padding: 16px;
      max-height: calc(100vh - 80px);
    }

    .copy-input__box {
      max-width: 100%;
    }
  }
}
</style>
