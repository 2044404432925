<template lang="pug">
  .page.page-error
    Error404(v-if="error.statusCode === 404")
    InfoMessage(v-else type="error")
      | {{ infoMessage }}
</template>

<script>
import Error404 from '~/components/Error404/Error404'
export default {
  name: 'PageError',

  components: { Error404 },

  layout: 'default',

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  computed: {
    infoMessage () {
      let message = this.$t('unknownError')

      if (this.error?.message) {
        message = `${this.$t('error')}: ${this.error?.message}`
      }

      return message
    }
  },

  mounted () {
    if (this.error.statusCode === 401) {
      this.$store.dispatch('modal/showModal', {
        component: 'Auth',
        data: { mode: 'login', afterLogin: () => location.reload() }
      })
    }
  }
}
</script>

<style lang="scss">
.page-error {
  @include scroll;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 766px;
  max-height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;

  > .info-message {
    width: auto;
    margin: 40px 20px;
  }
}
</style>
