<template lang="pug">
  .checkout
    img.checkout__device-image(:src="require(`~/assets/img/store/${product.image}.png`)" :alt="product.name")
    .checkout__wrapper
      .checkout__device-name {{ product.type }}
      .checkout__device-type RED MAGIC 7 Pro
      .checkout__device-price
        .checkout__device-coins-icon
          InlineSvg.currency__coins(height="24" width="24" :src="require('~/assets/img/icons/coins.svg')")
        .checkout__device-amount {{ product.priceInCoins }}
        .checkout__device-currency {{ $t('coins') }}
      Button.checkout__device-button(is-primary @click="buy" :id="product.id") {{ $t('buy') }}
      .checkout__description
        .checkout__description-title {{ $t('description')}}
        p.checkout__description-text {{ $t('deviceInfo')}}
        .checkout__description-title {{ $t('characteristics')}}
        p.checkout__description-subtitle {{ $t('mainCharacteristics') }}

      .checkout__rows
        .checkout__row
          span {{ $t('display') }}
          strong Amoled 6.8’’

        .checkout__row
          span {{ $t('chip') }}
          strong Qualcomm Snapdragon 8 Gen 1
        .checkout__row
          span {{ $t('hdr') }}
          strong {{ $t('yes') }}
        .checkout__row
          span {{ $t('frequencyUpdate') }}
          strong 120Hz
        .checkout__row
          span {{ $t('battery') }}
          strong 5000mAh
        .checkout__row
          span {{ $t('mainCamera') }}
          strong 64 megapixels

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ModalCheckoutDevice',

  props: {
    product: {
      type: Object,
      default: null
    },

    afterPay: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    ...mapMutations('modal', ['toggle']),
    ...mapState('user', ['user']),

    needMoreMoney () {
      const total = this.total

      if (this.user?.balance < total) {
        return total - this.user.realBalance
      } else {
        return 0
      }
    },

    total () {
      return this.product.price
    }
  },

  methods: {
    ...mapActions('user', ['fetchProducts']),
    ...mapActions('modal', ['showModal']),

    buy () {
      this.$gtag.event('click_phone_device_modal_buy', {})

      this.showModal({
        component: 'ModalEurOrCoins'
      })
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalcheckoutdevice {
  padding: 24px 16px 12px;
  max-width: 90vw;
  max-height: 95%;
  overflow-y: auto;

  @media (min-width: $breakpointTablet) {
    max-width: 440px;
  }

  @media (min-width: $breakpointDesktop) {
    max-width: 759px;
    padding: 24px 24px 12px;

    .modal__close {
      top: 24px;
    }
  }
}

.checkout {

  @media (min-width: $breakpointDesktop) {
    display: flex;
  }

  &__info {
    margin-top: 20px;
    font-size: 20px;
    color: $green40;
  }

  &__device-image {
    max-height: 342px;
    flex-shrink: 0;
    margin-bottom: 16px;

    @media (min-width: $breakpointDesktop) {
      margin-right: 40px;
      margin-bottom: 0;
    }
  }

  &__device {

    &-name {
      font-size: 12px;
      text-transform: uppercase;
      color: #90a4ae;
    }

    &-type {
      margin-bottom: 24px;
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      color: $white;
    }

    &-price {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-column-gap: 8px;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 28px;
    }

    &-amount {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $white;
    }

    &-currency {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $blue-grey10;
      grid-column-start: 2;
    }

    &-button {
      width: 100% !important;

      @media (min-width: $breakpointDesktop) {
        width: 245px !important;
      }
    }
  }

  button.button {
    @media (max-width: $breakpointDesktop) {
      width: 100%;
    }
  }

  &__description {
    &-title {
      margin-top: 40px;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: $white;
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      color: $white;
    }

    &-subtitle {
      font-size: 16px;
      color: #CFD8DC;
    }
  }

  &__rows {
    margin: 24px 0 32px;
  }

  &__row {
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #90A4AE;
    margin-bottom: 20px;
    text-transform: none;

    strong {
      font-size: 12px;
      font-weight: 400;
      color: $white;

      @media (min-width: $breakpointDesktop) {
        font-size: 16px;
      }
    }
  }

  &__image {
    display: block;
    margin: 56px auto 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: $font-bold;
    color: #fff;
    text-align: center;
    line-height: 34px;
  }
}
</style>
