<template lang="pug">
  .phase-modal
    .phase-modal__header
      header.phase-modal__header-info(:class="getStatusClass()")
        .phase-modal__header-state
            InlineSvg(:src="require('~/assets/img/icons/status.svg')")
            | {{ getStatusText() }}
        .phase-modal__header-title {{ getRoundTitleByType(stage.round_type) }}

      .phase-modal__header-counter(v-if="isCounterVisible")
        span {{ $t('timerStartsIn') }}&nbsp;
        Countdown(:end-time="startsAt")
          span(slot="process" slot-scope="{ timeObj }")
            | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}

      //.phase-modal__header-nav
      //  Tabs(:items="tabs" :active-tab-index="currentTabIndex" @change="onTabChange")

    TournamentPageTabs.phase-modal__tabs(:tournament="tournament" no-content :outside-tabs="tabs" @setTab="onTabChange")

    .phase-modal__body
      .phase-modal__tab(v-if="currentTabId === 'info'")
        template(v-if="hasAccess && ['open', 'in_progress'].includes(tournament.state)")
          .phase-modal__access.tournament-settings
            template(v-if="isLichess && hasAnticheat")
              .tournament-settings__title {{ $t('accessToTheTournament') }}
              CopyInput(:value="tournament.password" icon="lock-open" :title="$t('tournamentPassword')")
              .tournament-settings__buttons
                Button(is-primary @click="onEnterEventClick") {{ $t('goToGame') }}
                VideoChatButton(v-if="webcamLink" @click="onWebcamClick") {{ $t('videoLaunch') }}

            template(v-if="isPubg || isFreeFire || isPubgNewState")
              .tournament-settings__title {{ $t('accessToTheTournament') }}

              .tournament-settings__hint(v-if="!pubgLobby") {{ $t('lobbyDataWillBeSoon') }}
              CopyInput(v-if="pubgLobby" :value="pubgLobby" icon="lock-open" :title="$t('lobbyId')")
              CopyInput(v-if="pubgPassword" :value="pubgPassword" icon="lock-open" :title="this.$t('tournamentPassword')")

            template(v-if="isClashRoyale")
              .tournament-settings__title {{ $t('accessToTheTournament') }}
              .tournament-settings__hint(v-if="!clashRoyaleTag") {{ $t('lobbyDataWillBeSoon') }}
              template(v-else)
                CopyInput.data__lobby-input(v-if="clashRoyaleTag" :value="clashRoyaleTag" icon="lock-open" :title="$t('tag')")
                CopyInput.data__lobby-input(v-if="clashRoyalePassword" :value="clashRoyalePassword" icon="lock-open" :title="$t('password')")
                Button(is-primary @click="onEnterEventClick") {{ $t('openGame') }}

            template(v-if="isBrawlStars")
              template(v-if="!brawlStarsRoomCode")
                .tournament-settings__title {{ $t('accessToTheTournament') }}
                .tournament-settings__hint {{ $t('lobbyDataWillBeSoon') }}

              template(v-else)
                .tournament-settings__title {{ $t('enterIsOpen') }}
                .tournament-settings__hint {{ $t('useCodeOrClickButton') }}
                CopyInput(:value="brawlStarsRoomCode" icon="lock-open" :title="$t('lobbyCode')")
                Button(is-primary @click="onEnterEventClick") {{ $t('enterLobby') }}

        //.phase-modal__info-block.phase-modal__info-members
        //  .phase-modal__info-members-item
        //    .phase-modal__info-members-item-value {{ allMembersCount }}
        //    .phase-modal__info-members-item-title {{ isTeams ? $t('teamsCount') : $t('membersCount') }}

        //.phase-modal__info-block.phase-modal__info-date
        //  .phase-modal__info-date-item
        //    span {{ $t('start') }}:&nbsp;
        //    | &nbsp;{{ dateText }}
        //
        //  .phase-modal__info-date-item(v-if="isCounterVisible")
        //    span {{ $t('timerStartsIn') }}
        //    | &nbsp;
        //    Countdown(:end-time="startsAt")
        //      span(slot="process" slot-scope="{ timeObj }")
        //        | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
        //
        //.phase-modal__info-block.phase-modal__info-help
        //  a.phase-modal__info-help-item(href="#" v-support-button)
        //    InlineSvg(:src="require('~/assets/img/icons/info.svg')" width="24" height="24")
        //    | {{ $t('help') }}
      .phase-modal__tab.phase-modal__tab-players(v-if="currentTabId === 'players'")
        .phase-modal__list-header(v-if="withPoints")
          span {{ $t('name') }}
          span {{ $t('points') }}

        .phase-modal__list(v-if="!isTeams")
          PlayerRow(
            v-for="player in players"
            :key="player.id"
            :player="player"
            :with-half-star="player.place > 0"
            :with-winner-points="withPoints"
            with-game-account
            :with-menu="game.name === 'lichess'"
            location="tournament"
            :tournament="tournament"
          )
        .phase-modal__list(v-else)
          TeamRow(
            v-for="(team, index) in teams"
            :key="'reward_kill' + index"
            :team="team"
            with-kill
            with-points
            :with-place="team.place > 0"
          )

</template>

<script>
import { mapGetters, mapState } from 'vuex'
// import Tabs from '~/components/Tabs/Tabs'
import CopyInput from '~/components/CopyInput/CopyInput'
import { EVENT_STATUSES } from '~/utils/constants'
import PlayerRow from '~/components/PlayerRow/PlayerRow'
import TeamRow from '~/components/TeamRow/TeamRow'
import TournamentSettings from '~/components/TournamentSettings/TournamentSettings'
import TournamentPageTabs from '~/components/TournamentPageComponents/TournamentPageTabs'

export default {
  name: 'PhaseModal',

  components: { PlayerRow, CopyInput, TeamRow, TournamentPageTabs },

  extends: TournamentSettings,

  props: {
    tournament: {
      type: Object,
      required: true
    },

    parentTournament: {
      type: Object,
      required: true
    },

    stage: {
      type: Object,
      required: true
    },

    my: {
      type: Boolean,
      default: false
    }
  },

  data ({ tournament }) {
    let currentTabId = 'info'

    if (tournament.state === 'completed' || !this.my) {
      currentTabId = 'players'
    }

    return {
      currentTabId
    }
  },

  computed: {
    ...mapGetters('viewport', ['isMobile']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAnticheatEnabled']),
    ...mapState('games', ['gamesById', 'gamesByName']),

    bgImage () {
      const img = this.tournament.image_url || this.parentTournament.image_url
      return img ? `url(${img})` : null
    },

    // tabs () {
    //   return [
    //     { title: this.$t('information'), id: 'info' },
    //     { title: this.$t('members'), id: 'players' }
    //   ]
    // },

    tabs () {
      if (this.tournament.state === 'completed' || !this.my) {
        return [{
          id: 'players',
          i18nKey: 'tournamentPageTabParticipantsTitle'
        }]
      }

      return [
        {
          id: 'info',
          i18nKey: 'tournamentPageTabOverviewTitle'
        },
        {
          id: 'players',
          i18nKey: 'tournamentPageTabParticipantsTitle'
        }
      ]
    },

    isTeams () {
      return !!this.tournament.teams
    },

    players () {
      let players = this.tournament.players

      if (this.withPoints) {
        const winners = this.tournament.extra_data?.state?.game?.winners
        players = players.map((p) => {
          let player = p
          const username = p.game_account?.username
          const winner = winners?.find(w => w.username === username)

          if (winner) {
            player = {
              ...player,
              winner_points: winner.points
            }
          }

          return player
        })
      }

      if (this.tournament?.state === 'completed') {
        players = players.slice().sort((a, b) => {
          return Number(b.place > 0) - Number(a.place > 0)
        })
      }

      return players
    },

    teams () {
      if (this.tournament.state === 'completed') {
        return this.tournament.teams.slice().sort((a, b) => {
          return Number(b.place > 0) - Number(a.place > 0)
        })
      }

      return this.tournament.teams
    },

    allMembersCount () {
      return this.tournament.players_count
    },

    currentTabIndex () {
      return this.tabs.findIndex(tab => tab.id === this.currentTabId)
    },

    withPoints () {
      const isSequence = this.parentTournament.type === 'sequence'

      return isSequence && !!this.tournament.extra_data?.state?.game?.winners
    }
  },

  beforeDestroy () {
    this.$socket.off(`tournament:${this.tournament.id}`, this.onSocketUpdate)
  },

  mounted () {
    this.$socket.on(`tournament:${this.tournament.id}`, this.onSocketUpdate)
  },

  methods: {
    onSocketUpdate (tournament) {
      console.log('SOCKET MODAL TOURNAMENT', tournament)
      this.$store.commit('modal/updateComponentData', {
        component: 'PhaseModal',
        data: { tournament }
      })
      this.tournament = tournament
    },

    getRoundTitleByType (type) {
      return (
        {
          group: this.$t('qualifyingGroup'),
          quarter: this.$t('stageQuarter'),
          'semi-final': this.$t('stageSemifinal'),
          final: this.$t('stageFinal')
        }[type] || type
      )
    },

    onTabChange (index) {
      this.currentTabId = this.tabs[index]?.id
      this.$emit('recalculatePosition')
    },

    getStatusText () {
      return this.$t(EVENT_STATUSES[this.tournament.state])
    },

    getStatusClass () {
      const state = this.tournament.state
      return {
        '--opened': state === 'open',
        '--closed': ['completed', 'cancelled'].includes(state),
        '--started': state === 'in_progress'
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--phasemodal {
  padding: 0;
  max-width: 736px;
  overflow: hidden;
}

.phase-modal {
  &__list-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $blue-grey40;
    padding: 8px 2% 16px 21%;

    span {
      @include caption;

      color: $blue-grey10;
    }
  }

  &__tab {
    padding-top: 0;
  }

  &__tab-players {
    @include scroll;

    padding: 0 16px;
    margin: 16px -24px;
    overflow-y: auto;
    max-height: calc(100vh - 405px);

    @media (max-width: $breakpointMobile) {
      margin-top: 8px;
      padding: 0 8px;
      margin-bottom: -16px;
      max-height: calc(100vh - 350px);
    }
  }

  .player-row {
    margin: 0;
    border-bottom: 1px solid $blue-grey40;
    border-radius: 0;
    padding: 8px 0;

    &:last-child {
      border-bottom: 0;
    }

    @media (max-width: $breakpointMobile) {
      margin-bottom: 8px;
      padding: 0;
    }
  }

  .team-row {
    background: $blue-grey60;

    &:first-child {
      margin-top: 0;
    }
  }

  .notif-tournament {
    background: $blue-grey60;
    margin-top: 24px;

    .anticheat-tooltip {
      width: 100%;
    }

    button.button.button--secondary {
      background: $blue-grey50;
    }
  }

  &__info {
    &-block {
      border-top: 1px solid $blue-grey50;
      height: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    &-members-item {
      @include heading2;

      text-align: center;
      padding: 0 32px;

      &-title {
        @include caption-small;

        color: $blue-grey20;
        margin-top: 6px;
      }
    }

    &-date {
      justify-content: space-around;
    }

    &-date-item {
      @include heading4;

      color: $blue-grey05;
      opacity: 0.9;

      > span:first-child {
        @include caption-small;
      }
    }

    &-help {
      height: 64px;

      &-item {
        svg {
          path,
          rect {
            fill: #fff;
          }

          margin-right: 10px;
        }

        height: 48px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          background: $blue-grey60;
        }

        &:active {
          background: $blue-grey50;
        }
      }
    }
  }

  &__access {
    padding-top: 32px;
    padding-bottom: 40px;

    &-title {
      @include heading4;

      color: #fff;
      margin-bottom: 24px;
    }

    &-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }

  &__body {
    padding: 0 24px 40px;

    .copy-input {
      width: 100%;

      &__box {
        max-width: 100%;
      }
    }
  }

  &__header {
    position: relative;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    padding-top: 20px;

    &-counter {
      @include caption-small;

      white-space: nowrap;
      text-align: right;
      color: $blue-grey05;
      position: relative;
      z-index: 2;
      padding-right: 24px;
      padding-bottom: 24px;

      > span {
        display: block;

        &:first-child {
          margin-bottom: 12px;
        }

        &:last-child {
          @include heading3;

          color: #fff;
        }
      }

      @media (max-width: $breakpointTablet) {
        display: none;
      }
    }

    &-division {
      @include heading6;

      color: $blue-grey05;
      margin-top: 8px;
    }

    &-info {
      width: 100%;
      padding: 24px;
      position: relative;
      z-index: 2;
    }

    &-title {
      @include heading2;

      margin-top: 8px;
      color: #fff;
    }

    &-state {
      background: $black-transparent;
      padding: 0 16px;
      border-radius: 8px;
      color: #fff;
      font-style: normal;
      font-weight: $font-medium;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      height: 32px;
      width: fit-content;

      .--opened & {
        svg,
        path {
          fill: $green50;
        }
      }

      .--closed & {
        svg,
        path {
          fill: $red50;
        }
      }

      .--started & {
        svg,
        path {
          fill: $amber60;
        }
      }

      svg {
        margin-right: 8px;
      }
    }

    &-nav {
      height: 68px;
      background: rgb(21 31 45 / 50%);
      backdrop-filter: blur(24px);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 12px;

      .tabs {
        height: 100%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      background:
        radial-gradient(
          50% 50% at 50% 50%,
          rgb(28 39 53 / 0%) 0%,
          rgb(28 39 53 / 70%) 100%
        );
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  &__line {
    width: 100%;
    height: 0;
    margin: 20px 0;
    border-top: 1px solid $blue-grey50;
  }

  &__tabs {
    width: calc(100% - 40px) !important;
    margin: 0 20px;
  }
}
</style>
