import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dcf1d1cc = () => interopDefault(import('../pages/become-advertiser.vue' /* webpackChunkName: "pages/become-advertiser" */))
const _3d932670 = () => interopDefault(import('../pages/bonus.vue' /* webpackChunkName: "pages/bonus" */))
const _5b2ee3fb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _44ccee02 = () => interopDefault(import('../pages/earn/index.vue' /* webpackChunkName: "pages/earn/index" */))
const _26eaed6d = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _561eb734 = () => interopDefault(import('../pages/leaderboard.vue' /* webpackChunkName: "pages/leaderboard" */))
const _24d68712 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _017948c6 = () => interopDefault(import('../pages/menu.vue' /* webpackChunkName: "pages/menu" */))
const _5eba175e = () => interopDefault(import('../pages/missions/index.vue' /* webpackChunkName: "pages/missions/index" */))
const _0ff8289a = () => interopDefault(import('../pages/myvvv.vue' /* webpackChunkName: "pages/myvvv" */))
const _5fc797de = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _48d923be = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _447de112 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7f22cf30 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _25cb41b1 = () => interopDefault(import('../pages/profile/achievements.vue' /* webpackChunkName: "pages/profile/achievements" */))
const _2da7c45f = () => interopDefault(import('../pages/profile/balance.vue' /* webpackChunkName: "pages/profile/balance" */))
const _5a88b634 = () => interopDefault(import('../pages/profile/events.vue' /* webpackChunkName: "pages/profile/events" */))
const _466e05b7 = () => interopDefault(import('../pages/profile/favourites.vue' /* webpackChunkName: "pages/profile/favourites" */))
const _559b9bb8 = () => interopDefault(import('../pages/profile/game-accounts.vue' /* webpackChunkName: "pages/profile/game-accounts" */))
const _47e142ce = () => interopDefault(import('../pages/profile/goods.vue' /* webpackChunkName: "pages/profile/goods" */))
const _7becbb8a = () => interopDefault(import('../pages/profile/goods-moderation.vue' /* webpackChunkName: "pages/profile/goods-moderation" */))
const _1c987143 = () => interopDefault(import('../pages/profile/lichess-connect.vue' /* webpackChunkName: "pages/profile/lichess-connect" */))
const _043f2faa = () => interopDefault(import('../pages/profile/notifications.vue' /* webpackChunkName: "pages/profile/notifications" */))
const _75b53e9c = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _8b30a8c4 = () => interopDefault(import('../pages/profile/quests.vue' /* webpackChunkName: "pages/profile/quests" */))
const _ca7aefd4 = () => interopDefault(import('../pages/profile/ref.vue' /* webpackChunkName: "pages/profile/ref" */))
const _f8137562 = () => interopDefault(import('../pages/profile/sales.vue' /* webpackChunkName: "pages/profile/sales" */))
const _56f6364d = () => interopDefault(import('../pages/profile/security.vue' /* webpackChunkName: "pages/profile/security" */))
const _0d60bf62 = () => interopDefault(import('../pages/profile/stats.vue' /* webpackChunkName: "pages/profile/stats" */))
const _ed72c1ac = () => interopDefault(import('../pages/profile/subscription.vue' /* webpackChunkName: "pages/profile/subscription" */))
const _03f151f8 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _3d338b8d = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _7574b31f = () => interopDefault(import('../pages/subscriptions.vue' /* webpackChunkName: "pages/subscriptions" */))
const _e8b37bda = () => interopDefault(import('../pages/tournaments.vue' /* webpackChunkName: "pages/tournaments" */))
const _176d6235 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _b99a71c0 = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _152ffb30 = () => interopDefault(import('../pages/vvvpass.vue' /* webpackChunkName: "pages/vvvpass" */))
const _349ef002 = () => interopDefault(import('../pages/desktop/download.vue' /* webpackChunkName: "pages/desktop/download" */))
const _f8eeb37e = () => interopDefault(import('../pages/desktop/howto.vue' /* webpackChunkName: "pages/desktop/howto" */))
const _61bcf5c6 = () => interopDefault(import('../pages/earn/referral.vue' /* webpackChunkName: "pages/earn/referral" */))
const _8d3811fa = () => interopDefault(import('../pages/earn/stories.vue' /* webpackChunkName: "pages/earn/stories" */))
const _20a67c9b = () => interopDefault(import('../pages/earn/verification.vue' /* webpackChunkName: "pages/earn/verification" */))
const _2c26a34b = () => interopDefault(import('../pages/games/gamepix/index.vue' /* webpackChunkName: "pages/games/gamepix/index" */))
const _01c48093 = () => interopDefault(import('../pages/info/about.vue' /* webpackChunkName: "pages/info/about" */))
const _41b6fe9f = () => interopDefault(import('../pages/info/age-restrictions.vue' /* webpackChunkName: "pages/info/age-restrictions" */))
const _70edf2b7 = () => interopDefault(import('../pages/info/anticheat.vue' /* webpackChunkName: "pages/info/anticheat" */))
const _27573e88 = () => interopDefault(import('../pages/info/coins.vue' /* webpackChunkName: "pages/info/coins" */))
const _02f7a9bd = () => interopDefault(import('../pages/info/contacts.vue' /* webpackChunkName: "pages/info/contacts" */))
const _6f62d67e = () => interopDefault(import('../pages/info/cookie-policy.vue' /* webpackChunkName: "pages/info/cookie-policy" */))
const _3f2a47c3 = () => interopDefault(import('../pages/info/g-cash.vue' /* webpackChunkName: "pages/info/g-cash" */))
const _0c352110 = () => interopDefault(import('../pages/info/overwolf.vue' /* webpackChunkName: "pages/info/overwolf" */))
const _31c61007 = () => interopDefault(import('../pages/info/referral.vue' /* webpackChunkName: "pages/info/referral" */))
const _4c0a3031 = () => interopDefault(import('../pages/info/risk-disclosure.vue' /* webpackChunkName: "pages/info/risk-disclosure" */))
const _8d66d926 = () => interopDefault(import('../pages/info/tickets.vue' /* webpackChunkName: "pages/info/tickets" */))
const _1b0fff46 = () => interopDefault(import('../pages/marketplace/add.vue' /* webpackChunkName: "pages/marketplace/add" */))
const _22cae6df = () => interopDefault(import('../pages/marketplace/add-one.vue' /* webpackChunkName: "pages/marketplace/add-one" */))
const _2dae22aa = () => interopDefault(import('../pages/marketplace/become-seller.vue' /* webpackChunkName: "pages/marketplace/become-seller" */))
const _69a4124c = () => interopDefault(import('../pages/profile/personal-data.vue' /* webpackChunkName: "pages/profile/personal-data" */))
const _635925b0 = () => interopDefault(import('../pages/profile/verification.vue' /* webpackChunkName: "pages/profile/verification" */))
const _3b1968ae = () => interopDefault(import('../pages/steam/register.vue' /* webpackChunkName: "pages/steam/register" */))
const _7fb90435 = () => interopDefault(import('../pages/store/cart.vue' /* webpackChunkName: "pages/store/cart" */))
const _57c2ccdb = () => interopDefault(import('../pages/store/checkout.vue' /* webpackChunkName: "pages/store/checkout" */))
const _923ee5dc = () => interopDefault(import('../pages/store/gc-checkout.vue' /* webpackChunkName: "pages/store/gc-checkout" */))
const _0518f226 = () => interopDefault(import('../pages/games/gamepix/_name/index.vue' /* webpackChunkName: "pages/games/gamepix/_name/index" */))
const _55a60744 = () => interopDefault(import('../pages/marketplace/order/_id.vue' /* webpackChunkName: "pages/marketplace/order/_id" */))
const _1360e5fc = () => interopDefault(import('../pages/marketplace/product/_id/index.vue' /* webpackChunkName: "pages/marketplace/product/_id/index" */))
const _1d730e6b = () => interopDefault(import('../pages/offer/ad/_partner.vue' /* webpackChunkName: "pages/offer/ad/_partner" */))
const _09fe653c = () => interopDefault(import('../pages/user/id/_id.js' /* webpackChunkName: "pages/user/id/_id" */))
const _52f9b452 = () => interopDefault(import('../pages/marketplace/product/_id/old.vue' /* webpackChunkName: "pages/marketplace/product/_id/old" */))
const _33b09636 = () => interopDefault(import('../pages/marketplace/product/_id/payment.vue' /* webpackChunkName: "pages/marketplace/product/_id/payment" */))
const _6b2d73e4 = () => interopDefault(import('../pages/games/gamepix/_name/_id.vue' /* webpackChunkName: "pages/games/gamepix/_name/_id" */))
const _59abe8d0 = () => interopDefault(import('../pages/marketplace/_app/index.vue' /* webpackChunkName: "pages/marketplace/_app/index" */))
const _73def2e9 = () => interopDefault(import('../pages/missions/_name.vue' /* webpackChunkName: "pages/missions/_name" */))
const _3d4a83b0 = () => interopDefault(import('../pages/offer/_id.vue' /* webpackChunkName: "pages/offer/_id" */))
const _81816192 = () => interopDefault(import('../pages/p/_alias.vue' /* webpackChunkName: "pages/p/_alias" */))
const _8db419fa = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _a16aa62a = () => interopDefault(import('../pages/store/_tab.vue' /* webpackChunkName: "pages/store/_tab" */))
const _43f4369a = () => interopDefault(import('../pages/t/_alias.vue' /* webpackChunkName: "pages/t/_alias" */))
const _3d12320d = () => interopDefault(import('../pages/user/_user.vue' /* webpackChunkName: "pages/user/_user" */))
const _1ac1644a = () => interopDefault(import('../pages/marketplace/_app/_category.vue' /* webpackChunkName: "pages/marketplace/_app/_category" */))
const _e3ad2906 = () => interopDefault(import('../pages/_game/index.vue' /* webpackChunkName: "pages/_game/index" */))
const _56131ea9 = () => interopDefault(import('../pages/_game/duels/index.vue' /* webpackChunkName: "pages/_game/duels/index" */))
const _5b84e5ce = () => interopDefault(import('../pages/_game/phased-tournaments/index.vue' /* webpackChunkName: "pages/_game/phased-tournaments/index" */))
const _537dfa50 = () => interopDefault(import('../pages/_game/tournaments/index.vue' /* webpackChunkName: "pages/_game/tournaments/index" */))
const _26d10a5e = () => interopDefault(import('../pages/_game/tournaments/new/_id.vue' /* webpackChunkName: "pages/_game/tournaments/new/_id" */))
const _8c0a915e = () => interopDefault(import('../pages/_game/duels/_id.vue' /* webpackChunkName: "pages/_game/duels/_id" */))
const _91e3f894 = () => interopDefault(import('../pages/_game/phased-tournaments/_id.vue' /* webpackChunkName: "pages/_game/phased-tournaments/_id" */))
const _113c4980 = () => interopDefault(import('../pages/_game/tournaments/_id.vue' /* webpackChunkName: "pages/_game/tournaments/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/become-advertiser",
    component: _dcf1d1cc,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___en-US"
  }, {
    path: "/bonus",
    component: _3d932670,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___en-US"
  }, {
    path: "/de",
    component: _5b2ee3fb,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/earn",
    component: _44ccee02,
    pathToRegexpOptions: {"strict":true},
    name: "earn___en-US"
  }, {
    path: "/es",
    component: _5b2ee3fb,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/games",
    component: _26eaed6d,
    pathToRegexpOptions: {"strict":true},
    name: "games___en-US"
  }, {
    path: "/it",
    component: _5b2ee3fb,
    pathToRegexpOptions: {"strict":true},
    name: "index___it"
  }, {
    path: "/kk",
    component: _5b2ee3fb,
    pathToRegexpOptions: {"strict":true},
    name: "index___kk"
  }, {
    path: "/leaderboard",
    component: _561eb734,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___en-US"
  }, {
    path: "/login",
    component: _24d68712,
    pathToRegexpOptions: {"strict":true},
    name: "login___en-US"
  }, {
    path: "/menu",
    component: _017948c6,
    pathToRegexpOptions: {"strict":true},
    name: "menu___en-US"
  }, {
    path: "/missions",
    component: _5eba175e,
    pathToRegexpOptions: {"strict":true},
    name: "missions___en-US"
  }, {
    path: "/myvvv",
    component: _0ff8289a,
    pathToRegexpOptions: {"strict":true},
    name: "myvvv___en-US"
  }, {
    path: "/notifications",
    component: _5fc797de,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___en-US"
  }, {
    path: "/privacy-policy",
    component: _48d923be,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en-US"
  }, {
    path: "/profile",
    component: _447de112,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en-US",
    children: [{
      path: "account",
      component: _7f22cf30,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___en-US"
    }, {
      path: "achievements",
      component: _25cb41b1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___en-US"
    }, {
      path: "balance",
      component: _2da7c45f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___en-US"
    }, {
      path: "events",
      component: _5a88b634,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___en-US"
    }, {
      path: "favourites",
      component: _466e05b7,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___en-US"
    }, {
      path: "game-accounts",
      component: _559b9bb8,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___en-US"
    }, {
      path: "goods",
      component: _47e142ce,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___en-US"
    }, {
      path: "goods-moderation",
      component: _7becbb8a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___en-US"
    }, {
      path: "lichess-connect",
      component: _1c987143,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___en-US"
    }, {
      path: "notifications",
      component: _043f2faa,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___en-US"
    }, {
      path: "orders",
      component: _75b53e9c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___en-US"
    }, {
      path: "quests",
      component: _8b30a8c4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___en-US"
    }, {
      path: "ref",
      component: _ca7aefd4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___en-US"
    }, {
      path: "sales",
      component: _f8137562,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___en-US"
    }, {
      path: "security",
      component: _56f6364d,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___en-US"
    }, {
      path: "stats",
      component: _0d60bf62,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___en-US"
    }, {
      path: "subscription",
      component: _ed72c1ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___en-US"
    }]
  }, {
    path: "/pt",
    component: _5b2ee3fb,
    pathToRegexpOptions: {"strict":true},
    name: "index___pt"
  }, {
    path: "/reset",
    component: _03f151f8,
    pathToRegexpOptions: {"strict":true},
    name: "reset___en-US"
  }, {
    path: "/store",
    component: _3d338b8d,
    pathToRegexpOptions: {"strict":true},
    name: "store___en-US"
  }, {
    path: "/subscriptions",
    component: _7574b31f,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___en-US"
  }, {
    path: "/tournaments",
    component: _e8b37bda,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___en-US"
  }, {
    path: "/user",
    component: _176d6235,
    pathToRegexpOptions: {"strict":true},
    name: "user___en-US"
  }, {
    path: "/verify",
    component: _b99a71c0,
    pathToRegexpOptions: {"strict":true},
    name: "verify___en-US"
  }, {
    path: "/vvvpass",
    component: _152ffb30,
    pathToRegexpOptions: {"strict":true},
    name: "vvvpass___en-US"
  }, {
    path: "/de/become-advertiser",
    component: _dcf1d1cc,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___de"
  }, {
    path: "/de/bonus",
    component: _3d932670,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___de"
  }, {
    path: "/de/earn",
    component: _44ccee02,
    pathToRegexpOptions: {"strict":true},
    name: "earn___de"
  }, {
    path: "/de/games",
    component: _26eaed6d,
    pathToRegexpOptions: {"strict":true},
    name: "games___de"
  }, {
    path: "/de/leaderboard",
    component: _561eb734,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___de"
  }, {
    path: "/de/login",
    component: _24d68712,
    pathToRegexpOptions: {"strict":true},
    name: "login___de"
  }, {
    path: "/de/menu",
    component: _017948c6,
    pathToRegexpOptions: {"strict":true},
    name: "menu___de"
  }, {
    path: "/de/missions",
    component: _5eba175e,
    pathToRegexpOptions: {"strict":true},
    name: "missions___de"
  }, {
    path: "/de/myvvv",
    component: _0ff8289a,
    pathToRegexpOptions: {"strict":true},
    name: "myvvv___de"
  }, {
    path: "/de/notifications",
    component: _5fc797de,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___de"
  }, {
    path: "/de/privacy-policy",
    component: _48d923be,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___de"
  }, {
    path: "/de/profile",
    component: _447de112,
    pathToRegexpOptions: {"strict":true},
    name: "profile___de",
    children: [{
      path: "account",
      component: _7f22cf30,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___de"
    }, {
      path: "achievements",
      component: _25cb41b1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___de"
    }, {
      path: "balance",
      component: _2da7c45f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___de"
    }, {
      path: "events",
      component: _5a88b634,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___de"
    }, {
      path: "favourites",
      component: _466e05b7,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___de"
    }, {
      path: "game-accounts",
      component: _559b9bb8,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___de"
    }, {
      path: "goods",
      component: _47e142ce,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___de"
    }, {
      path: "goods-moderation",
      component: _7becbb8a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___de"
    }, {
      path: "lichess-connect",
      component: _1c987143,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___de"
    }, {
      path: "notifications",
      component: _043f2faa,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___de"
    }, {
      path: "orders",
      component: _75b53e9c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___de"
    }, {
      path: "quests",
      component: _8b30a8c4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___de"
    }, {
      path: "ref",
      component: _ca7aefd4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___de"
    }, {
      path: "sales",
      component: _f8137562,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___de"
    }, {
      path: "security",
      component: _56f6364d,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___de"
    }, {
      path: "stats",
      component: _0d60bf62,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___de"
    }, {
      path: "subscription",
      component: _ed72c1ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___de"
    }]
  }, {
    path: "/de/reset",
    component: _03f151f8,
    pathToRegexpOptions: {"strict":true},
    name: "reset___de"
  }, {
    path: "/de/store",
    component: _3d338b8d,
    pathToRegexpOptions: {"strict":true},
    name: "store___de"
  }, {
    path: "/de/subscriptions",
    component: _7574b31f,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___de"
  }, {
    path: "/de/tournaments",
    component: _e8b37bda,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___de"
  }, {
    path: "/de/user",
    component: _176d6235,
    pathToRegexpOptions: {"strict":true},
    name: "user___de"
  }, {
    path: "/de/verify",
    component: _b99a71c0,
    pathToRegexpOptions: {"strict":true},
    name: "verify___de"
  }, {
    path: "/de/vvvpass",
    component: _152ffb30,
    pathToRegexpOptions: {"strict":true},
    name: "vvvpass___de"
  }, {
    path: "/desktop/download",
    component: _349ef002,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___en-US"
  }, {
    path: "/desktop/howto",
    component: _f8eeb37e,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___en-US"
  }, {
    path: "/earn/referral",
    component: _61bcf5c6,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___en-US"
  }, {
    path: "/earn/stories",
    component: _8d3811fa,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___en-US"
  }, {
    path: "/earn/verification",
    component: _20a67c9b,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___en-US"
  }, {
    path: "/es/become-advertiser",
    component: _dcf1d1cc,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___es"
  }, {
    path: "/es/bonus",
    component: _3d932670,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___es"
  }, {
    path: "/es/earn",
    component: _44ccee02,
    pathToRegexpOptions: {"strict":true},
    name: "earn___es"
  }, {
    path: "/es/games",
    component: _26eaed6d,
    pathToRegexpOptions: {"strict":true},
    name: "games___es"
  }, {
    path: "/es/leaderboard",
    component: _561eb734,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___es"
  }, {
    path: "/es/login",
    component: _24d68712,
    pathToRegexpOptions: {"strict":true},
    name: "login___es"
  }, {
    path: "/es/menu",
    component: _017948c6,
    pathToRegexpOptions: {"strict":true},
    name: "menu___es"
  }, {
    path: "/es/missions",
    component: _5eba175e,
    pathToRegexpOptions: {"strict":true},
    name: "missions___es"
  }, {
    path: "/es/myvvv",
    component: _0ff8289a,
    pathToRegexpOptions: {"strict":true},
    name: "myvvv___es"
  }, {
    path: "/es/notifications",
    component: _5fc797de,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___es"
  }, {
    path: "/es/privacy-policy",
    component: _48d923be,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___es"
  }, {
    path: "/es/profile",
    component: _447de112,
    pathToRegexpOptions: {"strict":true},
    name: "profile___es",
    children: [{
      path: "account",
      component: _7f22cf30,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___es"
    }, {
      path: "achievements",
      component: _25cb41b1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___es"
    }, {
      path: "balance",
      component: _2da7c45f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___es"
    }, {
      path: "events",
      component: _5a88b634,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___es"
    }, {
      path: "favourites",
      component: _466e05b7,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___es"
    }, {
      path: "game-accounts",
      component: _559b9bb8,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___es"
    }, {
      path: "goods",
      component: _47e142ce,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___es"
    }, {
      path: "goods-moderation",
      component: _7becbb8a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___es"
    }, {
      path: "lichess-connect",
      component: _1c987143,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___es"
    }, {
      path: "notifications",
      component: _043f2faa,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___es"
    }, {
      path: "orders",
      component: _75b53e9c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___es"
    }, {
      path: "quests",
      component: _8b30a8c4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___es"
    }, {
      path: "ref",
      component: _ca7aefd4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___es"
    }, {
      path: "sales",
      component: _f8137562,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___es"
    }, {
      path: "security",
      component: _56f6364d,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___es"
    }, {
      path: "stats",
      component: _0d60bf62,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___es"
    }, {
      path: "subscription",
      component: _ed72c1ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___es"
    }]
  }, {
    path: "/es/reset",
    component: _03f151f8,
    pathToRegexpOptions: {"strict":true},
    name: "reset___es"
  }, {
    path: "/es/store",
    component: _3d338b8d,
    pathToRegexpOptions: {"strict":true},
    name: "store___es"
  }, {
    path: "/es/subscriptions",
    component: _7574b31f,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___es"
  }, {
    path: "/es/tournaments",
    component: _e8b37bda,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___es"
  }, {
    path: "/es/user",
    component: _176d6235,
    pathToRegexpOptions: {"strict":true},
    name: "user___es"
  }, {
    path: "/es/verify",
    component: _b99a71c0,
    pathToRegexpOptions: {"strict":true},
    name: "verify___es"
  }, {
    path: "/es/vvvpass",
    component: _152ffb30,
    pathToRegexpOptions: {"strict":true},
    name: "vvvpass___es"
  }, {
    path: "/games/gamepix",
    component: _2c26a34b,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix___en-US"
  }, {
    path: "/info/about",
    component: _01c48093,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___en-US"
  }, {
    path: "/info/age-restrictions",
    component: _41b6fe9f,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___en-US"
  }, {
    path: "/info/anticheat",
    component: _70edf2b7,
    pathToRegexpOptions: {"strict":true},
    name: "info-anticheat___en-US"
  }, {
    path: "/info/coins",
    component: _27573e88,
    pathToRegexpOptions: {"strict":true},
    name: "info-coins___en-US"
  }, {
    path: "/info/contacts",
    component: _02f7a9bd,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___en-US"
  }, {
    path: "/info/cookie-policy",
    component: _6f62d67e,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___en-US"
  }, {
    path: "/info/g-cash",
    component: _3f2a47c3,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___en-US"
  }, {
    path: "/info/overwolf",
    component: _0c352110,
    pathToRegexpOptions: {"strict":true},
    name: "info-overwolf___en-US"
  }, {
    path: "/info/referral",
    component: _31c61007,
    pathToRegexpOptions: {"strict":true},
    name: "info-referral___en-US"
  }, {
    path: "/info/risk-disclosure",
    component: _4c0a3031,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___en-US"
  }, {
    path: "/info/tickets",
    component: _8d66d926,
    pathToRegexpOptions: {"strict":true},
    name: "info-tickets___en-US"
  }, {
    path: "/it/become-advertiser",
    component: _dcf1d1cc,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___it"
  }, {
    path: "/it/bonus",
    component: _3d932670,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___it"
  }, {
    path: "/it/earn",
    component: _44ccee02,
    pathToRegexpOptions: {"strict":true},
    name: "earn___it"
  }, {
    path: "/it/games",
    component: _26eaed6d,
    pathToRegexpOptions: {"strict":true},
    name: "games___it"
  }, {
    path: "/it/leaderboard",
    component: _561eb734,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___it"
  }, {
    path: "/it/login",
    component: _24d68712,
    pathToRegexpOptions: {"strict":true},
    name: "login___it"
  }, {
    path: "/it/menu",
    component: _017948c6,
    pathToRegexpOptions: {"strict":true},
    name: "menu___it"
  }, {
    path: "/it/missions",
    component: _5eba175e,
    pathToRegexpOptions: {"strict":true},
    name: "missions___it"
  }, {
    path: "/it/myvvv",
    component: _0ff8289a,
    pathToRegexpOptions: {"strict":true},
    name: "myvvv___it"
  }, {
    path: "/it/notifications",
    component: _5fc797de,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___it"
  }, {
    path: "/it/privacy-policy",
    component: _48d923be,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___it"
  }, {
    path: "/it/profile",
    component: _447de112,
    pathToRegexpOptions: {"strict":true},
    name: "profile___it",
    children: [{
      path: "account",
      component: _7f22cf30,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___it"
    }, {
      path: "achievements",
      component: _25cb41b1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___it"
    }, {
      path: "balance",
      component: _2da7c45f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___it"
    }, {
      path: "events",
      component: _5a88b634,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___it"
    }, {
      path: "favourites",
      component: _466e05b7,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___it"
    }, {
      path: "game-accounts",
      component: _559b9bb8,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___it"
    }, {
      path: "goods",
      component: _47e142ce,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___it"
    }, {
      path: "goods-moderation",
      component: _7becbb8a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___it"
    }, {
      path: "lichess-connect",
      component: _1c987143,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___it"
    }, {
      path: "notifications",
      component: _043f2faa,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___it"
    }, {
      path: "orders",
      component: _75b53e9c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___it"
    }, {
      path: "quests",
      component: _8b30a8c4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___it"
    }, {
      path: "ref",
      component: _ca7aefd4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___it"
    }, {
      path: "sales",
      component: _f8137562,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___it"
    }, {
      path: "security",
      component: _56f6364d,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___it"
    }, {
      path: "stats",
      component: _0d60bf62,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___it"
    }, {
      path: "subscription",
      component: _ed72c1ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___it"
    }]
  }, {
    path: "/it/reset",
    component: _03f151f8,
    pathToRegexpOptions: {"strict":true},
    name: "reset___it"
  }, {
    path: "/it/store",
    component: _3d338b8d,
    pathToRegexpOptions: {"strict":true},
    name: "store___it"
  }, {
    path: "/it/subscriptions",
    component: _7574b31f,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___it"
  }, {
    path: "/it/tournaments",
    component: _e8b37bda,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___it"
  }, {
    path: "/it/user",
    component: _176d6235,
    pathToRegexpOptions: {"strict":true},
    name: "user___it"
  }, {
    path: "/it/verify",
    component: _b99a71c0,
    pathToRegexpOptions: {"strict":true},
    name: "verify___it"
  }, {
    path: "/it/vvvpass",
    component: _152ffb30,
    pathToRegexpOptions: {"strict":true},
    name: "vvvpass___it"
  }, {
    path: "/kk/become-advertiser",
    component: _dcf1d1cc,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___kk"
  }, {
    path: "/kk/bonus",
    component: _3d932670,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___kk"
  }, {
    path: "/kk/earn",
    component: _44ccee02,
    pathToRegexpOptions: {"strict":true},
    name: "earn___kk"
  }, {
    path: "/kk/games",
    component: _26eaed6d,
    pathToRegexpOptions: {"strict":true},
    name: "games___kk"
  }, {
    path: "/kk/leaderboard",
    component: _561eb734,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___kk"
  }, {
    path: "/kk/login",
    component: _24d68712,
    pathToRegexpOptions: {"strict":true},
    name: "login___kk"
  }, {
    path: "/kk/menu",
    component: _017948c6,
    pathToRegexpOptions: {"strict":true},
    name: "menu___kk"
  }, {
    path: "/kk/missions",
    component: _5eba175e,
    pathToRegexpOptions: {"strict":true},
    name: "missions___kk"
  }, {
    path: "/kk/myvvv",
    component: _0ff8289a,
    pathToRegexpOptions: {"strict":true},
    name: "myvvv___kk"
  }, {
    path: "/kk/notifications",
    component: _5fc797de,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___kk"
  }, {
    path: "/kk/privacy-policy",
    component: _48d923be,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___kk"
  }, {
    path: "/kk/profile",
    component: _447de112,
    pathToRegexpOptions: {"strict":true},
    name: "profile___kk",
    children: [{
      path: "account",
      component: _7f22cf30,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___kk"
    }, {
      path: "achievements",
      component: _25cb41b1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___kk"
    }, {
      path: "balance",
      component: _2da7c45f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___kk"
    }, {
      path: "events",
      component: _5a88b634,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___kk"
    }, {
      path: "favourites",
      component: _466e05b7,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___kk"
    }, {
      path: "game-accounts",
      component: _559b9bb8,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___kk"
    }, {
      path: "goods",
      component: _47e142ce,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___kk"
    }, {
      path: "goods-moderation",
      component: _7becbb8a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___kk"
    }, {
      path: "lichess-connect",
      component: _1c987143,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___kk"
    }, {
      path: "notifications",
      component: _043f2faa,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___kk"
    }, {
      path: "orders",
      component: _75b53e9c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___kk"
    }, {
      path: "quests",
      component: _8b30a8c4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___kk"
    }, {
      path: "ref",
      component: _ca7aefd4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___kk"
    }, {
      path: "sales",
      component: _f8137562,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___kk"
    }, {
      path: "security",
      component: _56f6364d,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___kk"
    }, {
      path: "stats",
      component: _0d60bf62,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___kk"
    }, {
      path: "subscription",
      component: _ed72c1ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___kk"
    }]
  }, {
    path: "/kk/reset",
    component: _03f151f8,
    pathToRegexpOptions: {"strict":true},
    name: "reset___kk"
  }, {
    path: "/kk/store",
    component: _3d338b8d,
    pathToRegexpOptions: {"strict":true},
    name: "store___kk"
  }, {
    path: "/kk/subscriptions",
    component: _7574b31f,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___kk"
  }, {
    path: "/kk/tournaments",
    component: _e8b37bda,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___kk"
  }, {
    path: "/kk/user",
    component: _176d6235,
    pathToRegexpOptions: {"strict":true},
    name: "user___kk"
  }, {
    path: "/kk/verify",
    component: _b99a71c0,
    pathToRegexpOptions: {"strict":true},
    name: "verify___kk"
  }, {
    path: "/kk/vvvpass",
    component: _152ffb30,
    pathToRegexpOptions: {"strict":true},
    name: "vvvpass___kk"
  }, {
    path: "/marketplace/add",
    component: _1b0fff46,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___en-US"
  }, {
    path: "/marketplace/add-one",
    component: _22cae6df,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add-one___en-US"
  }, {
    path: "/marketplace/become-seller",
    component: _2dae22aa,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___en-US"
  }, {
    path: "/profile/personal-data",
    component: _69a4124c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___en-US"
  }, {
    path: "/profile/verification",
    component: _635925b0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___en-US"
  }, {
    path: "/pt/become-advertiser",
    component: _dcf1d1cc,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___pt"
  }, {
    path: "/pt/bonus",
    component: _3d932670,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___pt"
  }, {
    path: "/pt/earn",
    component: _44ccee02,
    pathToRegexpOptions: {"strict":true},
    name: "earn___pt"
  }, {
    path: "/pt/games",
    component: _26eaed6d,
    pathToRegexpOptions: {"strict":true},
    name: "games___pt"
  }, {
    path: "/pt/leaderboard",
    component: _561eb734,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___pt"
  }, {
    path: "/pt/login",
    component: _24d68712,
    pathToRegexpOptions: {"strict":true},
    name: "login___pt"
  }, {
    path: "/pt/menu",
    component: _017948c6,
    pathToRegexpOptions: {"strict":true},
    name: "menu___pt"
  }, {
    path: "/pt/missions",
    component: _5eba175e,
    pathToRegexpOptions: {"strict":true},
    name: "missions___pt"
  }, {
    path: "/pt/myvvv",
    component: _0ff8289a,
    pathToRegexpOptions: {"strict":true},
    name: "myvvv___pt"
  }, {
    path: "/pt/notifications",
    component: _5fc797de,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___pt"
  }, {
    path: "/pt/privacy-policy",
    component: _48d923be,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___pt"
  }, {
    path: "/pt/profile",
    component: _447de112,
    pathToRegexpOptions: {"strict":true},
    name: "profile___pt",
    children: [{
      path: "account",
      component: _7f22cf30,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___pt"
    }, {
      path: "achievements",
      component: _25cb41b1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___pt"
    }, {
      path: "balance",
      component: _2da7c45f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___pt"
    }, {
      path: "events",
      component: _5a88b634,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___pt"
    }, {
      path: "favourites",
      component: _466e05b7,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___pt"
    }, {
      path: "game-accounts",
      component: _559b9bb8,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___pt"
    }, {
      path: "goods",
      component: _47e142ce,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___pt"
    }, {
      path: "goods-moderation",
      component: _7becbb8a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___pt"
    }, {
      path: "lichess-connect",
      component: _1c987143,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___pt"
    }, {
      path: "notifications",
      component: _043f2faa,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___pt"
    }, {
      path: "orders",
      component: _75b53e9c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___pt"
    }, {
      path: "quests",
      component: _8b30a8c4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___pt"
    }, {
      path: "ref",
      component: _ca7aefd4,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___pt"
    }, {
      path: "sales",
      component: _f8137562,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___pt"
    }, {
      path: "security",
      component: _56f6364d,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___pt"
    }, {
      path: "stats",
      component: _0d60bf62,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___pt"
    }, {
      path: "subscription",
      component: _ed72c1ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___pt"
    }]
  }, {
    path: "/pt/reset",
    component: _03f151f8,
    pathToRegexpOptions: {"strict":true},
    name: "reset___pt"
  }, {
    path: "/pt/store",
    component: _3d338b8d,
    pathToRegexpOptions: {"strict":true},
    name: "store___pt"
  }, {
    path: "/pt/subscriptions",
    component: _7574b31f,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___pt"
  }, {
    path: "/pt/tournaments",
    component: _e8b37bda,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___pt"
  }, {
    path: "/pt/user",
    component: _176d6235,
    pathToRegexpOptions: {"strict":true},
    name: "user___pt"
  }, {
    path: "/pt/verify",
    component: _b99a71c0,
    pathToRegexpOptions: {"strict":true},
    name: "verify___pt"
  }, {
    path: "/pt/vvvpass",
    component: _152ffb30,
    pathToRegexpOptions: {"strict":true},
    name: "vvvpass___pt"
  }, {
    path: "/steam/register",
    component: _3b1968ae,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___en-US"
  }, {
    path: "/store/cart",
    component: _7fb90435,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___en-US"
  }, {
    path: "/store/checkout",
    component: _57c2ccdb,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___en-US"
  }, {
    path: "/store/gc-checkout",
    component: _923ee5dc,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___en-US"
  }, {
    path: "/de/desktop/download",
    component: _349ef002,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___de"
  }, {
    path: "/de/desktop/howto",
    component: _f8eeb37e,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___de"
  }, {
    path: "/de/earn/referral",
    component: _61bcf5c6,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___de"
  }, {
    path: "/de/earn/stories",
    component: _8d3811fa,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___de"
  }, {
    path: "/de/earn/verification",
    component: _20a67c9b,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___de"
  }, {
    path: "/de/games/gamepix",
    component: _2c26a34b,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix___de"
  }, {
    path: "/de/info/about",
    component: _01c48093,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___de"
  }, {
    path: "/de/info/age-restrictions",
    component: _41b6fe9f,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___de"
  }, {
    path: "/de/info/anticheat",
    component: _70edf2b7,
    pathToRegexpOptions: {"strict":true},
    name: "info-anticheat___de"
  }, {
    path: "/de/info/coins",
    component: _27573e88,
    pathToRegexpOptions: {"strict":true},
    name: "info-coins___de"
  }, {
    path: "/de/info/contacts",
    component: _02f7a9bd,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___de"
  }, {
    path: "/de/info/cookie-policy",
    component: _6f62d67e,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___de"
  }, {
    path: "/de/info/g-cash",
    component: _3f2a47c3,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___de"
  }, {
    path: "/de/info/overwolf",
    component: _0c352110,
    pathToRegexpOptions: {"strict":true},
    name: "info-overwolf___de"
  }, {
    path: "/de/info/referral",
    component: _31c61007,
    pathToRegexpOptions: {"strict":true},
    name: "info-referral___de"
  }, {
    path: "/de/info/risk-disclosure",
    component: _4c0a3031,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___de"
  }, {
    path: "/de/info/tickets",
    component: _8d66d926,
    pathToRegexpOptions: {"strict":true},
    name: "info-tickets___de"
  }, {
    path: "/de/marketplace/add",
    component: _1b0fff46,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___de"
  }, {
    path: "/de/marketplace/add-one",
    component: _22cae6df,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add-one___de"
  }, {
    path: "/de/marketplace/become-seller",
    component: _2dae22aa,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___de"
  }, {
    path: "/de/profile/personal-data",
    component: _69a4124c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___de"
  }, {
    path: "/de/profile/verification",
    component: _635925b0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___de"
  }, {
    path: "/de/steam/register",
    component: _3b1968ae,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___de"
  }, {
    path: "/de/store/cart",
    component: _7fb90435,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___de"
  }, {
    path: "/de/store/checkout",
    component: _57c2ccdb,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___de"
  }, {
    path: "/de/store/gc-checkout",
    component: _923ee5dc,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___de"
  }, {
    path: "/es/desktop/download",
    component: _349ef002,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___es"
  }, {
    path: "/es/desktop/howto",
    component: _f8eeb37e,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___es"
  }, {
    path: "/es/earn/referral",
    component: _61bcf5c6,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___es"
  }, {
    path: "/es/earn/stories",
    component: _8d3811fa,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___es"
  }, {
    path: "/es/earn/verification",
    component: _20a67c9b,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___es"
  }, {
    path: "/es/games/gamepix",
    component: _2c26a34b,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix___es"
  }, {
    path: "/es/info/about",
    component: _01c48093,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___es"
  }, {
    path: "/es/info/age-restrictions",
    component: _41b6fe9f,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___es"
  }, {
    path: "/es/info/anticheat",
    component: _70edf2b7,
    pathToRegexpOptions: {"strict":true},
    name: "info-anticheat___es"
  }, {
    path: "/es/info/coins",
    component: _27573e88,
    pathToRegexpOptions: {"strict":true},
    name: "info-coins___es"
  }, {
    path: "/es/info/contacts",
    component: _02f7a9bd,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___es"
  }, {
    path: "/es/info/cookie-policy",
    component: _6f62d67e,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___es"
  }, {
    path: "/es/info/g-cash",
    component: _3f2a47c3,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___es"
  }, {
    path: "/es/info/overwolf",
    component: _0c352110,
    pathToRegexpOptions: {"strict":true},
    name: "info-overwolf___es"
  }, {
    path: "/es/info/referral",
    component: _31c61007,
    pathToRegexpOptions: {"strict":true},
    name: "info-referral___es"
  }, {
    path: "/es/info/risk-disclosure",
    component: _4c0a3031,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___es"
  }, {
    path: "/es/info/tickets",
    component: _8d66d926,
    pathToRegexpOptions: {"strict":true},
    name: "info-tickets___es"
  }, {
    path: "/es/marketplace/add",
    component: _1b0fff46,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___es"
  }, {
    path: "/es/marketplace/add-one",
    component: _22cae6df,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add-one___es"
  }, {
    path: "/es/marketplace/become-seller",
    component: _2dae22aa,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___es"
  }, {
    path: "/es/profile/personal-data",
    component: _69a4124c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___es"
  }, {
    path: "/es/profile/verification",
    component: _635925b0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___es"
  }, {
    path: "/es/steam/register",
    component: _3b1968ae,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___es"
  }, {
    path: "/es/store/cart",
    component: _7fb90435,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___es"
  }, {
    path: "/es/store/checkout",
    component: _57c2ccdb,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___es"
  }, {
    path: "/es/store/gc-checkout",
    component: _923ee5dc,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___es"
  }, {
    path: "/it/desktop/download",
    component: _349ef002,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___it"
  }, {
    path: "/it/desktop/howto",
    component: _f8eeb37e,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___it"
  }, {
    path: "/it/earn/referral",
    component: _61bcf5c6,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___it"
  }, {
    path: "/it/earn/stories",
    component: _8d3811fa,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___it"
  }, {
    path: "/it/earn/verification",
    component: _20a67c9b,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___it"
  }, {
    path: "/it/games/gamepix",
    component: _2c26a34b,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix___it"
  }, {
    path: "/it/info/about",
    component: _01c48093,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___it"
  }, {
    path: "/it/info/age-restrictions",
    component: _41b6fe9f,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___it"
  }, {
    path: "/it/info/anticheat",
    component: _70edf2b7,
    pathToRegexpOptions: {"strict":true},
    name: "info-anticheat___it"
  }, {
    path: "/it/info/coins",
    component: _27573e88,
    pathToRegexpOptions: {"strict":true},
    name: "info-coins___it"
  }, {
    path: "/it/info/contacts",
    component: _02f7a9bd,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___it"
  }, {
    path: "/it/info/cookie-policy",
    component: _6f62d67e,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___it"
  }, {
    path: "/it/info/g-cash",
    component: _3f2a47c3,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___it"
  }, {
    path: "/it/info/overwolf",
    component: _0c352110,
    pathToRegexpOptions: {"strict":true},
    name: "info-overwolf___it"
  }, {
    path: "/it/info/referral",
    component: _31c61007,
    pathToRegexpOptions: {"strict":true},
    name: "info-referral___it"
  }, {
    path: "/it/info/risk-disclosure",
    component: _4c0a3031,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___it"
  }, {
    path: "/it/info/tickets",
    component: _8d66d926,
    pathToRegexpOptions: {"strict":true},
    name: "info-tickets___it"
  }, {
    path: "/it/marketplace/add",
    component: _1b0fff46,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___it"
  }, {
    path: "/it/marketplace/add-one",
    component: _22cae6df,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add-one___it"
  }, {
    path: "/it/marketplace/become-seller",
    component: _2dae22aa,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___it"
  }, {
    path: "/it/profile/personal-data",
    component: _69a4124c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___it"
  }, {
    path: "/it/profile/verification",
    component: _635925b0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___it"
  }, {
    path: "/it/steam/register",
    component: _3b1968ae,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___it"
  }, {
    path: "/it/store/cart",
    component: _7fb90435,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___it"
  }, {
    path: "/it/store/checkout",
    component: _57c2ccdb,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___it"
  }, {
    path: "/it/store/gc-checkout",
    component: _923ee5dc,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___it"
  }, {
    path: "/kk/desktop/download",
    component: _349ef002,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___kk"
  }, {
    path: "/kk/desktop/howto",
    component: _f8eeb37e,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___kk"
  }, {
    path: "/kk/earn/referral",
    component: _61bcf5c6,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___kk"
  }, {
    path: "/kk/earn/stories",
    component: _8d3811fa,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___kk"
  }, {
    path: "/kk/earn/verification",
    component: _20a67c9b,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___kk"
  }, {
    path: "/kk/games/gamepix",
    component: _2c26a34b,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix___kk"
  }, {
    path: "/kk/info/about",
    component: _01c48093,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___kk"
  }, {
    path: "/kk/info/age-restrictions",
    component: _41b6fe9f,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___kk"
  }, {
    path: "/kk/info/anticheat",
    component: _70edf2b7,
    pathToRegexpOptions: {"strict":true},
    name: "info-anticheat___kk"
  }, {
    path: "/kk/info/coins",
    component: _27573e88,
    pathToRegexpOptions: {"strict":true},
    name: "info-coins___kk"
  }, {
    path: "/kk/info/contacts",
    component: _02f7a9bd,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___kk"
  }, {
    path: "/kk/info/cookie-policy",
    component: _6f62d67e,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___kk"
  }, {
    path: "/kk/info/g-cash",
    component: _3f2a47c3,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___kk"
  }, {
    path: "/kk/info/overwolf",
    component: _0c352110,
    pathToRegexpOptions: {"strict":true},
    name: "info-overwolf___kk"
  }, {
    path: "/kk/info/referral",
    component: _31c61007,
    pathToRegexpOptions: {"strict":true},
    name: "info-referral___kk"
  }, {
    path: "/kk/info/risk-disclosure",
    component: _4c0a3031,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___kk"
  }, {
    path: "/kk/info/tickets",
    component: _8d66d926,
    pathToRegexpOptions: {"strict":true},
    name: "info-tickets___kk"
  }, {
    path: "/kk/marketplace/add",
    component: _1b0fff46,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___kk"
  }, {
    path: "/kk/marketplace/add-one",
    component: _22cae6df,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add-one___kk"
  }, {
    path: "/kk/marketplace/become-seller",
    component: _2dae22aa,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___kk"
  }, {
    path: "/kk/profile/personal-data",
    component: _69a4124c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___kk"
  }, {
    path: "/kk/profile/verification",
    component: _635925b0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___kk"
  }, {
    path: "/kk/steam/register",
    component: _3b1968ae,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___kk"
  }, {
    path: "/kk/store/cart",
    component: _7fb90435,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___kk"
  }, {
    path: "/kk/store/checkout",
    component: _57c2ccdb,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___kk"
  }, {
    path: "/kk/store/gc-checkout",
    component: _923ee5dc,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___kk"
  }, {
    path: "/pt/desktop/download",
    component: _349ef002,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___pt"
  }, {
    path: "/pt/desktop/howto",
    component: _f8eeb37e,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___pt"
  }, {
    path: "/pt/earn/referral",
    component: _61bcf5c6,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___pt"
  }, {
    path: "/pt/earn/stories",
    component: _8d3811fa,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___pt"
  }, {
    path: "/pt/earn/verification",
    component: _20a67c9b,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___pt"
  }, {
    path: "/pt/games/gamepix",
    component: _2c26a34b,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix___pt"
  }, {
    path: "/pt/info/about",
    component: _01c48093,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___pt"
  }, {
    path: "/pt/info/age-restrictions",
    component: _41b6fe9f,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___pt"
  }, {
    path: "/pt/info/anticheat",
    component: _70edf2b7,
    pathToRegexpOptions: {"strict":true},
    name: "info-anticheat___pt"
  }, {
    path: "/pt/info/coins",
    component: _27573e88,
    pathToRegexpOptions: {"strict":true},
    name: "info-coins___pt"
  }, {
    path: "/pt/info/contacts",
    component: _02f7a9bd,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___pt"
  }, {
    path: "/pt/info/cookie-policy",
    component: _6f62d67e,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___pt"
  }, {
    path: "/pt/info/g-cash",
    component: _3f2a47c3,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___pt"
  }, {
    path: "/pt/info/overwolf",
    component: _0c352110,
    pathToRegexpOptions: {"strict":true},
    name: "info-overwolf___pt"
  }, {
    path: "/pt/info/referral",
    component: _31c61007,
    pathToRegexpOptions: {"strict":true},
    name: "info-referral___pt"
  }, {
    path: "/pt/info/risk-disclosure",
    component: _4c0a3031,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___pt"
  }, {
    path: "/pt/info/tickets",
    component: _8d66d926,
    pathToRegexpOptions: {"strict":true},
    name: "info-tickets___pt"
  }, {
    path: "/pt/marketplace/add",
    component: _1b0fff46,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___pt"
  }, {
    path: "/pt/marketplace/add-one",
    component: _22cae6df,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add-one___pt"
  }, {
    path: "/pt/marketplace/become-seller",
    component: _2dae22aa,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___pt"
  }, {
    path: "/pt/profile/personal-data",
    component: _69a4124c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___pt"
  }, {
    path: "/pt/profile/verification",
    component: _635925b0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___pt"
  }, {
    path: "/pt/steam/register",
    component: _3b1968ae,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___pt"
  }, {
    path: "/pt/store/cart",
    component: _7fb90435,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___pt"
  }, {
    path: "/pt/store/checkout",
    component: _57c2ccdb,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___pt"
  }, {
    path: "/pt/store/gc-checkout",
    component: _923ee5dc,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___pt"
  }, {
    path: "/de/games/gamepix/:name",
    component: _0518f226,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name___de"
  }, {
    path: "/de/marketplace/order/:id?",
    component: _55a60744,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___de"
  }, {
    path: "/de/marketplace/product/:id",
    component: _1360e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___de"
  }, {
    path: "/de/offer/ad/:partner?",
    component: _1d730e6b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___de"
  }, {
    path: "/de/user/id/:id?",
    component: _09fe653c,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___de"
  }, {
    path: "/es/games/gamepix/:name",
    component: _0518f226,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name___es"
  }, {
    path: "/es/marketplace/order/:id?",
    component: _55a60744,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___es"
  }, {
    path: "/es/marketplace/product/:id",
    component: _1360e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___es"
  }, {
    path: "/es/offer/ad/:partner?",
    component: _1d730e6b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___es"
  }, {
    path: "/es/user/id/:id?",
    component: _09fe653c,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___es"
  }, {
    path: "/it/games/gamepix/:name",
    component: _0518f226,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name___it"
  }, {
    path: "/it/marketplace/order/:id?",
    component: _55a60744,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___it"
  }, {
    path: "/it/marketplace/product/:id",
    component: _1360e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___it"
  }, {
    path: "/it/offer/ad/:partner?",
    component: _1d730e6b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___it"
  }, {
    path: "/it/user/id/:id?",
    component: _09fe653c,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___it"
  }, {
    path: "/kk/games/gamepix/:name",
    component: _0518f226,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name___kk"
  }, {
    path: "/kk/marketplace/order/:id?",
    component: _55a60744,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___kk"
  }, {
    path: "/kk/marketplace/product/:id",
    component: _1360e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___kk"
  }, {
    path: "/kk/offer/ad/:partner?",
    component: _1d730e6b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___kk"
  }, {
    path: "/kk/user/id/:id?",
    component: _09fe653c,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___kk"
  }, {
    path: "/pt/games/gamepix/:name",
    component: _0518f226,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name___pt"
  }, {
    path: "/pt/marketplace/order/:id?",
    component: _55a60744,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___pt"
  }, {
    path: "/pt/marketplace/product/:id",
    component: _1360e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___pt"
  }, {
    path: "/pt/offer/ad/:partner?",
    component: _1d730e6b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___pt"
  }, {
    path: "/pt/user/id/:id?",
    component: _09fe653c,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___pt"
  }, {
    path: "/de/marketplace/product/:id?/old",
    component: _52f9b452,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-old___de"
  }, {
    path: "/de/marketplace/product/:id?/payment",
    component: _33b09636,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___de"
  }, {
    path: "/es/marketplace/product/:id?/old",
    component: _52f9b452,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-old___es"
  }, {
    path: "/es/marketplace/product/:id?/payment",
    component: _33b09636,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___es"
  }, {
    path: "/it/marketplace/product/:id?/old",
    component: _52f9b452,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-old___it"
  }, {
    path: "/it/marketplace/product/:id?/payment",
    component: _33b09636,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___it"
  }, {
    path: "/kk/marketplace/product/:id?/old",
    component: _52f9b452,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-old___kk"
  }, {
    path: "/kk/marketplace/product/:id?/payment",
    component: _33b09636,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___kk"
  }, {
    path: "/pt/marketplace/product/:id?/old",
    component: _52f9b452,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-old___pt"
  }, {
    path: "/pt/marketplace/product/:id?/payment",
    component: _33b09636,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___pt"
  }, {
    path: "/de/games/gamepix/:name/:id",
    component: _6b2d73e4,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name-id___de"
  }, {
    path: "/es/games/gamepix/:name/:id",
    component: _6b2d73e4,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name-id___es"
  }, {
    path: "/it/games/gamepix/:name/:id",
    component: _6b2d73e4,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name-id___it"
  }, {
    path: "/kk/games/gamepix/:name/:id",
    component: _6b2d73e4,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name-id___kk"
  }, {
    path: "/pt/games/gamepix/:name/:id",
    component: _6b2d73e4,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name-id___pt"
  }, {
    path: "/de/marketplace/:app",
    component: _59abe8d0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___de"
  }, {
    path: "/de/missions/:name",
    component: _73def2e9,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___de"
  }, {
    path: "/de/offer/:id?",
    component: _3d4a83b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___de"
  }, {
    path: "/de/p/:alias?",
    component: _81816192,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___de"
  }, {
    path: "/de/product/:id?",
    component: _8db419fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___de"
  }, {
    path: "/de/store/:tab",
    component: _a16aa62a,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___de"
  }, {
    path: "/de/t/:alias?",
    component: _43f4369a,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___de"
  }, {
    path: "/de/user/:user",
    component: _3d12320d,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___de"
  }, {
    path: "/es/marketplace/:app",
    component: _59abe8d0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___es"
  }, {
    path: "/es/missions/:name",
    component: _73def2e9,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___es"
  }, {
    path: "/es/offer/:id?",
    component: _3d4a83b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___es"
  }, {
    path: "/es/p/:alias?",
    component: _81816192,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___es"
  }, {
    path: "/es/product/:id?",
    component: _8db419fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___es"
  }, {
    path: "/es/store/:tab",
    component: _a16aa62a,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___es"
  }, {
    path: "/es/t/:alias?",
    component: _43f4369a,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___es"
  }, {
    path: "/es/user/:user",
    component: _3d12320d,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___es"
  }, {
    path: "/games/gamepix/:name",
    component: _0518f226,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name___en-US"
  }, {
    path: "/it/marketplace/:app",
    component: _59abe8d0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___it"
  }, {
    path: "/it/missions/:name",
    component: _73def2e9,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___it"
  }, {
    path: "/it/offer/:id?",
    component: _3d4a83b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___it"
  }, {
    path: "/it/p/:alias?",
    component: _81816192,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___it"
  }, {
    path: "/it/product/:id?",
    component: _8db419fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___it"
  }, {
    path: "/it/store/:tab",
    component: _a16aa62a,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___it"
  }, {
    path: "/it/t/:alias?",
    component: _43f4369a,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___it"
  }, {
    path: "/it/user/:user",
    component: _3d12320d,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___it"
  }, {
    path: "/kk/marketplace/:app",
    component: _59abe8d0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___kk"
  }, {
    path: "/kk/missions/:name",
    component: _73def2e9,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___kk"
  }, {
    path: "/kk/offer/:id?",
    component: _3d4a83b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___kk"
  }, {
    path: "/kk/p/:alias?",
    component: _81816192,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___kk"
  }, {
    path: "/kk/product/:id?",
    component: _8db419fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___kk"
  }, {
    path: "/kk/store/:tab",
    component: _a16aa62a,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___kk"
  }, {
    path: "/kk/t/:alias?",
    component: _43f4369a,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___kk"
  }, {
    path: "/kk/user/:user",
    component: _3d12320d,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___kk"
  }, {
    path: "/marketplace/order/:id?",
    component: _55a60744,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___en-US"
  }, {
    path: "/marketplace/product/:id",
    component: _1360e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___en-US"
  }, {
    path: "/offer/ad/:partner?",
    component: _1d730e6b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___en-US"
  }, {
    path: "/pt/marketplace/:app",
    component: _59abe8d0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___pt"
  }, {
    path: "/pt/missions/:name",
    component: _73def2e9,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___pt"
  }, {
    path: "/pt/offer/:id?",
    component: _3d4a83b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___pt"
  }, {
    path: "/pt/p/:alias?",
    component: _81816192,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___pt"
  }, {
    path: "/pt/product/:id?",
    component: _8db419fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___pt"
  }, {
    path: "/pt/store/:tab",
    component: _a16aa62a,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___pt"
  }, {
    path: "/pt/t/:alias?",
    component: _43f4369a,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___pt"
  }, {
    path: "/pt/user/:user",
    component: _3d12320d,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___pt"
  }, {
    path: "/user/id/:id?",
    component: _09fe653c,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___en-US"
  }, {
    path: "/marketplace/product/:id?/old",
    component: _52f9b452,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-old___en-US"
  }, {
    path: "/marketplace/product/:id?/payment",
    component: _33b09636,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___en-US"
  }, {
    path: "/de/marketplace/:app?/:category",
    component: _1ac1644a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___de"
  }, {
    path: "/es/marketplace/:app?/:category",
    component: _1ac1644a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___es"
  }, {
    path: "/games/gamepix/:name/:id",
    component: _6b2d73e4,
    pathToRegexpOptions: {"strict":true},
    name: "games-gamepix-name-id___en-US"
  }, {
    path: "/it/marketplace/:app?/:category",
    component: _1ac1644a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___it"
  }, {
    path: "/kk/marketplace/:app?/:category",
    component: _1ac1644a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___kk"
  }, {
    path: "/pt/marketplace/:app?/:category",
    component: _1ac1644a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___pt"
  }, {
    path: "/de/:game",
    component: _e3ad2906,
    pathToRegexpOptions: {"strict":true},
    name: "game___de"
  }, {
    path: "/es/:game",
    component: _e3ad2906,
    pathToRegexpOptions: {"strict":true},
    name: "game___es"
  }, {
    path: "/it/:game",
    component: _e3ad2906,
    pathToRegexpOptions: {"strict":true},
    name: "game___it"
  }, {
    path: "/kk/:game",
    component: _e3ad2906,
    pathToRegexpOptions: {"strict":true},
    name: "game___kk"
  }, {
    path: "/marketplace/:app",
    component: _59abe8d0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___en-US"
  }, {
    path: "/missions/:name",
    component: _73def2e9,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___en-US"
  }, {
    path: "/offer/:id?",
    component: _3d4a83b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___en-US"
  }, {
    path: "/p/:alias?",
    component: _81816192,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___en-US"
  }, {
    path: "/product/:id?",
    component: _8db419fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___en-US"
  }, {
    path: "/pt/:game",
    component: _e3ad2906,
    pathToRegexpOptions: {"strict":true},
    name: "game___pt"
  }, {
    path: "/store/:tab",
    component: _a16aa62a,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___en-US"
  }, {
    path: "/t/:alias?",
    component: _43f4369a,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___en-US"
  }, {
    path: "/user/:user",
    component: _3d12320d,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___en-US"
  }, {
    path: "/de/:game/duels",
    component: _56131ea9,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___de"
  }, {
    path: "/de/:game/phased-tournaments",
    component: _5b84e5ce,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___de"
  }, {
    path: "/de/:game/tournaments",
    component: _537dfa50,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___de"
  }, {
    path: "/es/:game/duels",
    component: _56131ea9,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___es"
  }, {
    path: "/es/:game/phased-tournaments",
    component: _5b84e5ce,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___es"
  }, {
    path: "/es/:game/tournaments",
    component: _537dfa50,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___es"
  }, {
    path: "/it/:game/duels",
    component: _56131ea9,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___it"
  }, {
    path: "/it/:game/phased-tournaments",
    component: _5b84e5ce,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___it"
  }, {
    path: "/it/:game/tournaments",
    component: _537dfa50,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___it"
  }, {
    path: "/kk/:game/duels",
    component: _56131ea9,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___kk"
  }, {
    path: "/kk/:game/phased-tournaments",
    component: _5b84e5ce,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___kk"
  }, {
    path: "/kk/:game/tournaments",
    component: _537dfa50,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___kk"
  }, {
    path: "/pt/:game/duels",
    component: _56131ea9,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___pt"
  }, {
    path: "/pt/:game/phased-tournaments",
    component: _5b84e5ce,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___pt"
  }, {
    path: "/pt/:game/tournaments",
    component: _537dfa50,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___pt"
  }, {
    path: "/de/:game/tournaments/new/:id?",
    component: _26d10a5e,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___de"
  }, {
    path: "/es/:game/tournaments/new/:id?",
    component: _26d10a5e,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___es"
  }, {
    path: "/it/:game/tournaments/new/:id?",
    component: _26d10a5e,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___it"
  }, {
    path: "/kk/:game/tournaments/new/:id?",
    component: _26d10a5e,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___kk"
  }, {
    path: "/pt/:game/tournaments/new/:id?",
    component: _26d10a5e,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___pt"
  }, {
    path: "/de/:game/duels/:id",
    component: _8c0a915e,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___de"
  }, {
    path: "/de/:game/phased-tournaments/:id?",
    component: _91e3f894,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___de"
  }, {
    path: "/de/:game/tournaments/:id",
    component: _113c4980,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___de"
  }, {
    path: "/es/:game/duels/:id",
    component: _8c0a915e,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___es"
  }, {
    path: "/es/:game/phased-tournaments/:id?",
    component: _91e3f894,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___es"
  }, {
    path: "/es/:game/tournaments/:id",
    component: _113c4980,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___es"
  }, {
    path: "/it/:game/duels/:id",
    component: _8c0a915e,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___it"
  }, {
    path: "/it/:game/phased-tournaments/:id?",
    component: _91e3f894,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___it"
  }, {
    path: "/it/:game/tournaments/:id",
    component: _113c4980,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___it"
  }, {
    path: "/kk/:game/duels/:id",
    component: _8c0a915e,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___kk"
  }, {
    path: "/kk/:game/phased-tournaments/:id?",
    component: _91e3f894,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___kk"
  }, {
    path: "/kk/:game/tournaments/:id",
    component: _113c4980,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___kk"
  }, {
    path: "/pt/:game/duels/:id",
    component: _8c0a915e,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___pt"
  }, {
    path: "/pt/:game/phased-tournaments/:id?",
    component: _91e3f894,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___pt"
  }, {
    path: "/pt/:game/tournaments/:id",
    component: _113c4980,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___pt"
  }, {
    path: "/marketplace/:app?/:category",
    component: _1ac1644a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___en-US"
  }, {
    path: "/",
    component: _5b2ee3fb,
    pathToRegexpOptions: {"strict":true},
    name: "index___en-US"
  }, {
    path: "/:game",
    component: _e3ad2906,
    pathToRegexpOptions: {"strict":true},
    name: "game___en-US"
  }, {
    path: "/:game/duels",
    component: _56131ea9,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___en-US"
  }, {
    path: "/:game/phased-tournaments",
    component: _5b84e5ce,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___en-US"
  }, {
    path: "/:game/tournaments",
    component: _537dfa50,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___en-US"
  }, {
    path: "/:game/tournaments/new/:id?",
    component: _26d10a5e,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___en-US"
  }, {
    path: "/:game/duels/:id",
    component: _8c0a915e,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___en-US"
  }, {
    path: "/:game/phased-tournaments/:id?",
    component: _91e3f894,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___en-US"
  }, {
    path: "/:game/tournaments/:id",
    component: _113c4980,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___en-US"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
