<template lang="pug">
.avatar-with-stars
  //.avatar-with-stars__stars
  //  .avatar-with-stars__star(v-for="i in 5" :key="i")
  //    InlineSvg(:src="require('~/assets/img/star.svg')" :class="getStarClass(i)")
  img.avatar-with-stars__img(v-if="src" :src="src" alt="avatar")
  .avatar-with-stars__img(v-else)
</template>

<script>
import UsersService from '@/services/UsersService'

export default {
  name: 'AvatarWithStars',

  props: {
    src: {
      type: String,
      default: undefined
    },

    userId: {
      type: Number,
      default: undefined
    }
  },

  data () {
    return {
      stars: []
    }
  },

  computed: {
    starsCount () {
      return this.stars.length
    }
  },

  mounted () {
    this.loadUserStatus()
  },

  methods: {
    loadUserStatus () {
      if (this.userId) {
        UsersService.getUserStatus(this.$axios, this.userId).then((response) => {
          this.stars = response.data?.data
        })
      }
    },

    getStarClass (i) {
      return {
        'avatar-with-stars__star-img': true,
        'avatar-with-stars__star-img--grey': i > this.starsCount
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-with-stars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__img {
    width: 36px;
    max-width: 36px;
    height: 36px;
    max-height: 36px;
    border-radius: 50%;
    background-color: $blue-grey30;
  }

  &__stars {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 1px;
    align-items: center;
    cursor: pointer;
  }

  &__star {
    &-img {
      width: 8px;
      height: 8px;

      &--grey {
        ::v-deep path {
          fill: $blue-grey50;
        }
      }
    }

    &:nth-child(1) {
      margin-bottom: -20px;
    }

    &:nth-child(2) {
      margin-bottom: -7px;
    }

    &:nth-child(3) {
      margin-bottom: 2px;
      margin-right: 3px;
      margin-left: 3px;
    }

    &:nth-child(4) {
      margin-bottom: -7px;
    }

    &:nth-child(5) {
      margin-bottom: -20px;
    }
  }
}
</style>
