<template lang="pug">
  aside.sidebar
    .sidebar__body
      span.sidebar__title(v-t="'games'")
      .sidebar__body-scroll
        NuxtLink.sidebar__link(
          v-for="(game, index) in linkedList"
          :key="`game_${index}`"
          :to="localeLocation(game.mainPageLink)"
          :title="game.extra_data.title"
        )
          .sidebar__link-body
            .sidebar__link-icon
              img(v-if="game.extra_data.icon" :src="game.extra_data.icon", :alt="game.name")
          span.sidebar__link-subtext {{ game.extra_data.title }}

        NuxtLink.sidebar__link(
          v-for="(game, index) in otherList"
          :key="'other' + index"
          :to="localeLocation(game.mainPageLink)"
          :title="game.extra_data.title"
        )
          .sidebar__link-body
            .sidebar__link-icon
              img(v-if="game.extra_data.icon" :src="game.extra_data.icon", :alt="game.name")
          span.sidebar__link-subtext {{ game.extra_data.title }}

      span.sidebar__title(v-t="'offerwalls'")
      .sidebar__body-scroll
        NuxtLink.sidebar__link.sidebar__link--offerwall(
          v-for="(offerwall, index) in missionsGroups"
          :key="`offerwall_${index}`"
          :to="localeLocation(`/missions/${offerwall.name}`)"
          :title="offerwall.title"
        )
          .sidebar__link-body.sidebar__link-body--offerwall(v-if="offerwall.id === 100000")
            .sidebar__link-icon.sidebar__link-icon--offerwall
              img(v-if="offerwall.icon" :src="offerwall.icon", :alt="offerwall.title")
          template(v-else)
            .sidebar__link-body.sidebar__link-body--offerwall.sidebar__link-body--offerwall-tablet
              .sidebar__link-icon.sidebar__link-icon--offerwall
                img(v-if="offerwall.iconShort" :src="offerwall.iconShort", :alt="offerwall.title")
            span.sidebar__link-subtext.sidebar__link-subtext--offerwall {{ offerwall.title }}

      a.sidebar__link.sidebar__support-button(ref="toggler" @click.stop="toggleMenu")
        .sidebar__link-body
          .sidebar__link-icon(:title="supportButtonText")
            InlineSvg(:src="require('~/assets/img/icons/question-mark-yellow.svg')" :alt="$t('userSupportCenter')")

    transition(name="slide-down")
      .sidebar__menu(v-if="isMenuVisible" ref="menu")
        .sidebar__menu-list
          .sidebar__menu-list-item(v-support-button @click="toggleMenu")
            InlineSvg(:src="require('~/assets/img/icons/chat.svg')")
            | {{ $t('askSupport') }}

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SocialLinks from '~/components/SocialLinks/SocialLinks'
import OfferwallService from '~/services/OfferwallService'

export default {
  name: 'Sidebar',
  components: { SocialLinks },
  data () {
    return {
      isOpened: false,
      isMenuVisible: false
    }
  },

  computed: {
    ...mapState('games', ['games']),
    ...mapGetters('viewport', ['isHeaderMobile']),
    ...mapGetters('user', ['gameAccount', 'isAuthorized', 'isAdmin']),

    list () {
      return this.games.filter(game => game.is_enabled).filter(game => game.event_count)
    },

    linkedList () {
      return this.list.filter(game => this.gameAccount(game.name))
    },

    missionsGroups () {
      return [
        {
          id: 100000,
          title: '',
          name: '',
          subtitle: '',
          icon: 'https://cdn.gaming-goods.com/images/other/missions/all.png',
          isOfferWall: false,
          offerWallLink: () => ''
        },
        OfferwallService.getOfferwallGroup(this.$axios, this.user?.id).find(item => item.id === 1),
        ...OfferwallService.getOfferwallGroup(this.$axios, this.user?.id, undefined, this.isAdmin).filter(item => item.id !== 1)
      ]
    },

    otherList () {
      return this.list.filter(game => !this.gameAccount(game.name)).filter(game => game.is_tournament_enabled)
    },

    supportButtonText () {
      return this.isHeaderMobile ? this.$t('help') : this.$t('supportService')
    }
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    toggleMenu () {
      this.isMenuVisible = !this.isMenuVisible
    },

    toggle () {
      this.isOpened = !this.isOpened
    },

    onClickOutside ({ target }) {
      if (this.isOpened && !this.$el.contains(target)) {
        this.toggle(false)
      }

      if (this.isMenuVisible && !this.$refs.menu.contains(target)) {
        this.isMenuVisible = false
      }
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  display: none;

  @media (min-width: $breakpointTablet) {
    position: absolute;
    top: 64px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include scroll-none;

    width: 72px;
    height: 100vh;
    padding: 8px 8px 128px;
    border-right: 1px solid $blue-grey50;
    transition: width 0.3s $easing, top 0.2s $easing, height 0s;
    background: $blue-grey90;
    z-index: -1;
    will-change: auto;
    margin-bottom: 64px;
    overflow-y: auto;
  }

  @media (min-width: $breakpointDesktopMedium) {
    width: 125px;
  }

  &__title {
    display: block;
    margin: 8px auto;
    width: max-content;
    max-width: 120px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $white;

    @media (min-width: $breakpointDesktopMedium) {
      font-size: 14px;
    }
  }

  &__menu {
    background: $blue-grey70;
    padding: 16px 20px 20px;
    border: 1px solid $blue-grey40;
    position: fixed;
    bottom: 63px;
    left: 8px;
    border-radius: 8px;
    color: #fff;
    width: 240px;
    z-index: -1;

    .social-links__social-item,
    &-list-item {
      @include hint;

      margin-bottom: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;

        path {
          fill: #9bafca;
          transition: all 0.2s $easing;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &-list {
      &-title {
        @include caption;

        color: $blue-grey10;
        margin-bottom: 12px;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content 1fr max-content;
    overflow: hidden;

    &-scroll {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 16px;
      max-height: 40vh;
      scrollbar-width: thin;
      scrollbar-color: $blue-grey70 $white;
      border-bottom: 2px dashed $blue-grey40;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  &__hide-btn-close {
    transform-origin: center;
    transform: scale(1);
    transition: 0.3s $easing;
    position: absolute;
  }

  &__hide-btn-open {
    display: flex;
    align-items: center;
    transition: 0.3s $easing;
    transform: translateX(20px) scale(0);
    transform-origin: left;
  }

  &__hide-btn {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: 0.3s $easing;
  }

  &__hide-btn-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hide-btn-text {
    @include heading7;

    color: $white;
    margin-left: 24px;
    transition: 0.3s $easing;
    transform: translateX(-30px);
  }

  &__link.nuxt-link-exact-active.nuxt-link-active {
    background-color: #1e88e5;
  }

  &__link {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 8px;
    transition: 0.3s $easing;
    background: $blue-grey80;
    position: relative;
    cursor: pointer;

    @media (min-width: $breakpointDesktopMedium) {
      width: 100%;
    }

    &-subtext {
      display: none;

      @media (min-width: $breakpointDesktopMedium) {
        display: block;
        width: 100%;
        text-align: left;
        padding-left: 9px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $white;
      }

      &--offerwall {
        @media (min-width: $breakpointDesktopMedium) {
          padding: 6px 10px;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.2px;
          text-align: center;
          color: $white;
        }
      }
    }

    &--offerwall {
      width: 100%;
    }

    &-icon {
      width: 24px;
      height: auto;
      min-width: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &--offerwall {
        width: 100%;
        height: 16px;

        img {
          width: 90% !important;
          object-fit: contain !important;
        }
      }

      svg,
      path,
      rect {
        transition: 0.3s $easing;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name {
      @include heading7;

      color: $blue-grey10;
      margin-left: 12px;

      //min-width: 132px;
      //width: 132px;
      transition: 0.3s $easing;
      opacity: 0;
    }

    &-btn-icon {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: flex-start;

      svg,
      path,
      rect {
        transition: 0.3s $easing;
      }
    }

    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      transition: 0.3s $easing;
      border-radius: 8px;
      padding: 0;
      width: 36px;

      &--offerwall {
        padding: 2px 0;
        height: 24px;
        width: 100%;

        @media (min-width: $breakpointDesktopMedium) {
          padding: 20px 0;
        }

        &-tablet {
          display: block;

          @media (min-width: $breakpointDesktopMedium) {
            display: none;
          }
        }
      }
    }

    &-btn {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      margin-right: 8px;
      transition: 0.3s $easing;
      cursor: pointer;
      position: absolute;
      border-radius: 8px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      z-index: -1;
    }

    &.sidebar__support-button {
      position: fixed;
      bottom: 0;
      width: 48px;
      z-index: 2;
      background: #3949ab;

      @media (min-width: $breakpointDesktopMedium) {
        width: 104px;
      }

      &.--highlight {
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: -4px;
          top: -4px;
          right: -4px;
          bottom: -4px;
          pointer-events: none;
          border: 2px solid #3949ab;
          border-radius: 8px;
        }
      }

      svg {
        path:first-child {
          fill: #fff;
          opacity: 1;
        }

        path:last-child {
          fill: #3949ab;
          opacity: 1;
        }
      }
    }
  }

  &__link-body:hover {
    background: $blue-grey60;

    .sidebar__link {
      &-name {
        color: $primary40;
      }

      &-icon {
        svg,
        path,
        rect {
          fill: $primary60;
        }
      }
    }

    &-btn:hover {
      .sidebar__link {
        &-btn-icon {
          svg,
          path,
          rect {
            fill: $primary60;
          }
        }
      }

      background: $blue-grey70;
    }

    &-btn ~ .sidebar__link-drop {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
