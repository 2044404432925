<template lang="pug">
  .modal-message
    .modal-message__header
      InlineSvg.modal__image(
        v-if="image.src"
        :src="image.src"
      )
      p.modal-message__header-text {{ title || $t('error') }}

    .modal-message__body
      .modal-message__text(v-html="text")
      .modal-message__promocode(v-if="promocode") {{ promocode }}

    .modal-message__footer
      Component.modal-message__footer-button(
        v-for="(button, index) in modalButtons"
        :is="button.component || 'Button'"
        :key="index"
        v-bind="button.props"
        @click="onAction(button)"
      )
        | {{ button.title }}

</template>

<script>
import HelpButton from '~/components/HelpButton'

export default {
  name: 'ModalMessage',

  components: {
    HelpButton
  },

  props: {
    type: {
      type: String,
      default: 'error'
    },

    buttons: {
      type: Array,
      default: () => []
    },

    text: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    promocode: {
      type: String,
      default: ''
    },

    image: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {}
  },

  computed: {
    typeIsError () {
      return this.type === 'error'
    },

    modalButtons () {
      if (this.buttons.length) {
        return this.buttons
          .filter(button => button)
          .map((button) => {
            if (!button.props) {
              button.props = { isPrimary: true }
            }

            return button
          })
      }

      if (this.type === 'error') {
        return [
          {
            props: { isPrimary: true },
            title: this.$t('editProfile'),
            action: () => {
              this.$router.push(this.localeLocation('/profile/account'))
            }
          }
        ]
      } else {
        return [{ props: { isPrimary: true }, title: this.$t('buttonOk') }]
      }
    }
  },

  methods: {
    closeModal () {
      this.$store.commit('modal/toggle')
    },

    onAction ({ action, shouldClose = true }) {
      action?.()

      if (shouldClose) {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalmessagenewdesign {
  padding-bottom: 0;
  max-width: 408px;

  .modal__footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
  }

  .info-message {
    margin: 24px 0;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .button {
    margin: 4px 0;
  }

  .button + .button {
    margin-left: 16px;
  }

  .modal__image {
    width: 100%;
    margin-bottom: 24px;
  }

  @media (max-width: $breakpointMobile) {
    .modal__footer {
      flex-direction: column;
      align-items: flex-end;

      .button {
        width: 100%;
      }

      .button + .button {
        margin: 8px 0 0;
      }
    }
  }
}

.modal-message {
  &__header {
    &-text {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      color: $white;
      text-align: center;
    }
  }

  &__text {
      margin: 8px auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $blue-grey05;
      text-align: center;
      max-width: 170px;
    }

  &__promocode {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    background: $blue-grey80;
    border-radius: 8px;
    color: $white;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__footer {
    margin-top: 20px;
    margin-bottom: 24px;
    width: 100%;

    &-button {
      width: 100% !important;
    }
  }
}
</style>
