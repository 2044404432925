<template lang="pug">
  .modal-quality-check
    .modal-quality-check__header
      //a.modal-quality-check__close(@click.prevent="close")
      //  InlineSvg(:src="require('~/assets/img/icons/close.svg')")
    .modal-quality-check__body
      .modal-quality-check__icon
        img.modal-quality-check__icon-img(:src="iconUrl" alt="icon")

      p.modal-quality-check__title {{ $t(title, { percent: 0 }) }}

      p.modal-quality-check__text(v-if="step < 2" v-t="text")

      .modal-quality-check__description(v-else)
        p.modal-quality-check__violations-header(v-if="uuid") UUID: {{ uuid }}
        p.modal-quality-check__violations-header(v-if="fingerprint") Fingerprint: {{ fingerprint }}
        p.modal-quality-check__violations-header(v-if="reason && reason !== 'OK'") {{ $t('violations') }}:

        ul.modal-quality-check__ul(v-if="reason && reason !== 'OK'")
          li.modal-quality-check__li {{ reason }}

      .modal-quality-check__buttons
        template(v-if="step === 0")
          Button.modal-quality-check__button(is-primary @click="check") {{ $t('qualityCheckModalButtonCheck') }}
          //Button.modal-quality-check__button(is-secondary @click="close") {{ $t('skip') }}
        template(v-else-if="step === 1")
          Button.modal-quality-check__button(is-primary @click="close") {{ $t('qualityCheckModalButtonContinue') }}
        template(v-else-if="step === 2")
          Button.modal-quality-check__button(is-primary @click="close") {{ $t('qualityCheckModalButtonContinue') }}
          //Button.modal-quality-check__button(is-secondary) {{ $t('qualityCheckModalButtonCancel') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CheckPermissionsService from '@/services/CheckPermissionsService'
import { create24MetricsScript } from '~/utils/24metrics'

export default {
  name: 'ModalQualityCheck',

  data () {
    return {
      step: 0,
      times: 0,
      percent: '___',
      points: {},
      reason: undefined,
      transactionId: undefined,
      fingerprint: undefined,
      uuid: undefined
    }
  },

  computed: {
    ...mapState('user', ['user']),

    iconUrl () {
      let url = ''
      switch (this.step) {
        case 0:
          url = 'https://cdn.gaming-goods.com/images/other/quality-check/quality-check-modal-1.png'
          break
        case 1:
          url = 'https://cdn.gaming-goods.com/images/other/quality-check/quality-check-modal-2.png'
          break
        case 2:
          url = 'https://cdn.gaming-goods.com/images/other/quality-check/quality-check-modal-3.png'
          break
      }
      return url
    },

    title () {
      let title = ''
      switch (this.step) {
        case 0:
          title = 'qualityCheckModalTitle0'
          break
        case 1:
          title = 'qualityCheckModalTitle1'
          break
        case 2:
          title = 'qualityCheckModalTitle2'
          break
      }
      return title
    },

    text () {
      let text = ''
      switch (this.step) {
        case 0:
          text = 'qualityCheckModalText0'
          break
        case 1:
          text = 'qualityCheckModalText1'
          break
      }
      return text
    }
  },

  mounted () {
    const date = new Date().getTime()
    create24MetricsScript(this.$el, this.$cookiz, this.$axios, { id: `v_offer_${date}`, source: 'V-Offers' }, this.user?.id, 'check-offer-result', `v${this.user?.id}_${date}`, (a, b) => {
      this.fingerprint = a
      this.uuid = b
    })
  },

  methods: {
    ...mapMutations('modal', ['toggle']),
    ...mapActions('modal', ['showModal']),

    async checkResult () {
      const { data } = await CheckPermissionsService.checkResultUserPaymentChance(this.$axios, this.transactionId)

      this.reason = data.data?.result

      if (!this.reason && this.times < 3) {
        this.times++
        setTimeout(() => {
          this.checkResult()
        }, 30000)
        return
      }

      if (this.reason === 'OK') {
        this.step = 1
      } else {
        this.step = 2
      }
      this.$nuxt.$loading.finish()
    },

    async check () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await CheckPermissionsService.checkUserPaymentChance(this.$axios, this.fingerprint, this.uuid)

        this.transactionId = data.data?.transaction

        setTimeout(() => {
          this.checkResult()
        }, 30000)
      } catch (e) {
        console.log(e)
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            text: this.$t('errorCode22')
          }
        })
        this.$nuxt.$loading.finish()
      }
    },

    close () {
      this.toggle()
    }
  }
}
</script>

<style>
.modal.modal--modalqualitycheck {
  padding-bottom: 0;
  max-width: 488px;
}
</style>

<style lang="scss" scoped>
.modal-quality-check {
  padding-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__close {
    border-radius: 50%;
    padding: 10px;
    background: $blue-grey60;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $white;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__text {
    margin-top: 16px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $blue-grey05;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      margin-top: 24px;
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }

  &__button {
    width: 100%;
  }

  &__description {
    margin-top: 64px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $white;
  }

  &__li {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $blue-grey05;
    margin-top: 8px;
    list-style-type: disc;
    margin-left: 24px;
  }
}
</style>
