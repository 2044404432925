<template lang="pug">
  header.header-wrapper.is-sticky
    .header(:class="{'--mini': shouldMinimize}")
      NuxtLink.main-link(:to='localeLocation("/")')
        .header__logo-box
          InlineSvg.header__logo(:src="require('~/assets/img/new-logo.svg')")
          InlineSvg.header__logo-big(:src="require('~/assets/img/VVV-logo.svg')")
          span.header__product-name Gaming-Goods

      .header__menu
        .header__actbtns.header__actbtns--desktop
          NuxtLink.header__act-link-exact(@click="clickMarketplaceEvent" :to="localeLocation('/')")
            span {{ $t('marketplace') }}
          NuxtLink.header__act-link(:to="localeLocation('/missions')")
            span {{ $t('earn') }}
          NuxtLink.header__act-link(:to="localeLocation('/games')")
            span {{ $t('play') }}

          //NuxtLink.header__act-link(:to="localeLocation('/bonus')")
          //  span VVV Bonus

      client-only
        .header__ui-box
          .header__currency-wrap(v-if="isAuthorized")
            .header__currency-box.header__money-box(@click="onCurrencyClick")
              .header__value-wrap(:title="'EUR: ' + canBeWithdrawnAmount")
                Currency(type="money" real)
                  | {{ +canBeWithdrawnAmount < 1000 ? canBeWithdrawnAmount : ((+canBeWithdrawnAmount / 1000).toFixed(1) + 'K') }}

            .header__currency-box.header__money-box(@click="onCurrencyClick")
              .header__value-wrap(:title="'G-Cash: ' + gBalanceRounded")
                Currency(type="money")
                  | {{ +gBalanceRounded < 1000 ? gBalanceRounded : ((+gBalanceRounded / 1000).toFixed(1) + 'K') }}

            //.header__currency-box.header__coin-box(
            //  :class="{'--increment': isCoinsIncrement, '--decrement': isCoinsDecrement}"
            //  @click="onCurrencyClick"
            //)
            //  .header__value-wrap(:title="$t('coins') + ': ' + user.coins.toFixed(0)")
            //    Currency
            //      | {{ user.coins < 1000 ? user.coins.toFixed(0) : user.coins < 1000000 ? ((user.coins / 1000).toFixed(1) + 'K') : ((user.coins / 1000000).toFixed(1) + 'M') }}

          .header__fav-wrap(v-if="isAuthorized")

            NuxtLink.header__notification-box.header__ico-box(:to="localeLocation('/profile/favourites')")
              InlineSvg.header__not-ico--small(:src="require('~/assets/img/icons/heart.svg')")

          .header__fav-wrap(v-if="isAuthorized")

            .header__notification-box.header__ico-box(@click.stop="toggleNotifications")
              InlineSvg.header__not-ico(:src="require('./img/notifications.svg')")
              transition(name="fade-scale" duration="200")
                span.header__notification-counter(v-if="unreadCount") {{ unreadCount }}

          .header__lang(v-if="!isAuthorized" @click.prevent.stop="toggleLang")
            img(
              :src="require(`~/assets/img/country/${flagPath}.svg`)"
              alt="language"
              width="20"
              height="20"
            )

          .header__auth-mob(v-if="!isAuthorized" @click="onSignInClick")
            img(
              :src="require(`~/assets/img/icons/sign-in.svg`)"
              alt="language"
              width="30"
              height="30"
            )

          .header__auth-box(v-if="!isAuthorized")
            Button.header__sign-in(is-primary icon="sign-in" @click.native="onSignInClick") {{ $t('authButton') }}

          .header__profile-box(v-if="isAuthorized" @click.stop="toggleMenu")
            .header__profile-pic-wrap
              img.header__profile-pic(:src="user.avatar", alt="user.full_name" :style="frameColor ? { 'border': `2px solid ${frameColor}` } : {}")
            span.header__user-name(v-if="!this.isHeaderMobile") {{ user.full_name }}
            img(v-if="!this.isHeaderMobile" src="./img/ar-sm-d.svg" :class="{ '--open': isMenuVisible }" alt="arrow")
            .header__profile-box__dot(v-if="newMessagesLength") {{ newMessagesLength }}

          transition(name="slide-up")
            HeaderLang(v-if="isLangVisible" @close="toggleLang" @back="toggleMenu")

          transition(name="slide-up")
            HeaderCurrency(v-if="isCurrencyVisible" @close="toggleCurrency" @back="toggleMenu")

          template(v-if="isAuthorized")
            transition(name="slide-up")
              HeaderMenu(v-if="isMenuVisible" @close="toggleMenu" @lang="toggleLang" @currency="toggleCurrency")

            transition(name="slide-up")
              NotificationsList(v-if="isNotificationsVisible" @close="toggleNotifications")

            NewNotification

    // Sidebar(v-if="!isHeaderMobile" :class="{'--header-mini': shouldMinimize}")
</template>

<script>
import { throttle } from 'throttle-debounce'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import HeaderMenu from './HeaderMenu'
import NotificationsList from './NotificationsList'
import SocialLinks from '~/components/SocialLinks/SocialLinks'
import NewNotification from '~/components/Header/NewNotification'
import Wallet from '~/components/Header/Wallet'
// import Sidebar from '~/components/Sidebar/Sidebar'
import HeaderLang from '~/components/Header/HeaderLang'
import HeaderCurrency from '~/components/Header/HeaderCurrency'
import PendingRewardsService from '~/services/PendingRewardsService'

let animationFrameId = null

export default {
  name: 'Header',

  jsonld () {
    if (['index', 'info-contacts'].includes(this.$route?.name?.split('___')?.[0])) {
      return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': this.$config.WWW_HOST,
        url: this.$config.WWW_HOST,
        email: 'tech.support@gaming-goods.com',
        logo: `${this.$config.WWW_HOST}/icon.png`,
        name: 'Gaming-Goods',
        legalName: 'Solian Enterprises Limited',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Limassol',
          addressCountry: 'CY',
          postalCode: 3031,
          streetAddress: 'Emmanouil road 44,  KIRZIS CENTER',
          telephone: '+35780080579'
        },
        telephone: '+35780080579',
        description: this.$t('platformDescription'),
        location: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 35.1829199,
            longitude: 33.3401537
          }
        },
        sameAs: [
          'https://www.instagram.com/gaming_goods_com/',
          'https://www.youtube.com/channel/UCzSe4KZ0i0X4pey1gbShbfw',
          'https://twitter.com/gaming_goods',
          'https://www.facebook.com/gaming_goods',
          'https://t.me/gaming_goods_com_marketplace'
        ]
      }
    }

    return {}
  },

  components: {
    // Sidebar,
    Wallet,
    NewNotification,
    SocialLinks,
    HeaderMenu,
    HeaderLang,
    HeaderCurrency,
    NotificationsList
  },

  data () {
    return {
      isMenuVisible: false,
      isWalletVisible: false,
      isLangVisible: false,
      isCurrencyVisible: false,
      isMinimized: false,
      isCommunitiesVisible: false,

      trophyAmountWeek: 0,
      trophyAmount: 0,

      isCoinsIncrement: false,
      isCoinsDecrement: false,

      fromCoins: null,
      toCoins: null,

      fromBalance: null,
      toBalance: null,

      sidebarHeight: 'calc(100vh - 128px)',

      pendingRewardsCount: undefined,

      leaderboardPlace: undefined
    }
  },

  computed: {
    ...mapState('user', ['user', 'showOfferwallPath', 'gBalance']),
    ...mapState('notifications', ['notifications', 'isNotificationsVisible']),
    ...mapState('chat', ['newMessages']),
    ...mapGetters('viewport', ['isHeaderMobile', 'isDesktop']),
    ...mapGetters('global', ['getPlatformRemoteFeatures']),
    ...mapGetters('user', ['canBeWithdrawnAmount']),

    isShowIntegration () {
      return this.getPlatformRemoteFeatures?.sovcombank
    },

    flagPath () {
      return this.$i18n.locale?.split?.('-')[0] || 'en'
    },

    newMessagesLength () {
      return this.newMessages?.length
    },

    unreadCount () {
      return this.notifications.filter((item) => {
        return item && !item.is_read
      }).length
    },

    isAuthorized () {
      return !!this.user
    },

    isSeller () {
      return this.user?.is_seller
    },

    shouldMinimize () {
      return this.isDesktop || this.isMinimized
    },

    isOfferwallPath () {
      return (
        this.showOfferwallPath ||
        !(
          this.$cookiz.get('offerwall-tickets-dot-hide') &&
          this.$cookiz.get('offerwall-euro-dot-hide')
        )
      )
    },

    frameColor () {
      return this.user?.custom_status?.split('ϴ')[3]
    },

    leaderboardPlaceNumber () {
      return this.leaderboardPlace?.place || '__'
    },

    gBalanceRounded () {
      return (this.gBalance / 100).toFixed(2).replace('.00', '')
    }
  },

  watch: {
    $route () {
      this.toggleStoreNotifications(false)
    },

    'user.coins' (value, prev) {
      this.isCoinsIncrement = false
      this.isCoinsDecrement = false

      this.fromCoins = prev
      this.toCoins = value

      this.$nextTick(() => {
        if (prev && value > prev) {
          this.isCoinsIncrement = true
        } else if (prev && value < prev) {
          this.isCoinsDecrement = true
        }
      })
    },

    'user.balance' (value, prev) {
      this.fromBalance = prev
      this.toBalance = value
    }
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)

    this.fetchCanBeWithdrawn()

    if (this.user) {
      this.$store.dispatch('notifications/fetchNotifications')
      this.$store.dispatch('user/fetchProducts')
    }

    this.throttledCheckPosition = throttle(50, this.checkPosition)

    if (!this.isDesktop) {
      window.addEventListener('scroll', this.throttledCheckPosition)
      this.checkPosition()
    }

    if (this.$route.query.payment_status || this.$route.query.transaction) {
      if (this.$route.path.includes('profile/orders')) {
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            text: this.$t('orderSuccessfullyPaid')
          }
        })
      }
    }

    if (this.isAuthorized) {
      this.getPendingRewardsCount()
    }
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
    window.removeEventListener('scroll', this.throttledCheckPosition)
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('user', ['fetchCanBeWithdrawn']),
    ...mapMutations('notifications', {
      toggleStoreNotifications: 'toggleNotifications'
    }),

    clickMarketplaceEvent () {
      this.$gtag.event('marketplace_click', { account_id: this.user?.id, place: 'header' })
    },

    openPendingModal () {
      this.showModal({
        component: 'ModalPending'
      })
    },

    openModalQualityCheck () {
      this.showModal({
        component: 'ModalQualityCheck'
      })
    },

    onCurrencyClick () {
      this.$router.push(this.localeLocation('/profile/balance'))
    },

    onClickOutside () {
      if (this.isCommunitiesVisible) {
        this.isCommunitiesVisible = false
      }
    },

    toggleCommunities () {
      this.isCommunitiesVisible = !this.isCommunitiesVisible
    },

    toggleCurrency () {
      setTimeout(() => {
        this.isCurrencyVisible = !this.isCurrencyVisible
      }, 100)
    },

    toggleLang () {
      setTimeout(() => {
        this.isLangVisible = !this.isLangVisible
      }, 100)
    },

    checkPosition () {
      if (animationFrameId) {
        window.cancelAnimationFrame(animationFrameId)
      }

      animationFrameId = window.requestAnimationFrame(() => {
        const HEADER_HEIGHT = 64
        // const HEADER_MIN_HEIGHT = 40
        const rect = this.$el.getBoundingClientRect()

        if (rect.top <= 0 && window.scrollY > HEADER_HEIGHT * 2) {
          this.isMinimized = true
        } else {
          this.isMinimized = false
        }

        if (!this.isMinimized && rect.top > 0) {
          this.sidebarHeight = `calc(100vh - ${HEADER_HEIGHT + rect.top}px)`
        }

        // this.$emit('minimized', this.isMinimized)
      })
    },

    toggleWallet () {
      this.$gtag.event('click_header_wallet_button', {})

      if (!this.isAuthorized) {
        return
      }

      this.isWalletVisible = !this.isWalletVisible
      this.toggleStoreNotifications(false)
      this.isMenuVisible = false
    },

    toggleMenu () {
      if (!this.isAuthorized) {
        return
      }

      if (!this.isMenuVisible) {
        this.isLangVisible = false
        this.isCurrencyVisible = false
      }

      setTimeout(() => {
        this.isMenuVisible = !this.isMenuVisible
        this.toggleStoreNotifications(false)
        this.isWalletVisible = false
      }, 100)
    },

    toggleNotifications () {
      if (!this.isAuthorized) {
        return
      }

      this.toggleStoreNotifications()
      this.isMenuVisible = false
      this.isWalletVisible = false
    },

    onSignInClick () {
      this.showModal({ component: 'Auth' })
      this.isMenuVisible = false
    },

    formatCoins (coins) {
      return Math.floor(coins)
    },

    async getPendingRewardsCount () {
      this.pendingRewardsCount = (await PendingRewardsService.getCount(this.$axios))?.data?.data?.count
    }
  }
}
</script>

<style lang="scss">
.header {
  .currency span {
    transition: all 0.2s $easing;
    will-change: auto;
  }

  .currency__icon {
      position: relative;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .currency__icon::after {
      content: "";
      width: 48%;
      height: 48%;
      background: $amber50;
      position: absolute;
      right: 17%;
      top: 15%;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
    }

    &.--increment .currency__icon::after {
      animation: coins-up 0.2s $easing;
      animation-iteration-count: 5;
    }

    &.--decrement .currency__icon::after {
      animation: coins-down 0.2s $easing;
      animation-iteration-count: 5;
      right: auto;
      top: auto;
      left: 13%;
      bottom: 10%;
    }
}
</style>

<style lang="scss" scoped>
.header {
  width: 100% !important;
  display: flex;
  font-size: $text-display;
  height: 64px;
  font-weight: $font-medium;
  background: $blue-grey90;
  color: $blue-grey05;
  border-top: 1px solid $blue-grey50;
  border-bottom: 1px solid $blue-grey50;
  transition: height 0.2s $easing;
  will-change: auto;

  &__leaderboard {
    text-align: center;
    width: 70px;
    margin-right: 10px;
    font-size: 10px;
    border-radius: 6px;
    overflow: hidden;

    @media (min-width: $breakpointTablet) {
      display: none;
    }

    &-img {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 2px;
      background-color: rgba(255, 179, 0, 0.1);

      @media (min-width: $breakpointTablet) {
        flex-direction: column;
        align-items: center;
        width: max-content;
        min-width: 52px;
        padding: 7px 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &-text {
      width: 100%;
      padding: 2px 0 4px;
      font-weight: 400;
      background-color: $blue-grey70;
    }
  }

  &__currency-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $breakpointDesktop) {
      flex-direction: row;
      align-items: center;
      margin-right: 0;
    }
  }

  &__waiting {
    position: relative;
  }

  &__actbtns {
    display: none;

    @media (min-width: $breakpointTablet) {
      display: grid;
      grid-template-columns: repeat(4, max-content);
      grid-gap: 12px;
      grid-template-rows: 40px;
      align-items: center;
    }

    &--desktop {
      display: grid;
      grid-template-columns: repeat(4, max-content);
      grid-gap: 12px;
      grid-template-rows: 40px;
      align-items: center;
    }
  }

  &__not-ico--small {
    height: 24px;
    width: 24px;
  }

  &__act {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 12px;
    background: $blue-grey70;
    border-radius: 8px;

    @media (min-width: $breakpointTablet) {
      position: relative;
    }

    &-link {
      position: relative;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $blue-grey05;

      &.nuxt-link-active {
        color: $primary60;
        padding-left: 10px;

        &::before {
          position: absolute;
          content: "";
          height: 4px;
          width: 4px;
          border-radius: 50%;
          background: $primary60;
          top: 8px;
          left: 0;
        }
      }

      &-exact {
        position: relative;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        color: $blue-grey10;

        &.nuxt-link-exact-active {
          color: $primary60;
          padding-left: 10px;

          &::before {
            position: absolute;
            content: "";
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background: $primary60;
            top: 8px;
            left: 0;
          }
        }
      }
    }

    &-store {
      background-color: #1E88E5;
    }

    &--special {
      padding: 0;
      overflow: hidden;

      span:first-child {
        padding: 10px 12px;
      }
    }
  }

  &__actbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: $blue-grey70;
    border-radius: 8px;

    &-bonus {
      position: absolute;
      top: 0;
      right: 5px;
    }

    &-store {
      margin-right: 10px;
    }

    &-text {
      display: none;

      @media (min-width: $breakpointDesktopWide) {
        display: inline;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $white;
      }
    }
  }

  &-wrapper {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 32px;
    flex-grow: 1;
    will-change: auto;
    transition: all 0.2s $easing;
    position: relative;

    &-popup {
      background: $blue-grey70;
      border: 1px solid $blue-grey40;
      padding: 8px;
      border-radius: 8px;
      position: absolute;
      top: 75%;
      left: 226px;

      @media (max-width: $breakpointDesktopMedium) {
        left: 0;
      }

      &-item {
        @include hint;

        color: #fff;
        display: block;
        padding: 8px;
        cursor: pointer;
      }

      .social-links a {
        @include hint;

        color: #fff;
        height: 44px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }

    &-item {
      @include button2;

      margin-right: 16px;
      cursor: pointer;
      color: #acbbcf;
      font-size: 18px;

      &.nuxt-link-exact-active {
        color: #1e88e5;
      }

      &:last-child {
        margin-right: 0;
      }

      &.--dots {
        display: none;
      }

      @media (max-width: $breakpointDesktopMedium) {
        display: none;

        &.--dots {
          display: block;
        }
      }
    }

    @media (max-width: $breakpointDesktop) {
      margin-left: 24px;
    }

    @media (max-width: $breakpointHeaderMobile) {
      display: none;
    }
  }

  &__logo-box {
    @include flex-center;
    margin-right: 6px;

    height: 100%;
    transition: all 0.2s $easing;
    will-change: auto;
    overflow: hidden;

    @media (min-width: $breakpointTablet) {
      width: 48px;
      margin-right: 0;
    }

    @media (min-width: $breakpointDesktop) {
      width: 180px;
    }

    span {
      @include button2;

      font-size: 16px;
      transition: all 0.2s $easing;
      will-change: auto;
      overflow: hidden;
      opacity: 1;
      width: 124px;
      color: white;
      letter-spacing: 0.5px;
    }
  }

  &__logo {
    @media (min-width: $breakpointDesktop) {
      display: none;
    }
  }

  &__logo-big {
    display: none;

    @media (min-width: $breakpointDesktop) {
      display: block;
    }
  }

  &__product-name {
    padding-left: 4px;
    color: inherit;
  }

  &__currency-box {
    font-size: 12px;
    cursor: pointer;

    @media (min-width: $breakpointTablet) {
      font-size: 16px;
    }
  }

  &__coin-box {
    padding-right: 12px;
    height: 100%;
    transition: all 0.2s $easing;
    will-change: auto;

    @media (min-width: $breakpointTablet) {
      height: auto;
    }

    & svg {
      fill: $amber50;
      position: relative;
      z-index: 2;
    }

    @keyframes coins-up {
      0% {
        opacity: 0;
        transform: translateY(-150%);
      }

      100% {
        transform: translateY(10%);
        opacity: 1;
      }
    }

    @keyframes coins-down {
      0% {
        opacity: 1;
        transform: translateY(-10%);
      }

      100% {
        transform: translateY(150%);
        opacity: 0;
      }
    }
  }

  &__money-box {

    & svg {
      fill: $green50;
      width: 16px;
      height: 16px;
    }

    .header__value-wrap {
      transition: all 0.2s $easing;
      will-change: auto;
    }
  }

  &__ico-box {
    @include flex-center;

    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;
    background: $blue-grey70;
    transition: all 0.2s $easing;
    will-change: auto;

    & svg {
      fill: $white;

      & rect {
        fill: #e9ecf2;
      }
    }

    &:hover {
      background: $blue-grey50;
    }

    &:active {
      background: $blue-grey40;
    }
  }

  &__ui-box {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    grid-gap: 6px;
    padding: 0 6px;
    margin-left: auto;
  }

  &__box {
    border-right: 1px solid $blue-grey50;
    display: flex;
    align-items: center;
  }

  &__lang {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 16px;
    transition: all 0.2s $easing;
    padding: 0 20px;
    margin-right: 8px;

    @media (max-width: $breakpointDesktop) {
      padding: 0 14px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__fav-wrap {
    @include flex-center;

    padding: 0 12px;
    border-right: 1px solid $blue-grey50;
    transition: all 0.2s $easing;
    will-change: auto;

    &-leaderboard {
      @media (min-width: $breakpointDesktop) {
        display: none;
      }
    }
  }

  &__notification-box {
    position: relative;
    padding: 0;
    margin: 0;
    border-right: 0;

    &.header__ico-box {
      &:hover {
        background: $blue-grey50;
      }

      &:active {
        background: $blue-grey40;
      }
    }
  }

  &__notification-counter {
    @include flex-center;

    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    background: $gradient-primary;
    color: $white;
    font-size: 12px;
    font-weight: $font-medium;
    border-radius: 50%;
    line-height: 121.5%;
    text-align: center;
  }

  &__profile-box {
    height: 100%;
    padding: 0 24px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    background: $blue-grey90;
    transition: all 0.2s $easing;
    will-change: auto;

    &:hover {
      background: $blue-grey70;
    }

    &:active {
      background: $blue-grey50;
    }

    &__dot {
      position: absolute;
      top: 8px;
      right: 2px;
      left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      background-color: $pink60;
      border-radius: 50%;
      color: $white;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
    }

    img {
      transition: transform 0.2s $easing;

      &.--open {
        transform: rotate(-180deg);
      }
    }
  }

  &__auth-box {
    height: 100%;
    padding: 0 24px 0 8px;
    display: none;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;

    @media (min-width: $breakpointDesktop) {
      display: flex;
    }
  }

  &__auth-mob {
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;

    @media (min-width: $breakpointDesktop) {
      display: none;
    }
  }

  &__profile-pic-wrap {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 16px;
    box-sizing: border-box;
    transition: all 0.2s $easing;
    will-change: auto;
  }

  &__profile-pic {
    width: 100%;
    height: auto;
    border-radius: 50% !important;
    object-fit: cover;
    transition: all 0.2s $easing;
    will-change: auto;
  }

  &__user-name {
    @include button;
    margin-left: 16px;
    margin-right: 8px;
    color: $blue-grey05;
    transition: all 0.2s $easing;
    will-change: auto;
  }

  &__sign-in.button {
    display: none;
    overflow: hidden;
    height: 48px;
    position: relative;
    width: 213px;

    @media (min-width: $breakpointDesktop) {
      display: block;
    }

    .button__body {
      position: static;
      max-width: 165px;
      transition: all 0.2s $easing;
      height: 100%;
    }

    &:hover .button__body {
      position: relative;
    }

    .button__icon {
      transition: all 0.2s $easing;
      opacity: 0;
      position: absolute;
      top: 3px;
      left: 5px;
    }

    .button__text {
      transition: all 0.2s $easing;
      opacity: 1;
    }
  }

  &.--mini {
    height: 50px;
    padding: 9px 12px;

    @media (min-width: $breakpointTablet) {
      height: 80px;
      padding: 11px 12px 11px;
    }

    .header {
      &__logo-box {

        span {
          width: 0;
          overflow: hidden;
          transition: all 0.2s $easing, width 0.1s $easing;
          opacity: 0;
        }
      }

      &__profile-box {
        padding: 0 12px;
      }

      &__coin-box {
        margin-left: 2px;
      }

      &__ico-box {
        width: 32px;
        height: 32px;
      }

      &__profile-pic {
        border-radius: 8px;
      }

      &__user-name {
        font-size: 14px;
      }
    }
  }
}

.main-link {
  display: flex;
}
</style>
