export default ({ $axios, store }) => {
  $axios.onRequest((config) => {
    const url = config.url

    // for handling url error
    if (url.includes('gtm')) {
      console.log('Request:', url)
    }

    if (!config?.url?.startsWith('http') && store.state.user?.token) {
      config.headers.Authorization = store.state.user.token
    }

    // config.meta = config.meta || {}
    // config.meta.requestStartedAt = new Date().getTime()

    return config
  })

  // $axios.onResponse((x) => {
  //   console.log(
  //     `Execution time for: ${x.config.url} - ${
  //       new Date().getTime() - x.config.meta?.requestStartedAt
  //     } ms`
  //   )
  //   return x
  // })

  $axios.onError((error) => {
    const errorCode = parseInt(error?.response?.status, 10)

    // 401 Unauthorized
    if (errorCode === 401) {
      store.commit('user/setToken', null)
    }
  })
}
