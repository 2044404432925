<template lang="pug">
.modal-leaderboard-info
  h2.modal-leaderboard-info__title {{ $t('yourPosition') }}
  p.modal-leaderboard-info__text {{ $t('everyWeekReward') }}

  .modal-leaderboard-info__container
    InlineSvg(:src="require('~/assets/img/backgrounds/leaderboardPosition.svg')")

  .modal-leaderboard-info__text(v-html="$t('earnDifferentWays')")

  .modal-leaderboard-info__text(v-html="$t('watchYourPosition')")

  Button.modal-leaderboard-info__button(is-primary @click="closeModal") {{ $t('goToLeaderboard') }}
</template>

<script>
import { mapMutations } from 'vuex'

export default {

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle(false)
      this.$router.push(this.localeLocation('/leaderboard'))
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalleaderboardinfo {
  max-width: 420px;

  @media (min-width: $breakpointTablet) {
    padding: 40px 51px !important;
    max-width: 520px;
  }
}
</style>

<style lang="scss" scoped>
.modal-leaderboard-info {
  color: $white;
  text-align: center;

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__container {
    padding: 5px 32px 0;
    margin: 24px auto 24px;
    border: 1px solid #0076FE;
    border-radius: 16px;
  }

  &__button {
    margin-top: 32px;
    width: 100% !important;
  }
}
</style>
