<template lang="pug">
  .layout-default
    LayoutBreak(v-if="false")
    LayoutStandart(v-else)
</template>

<script>
import LayoutStandart from '~/layouts/standart'
import LayoutBreak from '~/layouts/break'

export default {
  name: 'LayoutDefault',

  components: {
    LayoutStandart,
    LayoutBreak
  },

  watch: {
    $route: {
      handler (to, from) {
        const query = { ...this.$route.query }
        query.utm_source = query.utm_source || this.$cookiz.get('utm_source')
        query.utm_medium = query.utm_medium || this.$cookiz.get('utm_medium')
        query.utm_campaign = query.utm_campaign || this.$cookiz.get('utm_campaign')
        query.utm_term = query.utm_term || this.$cookiz.get('utm_term')
        query.utm_content = query.utm_content || this.$cookiz.get('utm_content')
        query.external_id = query.external_id || this.$cookiz.get('keitaroId')
        query.sub1 = query.sub1 || this.$cookiz.get('marketingSub1')
        query.pixel = query.pixel || this.$cookiz.get('pixel')
        query.fbclid = query.fbclid || this.$cookiz.get('fbclid')

        for (const key in query) {
          if (query[key] === undefined || query[key] === null || query[key] === '') {
            delete query[key]
          }
        }

        this.$router.push({ ...to, query: { ...to.query, ...query } })
      },
      immediate: true
    }
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.WWW_HOST + this.$route.path
        },
        {
          name: 'enot',
          content: this.$config.ENOT_CONTENT
        }
      ]
    }
  }
}
</script>
