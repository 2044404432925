<template lang="pug">
  .social-links(:class="{ '--list': isList }")
    a.social-links__social-item(:class="social.icon" v-for="social in socials" :key="social.icon" :href="social.link" target="_blank" rel="noopener noreferrer nofollow")
      InlineSvg(:src="require(`~/assets/img/icons/${social.icon}.svg`)" width="20" :title="social.name")
      span(v-if="isList") {{ social.name }}

</template>

<script>
export default {
  name: 'SocialLinks',

  props: {
    isList: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      socials: [
        {
          name: 'Discord',
          link: 'https://discord.gg/XvagBkhPpS',
          icon: 'discord'
        },
        {
          name: 'Telegram',
          link: 'https://t.me/gaming_goods_com_marketplace',
          icon: 'telegram'
        },
        // {
        //   name: 'VKontakte',
        //   link: 'https://vk.com/vvv_cash',
        //   icon: 'vk',
        // },
        {
          name: 'Facebook',
          link: 'https://www.facebook.com/gaming_goods',
          icon: 'facebook'
        },
        {
          name: 'Twitter',
          link: 'https://twitter.com/gaming_goods',
          icon: 'twitter'
        },
        {
          name: 'YouTube',
          link: 'https://www.youtube.com/channel/UCzSe4KZ0i0X4pey1gbShbfw/',
          icon: 'youtube'
        },
        {
          name: 'Instagram',
          link: 'https://Instagram.com/gaming_goods_com',
          icon: 'instagram'
        }
        // {
        //   name: 'Medium',
        //   link: 'https://medium.com/@gaming-goods.com',
        //   icon: 'medium',
        // },
      ]
    }
  }
}
</script>

<style lang="scss">
.social-links {
  margin: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__social-item {
    svg path {
      fill: $blue-grey20;
      transition: fill 0.2s $easing;
    }

    &:hover svg path,
    &:focus svg path {
      fill: $blue-grey05;
    }

    &.instagram:hover svg path,
    &.instagram:focus svg path {
      fill: #e1306c;
    }

    &.telegram:hover svg path,
    &.telegram:focus svg path {
      fill: #08c;
    }

    &.discord:hover svg path,
    &.discord:focus svg path {
      fill: #5865f2;
    }

    &.vk:hover svg path,
    &.vk:focus svg path {
      fill: #2787f5;
    }

    &.facebook:hover svg path,
    &.facebook:focus svg path {
      fill: #4267b2;
    }

    &.twitter:hover svg path,
    &.twitter:focus svg path {
      fill: #1da1f2;
    }

    &.youtube:hover svg path,
    &.youtube:focus svg path {
      fill: #f00;
    }

    &:active svg path {
      fill: $white;
    }

    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.--list {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;

    .social-links__social-item {
      justify-content: flex-start;
      margin-right: 0;

      svg {
        margin-right: 12px;
      }
    }
  }
}
</style>
