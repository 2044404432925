<template lang="pug">
  .tournaments-list(:class="{'--list': view === 'list', '--cells': view === 'cells', '--white': isWhite}")
    slot(name="title")

    .tournaments-list__body
      //div
      //  script(
      //    async
      //    src="https://dvypar.com/na/waWQiOjExMzMzNDQsInNpZCI6MTE3MDA5Niwid2lkIjo0MDA4NzMsInNyYyI6Mn0=eyJ.js"
      //    crossorigin="anonymous"
      //  )
      //
      //  ins(
      //    class="adsbygoogle"
      //    style="display:block; text-align: center; margin-top: 16px; width: 490px; height: 150px"
      //    data-ad-client="ca-pub-5425286351918334"
      //    data-ad-slot="2928619272"
      //    data-ad-format="auto"
      //    data-full-width-responsive="true"
      //  )

      slot(v-if="!tournaments.length" name="empty")

      transition-group.tournaments-list__body-inner(v-else-if="withTransition" name="fade-scale" duration="200")
        template(v-for="(item, index) in list")
          .tournaments-list__date(v-if="shouldShowDate(item, index)" :key="item.id + 'date'")
            | {{ getItemDate(item) }}
          TournamentCard(:key="item.id" :tournament="item" :view="view" :is-small="isSmall" :is-white="isWhite" always-short)

      .tournaments-list__body-inner(v-else)
        template(v-for="(item, index) in list")
          .tournaments-list__date(v-if="shouldShowDate(item, index)" :key="item.id + 'date'")
            | {{ getItemDate(item) }}
          TournamentCard(:key="item.id" :tournament="item" :view="view" :is-small="isSmall" :is-white="isWhite" always-short)

          //template(v-if="index === 0 && isHeaderMobile")
          //  #AdTrackGenericInterscrollerMobile
          //  script(type="text/javascript" src="https://delivery.r2b2.io/get/vvvgamers.com/generic/interscroller/mobile")

        //.tournaments-list__ad(v-if="adEnabled")
        //  img.tournaments-list__ad-img(src="https://cdn.gaming-goods.com/images/banners/discount%20card.png" alt="Balance icon")
        //
        //  .tournaments-list__ad-container
        //    div
        //      h1.tournaments-list__ad-title {{ $t('subscriptionAdvertModalTitle') }}
        //      p.tournaments-list__ad-text {{ $t('subscriptionAdvertModalSubtitle') }}
        //    Button.tournaments-list__ad-button#tournaments-list__ad-button(is-primary @click="goToSubscription") {{ $t('buy') }}

        //.tournaments-list__ad(v-if="adEnabled && !isHeaderMobile")
        //  #AdTrackGeneric300x250Desktop
        //  script(type="text/javascript" src="https://delivery.r2b2.io/get/vvvgamers.com/generic/300x250")

</template>

<script>
import { mapGetters } from 'vuex'
import TournamentCard from '~/components/TournamentCard/TournamentCardNewDesign'

export default {
  name: 'TournamentsList',

  components: {
    TournamentCard
  },

  props: {
    tournaments: {
      type: Array,
      required: true
    },

    view: {
      type: String,
      default: 'cells'
    },

    withTransition: {
      type: Boolean,
      default: true
    },

    withDate: {
      type: Boolean,
      default: false
    },

    isSmall: {
      type: Boolean,
      default: false
    },

    isWhite: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('viewport', ['isHeaderMobile']),

    list () {
      return this.tournaments
    },

    adEnabled () {
      return true
    }
  },

  methods: {
    shouldShowDate (item, index) {
      if (!this.withDate) {
        return false
      }

      if (index === 0) {
        return true
      }

      const prevItem = this.list[index - 1]
      let date = item?.date.replace(' ', 'T') + 'Z'
      date = this.$dateFns.parseISO(date)
      let prevDate = prevItem?.date.replace(' ', 'T') + 'Z'
      prevDate = this.$dateFns.parseISO(prevDate)

      return !this.$dateFns.isSameDay(date, prevDate)
    },

    getItemDate (item) {
      let date = item?.date?.replace(' ', 'T') + 'Z'
      date = this.$dateFns.parseISO(date)

      if (this.$dateFns.isYesterday(date)) {
        return (
          this.$dateFns.format(date, 'd MMMM — ', {
            locale: this.$i18n.locale
          }) + this.$t('yesterday')
        )
      }

      if (this.$dateFns.isToday(date)) {
        return (
          this.$dateFns.format(date, 'd MMMM — ', {
            locale: this.$i18n.locale
          }) + this.$t('today')
        )
      }

      if (this.$dateFns.isTomorrow(date)) {
        return (
          this.$dateFns.format(date, 'd MMMM — ', {
            locale: this.$i18n.locale
          }) + this.$t('tomorrow')
        )
      }

      const dayOfWeek = this.$dateFns.format(date, 'EEEE', {
        locale: this.$i18n.locale
      })

      return (
        this.$dateFns.format(date, 'd MMMM — ', {
          locale: this.$i18n.locale
        }) +
        dayOfWeek[0]?.toUpperCase() +
        dayOfWeek.substring(1)
      )
    },

    goToSubscription () {
      this.$router.push(this.localeLocation('/store/subscriptions?purchase=6'))
      this.$store.commit('modal/toggle')
    }
  }
}
</script>

<style lang="scss">
.tournaments-list {
  &__date {
    @include heading3;

    color: #fff;
    padding: 4px 0;
    grid-column: 1 / span 3;

    .--list & {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    @media (max-width: $breakpointMobile) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__body {
    width: 100%;

    &-inner {
      width: 100%;
      display: grid;
      column-gap: 20px;
      row-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 32px;

      @media (max-width: $breakpointDesktop) {
        margin-top: 24px;
      }
    }
  }

  &.--list {
    .tournaments-list__body {
      &-inner {
        display: block;
      }
    }
  }

  &__ad {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: $blue-grey70;

    @media (min-width: 650px) and (max-width: $breakpointDesktop) {
      flex-direction: row;
      padding-bottom: 0;
    }

    &.--extra {
      background-color: unset;
      justify-content: center;
      align-items: center;
    }

    &-img {
      width: 100%;
      max-height: 292px;
      border-radius: 8px;
      object-fit: cover;

      @media (min-width: 650px) and (max-width: $breakpointDesktop) {
        width: 260px;
        height: auto;
        overflow: hidden;
        flex-shrink: 0;
      }
    }

    &-container {
      background-color: $blue-grey70;
      margin-top: -36px;
      padding: 0 16px;
      color: $white;

      @media (min-width: 650px) and (max-width: $breakpointDesktop) {
        display: flex;
        margin-top: 0;
      }
    }

    &-title {
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 16px;

      @media (min-width: 650px) and (max-width: $breakpointDesktop) {
        font-size: 22px;
        margin-bottom: 12px;
      }
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;

      @media (min-width: 650px) and (max-width: $breakpointDesktop) {
        margin-bottom: 0;
        font-size: 14px;
      }
    }

    &-link {
      cursor: pointer;
    }

    &-button {
      width: 100% !important;
      margin: 32px 0 0 !important;

      @media (min-width: 650px) and (max-width: $breakpointDesktop) {
        max-width: 60px;
        margin-left: 12px !important;
      }
    }
  }

  &.--cells {
    @media (max-width: $breakpointDesktop) {
      .tournaments-list {
        &__body {
          &-inner {
            @include scroll-none;

            display: flex;
            overflow-x: auto;
            column-gap: 0;
            width: calc(100% + 64px);
            margin-left: -32px;

            &::after {
              content: "";
              min-width: 16px;
            }

            .tournament-card {
              margin-right: 16px;
              min-width: 340px;

              &:first-child {
                margin-left: 32px;
              }
            }
          }
        }
      }
    }

    &.--white {
      background: $white;
    }

    @media (max-width: $breakpointTablet) {
      .tournaments-list {
        &__body {
          &-inner {
            width: calc(100% + 48px);
            margin-left: -24px;

            &::after {
              min-width: 16px;
            }

            .tournament-card {
              margin-right: 8px;

              &:first-child {
                margin-left: 24px;
              }
            }
          }
        }
      }
    }

    @media (max-width: $breakpointMobile) {
      .tournaments-list {
        &__body {
          &-inner {
            display: block;
            width: 100%;
            margin-left: 0;

            &::after {
              display: none;
            }

            .tournament-card {
              margin-left: 0;
              margin-right: 0;
              min-width: 0;
              width: 100%;
              margin-bottom: 16px;

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
